<template>
    <TreeSelect :options="dictItems" @nodeSelect="onNodeSelect" showClear="true" @nodeExpand="onNodeExpand"
        :placeholder="placeholdertxt" selectionMode="single" v-model="selectedItem" :id="id"></TreeSelect>
</template>
<script>
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    export default {
        name: 'CalfTreeDropdown',
        emits: ['update:selectedValue', 'update:selectedTxt'],
        created() {
            this.loadInit();
        },
        props: ['selectedValue', 'selectedTxt', 'dictObjName', 'id', 'placeholder'],
        data() {
            return {
                selectedItem: null,
                placeholdertxt: '请选择',
                dictItems: []
            }
        },
        methods: {
            loadInit() {
                if (this.placeholder && this.placeholder != '') {
                    this.placeholdertxt = this.placeholder;
                }
                if (this.selectedValue != null && this.selectedValue != '' && this.selectedValue != '-1') {
                    this.selectedItem = {};
                    this.selectedItem[this.selectedValue] = true;
                }
                this.loadDict('-1');
            },
            clearSelect() {
                this.selectedItem = {};
            },
            onNodeSelect(v_node) {
                console.log('onNodeSelect....');
                console.log(this.selectedTxt);
                console.log(v_node);
                this.$emit('update:selectedValue', v_node.key);
                this.$emit('update:selectedTxt', v_node.label);
                console.log(v_node);
            },
            onNodeExpand(v_node) {
                console.log('onNodeExpand');
                console.log(v_node);
                if (!v_node.children || v_node.children.length == 0) {
                    this.loadDict(v_node.key, v_node);
                }
            },
            loadDict(v_parentid, v_nodes) {
                var dictObjarray = this.dictObjName.split('^');
                var dictName = dictObjarray[0];
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: v_parentid,
                        operation: '='
                    }]
                };
                if (dictObjarray.length > 1) {
                    dictlistwhere.conditions.push({
                        name: 'Categories',
                        value: dictObjarray[1],
                        operation: '='
                    });
                }
                var graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                    '){key label data parentid icon leaf children {key label data parentid icon leaf} }}';
                if (dictName == 'dictionary') {
                    graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                        '){key label data parentid icon leaf children {key label data parentid icon leaf} }}';
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        var dictData = dataObj[dictName + 'list'];
                        if (v_parentid == "-1") {
                            if (dictName == 'dictionary') {
                                this.dictItems = dictData[0].details;
                            } else {
                                this.dictItems = dictData;
                            }
                        } else {
                            if (!v_nodes.children) v_nodes['children'] = [];
                            if (dictName == 'dictionary') {
                                for (var k1 = 0; k1 < dictData[0].details.length; k1++) {
                                    v_nodes.children.push(dictData[0].details[k1]);
                                }
                            } else {
                                for (var k2 = 0; k2 < dictData.length; k2++) {
                                    v_nodes.children.push(dictData[k2]);
                                }
                            }
                        }
                    } else {
                        MessageTip.warnmsg(dictName + '字典数据加载失败');
                    }
                });
            },
        }
    }
</script>