<template>
    <div class="layout-menu-container">
        <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
    </div>
</template>

<script>
    import AppSubmenu from './AppSubmenu';

    export default {
        props: {
            model: Array,
        },
        methods: {
            onMenuItemClick(event) {
                this.$emit('menuitem-click', event);
            },
            bannerImage() {
                return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' :
                    'images/banner-primeblocks.png';
            },
        },
        computed: {
            darkTheme() {
                return this.$appState.darkTheme;
            }
        },
        components: {
            'AppSubmenu': AppSubmenu
        }
    }
</script>

<style scoped>

</style>