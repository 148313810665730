import {
    createApp
} from 'vue';
import messageTipVue from './Message.vue';
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';



import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const MessageTipInstance = createApp(messageTipVue);
MessageTipInstance.use(PrimeVue);
MessageTipInstance.use(ToastService);
MessageTipInstance.component('Toast', Toast);
const currentMsg = MessageTipInstance.mount('#app');

export default currentMsg;