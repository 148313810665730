const ExpensePayJs = {
    currencyChange(v_childelements, v_val, v_dictsource, v_allmainelements) {
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'paymoney') {
                    v_childelements[i].columns[i2].header = '费用金额(' + ename + ')';
                }
                if (v_childelements[i].columns[i2].field == 'invoiceamount') {
                    v_childelements[i].columns[i2].header = '发票金额(' + ename + ')';
                }
            }
        }
        if (v_allmainelements) {
            var allPayMoney = this.getAllCost(v_childelements);
            var exchangeRate = 1;
            for (var k2 = 0; k2 < v_allmainelements.length; k2++) {
                if (v_allmainelements[k2]['name'] == 'exchangerate') {
                    exchangeRate = Number(v_allmainelements[k2]['val']);
                    break;
                }
            }
            for (var k1 = 0; k1 < v_allmainelements.length; k1++) {
                if (v_allmainelements[k1]['name'] == 'allpaymoney') {
                    v_allmainelements[k1]['val'] = allPayMoney;
                }
                if (v_allmainelements[k1]['name'] == 'allpaymoneyhc') {
                    if (!isNaN(exchangeRate)) {
                        v_allmainelements[k1]['val'] = (allPayMoney * exchangeRate).toFixed(2);
                    }
                }
            }
        }
    },
    getAllCost(v_allchildements) {
        var allcost = 0;
        for (var k = 0; k < v_allchildements.length; k++) {
            if (v_allchildements[k]['name'] == 'paydetail') {
                for (var k1 = 0; k1 < v_allchildements[k].rows.length; k1++) {
                    var costamount = Number(v_allchildements[k].rows[k1]['paymoney']);
                    if (!isNaN(costamount)) {
                        allcost = allcost + costamount;
                    }
                }
                break;
            }
        }
        return allcost;
    },
    costcalculate(rowdata) {
        var taxratenum = Number(rowdata['taxrate']);
        var invoiceamountnum = Number(rowdata['invoiceamount']);
        console.log('costcalculate..');
        console.log(taxratenum);
        console.log(invoiceamountnum);
        if (!isNaN(taxratenum) && !isNaN(invoiceamountnum)) {
            if (taxratenum < 0) taxratenum = 0;
            rowdata['paymoney'] = invoiceamountnum * (1 - taxratenum).toFixed(2);
        }
        console.log(rowdata['paymoney']);
    },
};

export default ExpensePayJs;