<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <div v-if="qurylistelements2.length>0 || qurylistchildelements2.length>0" class="grid p-fluid mt-1">
            <template v-for="item in qurylistelements2" :key="item.name">
                <div class="field col-3 md:col-2">
                    <span class="p-float-label">
                        <Calendar v-if="item.type=='time2'" :showTime="true" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <Calendar v-if="item.type=='time'" :showTime="false" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <InputText v-if="item.type=='input'" :id="item.name" v-model="item.val" type="text" />
                        <Dropdown v-if="item.type=='drop'" showClear="true" v-model="item.val" :id="item.name"
                            :options="getDictData(item.dictName)" optionLabel="name" optionValue="code" />
                        <CalfInputDropdown v-if="item.type=='dropinput'" v-model:selectedValue="item.val"
                            v-model:selectedTxt="item.dictLabel" :id="item.name" :dictObjName="item.dictName" />
                        <LinkageDropDown v-if="item.type=='linkagedrop'"
                            v-model:selectedValue1="querydata[item.dictCode]"
                            v-model:selectedValue2="querydata[item.dictCode2]"
                            v-model:selectedValue3="querydata[item.dictCode3]" :bindselectedTxt="false"
                            :currentfieldname="item.name" :currentitem="item" :dictItems="getDictData(item.dictName)" />
                        <label :for="item.name">{{item.label}}</label>
                    </span>
                </div>
            </template>
            <template v-for="item in qurylistchildelements2" :key="item.name">
                <div class="field col-3 md:col-2">
                    <span class="p-float-label">
                        <Calendar v-if="item.type=='time2'" :showTime="true" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <Calendar v-if="item.type=='time'" :showTime="false" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <InputText v-if="item.type=='input'" :id="item.name" v-model="item.val" type="text" />
                        <Dropdown v-if="item.type=='drop'" showClear="true" v-model="item.val" :id="item.name"
                            :options="getDictData(item.dictName)" optionLabel="name" optionValue="code" />
                        <CalfInputDropdown v-if="item.type=='dropinput'" v-model:selectedValue="item.val"
                            v-model:selectedTxt="item.dictLabel" :id="item.name" :dictObjName="item.dictName" />
                        <LinkageDropDown v-if="item.type=='linkagedrop'"
                            v-model:selectedValue1="querydata[item.dictCode]"
                            v-model:selectedValue2="querydata[item.dictCode2]"
                            v-model:selectedValue3="querydata[item.dictCode3]" :bindselectedTxt="false"
                            :currentfieldname="item.name" :currentitem="item" :dictItems="getDictData(item.dictName)" />
                        <label :for="item.name">{{item.label}}</label>
                    </span>
                </div>
            </template>
        </div>
        <div class="grid p-fluid mt-0">
            <div class="field col-12 md:col-5">
                &nbsp;&nbsp;
            </div>
            <div class="field col-12 md:col-4">
                &nbsp;&nbsp;
            </div>
            <div class="field col-12 md:col-1">
                <Button label="查询" icon="pi pi-search" @click="loadData(1)" class="ml-auto" />
            </div>
            <div class="field col-12 md:col-1">
                <Button label="新建" icon="pi pi-plus" @click="createdetail()" class="ml-auto" />
            </div>
            <div class="field col-12 md:col-1">
                <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
            </div>
        </div>
    </div>
    <div class="card">
        <DataTable :value="universalPageList" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows scrollHeight="650px" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="50" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <Column :field="firstColName" :header="firstColLabel" style="flex-grow:1; flex-basis:300px" frozen>
                <template #body="slotProps">
                    <Button :label="formatfirstData(slotProps.data[firstColName],slotProps.data,firstColName)"
                        @click="opendetail(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
            <Column v-for="item in cols" :key="item.name" :field="item.name" :header="item.label"
                style="flex-grow:1; flex-basis:200px">
                <template #body="slotProps">
                    {{formatData(item,slotProps.data[item.name],slotProps.data,item.name)}}
                </template>
            </Column>
        </DataTable>
    </div>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="displayMaximizable" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <UniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button v-if="flowguid!=''" :label="currentState" icon="pi pi-sitemap"
                    class="p-button-raised p-button-text mr-2" @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import commonJs from '../js/Common';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import UniversalPage from '../components/UniversalPage.vue';
    import FlowHistory from './FlowHistory.vue';
    import MessageTip from './Message';
    import CommonJs from '../js/Common.js';
    import CalfInputDropdown from './CalfInputDropdown.vue';
    import LinkageDropDown from '../components/LinkageDropDown.vue';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm
            };
        },
        data() {
            return {
                selectedData: ref(),
                readOnly: true,
                flowguid: '',
                flowid: '',
                startnodeid: '',
                moduleName: '',
                firstColLabel: '',
                firstColName: '',
                firstColType: '',
                firstdictCode: '',
                firstdictCode2: '',
                firstdictCode3: '',
                graphqlFeild: '',
                qurylistelements: [],
                qurylistchildelements: [],
                qurylistelements2: [],
                qurylistchildelements2: [],
                currentRow: null,
                flowhistoryJson: null,
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                recordsubject: '',
                currentState: '',
                totalRecords: 0,
                first: 0,
                universalPageList: [],
                cols: [],
                dicts: {},
                querydata: {},
                notInputElement: ['DateString', 'LabelString', 'DateTimeString', 'Dictionary', 'AtuNumber', 'TreeDict',
                    'InputDict', 'LinkageDict', 'UpLoad'
                ],
                timeType: ['DateString', 'DateTimeString'],
                flowInfoType: ['flowhistorygroup', 'approvalresult'],
                flowViewType: ['shenpiview', 'tuihuiview', 'shenheview', 'banliview'],
                baseType: ['String', 'ID', 'Decimal', 'Int', 'Long', 'DateString', 'DateTimeString',
                    'Dictionary', 'AtuNumber', 'LabelString', 'TreeDict', 'InputDict', 'Textarea', 'LinkageDict',
                    'UpLoad'
                ],
                excludeElements: ['ischeck', 'isdel'],
            }
        },
        created() {
            this.initPara();
        },
        watch: {
            $route() {
                console.log('UniversalPageList--fullPath:' + this.$route.fullPath);
                this.initPara();
            }
        },
        methods: {
            initPara() {
                this.selectedData = ref();
                this.moduleName = '';
                this.flowguid = '';
                this.flowid = '';
                this.startnodeid = '';
                this.firstColLabel = '';
                this.firstColName = '';
                this.firstColType = '';
                this.firstDictName = '';
                this.firstDictLabel = '';
                this.firstdictCode = '';
                this.firstdictCode2 = '';
                this.firstdictCode3 = '';
                this.currentRow = null;
                this.flowhistoryJson = null;
                this.loading = false;
                this.butLoading = false;
                this.displayMaximizable = false;
                this.displayPosition = false;
                this.position = 'bottomleft';
                this.recordsubject = '';
                this.currentState = '';
                this.totalRecords = 0;
                this.first = 0;
                this.universalPageList = [];
                this.cols = [];
                this.dicts = {};
                this.querydata = {};
                this.qurylistelements = [];
                this.qurylistelements2 = [];
                this.qurylistchildelements = [];
                this.qurylistchildelements2 = [];
                this.graphqlFeild = '';
                this.moduleName = this.$route.query.module;
                if (this.$route.query.flowguid) {
                    this.flowguid = this.$route.query.flowguid;
                    if (this.flowguid != '')
                        this.loadFlowBaseInfo(this.flowguid);
                } else {
                    this.flowguid = '';
                }
                this.loadModuleStructure(this.moduleName);
            },
            loadData(v_page) {
                this.loading = true;
                this.loadListData(v_page, this.moduleName);
            },
            loadModuleStructure(v_moduleName) {
                if (v_moduleName && v_moduleName != null && typeof (v_moduleName) != 'undefined') {
                    console.log('UniversalPageList--loadModuleStructure');
                    console.log(this.currentRow);
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{__type(name: "' + v_moduleName +
                        '"){name description dictconfiginfo queryconfiginfo fields { name description dictconfiginfo queryconfiginfo type { name description }} } }'
                    ).then(res => {
                        if (res.errcode == "0") {
                            var moduleObj = JSON.parse(unescape(Base64.decode(res.data))).data.__type;
                            this.loadModuleData(moduleObj);
                        } else {
                            MessageTip.warnmsg('类型加载失败');
                        }
                    });
                }
            },
            loadFlowBaseInfo(v_flowguid) {
                if (this.flowid == '') {
                    var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'searchflowguid',
                            value: v_flowguid,
                            operation: ''
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{flowbaseinfolist(where:' + JSON.stringify(listwhere) +
                        '){flowid startnodeid }}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            var flowbaseObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                .flowbaseinfolist;
                            if (flowbaseObj.length > 0) {
                                this.flowid = flowbaseObj[0].flowid;
                                this.startnodeid = flowbaseObj[0].startnodeid;
                            }
                        } else {
                            MessageTip.warnmsg('流程基本信息加载失败');
                        }
                    });
                }
            },
            loadModuleData(v_moduleObj) {
                var regx = /^[^A-Za-z0-9]*$/;
                var graphqlFeild = '';
                var childStructure = [];
                if (v_moduleObj && v_moduleObj.fields) {
                    for (var i = 0; i < v_moduleObj.fields.length; i++) {
                        if (v_moduleObj.fields[i].queryconfiginfo && v_moduleObj.fields[i].queryconfiginfo != '') {
                            var queryArray = v_moduleObj.fields[i].queryconfiginfo.split('|');
                            var queryDictNameStr = '';
                            var querydictCode = '';
                            var querydictCode2 = '';
                            var querydictCode3 = '';
                            if (v_moduleObj.fields[i].type.name == 'Dictionary') {
                                queryDictNameStr = v_moduleObj.fields[i].dictconfiginfo;
                            }
                            if (v_moduleObj.fields[i].type.name == 'InputDict') {
                                var queryDictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                queryDictNameStr = queryDictArray[0];
                            }
                            if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                var queryDictArray2 = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                queryDictNameStr = queryDictArray2[0];
                                querydictCode = queryDictArray2[1];
                                if (queryDictArray2.length > 3) {
                                    querydictCode2 = queryDictArray2[3];
                                }
                                if (queryDictArray2.length > 5)
                                    querydictCode3 = queryDictArray2[5];
                            }
                            this.qurylistelements.push({
                                type: queryArray[2],
                                name: queryArray[0],
                                operation: queryArray[1],
                                val: '',
                                dictName: queryDictNameStr,
                                dictCode: querydictCode,
                                dictLabel: '',
                                dictCode2: querydictCode2,
                                dictLabel2: '',
                                dictCode3: querydictCode3,
                                dictLabel3: '',
                                label: queryArray[3],
                            });
                        }
                        if (this.baseType.includes(v_moduleObj.fields[i].type.name) && !this.excludeElements.includes(
                                v_moduleObj.fields[i].name)) {
                            graphqlFeild = graphqlFeild + v_moduleObj.fields[i].name + ' ';
                            var dictNameStr = '';
                            var dictLabelStr = '';
                            var dictCode = '';
                            var dictCode2 = '';
                            var dictCode3 = '';
                            if (v_moduleObj.fields[i].type.name == 'Dictionary') {
                                this.addDictCategories(v_moduleObj.fields[i].dictconfiginfo);
                                dictNameStr = v_moduleObj.fields[i].dictconfiginfo;
                            }
                            if (v_moduleObj.fields[i].type.name == 'InputDict') {
                                var dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                dictNameStr = dictArray[0];
                                dictLabelStr = dictArray[1];
                            }
                            if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                var dictArray2 = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                dictNameStr = dictArray2[0];
                                dictCode = dictArray2[1];
                                this.addDictCategories(dictNameStr);
                                if (dictArray2.length > 3) {
                                    dictCode2 = dictArray2[3];
                                }
                                if (dictArray2.length > 5)
                                    dictCode3 = dictArray2[5];
                            }
                            if (v_moduleObj.fields[i].name == 'currentstatename') {
                                v_moduleObj.fields[i].description = '当前节点';
                            }
                            console.log('1:' + v_moduleObj.fields[i].description);
                            if (regx.test(v_moduleObj.fields[i].description)) {
                                console.log('2:' + v_moduleObj.fields[i].description);
                                if (this.firstColName == '') {
                                    this.firstColName = v_moduleObj.fields[i].name;
                                    this.firstColLabel = v_moduleObj.fields[i].description;
                                    this.firstColType = v_moduleObj.fields[i].type.name;
                                    this.firstDictName = dictNameStr;
                                    this.firstDictLabel = dictLabelStr;
                                    this.firstdictCode = dictCode;
                                    this.firstdictCode2 = dictCode2;
                                    this.firstdictCode3 = dictCode3;
                                } else {
                                    this.cols.push({
                                        name: v_moduleObj.fields[i].name,
                                        label: v_moduleObj.fields[i].description,
                                        type: v_moduleObj.fields[i].type.name,
                                        dictName: dictNameStr,
                                        dictLabel: dictLabelStr,
                                        dictCode: dictCode,
                                        dictCode2: dictCode2,
                                        dictCode3: dictCode3,
                                    });
                                }
                            }
                        } else {
                            if (v_moduleObj.fields[i].type.name.substr(0, 1) == '[' && v_moduleObj.fields[i]
                                .type.name != '[flowhistorygroup]') {
                                var childStructureobj = {
                                    name: v_moduleObj.fields[i].name,
                                    typename: v_moduleObj.fields[i].type.name.replace('[', '').replace(']', '')
                                };
                                childStructure.push(childStructureobj);
                            }
                        }
                    }
                }
                this.graphqlFeild = graphqlFeild;
                if (childStructure.length > 0) {
                    for (var k = 0; k < childStructure.length; k++) {
                        var islast = false;
                        if (k == childStructure.length - 1) {
                            islast = true;
                        }
                        this.loadChildStructure(childStructure[k], islast);
                    }
                } else {
                    this.loadDict();
                }
            },
            loadChildStructure(v_childStructureObj, v_islast) {
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{__type(name: "' + v_childStructureObj.typename +
                        '"){name description queryconfiginfo fields { name dictconfiginfo description queryconfiginfo type { name description }} } }'
                    )
                    .then(res => {
                        if (res.errcode == "0") {
                            var childModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data.__type;
                            childModuleObj.name = v_childStructureObj.name;
                            for (var i = 0; i < childModuleObj.fields.length; i++) {
                                if (childModuleObj.fields[i].queryconfiginfo && childModuleObj.fields[i]
                                    .queryconfiginfo != '') {
                                    var queryArray = childModuleObj.fields[i].queryconfiginfo.split('|');
                                    var queryDictNameStr = '';
                                    var querydictCode = '';
                                    var querydictCode2 = '';
                                    var querydictCode3 = '';
                                    if (childModuleObj.fields[i].type.name == 'Dictionary') {
                                        this.addDictCategories(childModuleObj.fields[i].dictconfiginfo);
                                        queryDictNameStr = childModuleObj.fields[i].dictconfiginfo;
                                    }
                                    if (childModuleObj.fields[i].type.name == 'InputDict') {
                                        var queryDictArray = childModuleObj.fields[i].dictconfiginfo.split('|');
                                        queryDictNameStr = queryDictArray[0];
                                    }
                                    if (childModuleObj.fields[i].type.name == 'LinkageDict') {
                                        var queryDictArray2 = childModuleObj.fields[i].dictconfiginfo.split('|');
                                        this.addDictCategories(queryDictArray2[0]);
                                        queryDictNameStr = queryDictArray2[0];
                                        querydictCode = queryDictArray2[1];
                                        if (queryDictArray2.length > 3) {
                                            querydictCode2 = queryDictArray2[3];
                                        }
                                        if (queryDictArray2.length > 5)
                                            querydictCode3 = queryDictArray2[5];
                                    }
                                    this.qurylistchildelements.push({
                                        objname: childModuleObj.name,
                                        type: queryArray[2],
                                        name: queryArray[0],
                                        operation: queryArray[1],
                                        val: '',
                                        dictName: queryDictNameStr,
                                        dictCode: querydictCode,
                                        dictLabel: '',
                                        dictCode2: querydictCode2,
                                        dictLabel2: '',
                                        dictCode3: querydictCode3,
                                        dictLabel3: '',
                                        label: queryArray[3],
                                    });
                                }
                            }
                            if (v_islast) {
                                this.loadDict();
                            }
                        } else {
                            MessageTip.warnmsg(v_childStructureObj.typename + '类型加载失败');
                        }
                    });
            },
            loadListData(v_page, v_moduleName) {
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [],
                    child: []
                };
                console.log('loadListData');
                console.log(this.querydata);
                for (var i = 0; i < this.qurylistelements2.length; i++) {
                    if (this.qurylistelements2[i].type == 'linkagedrop') {
                        if (this.querydata[this.qurylistelements2[i].name.toLowerCase()] && this.querydata[this
                                .qurylistelements2[i].name.toLowerCase()] != '-1') {
                            listwhere.conditions.push({
                                name: this.qurylistelements2[i].name,
                                value: this.querydata[this.qurylistelements2[i].name.toLowerCase()],
                                operation: this.qurylistelements2[i].operation,
                            });
                        }
                    } else {
                        listwhere.conditions.push({
                            name: this.qurylistelements2[i].name,
                            value: this.qurylistelements2[i].val,
                            operation: this.qurylistelements2[i].operation,
                        });
                    }
                }
                console.log(this.qurylistchildelements2);
                for (var k = 0; k < this.qurylistchildelements2.length; k++) {
                    var conditionObj = null;
                    if (this.qurylistchildelements2[k].type == 'linkagedrop') {
                        if (this.querydata[this.qurylistchildelements2[k].name.toLowerCase()] && this.querydata[this
                                .qurylistchildelements2[k].name.toLowerCase()] != '-1') {
                            conditionObj = {
                                name: this.qurylistchildelements2[k].name,
                                value: this.querydata[this.qurylistchildelements2[k].name
                                    .toLowerCase()],
                                operation: this.qurylistchildelements2[k].operation,
                            };
                        }
                    } else {
                        conditionObj = {
                            name: this.qurylistchildelements2[k].name,
                            value: this.qurylistchildelements2[k].val,
                            operation: this.qurylistchildelements2[k].operation,
                        };
                    }
                    console.log(conditionObj);
                    if (conditionObj) {
                        var isFind = false;
                        for (var k1 = 0; k1 < listwhere.child.length; k1++) {
                            if (listwhere.child[k1].objname == this.qurylistchildelements2[k].objname) {
                                isFind = true;
                                listwhere.child[k1].conditions.push(conditionObj);
                                break;
                            }
                        }
                        if (!isFind) {
                            listwhere.child.push({
                                objname: this.qurylistchildelements2[k].objname,
                                conditions: [conditionObj],
                            });
                        }
                    }
                }
                console.log(listwhere);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{' + v_moduleName + 'list(where:' + JSON.stringify(listwhere) + '){' + this.graphqlFeild + '}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        if (jsonData.data[v_moduleName + 'list'] && jsonData.data[v_moduleName + 'list']
                            .length > 0) {
                            for (var i = this.cols.length - 1; i >= 0; i--) {
                                if (jsonData.data[v_moduleName + 'list'][0][this.cols[i].name] == null) {
                                    this.cols.splice(i, 1);
                                }
                            }
                        }
                        this.universalPageList = jsonData.data[v_moduleName + 'list'];
                        console.log(this.universalPageList);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                for (var proname in this.dicts) {
                    if (proname != '') {
                        var dictObjarray = proname.split('^');
                        if (dictObjarray.length > 1) {
                            dictlistwhere.conditions.push({
                                name: 'Categories',
                                value: dictObjarray[1],
                                operation: ''
                            });
                        } else {
                            dictlistwhere.conditions.push({
                                name: 'Categories',
                                value: dictObjarray[0],
                                operation: ''
                            });
                        }
                    }
                }
                if (dictlistwhere.conditions.length > 0) {
                    var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                        '){name details {name code child {name code child {name code child} } }}}';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                                this.dicts[dataObj.dictionarylist[k].name] = dataObj.dictionarylist[k].details;
                            }
                            for (var k1 = 0; k1 < this.qurylistelements.length; k1++) {
                                this.qurylistelements2.push(this.qurylistelements[k1]);
                            }
                            for (var k2 = 0; k2 < this.qurylistchildelements.length; k2++) {
                                this.qurylistchildelements2.push(this.qurylistchildelements[k2]);
                            }
                            this.loadData(1);
                        } else {
                            MessageTip.warnmsg('字典数据加载失败');
                        }
                    });
                } else {
                    for (var k1 = 0; k1 < this.qurylistelements.length; k1++) {
                        this.qurylistelements2.push(this.qurylistelements[k1]);
                    }
                    for (var k2 = 0; k2 < this.qurylistchildelements.length; k2++) {
                        this.qurylistchildelements2.push(this.qurylistchildelements[k2]);
                    }
                    this.loadData(1);
                }
            },
            addDictCategories(v_dictName) {
                if (v_dictName != '') {
                    var array = v_dictName.split('|');
                    var dictObjarray = array[0].split('^');
                    if (dictObjarray.length > 1) {
                        this.dicts[dictObjarray[1]] = [];
                    } else {
                        this.dicts[dictObjarray[0]] = [];
                    }
                }
            },
            getDictData(v_dictName) {
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                var dictData = [];
                if (this.dicts[dictName]) {
                    dictData = this.dicts[dictName];
                }
                console.log("dictName:" + v_dictName);
                console.log(dictData);
                return dictData;
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            opendetail(v_row) {
                v_row['bindpage'] = this.moduleName;
                v_row['flowguid'] = this.flowguid;
                if (!v_row.workflowid) {
                    v_row['workflowid'] = '-1';
                }
                if (!v_row.boxid) {
                    v_row['boxid'] = '-1';
                }
                this.readOnly = false;
                if (v_row.readonly && v_row.readonly == '1') {
                    this.readOnly = true;
                }
                this.butLoading = false;
                this.currentRow = v_row;
                var recordtitle = this.formatfirstData(v_row[this.firstColName], v_row, this.firstColName);
                this.recordsubject = recordtitle;
                this.currentState = '流程信息';
                if (v_row.currentstate) {
                    this.currentState = '流程信息-当前环节:' + v_row.currentstate;
                }
                if (v_row.bindpage != '') {
                    this.displayMaximizable = true;
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            createdetail() {
                var row = {
                    bindpage: this.moduleName,
                    id: '-1',
                    workflowid: '-1',
                    boxid: '-1',
                    flowguid: this.flowguid,
                    stateid: this.startnodeid,
                    flowid: this.flowid
                };
                this.readOnly = false;
                this.currentRow = row;
                this.recordsubject = '新建';
                this.currentState = '流程信息';
                if (row.bindpage != '') {
                    this.displayMaximizable = true;
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['id']) {
                    this.loading = true;
                    console.log(this.firstColName);
                    var id = this.selectedData['id'];
                    var title = this.selectedData[this.firstColName];
                    console.log(title + '---' + id);
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{' + this.moduleName + '(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.universalPageList.length; i++) {
                                        if (this.universalPageList[i]['id'] == id) {
                                            this.universalPageList.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            freshList() {
                this.loadData(1);
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            formatfirstData(value, v_data, v_currentfieldname) {
                var colobj = {
                    name: v_currentfieldname,
                    type: this.firstColType,
                    dictName: this.firstDictName,
                    dictLabel: this.firstDictLabel,
                    dictLabel2: '',
                    dictLabel3: '',
                    dictCode: this.firstdictCode,
                    dictCode2: this.firstdictCode2,
                    dictCode3: this.firstdictCode3,
                };
                return this.formatData(colobj, value, v_data, v_currentfieldname);
            },
            formatData(v_colobj, value, v_data, v_currentfieldname) {
                if (v_colobj.type == 'Dictionary') {
                    return this.formatDict(v_colobj.dictName, value);
                }
                if (v_colobj.type == 'InputDict') {
                    return this.formatInputDict(v_colobj.dictLabel, v_data);
                }
                if (v_colobj.type == 'LinkageDict') {
                    return this.formatLinkageDictionary(v_colobj, v_data, v_currentfieldname);
                }
                if (v_colobj.type == 'DateTimeString' || v_colobj.type == 'DateString') {
                    return commonJs.formatDate(v_colobj.type, value);
                }
                return value;
            },
            formatDict(v_dictName, value) {
                var name = value;
                var isFind = false;
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var i = 0; i < this.dicts[dictName].length; i++) {
                        if (this.dicts[dictName][i].code == value) {
                            name = this.dicts[dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            formatInputDict(v_dictLabel, v_data) {
                console.log('formatInputDict:' + v_dictLabel);
                console.log(v_data);
                if (v_data[v_dictLabel]) {
                    console.log(v_data[v_dictLabel]);
                    return v_data[v_dictLabel];
                } else {
                    console.log('空');
                    return '';
                }
            },
            formatLinkageDictionary(v_colobj, v_data, v_currentfieldname) {
                var firstname = '';
                var secondname = '';
                var thirdname = '';
                var firstvalue = '';
                if (v_colobj.dictCode && v_colobj.dictCode != '') {
                    firstvalue = v_data[v_colobj.dictCode];
                }
                var secondvalue = '';
                if (v_colobj.dictCode2 && v_colobj.dictCode2 != '') {
                    secondvalue = v_data[v_colobj.dictCode2];
                }
                var thirdvalue = '';
                if (v_colobj.dictCode3 && v_colobj.dictCode3 != '') {
                    thirdvalue = v_data[v_colobj.dictCode3];
                }
                console.log("formatLinkageDictionary:" + v_currentfieldname);
                console.log(this.dicts);
                console.log(v_colobj);
                var isFind = false;
                var dictName = '';
                var dictObjarray = v_colobj.dictName.split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var k = 0; k < this.dicts[dictName].length; k++) {
                        if (this.dicts[dictName][k].code == firstvalue) {
                            firstname = this.dicts[dictName][k].name;
                            if (secondvalue != '') {
                                for (var k2 = 0; k2 < this.dicts[dictName][k].child.length; k2++) {
                                    if (this.dicts[dictName][k].child[k2].code == secondvalue) {
                                        secondname = this.dicts[dictName][k].child[k2].name;
                                        if (thirdvalue != '') {
                                            for (var k3 = 0; k3 < this.dicts[dictName][k].child[k2].child
                                                .length; k3++) {
                                                if (this.dicts[dictName][k].child[k2].child[k3].code ==
                                                    thirdvalue) {
                                                    thirdname = this.dicts[dictName][k].child[k2].child[k3]
                                                        .name;
                                                    break;
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    if (v_colobj.dictCode == v_currentfieldname) {
                        return firstname;
                    }
                    if (v_colobj.dictCode2 == v_currentfieldname) {
                        return secondname;
                    }
                    if (v_colobj.dictCode3 == v_currentfieldname) {
                        return thirdname;
                    }
                } else {
                    return '';
                }
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
        components: {
            UniversalPage,
            FlowHistory,
            CalfInputDropdown,
            LinkageDropDown,
        }
    }
</script>