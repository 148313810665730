<template>
    <Toast />
    <div class="card">
        <div v-if="qurylistelements2.length>0" class="grid p-fluid mt-1">
            <template v-for="item in qurylistelements2" :key="item.name">
                <div class="field col-3 md:col-2">
                    <span class="p-float-label">
                        <Calendar v-if="item.type=='time2'" :showTime="true" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <Calendar v-if="item.type=='time'" :showTime="false" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" />
                        <InputText v-if="item.type=='input'" :id="item.name" v-model="item.val" type="text" />
                        <CalfDropdown v-if="item.type=='drop'" :showClear="true" :loadAll="true"
                            v-model:selectedValue="item.val" :id="item.name" :dictObjName="item.dictName" />
                        <CalfInputDropdown v-if="item.type=='dropinput'" v-model:selectedValue="item.val"
                            v-model:selectedTxt="item.dictLabel" :id="item.name" :dictObjName="item.dictName" />
                        <LinkageDropDown v-if="item.type=='linkagedrop'"
                            v-model:selectedValue1="querydata[item.dictCode]"
                            v-model:selectedValue2="querydata[item.dictCode2]"
                            v-model:selectedValue3="querydata[item.dictCode3]" :bindselectedTxt="false"
                            :currentfieldname="item.name" :currentitem="item" :dictItems="getDictData(item.dictName)" />
                        <CalfMultiSelect v-if="item.type=='multiselect'" v-model:selectedValue="item.val"
                            :id="item.name" :loadAll="true" :dictObjName="item.dictName" dictCategories="" />
                        <CalfTreeDropdown v-if="item.type=='treedict'" v-model:selectedValue="item.val" :id="item.name"
                            :dictObjName="item.dictName" />
                        <label :for="item.name">{{item.label}}</label>
                    </span>
                </div>
            </template>
            <div class="field col-3 md:col-1">
                <Button label="查询" icon="pi pi-search" @click="loadData(1)" class="ml-auto" />
            </div>
            <div class="field col-3 md:col-1">
                <Button label="导入" class="p-button-outlined m-auto" @click="ImportData()" />
            </div>
        </div>
    </div>
    <Card style="width:77vw">
        <template #content>
            <DataTable :value="listdata" v-model:selection="selectedData" :scrollable="true" stripedRows
                scrollHeight="650px" dataKey="id" :loading="loading" :rowClass="rowClass" scrollDirection="both"
                :paginator="true" :lazy="true" :rows="50" :totalRecords="totalRecords" :first="first"
                selectionMode="multiple" :metaKeySelection="false"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                @page="listPage($event)">
                <Column selectionMode="multiple" frozen headerStyle="width: 3em"></Column>
                <Column :field="firstColName" :header="firstColLabel" style="flex-grow:1; flex-basis:160px" frozen>
                    <template #body="slotProps">
                        <label class="oneline" v-tooltip.top="{ value: slotProps.data[firstColName], disabled: false }"
                            v-if="firstColType!='UpLoad'">{{formatfirstData(slotProps.data[firstColName],slotProps.data,firstColName)}}</label>
                        <Image v-if="firstColType=='UpLoad' && firstfileType=='img'"
                            :src="getFilePatch(slotProps.data[firstColName])" width="30" preview />
                        <a v-if="firstColType=='UpLoad' && firstfileType!='img'"
                            :href="getFilePatch(slotProps.data[firstColName])"
                            target="_blank">{{slotProps.data[firstfileName]}}</a>
                    </template>
                </Column>
                <Column v-for="item in cols" :key="item.name" :field="item.name" :header="item.label"
                    style="flex-grow:1; flex-basis:180px">
                    <template #body="slotProps">
                        <label class="oneline" v-tooltip.top="{ value: slotProps.data[item.name], disabled: false }"
                            v-if="item.type!='UpLoad'">{{formatData(item,slotProps.data[item.name],slotProps.data,item.name)}}</label>
                        <Image v-if="item.type=='UpLoad' && item.fileType=='img'"
                            :src="getFilePatch(slotProps.data[item.name])" width="30" preview />
                        <a v-if="item.type=='UpLoad' && item.fileType!='img'"
                            :href="getFilePatch(slotProps.data[item.name])"
                            target="_blank">{{slotProps.data[item.fileName]}}</a>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
</template>
<style lang="scss" scoped>
    .product-image {
        width: 30px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .oneline {
        width: 150px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    :deep(.row-accessories) {
        background-color: rgba(0, 0, 0, .15) !important;

    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import commonJs from '../js/Common';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    import Image from 'primevue/image';
    import CalfDropdown from './CalfDropdown.vue';
    import CalfMultiSelect from './CalfMultiSelect.vue';
    import CalfInputDropdown from './CalfInputDropdown.vue';
    import LinkageDropDown from './LinkageDropDown.vue';
    import CalfTreeDropdown from './CalfTreeDropdown.vue';
    export default {
        props: ['targetid', 'showImportDataDialog', 'freshParentData', 'getNewId', 'addQueryCondition', 'addCols'],
        created() {
            this.initPara();
        },
        data() {
            return {
                allselectedData: [],
                selectedData: ref(),
                configObj: ref(),
                qurylistelements: [],
                qurylistelements2: [],
                loading: false,
                butLoading: false,
                totalRecords: 0,
                first: 0,
                listdata: [],
                cols: [],
                dicts: {},
                querydata: {},
                firstColLabel: '',
                firstColName: '',
                firstColType: '',
                firstfileName: '',
                firstfileType: '',
                firstdictCode: '',
                firstdictCode2: '',
                firstdictCode3: '',
                graphqlFeild: '',
                graphqlChildObj: {},
                childMoudleGraphqlmaps: [],
                notInputElement: ['DateString', 'LabelString', 'DateTimeString', 'Dictionary', 'AtuNumber', 'TreeDict',
                    'InputDict', 'MultiSelect', 'LinkageDict', 'UpLoad'
                ],
                timeType: ['DateString', 'DateTimeString'],
                flowInfoType: ['flowhistorygroup', 'approvalresult'],
                flowViewType: ['shenpiview', 'tuihuiview', 'shenheview', 'banliview'],
                baseType: ['String', 'ID', 'Decimal', 'Int', 'Long', 'DateString', 'DateTimeString',
                    'Dictionary', 'AtuNumber', 'LabelString', 'TreeDict', 'MultiSelect', 'InputDict', 'Textarea',
                    'LinkageDict',
                    'UpLoad'
                ],
                excludeElements: ['ischeck', 'isdel'],
            }
        },
        methods: {
            initPara() {
                this.allselectedData = [];
                this.selectedData = ref();
                this.configObj = ref();
                this.startnodeid = '';
                this.firstColLabel = '';
                this.firstColName = '';
                this.firstfileName = '';
                this.firstfileType = '';
                this.firstColType = '';
                this.firstDictName = '';
                this.firstDictLabel = '';
                this.firstdictCode = '';
                this.firstdictCode2 = '';
                this.firstdictCode3 = '';
                this.loading = false;
                this.butLoading = false;
                this.totalRecords = 0;
                this.first = 0;
                this.listdata = [];
                this.cols = [];
                this.dicts = {};
                this.querydata = {};
                this.qurylistelements = [];
                this.qurylistelements2 = [];
                this.graphqlFeild = '';
                this.graphqlChildObj = {};
                this.childMoudleGraphqlmaps = [];
                this.loadImportConfig();
            },
            rowClass(data) {
                for (var i = 0; i < this.allselectedData.length; i++) {
                    if (this.allselectedData[i]['id'] == data['id']) {
                        return "row-accessories";
                    }
                }
            },
            loadData(v_page) {
                if (this.configObj.source.length > 0) {
                    this.loading = true;
                    this.loadListData(v_page, this.configObj.source[0].sourceschema)
                }
            },
            ImportData() {
                console.log('ImportData...');
                console.log(this.selectedData);
                console.log(this.configObj);
                var selectImportData = [];
                for (var proname in this.selectedData) {
                    var obj = {};
                    for (var k = 0; k < this.configObj.source[0].relation.length; k++) {
                        if (this.configObj.source[0].relation[k].sourcename != '') {
                            if (this.selectedData[proname][this.configObj.source[0].relation[k].sourcename]) {
                                if (!obj[this.configObj.source[0].relation[k].targetname]) {
                                    obj[this.configObj.source[0].relation[k].targetname] = [];
                                    for (var i = 0; i < this.selectedData[proname][this.configObj.source[0].relation[k]
                                            .sourcename
                                        ].length; i++) {
                                        var sourceRow = this.selectedData[proname][this.configObj.source[0].relation[k]
                                            .sourcename
                                        ][i];
                                        var row = {};
                                        if (sourceRow['id']) {
                                            row['id'] = '-' + sourceRow['id'];
                                        } else {
                                            row['id'] = this.getNewId();
                                        }
                                        for (var k2 = 0; k2 < this.configObj.source[0].relation.length; k2++) {
                                            if (this.configObj.source[0].relation[k].sourcename == this.configObj
                                                .source[0].relation[k2].sourcename && this
                                                .configObj.source[0].relation[k2].sourcefield != 'id') {
                                                row[this.configObj.source[0].relation[k2].targetfield] = sourceRow[this
                                                    .configObj.source[0].relation[k2].sourcefield];
                                            }
                                        }
                                        obj[this.configObj.source[0].relation[k].targetname].push(row);
                                    }
                                }
                            }
                        } else {
                            if (this.selectedData[proname][this.configObj.source[0].relation[k].sourcefield] || this
                                .selectedData[proname][this.configObj.source[0].relation[k].sourcefield] == '' || this
                                .selectedData[proname][this.configObj.source[0].relation[k].sourcefield] == '0') {
                                obj[this.configObj.source[0].relation[k].targetfield] = this.selectedData[proname][this
                                    .configObj
                                    .source[0].relation[k].sourcefield
                                ];
                                console.log(this.configObj.source[0].relation[k].targetfield + '=' + this.selectedData[
                                    proname][this
                                    .configObj
                                    .source[0].relation[k].sourcefield
                                ]);
                            }
                        }
                    }
                    if (this.selectedData[proname]['id']) {
                        obj['id'] = '-' + this.selectedData[proname]['id'];
                    } else {
                        obj['id'] = this.getNewId();
                    }
                    for (var selcolname in this.selectedData[proname]) {
                        var isadd = false;
                        for (var targetproname in obj) {
                            if (targetproname == selcolname) {
                                isadd = true;
                                break;
                            }
                        }
                        if (!isadd) {
                            console.log('ImportData...add');
                            console.log(selcolname);
                            obj[selcolname] = this.selectedData[proname][selcolname];
                        }
                    }
                    console.log('ImportData...2');
                    console.log(obj);
                    selectImportData.push(obj);
                    this.allselectedData.push(this.selectedData[proname]);
                }
                console.log('selectImportData.....');
                console.log(selectImportData);
                if (this.freshParentData) {
                    console.log('freshParentData.....');
                    this.freshParentData(selectImportData);
                }
                this.selectedData = ref();
                MessageTip.infomsg('添加成功，你可以继续导入');
                //this.$emit('update:showImportDataDialog', false);
            },
            getDictData(v_dictName) {
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                var dictData = [];
                if (this.dicts[dictName]) {
                    dictData = this.dicts[dictName];
                }
                return dictData;
            },
            formatfirstData(value, v_data, v_currentfieldname) {
                var colobj = {
                    name: v_currentfieldname,
                    type: this.firstColType,
                    dictName: this.firstDictName,
                    dictLabel: this.firstDictLabel,
                    dictLabel2: '',
                    dictLabel3: '',
                    dictCode: this.firstdictCode,
                    dictCode2: this.firstdictCode2,
                    dictCode3: this.firstdictCode3,
                };
                return this.formatData(colobj, value, v_data, v_currentfieldname);
            },
            getFilePatch(v_patch) {
                if (v_patch == '' || !v_patch) {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            formatData(v_colobj, value, v_data, v_currentfieldname) {
                if (v_colobj.type == 'Dictionary') {
                    return this.formatDict(v_colobj.dictName, value);
                }
                if (v_colobj.type == 'MultiSelect') {
                    return this.formatMultiSelect(value);
                }
                if (v_colobj.type == 'InputDict' || v_colobj.type == 'TreeDict') {
                    return this.formatInputDict(v_colobj.dictLabel, v_data);
                }
                if (v_colobj.type == 'LinkageDict') {
                    return this.formatLinkageDictionary(v_colobj, v_data, v_currentfieldname);
                }
                if (v_colobj.type == 'DateTimeString' || v_colobj.type == 'DateString') {
                    return commonJs.formatDate(v_colobj.type, value);
                }
                return value;
            },
            formatMultiSelect(value) {
                var name = '';
                if (value && value.length > 0) {
                    for (var i = 0; i < value.length; i++) {
                        if (name == '') {
                            name = value[i]['name'];
                        } else {
                            name = name + "," + value[i]['name'];
                        }
                    }
                }
                return name;
            },
            formatDict(v_dictName, value) {
                var name = value;
                var isFind = false;
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var i = 0; i < this.dicts[dictName].length; i++) {
                        if (this.dicts[dictName][i].code == value) {
                            name = this.dicts[dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            formatInputDict(v_dictLabel, v_data) {
                if (v_data[v_dictLabel]) {
                    return v_data[v_dictLabel];
                } else {
                    return '';
                }
            },
            formatLinkageDictionary(v_colobj, v_data, v_currentfieldname) {
                var firstname = '';
                var secondname = '';
                var thirdname = '';
                var firstvalue = '';
                if (v_colobj.dictCode && v_colobj.dictCode != '') {
                    firstvalue = v_data[v_colobj.dictCode];
                }
                var secondvalue = '';
                if (v_colobj.dictCode2 && v_colobj.dictCode2 != '') {
                    secondvalue = v_data[v_colobj.dictCode2];
                }
                var thirdvalue = '';
                if (v_colobj.dictCode3 && v_colobj.dictCode3 != '') {
                    thirdvalue = v_data[v_colobj.dictCode3];
                }
                var isFind = false;
                var dictName = '';
                var dictObjarray = v_colobj.dictName.split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var k = 0; k < this.dicts[dictName].length; k++) {
                        if (this.dicts[dictName][k].code == firstvalue) {
                            firstname = this.dicts[dictName][k].name;
                            if (secondvalue != '') {
                                for (var k2 = 0; k2 < this.dicts[dictName][k].child.length; k2++) {
                                    if (this.dicts[dictName][k].child[k2].code == secondvalue) {
                                        secondname = this.dicts[dictName][k].child[k2].name;
                                        if (thirdvalue != '') {
                                            for (var k3 = 0; k3 < this.dicts[dictName][k].child[k2].child
                                                .length; k3++) {
                                                if (this.dicts[dictName][k].child[k2].child[k3].code ==
                                                    thirdvalue) {
                                                    thirdname = this.dicts[dictName][k].child[k2].child[k3]
                                                        .name;
                                                    break;
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    if (v_colobj.dictCode == v_currentfieldname) {
                        return firstname;
                    }
                    if (v_colobj.dictCode2 == v_currentfieldname) {
                        return secondname;
                    }
                    if (v_colobj.dictCode3 == v_currentfieldname) {
                        return thirdname;
                    }
                } else {
                    return '';
                }
            },
            listPage(event) {
                var page = event.page;
                this.loadData(page + 1);
            },
            loadImportConfig() {
                if (this.targetid && this.targetid != null && typeof (this.targetid) != 'undefined') {
                    var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'id',
                            value: this.targetid,
                            operation: '=',
                        }],
                        child: []
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{dataimportconfigsetlist(where: ' + JSON.stringify(listwhere) +
                        '){targetschema targetschemacname source { sourceschema condition {sourcename field} relation { sourcename sourcefield targetname targetfield }} } }'
                    ).then(res => {
                        if (res.errcode == "0") {
                            var importConfigObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                .dataimportconfigsetlist;
                            if (importConfigObj.length > 0) {
                                this.configObj = importConfigObj[0];
                                if (this.configObj.source.length > 0) {
                                    requests.graphql_requests.post(
                                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                                        '{__type(name: "' + this.configObj.source[0].sourceschema +
                                        '"){name description dictconfiginfo queryconfiginfo fields { name description dictconfiginfo queryconfiginfo type { name description }} } }'
                                    ).then(res => {
                                        if (res.errcode == "0") {
                                            var moduleObj = JSON.parse(unescape(Base64.decode(res
                                                    .data)))
                                                .data.__type;
                                            this.loadModuleData(moduleObj);
                                        } else {
                                            MessageTip.warnmsg('类型加载失败');
                                        }
                                    });
                                }
                            }
                        } else {
                            MessageTip.warnmsg('导入配置加载失败');
                        }
                    });
                }
            },
            loadModuleData(v_moduleObj) {
                //var regx = /^[^A-Za-z0-9]*$/;
                var regx = /^[^0-9]*$/;
                var graphqlFeild = '';
                var childStructure = [];
                console.log('loadModuleData-1');
                console.log(v_moduleObj);
                console.log(this.configObj);
                if (v_moduleObj && v_moduleObj.fields) {
                    for (var i = 0; i < v_moduleObj.fields.length; i++) {
                        if (v_moduleObj.fields[i].queryconfiginfo && v_moduleObj.fields[i].queryconfiginfo != '') {
                            var queryArray = v_moduleObj.fields[i].queryconfiginfo.split('|');
                            var addToQueryCondition = false;
                            for (var k = 0; k < this.configObj.source[0].condition.length; k++) {
                                if (this.configObj.source[0].condition[k].field == queryArray[0].toLowerCase().replace(
                                        '[', '').replace(
                                        ']', '')) {
                                    addToQueryCondition = true;
                                    break;
                                }
                            }
                            console.log('loadModuleData-2');
                            console.log(queryArray[0]);
                            if (addToQueryCondition) {
                                var queryDictNameStr = '';
                                var querydictCode = '';
                                var querydictCode2 = '';
                                var querydictCode3 = '';
                                if (v_moduleObj.fields[i].type.name == 'Dictionary' || v_moduleObj.fields[i].type
                                    .name == 'MultiSelect') {
                                    queryDictNameStr = v_moduleObj.fields[i].dictconfiginfo;
                                }
                                if (v_moduleObj.fields[i].type.name == 'InputDict' || v_moduleObj.fields[i].type.name ==
                                    'TreeDict') {
                                    var queryDictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                    queryDictNameStr = queryDictArray[0];
                                }
                                if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                    var queryDictArray2 = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                    queryDictNameStr = queryDictArray2[0];
                                    querydictCode = queryDictArray2[1];
                                    if (queryDictArray2.length > 3) {
                                        querydictCode2 = queryDictArray2[3];
                                    }
                                    if (queryDictArray2.length > 5)
                                        querydictCode3 = queryDictArray2[5];
                                }
                                this.qurylistelements.push({
                                    type: queryArray[2],
                                    name: queryArray[0],
                                    operation: queryArray[1],
                                    val: '',
                                    dictName: queryDictNameStr,
                                    dictCode: querydictCode,
                                    dictLabel: '',
                                    dictCode2: querydictCode2,
                                    dictLabel2: '',
                                    dictCode3: querydictCode3,
                                    dictLabel3: '',
                                    label: queryArray[3],
                                });
                            }
                        }
                        if (this.baseType.includes(v_moduleObj.fields[i].type.name) && !this.excludeElements.includes(
                                v_moduleObj.fields[i].name)) {
                            graphqlFeild = graphqlFeild + v_moduleObj.fields[i].name + ' ';
                            var dictNameStr = '';
                            var dictLabelStr = '';
                            var dictCode = '';
                            var dictCode2 = '';
                            var dictCode3 = '';
                            var fileName = '';
                            var fileType = '';
                            if (v_moduleObj.fields[i].type.name == 'Dictionary' || v_moduleObj.fields[i].type
                                .name == 'MultiSelect') {
                                this.addDictCategories(v_moduleObj.fields[i].dictconfiginfo);
                                dictNameStr = v_moduleObj.fields[i].dictconfiginfo;
                            }
                            if (v_moduleObj.fields[i].type.name == 'UpLoad') {
                                dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                fileName = dictArray[0];
                                fileType = dictArray[1];
                            }
                            if (v_moduleObj.fields[i].type.name == 'InputDict' || v_moduleObj.fields[i].type.name ==
                                'TreeDict') {
                                var dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                dictNameStr = dictArray[0];
                                dictLabelStr = dictArray[1];
                            }
                            if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                var dictArray2 = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                dictNameStr = dictArray2[0];
                                dictCode = dictArray2[1];
                                this.addDictCategories(dictNameStr);
                                if (dictArray2.length > 3) {
                                    dictCode2 = dictArray2[3];
                                }
                                if (dictArray2.length > 5)
                                    dictCode3 = dictArray2[5];
                            }
                            if (v_moduleObj.fields[i].name == 'currentstatename') {
                                v_moduleObj.fields[i].description = '当前节点';
                            }
                            if (regx.test(v_moduleObj.fields[i].description) && v_moduleObj.fields[i].description !=
                                'GraphQL.Types.FieldType') {
                                if (this.firstColName == '') {
                                    this.firstColName = v_moduleObj.fields[i].name;
                                    this.firstColLabel = v_moduleObj.fields[i].description;
                                    this.firstColType = v_moduleObj.fields[i].type.name;
                                    this.firstfileName = fileName;
                                    this.firstfileType = fileType;
                                    this.firstDictName = dictNameStr;
                                    this.firstDictLabel = dictLabelStr;
                                    this.firstdictCode = dictCode;
                                    this.firstdictCode2 = dictCode2;
                                    this.firstdictCode3 = dictCode3;
                                } else {
                                    this.cols.push({
                                        name: v_moduleObj.fields[i].name,
                                        label: v_moduleObj.fields[i].description,
                                        type: v_moduleObj.fields[i].type.name,
                                        fileName: fileName,
                                        fileType: fileType,
                                        dictName: dictNameStr,
                                        dictLabel: dictLabelStr,
                                        dictCode: dictCode,
                                        dictCode2: dictCode2,
                                        dictCode3: dictCode3,
                                    });
                                }
                            }
                        } else {
                            if (v_moduleObj.fields[i].type.name.substr(0, 1) == '[' && v_moduleObj.fields[i]
                                .type.name != '[flowhistorygroup]') {
                                var childStructureobj = {
                                    name: v_moduleObj.fields[i].name,
                                    typename: v_moduleObj.fields[i].type.name.replace('[', '').replace(']', '')
                                };
                                childStructure.push(childStructureobj);
                            }
                        }
                    }
                }
                if (this.addCols) {
                    graphqlFeild = this.addCols(this.cols, graphqlFeild);
                    console.log('graphqlFeild:' + graphqlFeild);
                }
                this.graphqlFeild = graphqlFeild;
                if (childStructure.length > 0) {
                    for (var k2 = 0; k2 < childStructure.length; k2++) {
                        this.loadChildStructure(childStructure[k2], childStructure);
                    }
                } else {
                    console.log("qurylistelements:");
                    console.log(this.qurylistelements);
                    console.log("cols:");
                    console.log(this.cols);
                    this.loadDict();
                }
            },
            loadChildStructure(v_childStructureObj, v_childStructure) {
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{__type(name: "' + v_childStructureObj.typename +
                        '"){name description fields { name dictconfiginfo description type { name description }} } }')
                    .then(res => {
                        if (res.errcode == "0") {
                            var childModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data.__type;
                            console.log('childModuleObj-1');
                            console.log(childModuleObj);
                            console.log(v_childStructureObj);
                            console.log(v_childStructure);
                            childModuleObj.name = v_childStructureObj.name;
                            this.graphqlChildObj[v_childStructureObj.name] = {
                                type: 'child',
                                val: []
                            };
                            for (var i = 0; i < childModuleObj.fields.length; i++) {
                                if (this.baseType.includes(childModuleObj.fields[i].type.name) && !this
                                    .excludeElements.includes(childModuleObj.fields[i].name)) {
                                    var childInfo1 = {
                                        type: childModuleObj.fields[i].type.name,
                                        name: childModuleObj.fields[i].name
                                    };
                                    if (childModuleObj.fields[i].type.name == 'Dictionary' || childModuleObj.fields[
                                            i].type.name == 'LinkageDict') {
                                        childInfo1['dictconfiginfo'] = childModuleObj.fields[i].dictconfiginfo;
                                    }
                                    this.graphqlChildObj[v_childStructureObj.name]['val'].push(childInfo1);
                                }
                            }
                            this.childMoudleGraphqlmaps.push({
                                name: v_childStructureObj.name
                            });
                            if (this.childMoudleGraphqlmaps.length == v_childStructure.length) {
                                for (var i2 = 0; i2 < v_childStructure.length; i2++) {
                                    this.graphqlFeild = this.graphqlFeild + ' ' + v_childStructure[i2]['name'] +
                                        '{';
                                    for (var n2 = 0; n2 < this.graphqlChildObj[v_childStructure[i2]['name']]['val']
                                        .length; n2++) {
                                        var childInfo = this.graphqlChildObj[v_childStructure[i2]['name']]['val'][
                                            n2
                                        ];
                                        this.graphqlFeild = this.graphqlFeild + ' ' + childInfo['name'];
                                    }
                                    this.graphqlFeild = this.graphqlFeild + ' }';
                                }
                                this.loadDict();
                            }
                        } else {
                            MessageTip.warnmsg(v_childStructureObj.typename + '类型加载失败');
                        }
                    });
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                for (var proname in this.dicts) {
                    if (proname != '') {
                        var dictObjarray = proname.split('^');
                        if (dictObjarray.length > 1) {
                            dictlistwhere.conditions.push({
                                name: 'Categories',
                                value: dictObjarray[1],
                                operation: ''
                            });
                        } else {
                            dictlistwhere.conditions.push({
                                name: 'Categories',
                                value: dictObjarray[0],
                                operation: ''
                            });
                        }
                    }
                }
                if (dictlistwhere.conditions.length > 0) {
                    var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                        '){name details {name code child {name code child {name code child} } }}}';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                                this.dicts[dataObj.dictionarylist[k].name] = dataObj.dictionarylist[k].details;
                            }
                            for (var k1 = 0; k1 < this.qurylistelements.length; k1++) {
                                this.qurylistelements2.push(this.qurylistelements[k1]);
                            }
                            console.log(this.qurylistelements2);
                            this.loadData(1);
                        } else {
                            MessageTip.warnmsg('字典数据加载失败');
                        }
                    });
                } else {
                    for (var k1 = 0; k1 < this.qurylistelements.length; k1++) {
                        this.qurylistelements2.push(this.qurylistelements[k1]);
                    }
                    this.loadData(1);
                }
            },
            addDictCategories(v_dictName) {
                if (v_dictName != '') {
                    var array = v_dictName.split('|');
                    var dictObjarray = array[0].split('^');
                    if (dictObjarray.length > 1) {
                        this.dicts[dictObjarray[1]] = [];
                    } else {
                        this.dicts[dictObjarray[0]] = [];
                    }
                }
            },
            loadListData(v_page, v_moduleName) {
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [],
                    child: []
                };
                if (this.addQueryCondition) {
                    listwhere = this.addQueryCondition(listwhere, v_moduleName);
                }
                for (var k = 0; k < listwhere.conditions.length; k++) {
                    if (listwhere.conditions[k]['name'] == 'err') {
                        this.listdata = [];
                        this.totalRecords = 0;
                        this.first = 0;
                        this.loading = false;
                        return;
                    }
                }
                for (var i = 0; i < this.qurylistelements2.length; i++) {
                    if (this.qurylistelements2[i].type == 'linkagedrop') {
                        if (this.querydata[this.qurylistelements2[i].name.toLowerCase()] && this.querydata[this
                                .qurylistelements2[i].name.toLowerCase()] != '-1') {
                            listwhere.conditions.push({
                                name: this.qurylistelements2[i].name,
                                value: this.querydata[this.qurylistelements2[i].name.toLowerCase()],
                                operation: this.qurylistelements2[i].operation,
                            });
                        }
                    } else {
                        listwhere.conditions.push({
                            name: this.qurylistelements2[i].name,
                            value: this.qurylistelements2[i].val,
                            operation: this.qurylistelements2[i].operation,
                        });
                    }
                }
                console.log(listwhere);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{' + v_moduleName + 'list(where:' + JSON.stringify(listwhere) + '){' + this.graphqlFeild + '}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        if (jsonData.data[v_moduleName + 'list'] && jsonData.data[v_moduleName + 'list']
                            .length > 0) {
                            for (var i = this.cols.length - 1; i >= 0; i--) {
                                if (jsonData.data[v_moduleName + 'list'][0][this.cols[i].name] == null) {
                                    this.cols.splice(i, 1);
                                }
                            }
                        }
                        this.listdata = jsonData.data[v_moduleName + 'list'];
                        console.log(this.listdata);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
        },
        components: {
            CalfDropdown,
            CalfInputDropdown,
            LinkageDropDown,
            CalfMultiSelect,
            CalfTreeDropdown,
            Image,
        }
    }
</script>