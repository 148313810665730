<template>
    <div class="layout-footer">
        <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
        by
        <span class="font-medium ml-2">易汇</span>
    </div>
</template>

<script>
    export default {
        name: "AppFooter",
        methods: {
            footerImage() {
                /* return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg'; */
                return 'images/logo.png';
            }
        },
        computed: {
            darkTheme() {
                return this.$appState.darkTheme;
            }
        }
    }
</script>

<style scoped>

</style>