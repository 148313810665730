<template>
    <MultiSelect v-model="selectedItem" :id="id" :loading="loading" :filter="true" @change="dropchange"
        @filter="dropinput" :options="dictItems" optionLabel="name" optionValue="code" display="chip">
        <template #option="slotProps">
            <div>
                <div v-if="slotProps.option.comment!=''">{{slotProps.option.comment}}--{{slotProps.option.name}}</div>
                <div v-if="slotProps.option.comment==''">{{slotProps.option.name}}</div>
            </div>
        </template>
    </MultiSelect>
</template>
<script>
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    export default {
        name: 'CalfMultiSelect',
        emits: ['update:selectedValue'],
        created() {
            this.loadInit();
        },
        props: ['selectedValue', 'dictObjName', 'id', 'loadAll', 'dictCategories'],
        data() {
            return {
                loading: true,
                selectedItem: [],
                dictItems: []
            }
        },
        methods: {
            loadInit() {
                if (this.loadAll) {
                    this.loadDictSource();
                } else {
                    this.loading = false;
                }
            },
            dropchange(v_event) {
                console.log(v_event);
                setTimeout(() => {
                    var selectedObj = [];
                    for (var j = 0; j < this.selectedItem.length; j++) {
                        for (var i = 0; i < this.dictItems.length; i++) {
                            if (this.dictItems[i].code == this.selectedItem[j]) {
                                if (this.dictItems[i].comment != '') {
                                    selectedObj.push({
                                        name: this.dictItems[i].comment + '--' + this.dictItems[i].name,
                                        code: this.dictItems[i].code
                                    });
                                } else {
                                    selectedObj.push({
                                        name: this.dictItems[i].name,
                                        code: this.dictItems[i].code
                                    });
                                }
                                break;
                            }
                        }
                    }
                    this.$emit('update:selectedValue', selectedObj);
                }, 200);
            },
            dropinput(v_event) {
                console.log(v_event);
                if (!this.loadAll) {
                    this.loading = true;
                    var inputTxt = v_event.value;
                    if (inputTxt != '' && !this.loadAll) {
                        this.loadDictSource(inputTxt);
                    }
                }
            },
            loadDictSource(inputTxt) {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                if (inputTxt && inputTxt != '') {
                    dictlistwhere.conditions.push({
                        name: 'name',
                        value: inputTxt,
                        operation: 'like'
                    });
                }
                var dictCategorie = '';
                if (this.dictCategories && this.dictCategories != '') {
                    dictCategorie = this.dictCategories;
                }
                var dictObjarray = this.dictObjName.split('^');
                var dictName = dictObjarray[0];
                var dictIdentify = dictName + dictCategorie;
                if (dictObjarray.length > 1) {
                    dictCategorie = dictObjarray[1];
                    dictIdentify = dictName + dictObjarray[1];
                }
                if (dictCategorie && dictCategorie != '') {
                    console.log(dictCategorie);
                    dictlistwhere.conditions.push({
                        name: 'Categories',
                        value: dictCategorie,
                        operation: '='
                    });
                }
                console.log('dictIdentify:' + dictIdentify);
                console.log('inputTxt:' + inputTxt);
                var dictCacheData = this.loadDictFromCache(dictName, dictIdentify);
                if (dictCacheData && dictCacheData.length > 0 && (!inputTxt || inputTxt === '')) {
                    console.log('dictIdentify:2-' + dictIdentify);
                    this.dictItems = JSON.parse(dictCacheData);
                    console.log(this.dictItems);
                    this.initSelect();
                    this.loading = false;
                } else {
                    var graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                        '){name code comment}}';
                    if (dictCategorie && dictCategorie != '') {
                        graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                            '){name details {name code}}}';
                    }
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            var dictData = dataObj[dictName + 'list'];
                            if (dictCategorie && dictCategorie != '') {
                                dictData = [];
                                if (dataObj[dictName + 'list'].length > 0) {
                                    dictData = dataObj[dictName + 'list'][0]['details'];
                                }
                            }
                            for (var k = 0; k < dictData.length; k++) {
                                var needAdd = true;
                                for (var j = 0; j < this.dictItems.length; j++) {
                                    if (this.dictItems[j].code == dictData[k].code) {
                                        needAdd = false;
                                        break;
                                    }
                                }
                                if (needAdd) {
                                    if (dictCategorie && dictCategorie != '') {
                                        this.dictItems.push({
                                            name: dictData[k].name,
                                            code: dictData[k].code,
                                            comment: '',
                                        });
                                    } else {
                                        this.dictItems.push({
                                            name: dictData[k].name,
                                            code: dictData[k].code,
                                            comment: dictData[k].comment,
                                        });
                                    }
                                }
                            }
                            if (!inputTxt || inputTxt && inputTxt === '') {
                                console.log('dictIdentify:3-' + dictIdentify);
                                sessionStorage.setItem(dictIdentify, JSON.stringify(this.dictItems))
                            }
                            this.initSelect();
                        } else {
                            MessageTip.warnmsg(this.dictObjName + '字典数据加载失败');
                        }
                    });
                }
            },
            initSelect() {
                console.log('initSelect:' + this.dictObjName);
                if (this.selectedValue != null) {
                    for (var i = 0; i < this.selectedValue.length; i++) {
                        console.log(this.selectedValue[i]);
                        if (this.selectedValue[i].name && this.selectedValue[i].name != '') {
                            var nofind = true;
                            for (var j = 0; j < this.dictItems.length; j++) {
                                if (this.dictItems[j]['code'] == this.selectedValue[i].code) {
                                    nofind = false;
                                    break;
                                }
                            }
                            if (nofind) {
                                this.dictItems.push({
                                    name: this.selectedValue[i].name,
                                    code: this.selectedValue[i].code,
                                    comment: ''
                                });
                            }
                        }
                        if (this.selectedValue[i].code && parseInt(this.selectedValue[i].code) > 0)
                            this.selectedItem.push(this.selectedValue[i].code);
                    }
                }
            },
            loadDictFromCache(dictName, dictIdentify) {
                var dictCacheData = sessionStorage.getItem(dictIdentify);
                if (!dictCacheData || dictCacheData.length == 0) {
                    dictCacheData = sessionStorage.getItem(dictName);
                }
                return dictCacheData;
            }
        }
    }
</script>