import axios from 'axios';
import MessageTip from '../components/Message';

const plat_requests = axios.create({
    baseURL: '../',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 60000
})
const graphql_requests = axios.create({
    baseURL: '../',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 60000
})

const create_plat_requests = (corpid, storeid) => {
    const token = sessionStorage.getItem('usertoken');
    return axios.create({
        baseURL: '../',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'corpid': corpid,
            'storeid': storeid,
            'Access-Token': token
        },
        timeout: 60000
    })
}

// 错误处理函数
const err = (error) => {
    if (error.response) {
        const data = error.response.data
        if (error.response.status === 403) {
            MessageTip.errmsg(data.message || data.msg);
        }
        if (error.response.status === 401) {
            MessageTip.errmsg('你没有权限。');
        }
    }
    return Promise.reject(error)
}
// 请求拦截器
plat_requests.interceptors.request.use(config => {
    const token = sessionStorage.getItem('usertoken')
    if (token) {
        config.headers['Access-Token'] = token
    }
    return config
}, err)
graphql_requests.interceptors.request.use(config => {
    const token = sessionStorage.getItem('usertoken');
    if (token) {
        config.headers['Access-Token'] = token
    }
    return config
}, err)
// 接收拦截器
plat_requests.interceptors.response.use((response) => {
    const res = response.data
    console.log(res);
    console.log(res.errcode);
    if (res.errcode && res.errcode != '0' || res.code && res.code != '0') {
        if (res.errcode && res.errcode == "5" || res.code && res.code == '5') {
            console.log('您的账号同时登陆多个地方或设备，请退出重新登陆');
            MessageTip.dialogmsg("您的账号同时登陆多个地方或设备，请退出重新登陆！", () => {
                location.href = "/login/index.html";
            });
        } else {
            MessageTip.errmsg(res.err);
        }
        return res
    } else {
        if (!res.errcode && !res.code) {
            MessageTip.errmsg(res);
        }
        return res
    }
}, err)
graphql_requests.interceptors.response.use((response) => {
    const res = response.data
    console.log(res);
    console.log(res.errcode);
    if (res.errcode && res.errcode !== "0") {
        console.log('MessageTip.errmsg');
        if (res.errcode == "5") {
            console.log('您的账号同时登陆多个地方或设备，请退出重新登陆');
            MessageTip.dialogmsg("您的账号同时登陆多个地方或设备，请退出重新登陆！", () => {
                location.href = "/login/index.html";
            });
        } else {
            MessageTip.errmsg(res.err);
        }
        return res
    } else {
        return res
    }
}, err)


export default {
    plat_requests,
    graphql_requests,
    create_plat_requests,
    axios
}