<template>
    <InputText :id="id" v-model="atuNumer" :readonly="readonlyval" @input="inputTxtChange" @blur="inputTxtBlur"
        :placeholder="placeholdertxt" />
</template>
<script>
    export default {
        name: 'CalfAtuNumber',
        emits: ['update:inputValue'],
        created() {
            if (this.readonlyprop) {
                this.readonlyval = true;
            }
            this.loadInit();
        },
        props: ['inputValue', 'atuNumPlaceHolder', 'id', 'readonlyprop'],
        data() {
            var d = new Date();
            var m = d.getMonth() + 1;
            if (m < 10) m = '0' + m;
            return {
                atuNumer: null,
                placeholdertxt: '*****',
                readonlyval: false,
            }
        },
        methods: {
            loadInit() {
                if (this.atuNumPlaceHolder && this.atuNumPlaceHolder != "") {
                    this.placeholdertxt = this.atuNumPlaceHolder;
                }
                if (!this.inputValue || this.inputValue == '') {
                    this.atuNumer = this.placeholdertxt;
                    setTimeout(() => {
                        this.$emit('update:inputValue', this.atuNumer);
                    }, 50);
                } else {
                    this.atuNumer = this.inputValue;
                }
            },
            inputTxtChange() {
                setTimeout(() => {
                    this.$emit('update:inputValue', this.atuNumer);
                }, 50);
            },
            inputTxtBlur() {
                setTimeout(() => {
                    this.$emit('update:inputValue', this.atuNumer);
                }, 50);
            },
        }
    }
</script>