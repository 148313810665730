<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ProgressBar v-if="showWait" mode="indeterminate" style="height: .5em" />
    <div v-if="!showWait" class="card">
        <div class="grid">
            <template v-for="item in elements" :key="item.name">
                <!-- <div :class="getMainelementCss(item.name)"> -->
                <div v-if="!isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                    class="flex flex-column col-2">
                    <label :for="item.name">{{item.label}}</label>
                    <Calendar v-if="item.type=='DateTimeString'" :showTime="true" :id="item.name" v-model="item.val"
                        hourFormat="24" dateFormat="yy-mm-dd" :class='item.class' />
                    <Calendar v-if="item.type=='DateString'" :showTime="false" :id="item.name" v-model="item.val"
                        hourFormat="24" dateFormat="yy-mm-dd" :class='item.class' />
                    <InputText v-if="!notInputElement.includes(item.type)" :id="item.name" v-model="item.val"
                        v-tooltip.top="{ value: item.tip, disabled: false }" type="text" :class='item.class' />
                    <CalfAtuNumber v-if="item.type=='AtuNumber'" :id="item.name" v-model:inputValue="item.val"
                        :class='item.class' />
                    <CalfDropdown
                        v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || dropOtherConfig[item.name]=='') && item.type=='Dictionary'"
                        :showClear="true" :loadAll="true" v-model:selectedValue="item.val"
                        v-model:selectedTxt="noshowelements[item.dictLabel]" :ref="item.name" :id="item.name"
                        :dictObjName="item.dictName" :class='item.class' />
                    <CalfDropdown
                        v-if="dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name]!='' && item.type=='Dictionary'"
                        :showClear="true" :loadAll="true" v-model:selectedValue="item.val"
                        v-model:selectedTxt="noshowelements[item.dictLabel]" :dropchangeAfter="dropchangeAfter"
                        :id="item.name" :ref="item.name" :dictObjName="item.dictName" :class='item.class' />
                    <CalfInputDropdown v-if="item.type=='InputDict'" v-model:selectedValue="item.val"
                        v-model:selectedTxt="noshowelements[item.dictLabel]" :id="item.name" :canEditable="true"
                        :dictObjName="item.dictName" :class='item.class' />
                    <CalfMultiSelect v-if="item.type=='MultiSelect'" v-model:selectedValue="item.val" :id="item.name"
                        :loadAll="true" :dictObjName="item.dictName" dictCategories="" :class='item.class' />
                    <CalfTreeDropdown v-if="item.type=='TreeDict'" v-model:selectedValue="item.val"
                        v-model:selectedTxt="noshowelements[item.dictLabel]" :id="item.name"
                        :dictObjName="item.dictName" :class='item.class' />
                    <LinkageDropDown v-if="item.type=='LinkageDict'"
                        v-model:selectedValue1="noshowelements[item.dictCode]"
                        v-model:selectedTxt1="noshowelements[item.dictLabel]"
                        v-model:selectedValue2="noshowelements[item.dictCode2]"
                        v-model:selectedTxt2="noshowelements[item.dictLabel2]"
                        v-model:selectedValue3="noshowelements[item.dictCode3]"
                        v-model:selectedTxt3="noshowelements[item.dictLabel3]"
                        v-model:bindselectedTxt="item.bindselectedTxt" :currentfieldname="item.name" :currentitem="item"
                        :dictItems="getDictData(item.dictName)" :class='item.class' />
                    <UploadInput v-if="item.type=='UpLoad'" v-model:filePatch="item.val" :ctrlname="item.name"
                        v-model:fileName="noshowelements[item.fileName]" :fileType="item.fileType" :uptype="item.uptype"
                        :attachttype="item.attachttype" :class='item.class' />
                    <InputText v-if="item.type=='LabelString'" type="text" :id="item.name" :value="item.val"
                        :class='item.class' readonly v-tooltip.top="{ value: item.tip, disabled: false }" />
                </div>
                <div v-if="elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                        mainUdefinedAuth[item.name])" class="flex flex-column col-2">
                    <label :for="item.name">{{item.label}}</label>
                    <InputText v-if="item.type!='UpLoad'" type="text" :id="item.name"
                        :value="formatData(item,item.val,noshowelements,item.name)" :class='item.class' readonly
                        v-tooltip.top="{ value: item.tip, disabled: false }" />
                    <Image v-if="item.type=='UpLoad' && item.fileType=='img'" :id="item.name"
                        :src="getFilePatch(item.val)" width="30" preview />
                    <a v-if="item.type=='UpLoad' && item.fileType!='img'" :id="item.name" :href="getFilePatch(item.val)"
                        target="_blank">{{noshowelements[item.fileName]}}</a>
                </div>
                <!-- </div> -->
            </template>
        </div>
    </div>
    <div v-if="!showWait && textareaElements.length>0" class="card">
        <div class="grid">
            <template v-for="item in textareaElements" :key="item.name">
                <!-- <div class="field col"> -->
                <div v-if="(!editorConfig || !editorConfig[item.name]) && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                    class="flex flex-column col-12">
                    <label :for="item.name">{{item.label}}</label>
                    <Textarea :id="item.name" v-model="item.val" rows="3" cols="58" />
                    </div>
                    <div v-if="(!editorConfig || !editorConfig[item.name]) && (elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth && mainUdefinedAuth[item.name]))" class="flex flex-column col-12">
                        <h6>{{item.label}}</h6>
                         <ScrollPanel :id="item.name" style="height:100px">
                            <p v-html="item.val"></p>
                        </ScrollPanel>
                       <!--  <Textarea :id="item.name" v-model="item.val" class="p-filled" rows="3" cols="58" style="border:0;outline:none;" disabled />
                        <label :for="item.name">{{item.label}}</label>  style="border:0;outline:none;" -->
                    </div>
                     <div
                        v-if="editorConfig && editorConfig[item.name] && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                        class="flex flex-column col-12">
                          <h6>{{item.label}}</h6>
                           <Editor :id="item.name" v-model="item.val" editorStyle="height:120px">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button v-tooltip.bottom="'导入'" @click="toggle" aria-haspopup="true"
                                                aria-controls="overlay_menu"><svg viewBox="0 0 18 18">
                                                    <path class="ql-stroke"
                                                        d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                    </path>
                                                    <path class="ql-stroke"
                                                        d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                    </path>
                                                </svg></button>
                                            <Menu id="overlay_menu" ref="editorMenu" :model="editorMenuItems" :popup="true" />
                                        </span>
                                    </template>
                            </Editor>
                    </div>
                     <div v-if="editorConfig && editorConfig[item.name] && (elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth && mainUdefinedAuth[item.name]))" class="flex flex-column col-12">
                        <h6>{{item.label}}</h6>
                         <ScrollPanel :id="item.name" style="height:120px">
                            <p v-html="item.val"></p>
                        </ScrollPanel>
                    </div>
                <!-- </div> -->
            </template>
        </div>
    </div>
    <div v-if="!showWait && alonechildelements.length>0">
     <Card style="margin-bottom:1em" v-for="item in alonechildelements" :key="item.name">
            <template #title>
               <h6>{{item.header}}</h6>
            </template>
            <template #content>
             <DataTable :value="item.rows" v-model:selection="selectedRowData" v-model:expandedRows="expandedRowsData" showGridlines :scrollable="true"
                scrollHeight="500px" stripedRows scrollDirection="both" dataKey="id"
                editMode="cell" class="editable-cells-table" responsiveLayout="scroll" @cell-edit-init="cellEdit" @cell-edit-complete="cellEditComplete($event,item.name,item.rows)">
                 <template #header>
                    <Toolbar class="mb-2" v-if="!elementAuthority[item.name] && !isReadOnly">
                        <template #start>
                            <SplitButton v-if="importItems[item.name]" label="导入" class="p-button-success mr-2" :model="importItems[item.name]"></SplitButton>
                            <Button v-if="!childNewDataConfig || childNewDataConfig[item.name]" label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                                @click="createInfo(alonechildelements,item.name)" />
                            <Button v-if="!childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name]" label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                                @click="delInfo(alonechildelements,item.name)" />
                        </template>
                    </Toolbar>
                </template>
                <Column v-if="!elementAuthority[item.name] && !isReadOnly" selectionMode="multiple" frozen footerStyle="width:3em;flex:0;" bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                <Column v-if="childExpandeConfig && childExpandeConfig[item.name] && childExpandeConfig[item.name]['visible']" frozen footerStyle="width:3em;flex:0;" bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;">
                     <template #body="slotProps">
                          <button v-show="!childExpandeConfig[item.name]['ctrshowcol'] || slotProps.data[childExpandeConfig[item.name]['ctrshowcol']]==childExpandeConfig[item.name]['ctrshowval']" @click="expandertoggle2($event,slotProps.data)" aria-haspopup="true" :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span class="p-row-toggler-icon pi pi-chevron-right"></span><span class="p-ink"></span></button>
                          <OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true" :id="slotProps.data['id']" style="width:650px" :breakpoints="{'960px': '75vw'}">
                            <TabView v-if="!childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex2">
                                   <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config']" :key="expandeconfig.id" :header="expandeconfig.title">
                                        <DataTable :value="expandedata2[expandeconfig['data']]" dataKey="id" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata2,expandedata2[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                        :sortable="false" :frozen="col2.frozen">
                                                    <template #editor="{ data }">
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                        <CalfDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                        <CalfInputDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                        <InputText v-if="!isReadOnly && !col2.readOnly && col2.type==''" v-model="data[col2.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                                                        <div v-if="isReadOnly || col2.readOnly" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                    </template>
                                                    <template #body="{data}">
                                                        <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                            <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                            <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                            <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                        </template>
                                                        <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                            <label class="oneline"
                                                                v-tooltip.top="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                        </template>
                                                    </template>
                                                </Column>
                                        </DataTable>
                                   </TabPanel>
                            </TabView>
                            <TabView v-if="childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex2">
                                   <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]]" :key="expandeconfig.id" :header="expandeconfig.title">
                                        <DataTable :value="expandedata2[expandeconfig['data']]" dataKey="id" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata2,expandedata2[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                        :sortable="false" :frozen="col2.frozen">
                                                    <template #editor="{ data }">
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                        <CalfDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                        <CalfInputDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                        <InputText v-if="!isReadOnly && !col2.readOnly && col2.type==''" v-model="data[col2.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                                                        <div v-if="isReadOnly || col2.readOnly" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                    </template>
                                                    <template #body="{data}">
                                                        <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                            <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                            <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                            <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                        </template>
                                                        <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                            <label class="oneline"
                                                                v-tooltip.top="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                        </template>
                                                    </template>
                                                </Column>
                                        </DataTable>
                                    </TabPanel>
                            </TabView>
                          </OverlayPanel>
                     </template>
                </Column>
                <Column :footer="getChildTableFooter(item.name,col.field,item.rows)" v-for="col of item.columns" :field="col.field" :header="col.header" :key="col.field"
                    :sortable="false" :footerStyle="getChildTableColWidth(item.name,col.field)" :bodyStyle="getChildTableColWidth(item.name,col.field)" :headerStyle="getChildTableColWidth(item.name,col.field)" :frozen="col.frozen">
                    <template #editor="{ data }">
                        <InputText
                            v-if="!notInputElement.includes(col.type) && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model="data[col.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                        <Calendar
                            v-if="col.type=='DateTimeString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                            :showTime="true" />
                        <Calendar
                            v-if="col.type=='DateString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                            :showTime="false" />
                        <CalfDropdown
                            v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || !dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]" name="otheredit2" :id="col.field" :loadAll="true" :dictObjName="col.dictName"
                             placeholder="请选择" />
                        <CalfDropdown
                            v-if="(dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]" name="otheredit2" :id="col.field" :childtablename="item.name" :rowid="data['id']" :currentdata="data" :dropchangeAfter="childElementdropchangeAfter" :loadAll="true" :dictObjName="col.dictName"
                             placeholder="请选择" />
                        <CalfInputDropdown
                            v-if="col.type=='InputDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                            :dictObjName="col.dictName" name="otheredit2" :canEditable="true" />
                        <CalfTreeDropdown
                            v-if="col.type=='TreeDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]"
                            :dictObjName="col.dictName" />
                            <LinkageDropDown
                            v-if="col.type=='LinkageDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:selectedValue1="data[col.dictCode]" v-model:selectedTxt1="data[col.dictLabel]" v-model:selectedValue2="data[col.dictCode2]" v-model:selectedTxt2="data[col.dictLabel2]"
                            v-model:selectedValue3="data[col.dictCode3]" name="otheredit2" v-model:selectedTxt3="data[col.dictLabel3]" v-model:bindselectedTxt="col.bindselectedTxt" :currentfieldname="col.field" :currentitem="col" :dictItems="getDictData(col.dictName)" />
                            <UploadInput
                            v-if="col.type=='UpLoad' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                            v-model:filePatch="data[col.field]" ctrlname="otheredit2" v-model:fileName="data[col.fileName]" :fileType="col.fileType" :uptype="col.uptype" :attachttype="col.attachttype" />
                            <div v-if="col.type=='LabelString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" class="p-mb-3 p-text-left">
                            {{data[col.field]}}</div>
                        <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type!='UpLoad'" class="p-mb-3 p-text-left">
                            {{formatData(col,data[col.field],data,col.field)}}</div>
                        <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType=='img'" class="p-mb-3 p-text-left">
                            <Image :src="getFilePatch(data[col.field])" width="30" preview />
                        </div>
                        <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType!='img'" class="p-mb-3 p-text-left">
                                <a :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                        </div>
                    </template>
                    <template #body="{data}">
                        <template v-if="col.type=='DateTimeString' || col.type=='DateString' || col.type=='Dictionary' || col.type=='TreeDict' || col.type=='InputDict' || col.type=='LinkageDict' || col.type=='UpLoad'">
                           <label v-if="col.type!='UpLoad'">{{formatData(col,data[col.field],data,col.field)}}</label>
                           <Image v-if="col.type=='UpLoad' && col.fileType=='img'" :src="getFilePatch(data[col.field])" width="30" preview />
                           <a v-if="col.type=='UpLoad' && col.fileType!='img'" :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                         </template>
                        <template v-if="col.type!='DateTimeString' && col.type!='DateString' && col.type!='Dictionary' && col.type!='TreeDict' && col.type!='InputDict' && col.type!='LinkageDict' && col.type!='UpLoad'">
                              <label class="oneline"
                                 v-tooltip.top="{ value: data[col.field], disabled: false }">{{data[col.field]}}</label>
                        </template>
                    </template>
                </Column>
            </DataTable>
            </template>
     </Card>
    </div>
    <div v-if="!showWait && childelements.length>0" class="card">
        <TabView v-model:activeIndex="activeIndex">
            <TabPanel v-for="item in childelements" :key="item.name" :header="item.header">
                <Toolbar class="mb-2" v-if="!elementAuthority[item.name] && !isReadOnly">
                    <template #start>
                        <SplitButton v-if="importItems[item.name]" label="导入" class="p-button-success mr-2" :model="importItems[item.name]"></SplitButton>
                        <Button v-if="!childNewDataConfig || childNewDataConfig[item.name]" label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                            @click="createInfo(childelements,item.name)" />
                        <Button v-if="!childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name]" label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                            @click="delInfo(childelements,item.name)" />
                    </template>
                </Toolbar>
                <DataTable :value="item.rows" v-model:selection="selectedRowData" v-model:expandedRows="expandedRowsData" showGridlines :scrollable="true"
                    scrollHeight="500px" stripedRows scrollDirection="both" dataKey="id"
                    editMode="cell" class="editable-cells-table" responsiveLayout="scroll" @cell-edit-init="cellEdit($event,item.name,item.columns)" @cell-edit-complete="cellEditComplete($event,item.name,item.rows)">
                    <Column v-if="!elementAuthority[item.name] && !isReadOnly" selectionMode="multiple" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;"></Column>
                    <Column v-if="childExpandeConfig && childExpandeConfig[item.name] && childExpandeConfig[item.name]['visible']" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;">
                      <template #body="slotProps">
                            <button v-show="!childExpandeConfig[item.name]['ctrshowcol'] || slotProps.data[childExpandeConfig[item.name]['ctrshowcol']]==childExpandeConfig[item.name]['ctrshowval']" @click="expandertoggle($event,slotProps.data)" aria-haspopup="true" :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span class="p-row-toggler-icon pi pi-chevron-right"></span><span class="p-ink"></span></button>
                             <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" :id="slotProps.data['id']" style="width:650px" :breakpoints="{'960px': '75vw'}">
                                <TabView v-if="!childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex">
                                   <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config']" :key="expandeconfig.id" :header="expandeconfig.title">
                                        <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                        :sortable="false" :frozen="col2.frozen">
                                                    <template #editor="{ data }">
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                        <CalfDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                        <CalfInputDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                        <InputText v-if="!isReadOnly && !col2.readOnly && col2.type==''" v-model="data[col2.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                                                        <div v-if="isReadOnly || col2.readOnly" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                    </template>
                                                    <template #body="{data}">
                                                        <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                            <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                            <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                            <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                        </template>
                                                        <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                            <label class="oneline"
                                                                v-tooltip.top="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                        </template>
                                                    </template>
                                                </Column>
                                        </DataTable>
                                   </TabPanel>
                                </TabView>
                                <TabView v-if="childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex">
                                   <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config'+expandedata[childExpandeConfig[item.name]['tabcol']]]" :key="expandeconfig.id" :header="expandeconfig.title">
                                        <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                        :sortable="false" :frozen="col2.frozen">
                                                    <template #editor="{ data }">
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                        <Calendar v-if="!isReadOnly && !col2.readOnly && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                        <CalfDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                        <CalfInputDropdown v-if="!isReadOnly && !col2.readOnly && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                        <InputText v-if="!isReadOnly && !col2.readOnly && col2.type==''" v-model="data[col2.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                                                        <div v-if="isReadOnly || col2.readOnly" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                    </template>
                                                    <template #body="{data}">
                                                        <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                            <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                            <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                            <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                        </template>
                                                        <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                            <label class="oneline"
                                                                v-tooltip.top="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                        </template>
                                                    </template>
                                                </Column>
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                            </OverlayPanel>
                      </template>
                    </Column>
                    <Column :footer="getChildTableFooter(item.name,col.field,item.rows)" v-for="col of item.columns" :field="col.field" :header="col.header" :key="col.field"
                        :sortable="false" :footerStyle="getChildTableColWidth(item.name,col.field)" :bodyStyle="getChildTableColWidth(item.name,col.field)" :headerStyle="getChildTableColWidth(item.name,col.field)" :frozen="col.frozen">
                        <template #editor="{ data }">
                            <InputText
                                v-if="!notInputElement.includes(col.type) && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model="data[col.field]" style="width:120px" name="otheredit2" id="input2" autofocus />
                            <Calendar
                                v-if="col.type=='DateTimeString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                :showTime="true" />
                            <Calendar
                                v-if="col.type=='DateString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                :showTime="false" />
                            <CalfDropdown
                                v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || !dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :loadAll="true" :dictObjName="col.dictName"
                                 placeholder="请选择" />
                            <CalfDropdown
                                v-if="(dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :childtablename="item.name" :rowid="data['id']" :currentdata="data" :dropchangeAfter="childElementdropchangeAfter" :loadAll="true" :dictObjName="col.dictName"
                                placeholder="请选择" />
                            <CalfInputDropdown
                                v-if="col.type=='InputDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                :dictObjName="col.dictName" name="otheredit2" :canEditable="true" />
                            <CalfTreeDropdown
                                v-if="col.type=='TreeDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                :dictObjName="col.dictName" name="otheredit2" />
                                <LinkageDropDown
                                v-if="col.type=='LinkageDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:selectedValue1="data[col.dictCode]" v-model:selectedTxt1="data[col.dictLabel]" v-model:selectedValue2="data[col.dictCode2]" v-model:selectedTxt2="data[col.dictLabel2]"
                                v-model:selectedValue3="data[col.dictCode3]" name="otheredit2" v-model:selectedTxt3="data[col.dictLabel3]" v-model:bindselectedTxt="col.bindselectedTxt" :currentfieldname="col.field" :currentitem="col" :dictItems="getDictData(col.dictName)" />
                                <UploadInput
                                v-if="col.type=='UpLoad' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                v-model:filePatch="data[col.field]" ctrlname="otheredit2" v-model:fileName="data[col.fileName]" :fileType="col.fileType" :uptype="col.uptype" :attachttype="col.attachttype" />
                                <div v-if="col.type=='LabelString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" class="p-mb-3 p-text-left">
                                {{data[col.field]}}</div>
                            <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type!='UpLoad'" class="p-mb-3 p-text-left">
                                {{formatData(col,data[col.field],data,col.field)}}</div>
                            <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType=='img'" class="p-mb-3 p-text-left">
                                <Image :src="getFilePatch(data[col.field])" width="30" preview />
                            </div>
                            <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType!='img'" class="p-mb-3 p-text-left">
                                    <a :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                            </div>
                        </template>
                        <template #body="{data}">
                            <template  v-if="col.type=='DateTimeString' || col.type=='DateString' || col.type=='Dictionary' || col.type=='TreeDict' || col.type=='InputDict' || col.type=='LinkageDict' || col.type=='UpLoad'">
                               <label v-if="col.type!='UpLoad'">{{formatData(col,data[col.field],data,col.field)}}</label>
                               <Image v-if="col.type=='UpLoad' && col.fileType=='img'" :src="getFilePatch(data[col.field])" width="30" preview />
                               <a v-if="col.type=='UpLoad' && col.fileType!='img'" :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                            </template>
                            <template v-if="col.type!='DateTimeString' && col.type!='DateString' && col.type!='Dictionary' && col.type!='TreeDict' && col.type!='InputDict' && col.type!='LinkageDict' && col.type!='UpLoad'">
                              <label class="oneline"
                                 v-tooltip.top="{ value: data[col.field], disabled: false }">{{data[col.field]}}</label>
                            </template>
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
        </TabView>
    </div>
    <div class="card" v-if="!showWait && !isReadOnly && !elementAuthority[approvalresultproname] && approvalresult.details">
        <h5>处理结果</h5>
        <div class="grid" style="width:200px">
            <div v-for="approvaldict of approvalresult.dict" :key="approvaldict.value" class="col-12 md:col-6">
                <div class="field-radiobutton">
                    <RadioButton :id="approvaldict.value" name="approvalcategory" :value="approvaldict.value"
                        v-model="approvalresultValue" />
                    <label :for="approvaldict.value">{{approvaldict.label}}</label>
                </div>
            </div>
        </div>
        <h5 v-if="viewdata.categoryname && viewdata.details.length>0">{{viewdata.categoryname}}</h5>
        <Textarea v-if="viewdata.details.length==1" v-model="viewdata.details[0].content" rows="3" cols="120"/>
        <TabView v-if="viewdata.details.length>1" @tab-click="viewTabClick" class="tabview-custom">
            <TabPanel v-for="item in viewdata.details" :key="item.id">
                <template #header>
                    <Checkbox :id="item.id" :name="item.id" :value="item.uniqueid" v-model="selectViews" />
                    <span>{{item.uniquename}}</span>
                </template>
                <Textarea v-model="item.content" rows="3" cols="120"/>
            </TabPanel>
        </TabView>
    </div>
     <Dialog header="流转信息" v-model:visible="showFlowDialog" :style="{width: '70vw'}"
        :maximizable="false" :modal="true">
        <p class="p-m-0">
            <FlowEngine ref="flowengine" :id="id" :boxid="boxid" :workflowid="workflowid" :flowFreshListMethod="freshListMethod" :closeWinowMehod="closeParentMehod"  />
        </p>
    </Dialog>
     <Dialog header="数据导入" v-model:visible="showImportDataDialog" :style="{width:'80vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <DataImport ref="dataimport" :targetid="currenttargetid" :freshParentData="importfreshParentData" :getNewId="getNewId" :addQueryCondition="importAddQueryConditionMethod" :addCols="importAddColsMethod" v-model:showImportDataDialog="showImportDataDialog" />
        </p>
    </Dialog>
</template>
<style lang="scss" scoped>
.product-image {
    width: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}
.oneline {
    width: 150px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.labelTitle{
    font-weight:bold;
}
</style>                  
<script>
    import {
        ref,
    } from 'vue';
    import commonJs from '../js/Common';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    import Image from 'primevue/image';
    import Editor from 'primevue/editor';
    import CalfAtuNumber from './CalfAtuNumber.vue';
    import CalfDropdown from './CalfDropdown.vue';
    import CalfInputDropdown from './CalfInputDropdown.vue';
    import CalfMultiSelect from './CalfMultiSelect.vue';
    import CalfTreeDropdown from '../components/CalfTreeDropdown.vue';
    import LinkageDropDown from '../components/LinkageDropDown.vue';
    import DataImport from '../components/DataImport.vue';
    import FlowEngine from '../components/FlowEngine.vue';
    import UploadInput from '../components/UploadInput.vue';
    export default {
        name: 'UniversalPage',
         setup() {
            const editorMenu = ref();
            const editorMenuItems = [];
            const toggle = (event) => {
                editorMenu.value.toggle(event);
            };
            return {
                editorMenuItems,
                editorMenu,
                toggle
            };
        },
        data() {
            return {
                showWait:true,
                currenttargetid:'',
                currenttargetname:'',
                showImportDataDialog:false,
                showFlowDialog:false,
                //newId: 2,
                startEdit:{},
                activeIndex: 0,
                expandeactiveIndex:0,
                expandeactiveIndex2:0,
                butLoading: false,
                modulename: '',
                id: '',
                workflowid: '',
                boxid: '',
                flowid: '',
                stateid: '',
                flowguid: '',
                preViewTabIndex: 0,
                originalData: null,
                elementTypeData: {},
                selectedRowData: ref(),
                expandedRowsData:ref(),
                selectViews: ref([]),
                saveloading: false,
                dicts: {},
                approvalresultValue: 1,
                elements: [],
                textareaElements: [],
                noshowelements: {},
                childelements: [],
                alonechildelements: [],
                expandedata:{},
                expandedata2:{},
                childelementsorder: [],
                childImportDataConfig:{},
                childMoudleGraphqlmaps: [],
                elementauthoritymaps:[],
                childModuleStructure: [],
                elementAuthority: {},
                elementVisible: {},
                flowhistory: null,
                approvalresult: {},
                approvalresultproname: '',
                viewdata: {},
                childTableFooterObj:ref(),
                importItems:{},
                notInputElement: ['DateString','LabelString', 'DateTimeString', 'Dictionary', 'AtuNumber','TreeDict','MultiSelect', 'InputDict','LinkageDict','UpLoad'],
                timeType: ['DateString', 'DateTimeString'],
                flowInfoType: ['flowhistorygroup', 'approvalresult'],
                flowViewType: ['shenpiview', 'tuihuiview', 'shenheview', 'banliview'],
                baseType: ['String', 'ID', 'Decimal', 'Int', 'Long', 'DateString', 'DateTimeString',
                    'Dictionary','AtuNumber','LabelString','TreeDict','MultiSelect', 'InputDict', 'Textarea','LinkageDict','UpLoad'
                ],
                excludeElements: ['boxid', 'ischeck', 'isdel', 'workflowid']
            }
        },
        created() {
            console.log('UniversalPage--created');
            this.loadModuleStructure();
        },
        props: ['currentRow','handleDataBeforeMethod','loadDataAfterMethod','childaloneshowConfig','initDetailMethod','editorConfig','mainelementCss','CellEditCompleteMethod','CellEditCompleteExpandeMethod','childTableFooter','dropOtherConfig','noLoadChild','mainUdefinedAuth','childExpandeConfig','childNewDataConfig','childDelDataConfig','childUdefinedAuth', 'isReadOnly', 'closeButLoadingMethod', 'freshListMethod','saveBeforeMethod','importAddQueryConditionMethod','importAddColsMethod','closeParentMehod','getChildTableColWidthMehod','CellEditInit'],
        methods: {
            expandertoggle2(event,data){
               console.log(data);
               this.expandedata2=data;
               this.expandeactiveIndex2=0;
               this.$refs.op2.toggle(event);
            },
            expandertoggle(event,data){
              console.log(data);
              this.expandedata=data;
              this.expandeactiveIndex=0;
              this.$refs.op.toggle(event);
            },
            loadModuleStructure() {
                console.log('dropOtherConfig:');
                console.log(this.dropOtherConfig);
                console.log('UniversalPage--loadModuleStructure');
                console.log(this.currentRow);
                this.elementauthoritymaps=[];
                if(this.initDetailMethod){
                    this.editorMenuItems=[];
                    this.initDetailMethod(this.editorMenuItems);
                }
                this.childTableFooterObj=this.childTableFooter;
                this.butLoading = false;
                this.showFlowDialog=false;
                this.activeIndex = 0;
                this.startEdit={'startedit':false};
                //this.newId = 2;
                this.modulename = this.currentRow.bindpage;
                this.id = this.currentRow.id;
                this.workflowid = this.currentRow.workflowid;
                this.flowguid = this.currentRow.flowguid;
                if (this.currentRow.boxid) {
                    this.boxid = this.currentRow.boxid;
                } else {
                    this.boxid = '-1';
                }
                if (this.currentRow.stateid) {
                    this.stateid = this.currentRow.stateid;
                }
                if (this.currentRow.flowid) {
                    this.flowid = this.currentRow.flowid;
                }
                if (this.id == '') this.id = '-1';
                if (this.workflowid == '') this.workflowid = '-1';
                if (this.boxid == '') this.boxid = '-1';
                this.childelements=[];
                this.alonechildelements=[];

                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{__type(name: "' + this.modulename +
                    '"){name description dictconfiginfo fields { name description dictconfiginfo type { name description }} } }'
                ).then(res => {
                    if (res.errcode == "0") {
                        var mainModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data
                            .__type;
                        this.loadModuleData(mainModuleObj);
                    } else {
                        MessageTip.warnmsg('类型加载失败');
                    }
                });
            },
            loadModuleData(v_mainModuleObj) {
                var graphqlFeild = {};
                var childStructure = [];
                for (var i = 0; i < v_mainModuleObj.fields.length; i++) {
                    if (this.baseType.includes(v_mainModuleObj.fields[i].type.name) && !this.excludeElements
                        .includes(
                            v_mainModuleObj.fields[i].name)) {
                        graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:''};
                        if (v_mainModuleObj.fields[i].type.name == 'MultiSelect'){
                            graphqlFeild[v_mainModuleObj.fields[i].name]['val']='{id code name parentid}';
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'Dictionary' || v_mainModuleObj.fields[i].type.name == 'MultiSelect' || v_mainModuleObj.fields[i].type.name == 'LinkageDict') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]['dictconfiginfo']=v_mainModuleObj.fields[i].dictconfiginfo;
                            //this.addDictCategories(v_mainModuleObj.fields[i].dictconfiginfo);
                        }
                    } else {
                        if (v_mainModuleObj.fields[i].type.name.substr(0, 1) == '[' && v_mainModuleObj.fields[i].type.name != '[flowhistorygroup]') {
                            var childStructureobj={name:v_mainModuleObj.fields[i].name,typename:v_mainModuleObj.fields[i].type.name.replace('[', '').replace(']', '')};
                            childStructure.push(childStructureobj);
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'flowhistorygroup') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{uniqueid uniquename details {approver approvetime nodename approvecontent viewcontent status}}'};
                        }
                        if (v_mainModuleObj.fields[i].type.name == '[flowhistorygroup]') {
                             graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{uniqueid uniquename details {approver approvetime nodename approvecontent viewcontent status}}'};
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'approvalresult') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{column table dict {label value} details {id ischeck resultvalue uniqueid uniquename}}'};
                            this.approvalresultproname = v_mainModuleObj.fields[i].name;
                        }
                        if (this.flowViewType.includes(v_mainModuleObj.fields[i].type.name)) {
                             graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{categoryid categoryname details {id ischeck content uniqueid uniquename}}'};
                        }
                    }
                }
                if (childStructure.length > 0) {
                    for(var k=0;k<childStructure.length;k++){
                        this.childelementsorder.push(childStructure[k].name);
                        this.loadChildStructure(childStructure[k],childStructure,graphqlFeild,v_mainModuleObj);
                    }
                } else {
                    this.loadElementAuthority(childStructure, graphqlFeild, v_mainModuleObj);
                }
            },
            dropchangeAfter(v_elementName,v_othervalue,v_childtablename,v_currentdata,v_value,v_dictsource){
                console.log('dropchangeAfter....');
                console.log(v_othervalue);
                console.log(v_childtablename);
                console.log(v_currentdata);
                console.log(v_value);
                console.log(v_dictsource);
                if(this.dropOtherConfig && this.dropOtherConfig[v_elementName] && this.dropOtherConfig[v_elementName]!=''){
                  for(var i=0;i<this.elements.length;i++){
                     if(this.elements[i].name==this.dropOtherConfig[v_elementName]){
                         this.elements[i].val=v_othervalue;
                         break;
                     }
                  }
                }
                if(this.dropOtherConfig && this.dropOtherConfig[v_elementName+'fun']){
                    this.dropOtherConfig[v_elementName+'fun'](this.childelements,v_value,v_dictsource);
                }
            },
            childElementdropchangeAfter(v_elementName,v_value,v_childtablename,v_currentdata){
               if(this.dropOtherConfig && this.dropOtherConfig[v_childtablename]){
                    if(this.dropOtherConfig[v_childtablename][v_elementName] && this.dropOtherConfig[v_childtablename][v_elementName]!=''){
                         var setcolname= this.dropOtherConfig[v_childtablename][v_elementName];
                         v_currentdata[setcolname]=v_value;
                    }
               }
            },
            loadData(v_graphqlFeild, v_mainModuleObj) {
                console.log('loadData:....');
                console.log(v_graphqlFeild);
                console.log(this.elementVisible);
                console.log(this.elementAuthority);
                var graphqlFeild='';
                for(var gpro in v_graphqlFeild){
                    if(this.elementVisible[gpro]){
                        if(graphqlFeild==''){
                          graphqlFeild=gpro;
                        }else{
                          graphqlFeild=graphqlFeild+' '+gpro;
                        }
                        if(v_graphqlFeild[gpro]['type']=='MultiSelect'){
                           graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if(v_graphqlFeild[gpro]['type']=='Dictionary' || v_graphqlFeild[gpro]['type'] == 'MultiSelect' || v_graphqlFeild[gpro]['type'] == 'LinkageDict'){
                           this.addDictCategories(v_graphqlFeild[gpro]['dictconfiginfo']);
                        }                      
                        if (v_graphqlFeild[gpro]['type'] == 'flowhistorygroup') {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (v_graphqlFeild[gpro]['type'] == '[flowhistorygroup]') {
                              graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (v_graphqlFeild[gpro]['type'] == 'approvalresult') {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (this.flowViewType.includes(v_graphqlFeild[gpro]['type'])) {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if(v_graphqlFeild[gpro]['type']=='child'){
                            graphqlFeild=graphqlFeild+'{';
                            for(var n2=0;n2<v_graphqlFeild[gpro]['val'].length;n2++){
                                 var childInfo=v_graphqlFeild[gpro]['val'][n2];
                                 if(this.elementVisible[gpro+'.'+childInfo['name']]){
                                      if (childInfo.type =='childExpandeConfig') {
                                           graphqlFeild=graphqlFeild+' '+childInfo['val'];
                                      }else{
                                        graphqlFeild=graphqlFeild+' '+childInfo['name'];
                                      }
                                      if (childInfo.type =='Dictionary' || childInfo.type =='LinkageDict') {
                                        this.addDictCategories(childInfo['dictconfiginfo']);
                                      }
                                 }
                            }
                            graphqlFeild=graphqlFeild+'  isdel ischeck }';
                        }
                    }
                }
                this.loadDict();
                var graphql = '{' + this.modulename +
                    '(id:"' + this.id +
                    '",workflowid:"' + this.workflowid +
                    '",boxid:"' + this.boxid +
                    '",flowguid:"' + this.flowguid + '"){' + graphqlFeild + '}}';
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var childObjData = [];
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        if(this.handleDataBeforeMethod){
                            dataObj=this.handleDataBeforeMethod(dataObj);
                        }
                        this.originalData = dataObj;
                        console.log(dataObj);
                        for (var mainpro in dataObj) {
                            var mainData = dataObj[mainpro];
                            console.log(mainData);
                            for (var pro in mainData) {
                                if (mainData[pro] != null && typeof (mainData[pro]) !=
                                    'undefined') {
                                    var findResult = this.findElementName(pro, v_mainModuleObj);
                                    if (findResult.labelName != '' && !findResult.isChildObj) {
                                        if (this.timeType.includes(findResult.ctrType)) {
                                            if (mainData[pro] == '') {
                                                var mainelement1={
                                                    label: findResult.labelName,
                                                    val: '',
                                                    dictName: '',
                                                    dictCode:'',
                                                    dictLabel: '',
                                                    dictCode2: '',
                                                    dictLabel2: '',
                                                    dictCode3: '',
                                                    dictLabel3: '',
                                                    bindselectedTxt:false,
                                                    name: pro,
                                                    type: findResult.ctrType,
                                                    class:'',
                                                    tip:'',
                                                };
                                                this.elements.push(mainelement1);

                                            } else {
                                                var mainelement2={
                                                    label: findResult.labelName,
                                                    val: new Date(mainData[pro]),
                                                    dictName: '',
                                                    dictCode:'',
                                                    dictLabel: '',
                                                    dictCode2: '',
                                                    dictLabel2: '',
                                                    dictCode3: '',
                                                    dictLabel3: '',
                                                    bindselectedTxt:false,
                                                    name: pro,
                                                    type: findResult.ctrType,
                                                    class:'',
                                                    tip:mainData[pro],
                                                };
                                                this.elements.push(mainelement2);
                                            }
                                        } else {
                                            if (!this.flowInfoType.includes(findResult.ctrType) && !
                                                this
                                                .flowViewType.includes(findResult.ctrType) &&
                                                findResult
                                                .ctrType != '[flowhistorygroup]') {
                                                if (findResult.ctrType == 'Dictionary' || findResult.ctrType =='TreeDict' || findResult.ctrType =='MultiSelect' || findResult.ctrType =='InputDict' || findResult.ctrType =='LinkageDict') {
                                                    if(findResult.ctrType =='MultiSelect'){
                                                        var mainelement3={
                                                            label: findResult.labelName,
                                                            val: mainData[pro],
                                                            dictName: findResult.dictName,
                                                            dictCode: findResult.dictCode,
                                                            dictLabel: findResult.dictLabel,
                                                            dictCode2: findResult.dictCode2,
                                                            dictLabel2: findResult.dictLabel2,
                                                            dictCode3: findResult.dictCode3,
                                                            dictLabel3: findResult.dictLabel3,
                                                            bindselectedTxt:findResult.bindselectedTxt,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            class:'',
                                                            tip:'',
                                                        };
                                                        this.elements.push(mainelement3);
                                                    }else{
                                                        var mainelement4={
                                                            label: findResult.labelName,
                                                            val: mainData[pro].toString(),
                                                            dictName: findResult.dictName,
                                                            dictCode: findResult.dictCode,
                                                            dictLabel: findResult.dictLabel,
                                                            dictCode2: findResult.dictCode2,
                                                            dictLabel2: findResult.dictLabel2,
                                                            dictCode3: findResult.dictCode3,
                                                            dictLabel3: findResult.dictLabel3,
                                                            bindselectedTxt:findResult.bindselectedTxt,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            class:'',
                                                            tip:mainData[pro].toString(),
                                                        };
                                                        this.elements.push(mainelement4);
                                                    }
                                                    if(findResult.ctrType =='LinkageDict'){
                                                      this.noshowelements[pro] = mainData[pro];
                                                    }
                                                } else {
                                                    if (findResult.ctrType == 'Textarea') {
                                                        var mainelement5={
                                                            label: findResult.labelName,
                                                            val: mainData[pro],
                                                            dictName: '',
                                                            dictCode:'',
                                                            dictLabel: '',
                                                            dictCode2: '',
                                                            dictLabel2: '',
                                                            dictCode3: '',
                                                            dictLabel3: '',
                                                            bindselectedTxt:false,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            tip:'',
                                                        };
                                                        this.textareaElements.push(mainelement5);

                                                    } else {
                                                        if(findResult.ctrType =='UpLoad'){
                                                           var mainelement6={
                                                                label: findResult.labelName,
                                                                val: mainData[pro].toString(),
                                                                fileType: findResult.fileType,
                                                                uptype: findResult.uptype,
                                                                attachttype: findResult.attachttype,
                                                                name: pro,
                                                                type: findResult.ctrType,
                                                                fileName:findResult.fileName,
                                                                class:'',
                                                                tip:'',
                                                           };
                                                            this.elements.push(mainelement6);
                                                        }
                                                        else{
                                                            var mainelement7={
                                                                label: findResult.labelName,
                                                                val: mainData[pro],
                                                                dictName: '',
                                                                dictCode:'',
                                                                dictLabel: '',
                                                                dictCode2: '',
                                                                dictLabel2: '',
                                                                dictCode3: '',
                                                                dictLabel3: '',
                                                                bindselectedTxt:false,
                                                                name: pro,
                                                                type: findResult.ctrType,
                                                                class:'',
                                                                tip:mainData[pro],
                                                            };
                                                            this.elements.push(mainelement7);
                                                        }
                                                    }
                                                }
                                            } else {
                                                if (findResult.ctrType == 'flowhistorygroup') {
                                                    this.flowhistory = mainData[pro];
                                                }
                                                if (findResult.ctrType == '[flowhistorygroup]') {
                                                    this.flowhistory = mainData[pro];
                                                }
                                                if (findResult.ctrType == 'approvalresult') {
                                                    this.approvalresult = mainData[pro];
                                                }
                                                if (this.flowViewType.includes(findResult
                                                        .ctrType)) {
                                                    this.viewdata = mainData[pro];
                                                    if (this.viewdata.details) {
                                                        for (var i = 0; i < this.viewdata.details
                                                            .length; i++) {
                                                            this.selectViews.push(this.viewdata
                                                                .details[i]
                                                                .uniqueid);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        if (findResult.isChildObj) {
                                            childObjData.push({
                                                name: pro,
                                                data: mainData[pro],
                                                description: findResult.labelName
                                            });
                                        } else {
                                            this.noshowelements[pro] = mainData[pro];
                                        }
                                    }
                                }
                            }
                        }
                        this.addChildElements(childObjData);
                        if(this.loadDataAfterMethod){
                            this.loadDataAfterMethod(this.elements,this.childelements,this.originalData,this.noshowelements);
                        }
                        this.showWait=false;

                    } else {
                        MessageTip.warnmsg('数据加载失败');
                    }
                });
            },
            loadElementAuthority(v_childStructure, v_graphqlFeild, v_mainModuleObj) {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'schemaname',
                        value: this.modulename,
                        operation: '='
                    }, {
                        name: 'id',
                        value: this.id,
                        operation: '='
                    }, {
                        name: 'searchstateid',
                        value: this.stateid,
                        operation: '='
                    }, {
                        name: 'searchflowid',
                        value: this.flowid,
                        operation: '='
                    }]
                };
                var graphql = '{elementauthoritylist(where:' + JSON.stringify(dictlistwhere) +
                    '){modulename feildname readonly visible}}';
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql).then(
                    res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            this.elementVisible['createrid']=true;
                            this.elementVisible['creater']=true;
                            this.elementVisible['createtime']=true;
                            this.elementVisible['id']=true;
                            this.elementVisible['parentid']=true;
                            for (var i = 0; i < dataObj.elementauthoritylist.length; i++) {
                                this.elementAuthority[dataObj.elementauthoritylist[i].feildname] =
                                    dataObj
                                    .elementauthoritylist[i].readonly;
                                this.elementVisible[dataObj.elementauthoritylist[i].feildname] =
                                    dataObj
                                    .elementauthoritylist[i].visible;
                            }
                            if (v_childStructure.length == 0) {
                                console.log('loadData...1');
                                this.loadData(v_graphqlFeild, v_mainModuleObj);
                            }else{
                                for(var k=0;k<v_childStructure.length;k++){
                                    this.loadChildObjAth(v_childStructure[k].name,v_graphqlFeild,v_mainModuleObj,v_childStructure);
                                }
                            }
                        } else {
                            MessageTip.warnmsg('权限数据加载失败');
                        }
                    });
            },
            loadChildObjAth(v_name,v_graphqlFeild, v_mainModuleObj,v_childStructure) {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'schemaname',
                        value: v_name,
                        operation: '='
                    }, {
                        name: 'parentschemaname',
                        value: this.modulename,
                        operation: '='
                    }, {
                        name: 'id',
                        value: '',
                        operation: '='
                    }, {
                        name: 'searchstateid',
                        value: this.stateid,
                        operation: '='
                    }, {
                        name: 'searchflowid',
                        value: this.flowid,
                        operation: '='
                    }]
                };
                var graphql = '{elementauthoritylist(where:' + JSON.stringify(dictlistwhere) +
                    '){modulename feildname readonly visible}}';
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"), graphql).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        if (dataObj && dataObj.elementauthoritylist) {
                            for (var i = 0; i < dataObj.elementauthoritylist.length; i++) {
                                this.elementAuthority[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.' + dataObj
                                        .elementauthoritylist[i]
                                        .feildname] = dataObj.elementauthoritylist[i].readonly;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.' + dataObj
                                        .elementauthoritylist[i]
                                        .feildname] = dataObj.elementauthoritylist[i].visible;
                                
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.createrid']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.creater']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.createtime']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.id']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.parentid']=true;
                            }
                            this.elementauthoritymaps.push(v_name);
                            if(this.elementauthoritymaps.length==v_childStructure.length){
                               this.loadData(v_graphqlFeild, v_mainModuleObj);
                            }
                        } else {
                            MessageTip.warnmsg('子权限数据加载异常');
                        }
                    } else {
                        MessageTip.warnmsg('子对象权限信息加载失败');
                    }
                });
            },
            loadChildStructure(v_childStructureObj,v_childStructure,v_graphqlFeild,v_mainModuleObj){           
                
                    this.loadImportConfig(v_childStructureObj.typename,v_childStructureObj.name);
                    
                    requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),'{__type(name: "' + v_childStructureObj.typename +
                                    '"){name description fields { name dictconfiginfo description type { name description }} } }').then(res => {
                    if (res.errcode == "0") {
                        var childModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data.__type;
                        console.log('childModuleObj-1');
                        console.log(childModuleObj);
                        console.log(v_childStructureObj);
                        console.log(v_childStructure);
                        console.log(v_graphqlFeild);
                        childModuleObj.name=v_childStructureObj.name;
                        this.childModuleStructure.push(childModuleObj);
                        v_graphqlFeild[v_childStructureObj.name]={type:'child',val:[]};
                        var hasAddchildExpande={};
                        //var childGraphqlFeild =  v_childStructureObj.name + '{';
                        for (var i = 0; i < childModuleObj.fields.length; i++) {
                            if (this.baseType.includes(childModuleObj.fields[i].type.name) && !this.excludeElements.includes(childModuleObj.fields[i].name)) {
                                var childInfo1={type:childModuleObj.fields[i].type.name,name:childModuleObj.fields[i].name};
                                if (childModuleObj.fields[i].type.name =='Dictionary' || childModuleObj.fields[i].type.name =='LinkageDict') {
                                    childInfo1['dictconfiginfo']=childModuleObj.fields[i].dictconfiginfo;
                                    //this.addDictCategories(childModuleObj.fields[i].dictconfiginfo);
                                }
                                v_graphqlFeild[v_childStructureObj.name]['val'].push(childInfo1);
                            }else{
                                if(!hasAddchildExpande[v_childStructureObj.name] && this.childExpandeConfig && this.childExpandeConfig[v_childStructureObj.name]){
                                    for(var i2=0;i2<this.childExpandeConfig[v_childStructureObj.name]['config'].length;i2++){
                                         var childExpandeConfigObj=this.childExpandeConfig[v_childStructureObj.name]['config'][i2];
                                         var childInfo2={type:'childExpandeConfig',val:childExpandeConfigObj['feild'],name:childExpandeConfigObj['data']};
                                         v_graphqlFeild[v_childStructureObj.name]['val'].push(childInfo2);
                                    }
                                    hasAddchildExpande[v_childStructureObj.name]="1";
                                }
                            }
                        }
                        //childGraphqlFeild = childGraphqlFeild + ' isdel ischeck } ';
                        this.childMoudleGraphqlmaps.push({name:v_childStructureObj.name});
                        if(this.childMoudleGraphqlmaps.length==v_childStructure.length){
                            this.loadElementAuthority(v_childStructure, v_graphqlFeild,v_mainModuleObj);
                        }
                    } else {
                        MessageTip.warnmsg(v_childStructureObj.typename+'类型加载失败');
                    }
                });
            },
            findElementName(v_name, v_moduleObj) {
                var result = {
                    labelName: '',
                    isChildObj: false,
                    dictName: '',
                    dictCode:'',
                    dictLabel: '',
                    ctrType: '',
                    dictCode2: '',
                    dictLabel2: '',
                    dictCode3: '',
                    dictLabel3: '',
                    bindselectedTxt:false,
                    fileName:'',
                    fileType:'',
                    uptype:'',
                    attachttype:'',
                };
                if (v_name != "id") {
                    //var regx = /^[^A-Za-z0-9]*$/;
                    var regx=/^[^0-9]*$/;
                    for (var i = 0; i < v_moduleObj.fields.length; i++) {
                        if (v_moduleObj.fields[i].name == v_name) {
                            if (v_moduleObj.fields[i].type.name != '[flowhistorygroup]') {
                                if (regx.test(v_moduleObj.fields[i].description) && v_moduleObj.fields[i].description!='GraphQL.Types.FieldType') {
                                    result.labelName = v_moduleObj.fields[i].description;
                                    result.ctrType = v_moduleObj.fields[i].type.name;
                                    var dictArray;
                                    if (v_moduleObj.fields[i].type.name == 'Dictionary' || v_moduleObj.fields[i].type.name == 'MultiSelect' || v_moduleObj.fields[i].type.name == 'InputDict' || v_moduleObj.fields[i].type.name == 'TreeDict') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result.dictName = dictArray[0];
                                        result.dictLabel = dictArray[1];
                                    }
                                    if (v_moduleObj.fields[i].type.name == 'UpLoad') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result['fileName'] = dictArray[0];
                                        result['fileType'] = dictArray[1];
                                        result['uptype'] = dictArray[2];
                                        result['attachttype'] = dictArray[3];
                                    }
                                    if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result.dictName = dictArray[0];
                                        result.dictCode = dictArray[1];
                                        result.dictLabel = dictArray[2];
                                        if(dictArray.length>3){
                                         result.dictCode2 = dictArray[3];
                                        }
                                        if(dictArray.length>4)
                                         result.dictLabel2 = dictArray[4];
                                        if(dictArray.length>5)
                                         result.dictCode3 = dictArray[5];
                                        if(dictArray.length>6)
                                         result.dictLabel3 = dictArray[6];
                                    }
                                    if (v_moduleObj.fields[i].type.name.substr(0, 1) == '[') {
                                        result.isChildObj = true;
                                    }
                                    this.elementTypeData[v_name] = {
                                        ctrType: result.ctrType,
                                        isChildObj: result.isChildObj
                                    };
                                }
                            } else {
                                result.ctrType = v_moduleObj.fields[i].type.name;
                                result.labelName = v_moduleObj.fields[i].description;
                                this.elementTypeData[v_name] = {
                                    ctrType: result.ctrType,
                                    isChildObj: result.isChildObj
                                };
                            }
                            break;
                        }
                    }
                }
                if(result.dictLabel!='' || result.dictLabel2!='' || result.dictLabel3!=''){
                    result.bindselectedTxt=true;
                }
                return result;
            },
            addChildElements(v_childObjData) {
                console.log('addChildElements:');
                console.log(v_childObjData);
                console.log(this.childelementsorder);
                var newchildObjData=[];
                for(var n1=0;n1< this.childelementsorder.length;n1++){
                   for(var n2=0;n2< v_childObjData.length; n2++) {
                      if(v_childObjData[n2].name==this.childelementsorder[n1]){
                          newchildObjData.push(v_childObjData[n2]);
                          break;
                      }
                   }
                }
                v_childObjData=newchildObjData;
                console.log('排序后:');
                console.log(v_childObjData);
                //v_childObjData.sort(function(a,b){ return a.name.localeCompare(b.name)});
                for (var i = 0; i < v_childObjData.length; i++) {
                    var childObj = {
                        name: v_childObjData[i].name,
                        header: v_childObjData[i].description,
                        columns: [],
                        rows: [],
                        templaterows: [],
                        delrows: []
                    };
                    var childStructure = null;
                    for (var n = 0; n < this.childModuleStructure.length; n++) {
                        if (this.childModuleStructure[n].name == v_childObjData[i].name) {
                            childStructure = this.childModuleStructure[n];
                            break;
                        }
                    }
                    var timeCol = [];
                    var templateRow={};
                    for(var pro2=0; pro2 < childStructure.fields.length; pro2++){
                        var findResult = this.findElementName(childStructure.fields[pro2].name , childStructure);
                        if (findResult.labelName != '') {
                            var isfrozen = false;
                            if (childObj.columns.length == 0) {
                                isfrozen = true;
                            }
                            if (this.timeType.includes(findResult.ctrType)) {
                                timeCol.push(childStructure.fields[pro2].name);
                            }
                            if(this.elementVisible[childObj.name+'.'+childStructure.fields[pro2].name]){
                                var childcolumnelement={
                                    field: childStructure.fields[pro2].name,
                                    header: findResult.labelName,
                                    frozen: isfrozen,
                                    type: findResult.ctrType,
                                    dictName: findResult.dictName,
                                    dictCode: findResult.dictCode,
                                    dictLabel: findResult.dictLabel,
                                    dictCode2: findResult.dictCode2,
                                    dictLabel2: findResult.dictLabel2,
                                    dictCode3: findResult.dictCode3,
                                    dictLabel3: findResult.dictLabel3,
                                    bindselectedTxt:findResult.bindselectedTxt,
                                    fileName:findResult.fileName,
                                    fileType:findResult.fileType,
                                    uptype:findResult.uptype,
                                    attachttype:findResult.attachttype,
                                };
                                childObj.columns.push(childcolumnelement);
                            }
                        }
                        templateRow[childStructure.fields[pro2].name]='';
                    }
                    if (childObj.templaterows.length == 0) {
                        childObj.templaterows.push(templateRow);
                    }
                    for (var j = 0; j < v_childObjData[i].data.length; j++) {
                        var row = v_childObjData[i].data[j];
                        for (var k = 0; k < timeCol.length; k++) {
                            if (row[timeCol[k]] != '') {
                                row[timeCol[k]] = new Date(row[timeCol[k]]);
                            }
                        }
                        childObj.rows.push(row);
                    } 
                    if(this.noLoadChild && this.noLoadChild.length>0){
                       var noadd=false;
                       for(var k2=0;k2<this.noLoadChild.length;k2++){
                          if(childObj.name==this.noLoadChild[k2]){
                             noadd=true;
                             break;
                          }
                       }
                       if(!noadd){
                           if(this.childaloneshowConfig && this.childaloneshowConfig[childObj.name] && this.childaloneshowConfig[childObj.name]=='1'){
                             this.alonechildelements.push(childObj);
                           }else{
                             this.childelements.push(childObj);
                           }
                       }
                    }else{
                        if(this.childaloneshowConfig && this.childaloneshowConfig[childObj.name] && this.childaloneshowConfig[childObj.name]=='1'){
                           this.alonechildelements.push(childObj);
                        }else{
                           this.childelements.push(childObj);
                        }
                    }
                }
                this.initSumInfo(this.childelements);
                this.initSumInfo(this.alonechildelements);
            },
            addDictCategories(v_dictName) {
                if (v_dictName != '') {
                    console.log('addDictCategories:'+v_dictName);
                    var array=v_dictName.split('|');
                    var dictObjarray = array[0].split('^');
                    if(dictObjarray.length>1){
                       this.dicts[dictObjarray[1]] = [];
                    }else{
                       this.dicts[dictObjarray[0]] = [];
                    }                  
                }
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                for (var proname in this.dicts) {
                    if (proname != '') {
                        var dictObjarray = proname.split('^');
                         if (dictObjarray.length > 1) {
                              dictlistwhere.conditions.push({
                               name: 'Categories',
                               value: dictObjarray[1],
                               operation: ''
                             });
                         }else{
                             dictlistwhere.conditions.push({
                               name: 'Categories',
                               value: dictObjarray[0],
                               operation: ''
                             });
                         } 
                    }
                }
                if (dictlistwhere.conditions.length > 0) {
                    var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                        '){name details {name code child {name code child {name code child} } }}}';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                                this.dicts[dataObj.dictionarylist[k].name] = dataObj.dictionarylist[
                                    k].details;
                            }
                        } else {
                            MessageTip.warnmsg('字典数据加载失败');
                        }
                    });
                }
            },
            getDictData(v_dictName) {
                console.log('getDictData:'+v_dictName);
                console.log(this.dicts);
                var dictName='';
                var array=v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if(dictObjarray.length>1){
                    dictName=dictObjarray[1];
                }else{
                    dictName=dictObjarray[0];
                }
                var dictData = [];
                if (this.dicts[dictName]) {
                    dictData = this.dicts[dictName];
                }
                return dictData;
            },
            findLinkageDictValue(v_fieldname){
              for(var i=0;i<this.elements.length;i++){
                if(this.elements[i].name==v_fieldname){
                    return this.elements[i].val;
                }
              }
            },
            formatData(v_colobj, value, v_data,v_currentfieldname) {
                console.log('formatData:' + v_colobj.type);
                var retval=value;
                if (v_colobj.type == 'Dictionary') {
                    retval= this.formatDict(v_colobj.dictName, value);
                }
                if (v_colobj.type == 'MultiSelect') {
                    retval= this.formatMultiSelect(value);
                }
                if (v_colobj.type == 'InputDict' || v_colobj.type == 'TreeDict') {
                    retval= this.formatInputDict(v_colobj.dictLabel, v_data);
                }
                if (v_colobj.type == 'LinkageDict') {
                    retval= this.formatLinkageDictionary(v_colobj, v_data,v_currentfieldname);
                }
                if (v_colobj.type == 'DateTimeString' || v_colobj.type == 'DateString') {
                    retval= commonJs.formatDate(v_colobj.type, value);
                }
                if(v_colobj['tip'] && !v_colobj['differencetip']){
                    v_colobj['tip']=retval;
                }
                return retval;
            },
            formatInputDict(v_dictLabel, v_data) {
                console.log('formatInputDict:' + v_dictLabel);
                console.log(v_data);
                if (v_data == null) {
                    var txt = '';
                    for (var i = 0; i < this.elements.length; i++) {
                        if (this.elements[i].name == v_dictLabel) {
                            txt = this.elements[i].val;
                            break;
                        }
                    }
                    return txt;
                } else {
                    if (v_data[v_dictLabel]) {
                        return v_data[v_dictLabel];
                    } else {
                        return '';
                    }
                }
            },
            formatMultiSelect(value) {
                var name='';
                if(value && value.length>0){
                    for(var i=0;i<value.length;i++){
                        if(name==''){
                          name=value[i]['name'];
                        }else{
                          name=name+","+ value[i]['name'];
                        }
                    }
                }
                return name;
            },
            formatDict(v_dictName, value) {
                var name = value;
                var isFind = false;
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var i = 0; i < this.dicts[dictName].length; i++) {
                        if (this.dicts[dictName][i].code == value) {
                            name = this.dicts[dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            formatLinkageDictionary(v_colobj,v_data,v_currentfieldname) {
                var firstname='';
                var secondname='';
                var thirdname='';
                var firstvalue='';
                if(v_colobj.dictCode && v_colobj.dictCode!=''){
                    firstvalue=v_data[v_colobj.dictCode];
                }
                var secondvalue='';
                if(v_colobj.dictCode2 && v_colobj.dictCode2!=''){
                    secondvalue=v_data[v_colobj.dictCode2];
                }
                var thirdvalue='';
                if(v_colobj.dictCode3 && v_colobj.dictCode3!=''){
                    thirdvalue=v_data[v_colobj.dictCode3];
                }
                var isFind = false;
                var dictName = '';
                var dictObjarray = v_colobj.dictName.split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var k = 0; k < this.dicts[dictName].length; k++) {
                        if (this.dicts[dictName][k].code == firstvalue) {
                            firstname = this.dicts[dictName][k].name;
                            if(secondvalue!=''){
                                for(var k2=0;k2<this.dicts[dictName][k].child.length;k2++){
                                    if(this.dicts[dictName][k].child[k2].code == secondvalue){
                                        secondname=this.dicts[dictName][k].child[k2].name;
                                        if(thirdvalue!=''){
                                            for(var k3=0;k3<this.dicts[dictName][k].child[k2].child.length;k3++){
                                                if(this.dicts[dictName][k].child[k2].child[k3].code == thirdvalue){
                                                    thirdname=this.dicts[dictName][k].child[k2].child[k3].name;
                                                    break;
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    if(v_colobj.dictCode==v_currentfieldname){
                        return firstname;
                    }
                    if(v_colobj.dictCode2==v_currentfieldname){
                        return secondname;
                    }
                     if(v_colobj.dictCode3==v_currentfieldname){
                        return thirdname;
                    }
                } else {
                    return '';
                }
            },
            getFilePatch(v_patch){
                 if (v_patch == '' || !v_patch) {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/'+v_patch+'?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            viewTabClick(event) {
                console.log(event);
                if (this.preViewTabIndex == event.index) {
                    if (this.selectViews.includes(this.viewdata.details[event.index].uniqueid)) {
                        for (var i = 0; i < this.selectViews.length; i++) {
                            if (this.selectViews[i] == this.viewdata.details[event.index].uniqueid) {
                                this.selectViews.splice(i, 1);
                                break;
                            }
                        }
                    } else {
                        this.selectViews.push(this.viewdata.details[event.index].uniqueid);
                    }
                }
                this.preViewTabIndex = event.index;
            },
            saveInfoSyn(v_nocallflow){
               if (this.viewdata.details && this.viewdata.details.length > 0) {
                    if (this.viewdata.details.length > 1) {
                        for (var j1 = 0; j1 < this.viewdata.details.length; j1++) {
                            this.viewdata.details[j1].ischeck = '0';
                            this.approvalresult.details[j1].ischeck = '0';
                        }
                        for (var i = 0; i < this.selectViews.length; i++) {
                            for (var j = 0; j < this.viewdata.details.length; j++) {
                                if (this.selectViews[i] == this.viewdata.details[j].uniqueid) {
                                    this.viewdata.details[j].ischeck = '1';
                                }
                                if (this.selectViews[i] == this.approvalresult.details[j].uniqueid) {
                                    this.approvalresult.details[j].ischeck = '1';
                                    this.approvalresult.details[j].resultvalue = this.approvalresultValue;
                                }
                            }
                        }
                    } else {
                        if(this.viewdata && this.viewdata.details && this.viewdata.details.length>0){
                           this.viewdata.details[0].ischeck = '1';
                        }
                        if(this.approvalresult && this.approvalresult.details && this.approvalresult.details.length>0){
                            this.approvalresult.details[0].ischeck = '1';
                            this.approvalresult.details[0].resultvalue = this.approvalresultValue;
                        }
                    }
                }
                for (var mainpro in this.originalData) {
                    var mainData = this.originalData[mainpro];
                    console.log(mainpro);
                    console.log(mainData);
                    for (var pro in mainData) {
                        if (mainData[pro] != null && typeof (mainData[pro]) != 'undefined') {
                            if (this.elementTypeData[pro] && (this.elementTypeData[pro].ctrType ==
                                    'approvalresult' ||
                                    this.flowViewType.includes(this.elementTypeData[pro].ctrType))) {
                                if (this.elementTypeData[pro].ctrType == 'approvalresult') {
                                    mainData[pro] = this.approvalresult;
                                }
                                if (this.flowViewType.includes(this.elementTypeData[pro].ctrType)) {
                                    mainData[pro] = this.viewdata;
                                }
                            } else {
                                if (this.elementTypeData[pro] && this.elementTypeData[pro].isChildObj) {
                                    for (var n = 0; n < this.childelements.length; n++) {
                                        if (this.childelements[n].name == pro) {
                                             if(this.currentRow.isCopy){
                                               for(var k5=0;k5<this.childelements[n].rows.length;k5++){
                                                   this.childelements[n].rows[k5]['id']='-1';
                                                   this.childelements[n].rows[k5]['parentid']='-1';
                                               }
                                            }
                                            mainData[pro] = this.childelements[n].rows;
                                            for (var m = 0; m < this.childelements[n].delrows.length; m++) {
                                                this.childelements[n].delrows[m]["isdel"] = "1";
                                                mainData[pro].push(this.childelements[n].delrows[m]);
                                            }
                                            break;
                                        }
                                    }
                                    for (var n2 = 0; n2 < this.alonechildelements.length; n2++) {
                                        if (this.alonechildelements[n2].name == pro) {
                                             if(this.currentRow.isCopy){
                                               for(var k6=0;k6<this.alonechildelements[n2].rows.length;k6++){
                                                   this.alonechildelements[n2].rows[k6]['id']='-1';
                                                   this.alonechildelements[n2].rows[k6]['parentid']='-1';
                                               }
                                            }
                                            mainData[pro] = this.alonechildelements[n2].rows;
                                            for (var m2 = 0; m2 < this.alonechildelements[n2].delrows.length; m2++) {
                                                this.alonechildelements[n2].delrows[m2]["isdel"] = "1";
                                                mainData[pro].push(this.alonechildelements[n2].delrows[m2]);
                                            }
                                            break;
                                        }
                                    }
                                } else {
                                    if (this.noshowelements[pro]) {
                                        mainData[pro] = this.noshowelements[pro];
                                    }
                                    for (var k = 0; k < this.elements.length; k++) {
                                        if (this.elements[k].name == pro && this.elements[k].type!='LinkageDict') {
                                            mainData[pro] = this.elements[k].val;
                                            break;
                                        }
                                    }
                                    for (var k2 = 0; k2 < this.textareaElements.length; k2++) {
                                        if (this.textareaElements[k2].name == pro) {
                                            mainData[pro] = this.textareaElements[k2].val;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.originalData[this.modulename].flowhistory) {
                    delete this.originalData[this.modulename].flowhistory;
                }
                if(this.currentRow.isCopy){
                    this.originalData[this.modulename]['id']='-1';
                }else{
                     this.originalData[this.modulename]['id']=this.id;
                }
                console.log(this.originalData[this.modulename]);
                if(this.saveBeforeMethod){
                   this.originalData[this.modulename] =this.saveBeforeMethod(this.originalData[this.modulename]);
                }
                var graphql = `mutation{
        ` + this.modulename + `(flowguid:"` + this.flowguid + `",workflowid:"` + this.workflowid +
                    `",boxid:"` +
                    this.boxid + `",o:` +
                    JSON.stringify(this.originalData[this.modulename]) + `){id workflowid boxid errmsg }}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.freshListMethod();
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        if (!v_nocallflow && dataObj[this.modulename].workflowid && parseInt(dataObj[this.modulename]
                                .workflowid) > 0) {
                            this.submitFlow(dataObj[this.modulename].id, dataObj[this
                                .modulename].workflowid, dataObj[this.modulename].boxid);
                        } else {
                            this.id=dataObj[this.modulename].id;
                            MessageTip.successmsg('保存成功');
                        }
                    } else {
                        this.closeButLoadingMethod();
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            saveInfo(v_nocallflow) {
               setTimeout(() => {
                   this.saveInfoSyn(v_nocallflow);
               }, 50);
            },
            submitFlow(v_id, v_workflowid, v_boxid) {
                this.id=v_id;
                this.workflowid=v_workflowid;
                this.boxid=v_boxid;
                this.showFlowDialog=true;
            },
            modifyElements(v_elements){
              console.log('modifyElements...');
              console.log(v_elements);
              for(var i=0;i<v_elements.length;i++){
                for(var j=0;j<this.elements.length;j++){
                    if(v_elements[i]['name']==this.elements[j]['name']){
                        this.elements[j]['class']=v_elements[i]['class'];
                        this.elements[j]['tip']=v_elements[i]['tip'];
                        if(v_elements[i]['differencetip']){
                          this.elements[j]['differencetip']=v_elements[i]['differencetip'];
                        }
                        break;
                    }
                }
              }
              console.log(this.elements);
            },
            getNoShowElements(){
               return this.noshowelements;
            },
            createInfo(v_allchildelements,v_childname) {
                console.log("createInfo:" + v_childname);
                for(var i=0;i<v_allchildelements.length;i++) {
                    if(v_allchildelements[i]['name']==v_childname){
                        var tableObj = v_allchildelements[i];
                        if (tableObj.templaterows.length > 0) {
                            var newRow = {};
                            for (var pro in tableObj.templaterows[0]) {
                                if (pro == 'id') {
                                    newRow[pro] = this.getNewId();
                                } else {
                                    //newRow[pro] = tableObj.templaterows[0][pro];
                                    newRow[pro] = null;
                                }
                            }
                            tableObj.rows.push(newRow);
                        }
                    }
                }
            },
            delInfo(v_allchildelements,v_childname) {
                console.log(this.selectedRowData);
                if(!this.selectedRowData || this.selectedRowData.length==0){
                   MessageTip.warnmsg('请选择移除的数据');
                }
                for(var i2=0;i2<v_allchildelements.length;i2++) {
                    if(v_allchildelements[i2]['name']==v_childname){
                        var tableObj = v_allchildelements[i2];
                        for(var k2=0;k2<this.selectedRowData.length;k2++){
                          for (var i = 0; i < tableObj.rows.length; i++) {
                            if (parseInt(tableObj.rows[i]["id"]) == parseInt(this.selectedRowData[k2]["id"])) {
                                if (parseInt(this.selectedRowData[k2]["id"]) < 0) {
                                    tableObj.rows.splice(i, 1);
                                    console.log('del--' + i);
                                } else {
                                    tableObj.rows[i]["isdel"] = '1';
                                    tableObj.delrows.push(tableObj.rows[i]);
                                    tableObj.rows.splice(i, 1);
                                    console.log('setdel--' + i);
                                }
                                break;
                            }
                          }
                        }
                        break;
                    }
                }
                this.selectedRowData=null;
                document.body.click();
                console.log('delInfo---finish');
                console.log(v_allchildelements);
            },
            getNewId(){
              var rid= Date.now() + Math.floor(Math.random()*10+1);
              var id= -1 * rid;
              return id;
            },
            importInfo(v_childDataObjName,v_targetid){
                console.log(v_childDataObjName);
                console.log(v_targetid);
                this.currenttargetid=v_targetid;
                this.currenttargetname=v_childDataObjName;
                this.showImportDataDialog=true;
            },
            loadImportConfig(v_targetobjname,v_childname) {
                var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'targetschemaeq',
                            value: v_targetobjname,
                            operation: '=',
                        }],
                        child: []
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{dataimportconfigsetlist(where: ' + JSON.stringify(listwhere) +
                        '){id targetschema targetschemacname describe source { sourceschema condition {sourcename field} relation { sourcename sourcefield targetname targetfield }} } }'
                    ).then(res => {
                        if (res.errcode == "0") {
                            var configObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                .dataimportconfigsetlist;
                            this.childImportDataConfig[v_childname]=v_targetobjname;
                            if(configObj.length>0){
                                this.importItems[v_childname]=[];
                            }
                            for(var i=0;i<configObj.length;i++){
                                if(configObj[i].source.length > 0){
                                    this.importItems[v_childname].push({  
                                        label: configObj[i]['describe'],
                                        icon: 'pi pi-upload',
                                        targetobjname: v_childname,
                                        importsetid:configObj[i]['id'],
                                        command: (v_obj) => {
                                            console.log(v_obj.item['targetobjname']);
                                            console.log(v_obj.item['importsetid']);
                                            this.importInfo(v_obj.item['targetobjname'],v_obj.item['importsetid']);
                                        }
                                    });
                                }
                            }
                            console.log('loadImportConfig');
                            console.log(this.childImportDataConfig);
                        } else {
                            MessageTip.warnmsg('导入配置加载失败');
                        }
                    });
            },
            importfreshParentData(v_data){
               console.log('importfreshParentData');
               console.log(v_data);
               this.selectedRowData=null;
               this.importfreshParentDataSet(v_data,this.childelements);
               this.importfreshParentDataSet(v_data,this.alonechildelements);
            },
            importfreshParentDataSet(v_data,v_allchildelements){
                console.log("currenttargetname:"+this.currenttargetname);
                for(var i=0;i<v_allchildelements.length;i++){
                   if(v_allchildelements[i].name==this.currenttargetname){
                       for(var k=0;k<v_data.length;k++){
                          var isadd=true;
                          for(var k1=0;k1<v_allchildelements[i].rows.length;k1++){
                             if(v_allchildelements[i].rows[k1]['id']==v_data[k]['id']){
                                 isadd=false;
                                 break;
                             }
                          }
                          if(isadd){
                              console.log("importfreshParentDataSet:add:"+this.currenttargetname);
                              if(this.CellEditCompleteMethod){
                                  this.CellEditCompleteMethod(this.currenttargetname,'',v_allchildelements[i].rows,v_data[k],this.childTableFooterObj,this.childelements,this.alonechildelements,this.elements);
                              }
                              v_allchildelements[i].rows.push(v_data[k]);
                              console.log(v_allchildelements[i]);
                          }
                       }
                       break;
                   }
                }
            },
            cellEdit(event,tablename,colconfigs) {
                console.log('cellEdit...');
                console.log(event);
                console.log(tablename);
                console.log(colconfigs);
                console.log(this.startEdit);
                if(this.CellEditInit){
                    console.log('CellEditInit...');
                    this.CellEditInit(event,tablename,colconfigs);
                    console.log(colconfigs);
                }
                setTimeout(() => {
                    var input=document.getElementById("input2");
                    if (input && input!=document.activeElement){
                        console.log('cellEdit-2');
                        input.focus();
                    }
                    var input2=document.getElementsByName("otheredit2");
                    if (input2){
                        console.log('cellEdit-input2.length:');
                        console.log(input2.length);
                        if(input2.length>0) {
                          this.startEdit={'startedit':true};
                        }else{
                          this.startEdit={'startedit':false};
                        }
                        console.log(this.startEdit);
                    }else{
                         console.log('cellEdit-input2 is null:');
                         this.startEdit={'startedit':false};
                         console.log(this.startEdit);
                    }
                }, 10);
            },
            cellEditCompleteExpande(tablename,event,parentRow,allcurrentdata,allparentdata){
                console.log('cellEditCompleteExpande..');
                console.log(tablename);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                let { field } = event;
                for(var pro in event.newData){
                    event.data[pro] = event.newData[pro];
                }
                if(this.CellEditCompleteExpandeMethod){
                    setTimeout(() => {
                        console.log('CellEditCompleteExpandeMethod..');
                        this.CellEditCompleteExpandeMethod(tablename,field,event.data,parentRow,allcurrentdata,allparentdata,this.childTableFooterObj,this.elements,this.alonechildelements,this.childelements);
                    }, 50);
                }
            },
            cellEditComplete(event,tablename,v_alldata){
                console.log('cellEditComplete..');
                let { data,newValue, field,newData } = event;
                console.log("event:");
                console.log(event);
                console.log("field:");
                console.log(field);
                console.log("tablename:");
                console.log(tablename);
                console.log("v_alldata:");
                console.log(v_alldata);
                console.log("childTableFooterObj:");
                console.log(this.childTableFooterObj);
                console.log("data:");
                console.log(data);
                console.log("newData:");
                console.log(newData);
                console.log("newValue:");
                console.log(newValue);
                console.log("startEdit:");
                console.log(this.startEdit);
                if(!this.startEdit['startedit']){
                   return;
                }
                this.startEdit['startedit']=false;
                console.log('cellEditComplete..continue....');
                if(event.data['id']!=event.newData['id']){
                    console.log('cellEditComplete..id不相符....');
                    return;
                }
                for(var pro in event.newData){
                    event.data[pro] = event.newData[pro];
                }
                if(this.CellEditCompleteMethod){
                    this.CellEditCompleteMethod(tablename,field,v_alldata,event.data,this.childTableFooterObj,this.childelements,this.alonechildelements,this.elements);
                    console.log('cellEditComplete..3');
                    console.log(data);
                }
                for(var n2=0;n2<this.childelements.length;n2++){
                  this.sumAllField(this.childelements[n2]['name'],this.childelements[n2]['rows']);
                }
                for(var n3=0;n3<this.alonechildelements.length;n3++){
                  this.sumAllField(this.alonechildelements[n3]['name'],this.alonechildelements[n3]['rows']);
                }
            },
            sumAllField(tablename,v_alldata){
                if(this.childTableFooterObj && this.childTableFooterObj[tablename]){
                   var sumData={};
                   for(var i=0;i<v_alldata.length;i++){
                       for(var field in this.childTableFooterObj[tablename]){
                            if(!isNaN(Number(this.childTableFooterObj[tablename][field]))){
                                var tempnum=Number(v_alldata[i][field]);
                                if(sumData[field] || sumData[field]==0){
                                   if(!isNaN(tempnum)){
                                       sumData[field]=sumData[field]+tempnum;
                                   }
                                }else{
                                    if(!isNaN(tempnum)){
                                       sumData[field]=tempnum;
                                    }else{
                                        sumData[field]=0;
                                    }
                                }
                            }
                       }
                   }
                   for(var sumfield in sumData){
                       console.log('sumAllField..'+sumfield+'='+sumData[sumfield]);
                       this.childTableFooterObj[tablename][sumfield]=commonJs.moneySwitch(sumData[sumfield],3);
                   }
                }
            },
            // fomatFloat(src, pos) {
            //     return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
            // },
            getChildTableFooter(v_tablename,v_field,v_data){
               console.log('getChildTableFooter:');
               console.log(v_tablename);
               console.log(v_field);
               console.log(v_data);
               if(this.childTableFooterObj && this.childTableFooterObj[v_tablename] && (this.childTableFooterObj[v_tablename][v_field] || this.childTableFooterObj[v_tablename][v_field]==0)){
                   return this.childTableFooterObj[v_tablename][v_field];
               }
               return '';
            },
            initSumInfo(v_allchildelements){
               for(var k1=0;k1<v_allchildelements.length;k1++){
                   var item=v_allchildelements[k1];
                   if(this.childTableFooterObj && this.childTableFooterObj[item.name]){
                       for(var feild in this.childTableFooterObj[item.name]){
                           if(!isNaN(Number(this.childTableFooterObj[item.name][feild]))){
                               var summary=0;
                               for(var k2=0;k2<item.rows.length;k2++){
                                   var tempnum=Number(item.rows[k2][feild]);
                                   if(!isNaN(tempnum)){
                                        summary=summary+tempnum;
                                    }
                               }
                               this.childTableFooterObj[item.name][feild]=commonJs.moneySwitch(summary,3);
                           }
                       }
                   }
               }
            },
            getMainelementCss(elementname){
               var cssname='field col-12 md:col-2';
               if(this.mainelementCss && this.mainelementCss[elementname]){
                  cssname=this.mainelementCss[elementname];
               }
               return cssname;
            },
            setEditorValue(event){
                console.log('setEditorValue:');
                let { item } = event;
                console.log(item);
                for(var i=0;i<this.textareaElements.length;i++){
                    if(this.textareaElements[i]['name']==item.colname){
                        this.textareaElements[i]['val']=item.content;
                        break;
                    }
                }
            },
            getChildTableColWidth(v_childname,v_fieldname){
               console.log(v_childname);
               console.log(v_fieldname);
               if(this.getChildTableColWidthMehod){
                  return this.getChildTableColWidthMehod(v_childname,v_fieldname);
               }else{
                 return 'width:160px;';
               }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            CalfDropdown,
            CalfInputDropdown,
            CalfTreeDropdown,
            LinkageDropDown,
            DataImport,
            UploadInput,
            CalfMultiSelect,
            CalfAtuNumber,
            Editor,
            FlowEngine,
            Image,
        }
    }
</script>