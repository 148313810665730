 <template>
     <Dropdown :id="currentfieldname" v-model="selectedItem" :filter="true" :options="currentDictItems"
         @change="dropchange" optionLabel="name" optionValue="code" showClear="true" />
 </template>
 <script>
     export default {
         name: 'LinkageDropDown',
         emits: ['update:selectedValue1', 'update:selectedTxt1', 'update:selectedValue2', 'update:selectedTxt2',
             'update:selectedValue3', 'update:selectedTxt3'
         ],
         created() {
             this.loadInit();
         },
         props: ['selectedValue1', 'selectedTxt1', 'selectedValue2', 'selectedTxt2', 'selectedValue3', 'selectedTxt3',
             'dictItems', 'bindselectedTxt', 'currentitem', 'currentfieldname', 'freshLinkageDictValueMethod',
             'linkdropchangeAfter'
         ],
         data() {
             return {
                 selectedItem: null,
                 dictItems1: [],
                 dictItems2: [],
                 dictItems3: [],
                 currentDictItems: [],
                 level: -1,
             }
         },
         computed: {
             computedValue1() {
                 return this.selectedValue1;
             },
             computedValue2() {
                 return this.selectedValue2;
             },
             computedValue3() {
                 return this.selectedValue3;
             }
         },
         watch: {
             computedValue1: function (val) {
                 console.log("computedValue1");
                 console.log(val);
             },
             computedValue2: function (val) {
                 console.log("computedValue2");
                 console.log(val);
                 console.log("currentfieldname:" + this.currentfieldname + ",dictCode2:" + this.currentitem
                     .dictCode2 + ",selectedValue1:" + this.selectedValue1);
                 if (this.currentfieldname.toLowerCase() == this.currentitem.dictCode2.toLowerCase()) {
                     this.freshDict2Data();
                     if (val == '' || val == '-1') {
                         this.selectedItem = null;
                     } else {
                         this.selectedItem = val;
                     }
                 }
             },
             computedValue3: function (val) {
                 console.log("computedValue3");
                 console.log(val);
                 console.log("currentfieldname:" + this.currentfieldname + ",dictCode3:" + this.currentitem
                     .dictCode3 + ",selectedValue1:" + this.selectedValue1 + ",selectedValue2:" + this
                     .selectedValue2);
                 if (this.currentfieldname.toLowerCase() == this.currentitem.dictCode3.toLowerCase()) {
                     this.freshDict3Data();
                     if (val == '' || val == '-1') {
                         this.selectedItem = null;
                     } else {
                         this.selectedItem = val;
                     }
                 }
             }
         },
         methods: {
             loadInit() {
                 for (var i = 0; i < this.dictItems.length; i++) {
                     this.dictItems1.push({
                         name: this.dictItems[i].name,
                         code: this.dictItems[i].code,
                         ename: this.dictItems[i].ename,
                         other: this.dictItems[i].other,
                     });
                     if (this.selectedValue1 != null && this.selectedValue1 != '' && this.selectedValue1 == this
                         .dictItems[i].code) {
                         for (var j = 0; j < this.dictItems[i].child.length; j++) {
                             this.dictItems2.push({
                                 name: this.dictItems[i].child[j].name,
                                 code: this.dictItems[i].child[j].code,
                             });
                             if (this.selectedValue2 != null && this.selectedValue2 != '' && this.selectedValue2 == this
                                 .dictItems[i].child[j].code) {
                                 for (var k = 0; k < this.dictItems[i].child[j].child.length; k++) {
                                     this.dictItems3.push({
                                         name: this.dictItems[i].child[j].child[k].name,
                                         code: this.dictItems[i].child[j].child[k].code,
                                     });
                                 }
                             }
                         }
                     }
                 }
                 if (this.selectedValue1 && this.selectedValue1 != null && this.selectedValue1 != '') {
                     this.selectedItem = this.selectedValue1;
                 }
                 this.level = 1;
                 this.currentDictItems = this.dictItems1;
                 console.log("currentfieldname:" + this.currentfieldname);
                 console.log("dictCode2:" + this.currentitem.dictCode2);
                 if (this.currentfieldname.toLowerCase() == this.currentitem.dictCode2.toLowerCase()) {
                     this.currentDictItems = this.dictItems2;
                     this.level = 2;
                     if (this.selectedValue2 && this.selectedValue2 != null && this.selectedValue2 != '') {
                         this.selectedItem = this.selectedValue2;
                     }
                 }
                 if (this.currentfieldname.toLowerCase() == this.currentitem.dictCode3.toLowerCase()) {
                     this.currentDictItems = this.dictItems3;
                     this.level = 3;
                     if (this.selectedValue3 && this.selectedValue3 != null && this.selectedValue3 != '') {
                         this.selectedItem = this.selectedValue3;
                     }
                 }
             },
             freshDict2Data() {
                 this.dictItems2 = [];
                 for (var i = 0; i < this.dictItems.length; i++) {
                     if (this.selectedValue1 != null && this.selectedValue1 != '' && this.selectedValue1 == this
                         .dictItems[i].code) {
                         for (var j = 0; j < this.dictItems[i].child.length; j++) {
                             this.dictItems2.push({
                                 name: this.dictItems[i].child[j].name,
                                 code: this.dictItems[i].child[j].code,
                             });
                         }
                         break;
                     }
                 }
                 this.currentDictItems = this.dictItems2;
             },
             freshDict3Data() {
                 this.dictItems3 = [];
                 for (var i = 0; i < this.dictItems.length; i++) {
                     if (this.selectedValue1 != null && this.selectedValue1 != '' && this.selectedValue1 == this
                         .dictItems[i].code) {
                         for (var j = 0; j < this.dictItems[i].child.length; j++) {
                             if (this.selectedValue2 != null && this.selectedValue2 != '' && this.selectedValue2 ==
                                 this.dictItems[i].child[j].code) {
                                 for (var k = 0; k < this.dictItems[i].child[j].child.length; k++) {
                                     this.dictItems3.push({
                                         name: this.dictItems[i].child[j].child[k].name,
                                         code: this.dictItems[i].child[j].child[k].code,
                                     });
                                 }
                             }
                         }
                         break;
                     }
                 }
                 this.currentDictItems = this.dictItems3;
             },
             dropchange(v_event) {
                 var txt = '';
                 for (var i = 0; i < this.currentDictItems.length; i++) {
                     if (this.currentDictItems[i].code == v_event.value) {
                         txt = this.currentDictItems[i].name;
                         break;
                     }
                 }
                 console.log("level:" + this.level);
                 if (this.level == 1) {
                     this.$emit('update:selectedValue1', v_event.value);
                     if (this.currentitem.dictCode2 && this.currentitem.dictCode2 != '') {
                         if (this.selectedValue2 == '') {
                             this.$emit('update:selectedValue2', '-1');
                         } else {
                             this.$emit('update:selectedValue2', '');
                         }
                     }
                     if (this.currentitem.dictCode3 && this.currentitem.dictCode3 != '') {
                         if (this.selectedValue3 == '') {
                             this.$emit('update:selectedValue3', '-1');
                         } else {
                             this.$emit('update:selectedValue3', '');
                         }
                     }

                     if (this.bindselectedTxt) {
                         this.$emit('update:selectedTxt1', txt);
                         this.$emit('update:selectedTxt2', '');
                         this.$emit('update:selectedTxt3', '');
                     }
                     console.log("v_event.value:" + v_event.value);
                     console.log("selectedValue1:" + this.selectedValue1);
                     console.log("selectedValue2:" + this.selectedValue2);
                     console.log("selectedValue3:" + this.selectedValue3);
                 }
                 if (this.level == 2) {
                     this.$emit('update:selectedValue2', v_event.value);
                     if (this.currentitem.dictCode3 && this.currentitem.dictCode3 != '') {
                         if (this.selectedValue3 == '') {
                             this.$emit('update:selectedValue3', '-1');
                         } else {
                             this.$emit('update:selectedValue3', '');
                         }
                     }
                     if (this.bindselectedTxt) {
                         this.$emit('update:selectedTxt2', txt);
                         this.$emit('update:selectedTxt3', '');
                     }
                     console.log("v_event.value:" + v_event.value);
                     console.log("selectedValue1:" + this.selectedValue1);
                     console.log("selectedValue2:" + this.selectedValue2);
                     console.log("selectedValue3:" + this.selectedValue3);
                 }
                 if (this.level == 3) {
                     this.$emit('update:selectedValue3', v_event.value);
                     if (this.bindselectedTxt) {
                         this.$emit('update:selectedTxt3', txt);
                     }
                     console.log("v_event.value:" + v_event.value);
                     console.log("selectedValue1:" + this.selectedValue1);
                     console.log("selectedValue2:" + this.selectedValue2);
                     console.log("selectedValue3:" + this.selectedValue3);
                 }
                 if (this.linkdropchangeAfter) {
                     console.log('linkdropchangeAfter:');
                     console.log(this.currentDictItems);
                     this.linkdropchangeAfter(this.currentfieldname, txt, v_event.value, this.currentDictItems);
                 }
             },
         }
     }
 </script>