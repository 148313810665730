const PurchaseBoxJs = {
    initDetail() {
        console.log('PurchaseBoxJs---initDetail...');
        this.childNewDataConfig = {
            products: false,
            files: true,
            contractterms: true,
        };
        this.dropOtherConfig = {
            currency: 'exchangerate'
        };
        this.childTableFooter = {
            products: {
                mpn: '合计',
                quantity: 0,
                allprice: 0,
                grossweight: 0,
                netweight: 0
            }
        };
        this.childaloneshowConfig = {};
        return {
            'childNewDataConfig': this.childNewDataConfig,
            'dropOtherConfig': this.dropOtherConfig,
            'childTableFooter': this.childTableFooter,
            'childaloneshowConfig': this.childaloneshowConfig
        }
    },
    cellEditComplete(tablename, field, data, rowdata, childTableFooterObj) {
        console.log('PurchaseBoxJs--cellEditCompleteMethod..');
        console.log(data);
        if (tablename == 'products' && field == 'quantity' || field == 'unitprice') {
            var quantitynum = Number(rowdata['quantity']);
            var unitpricenum = Number(rowdata['unitprice']);
            if (!isNaN(quantitynum) && !isNaN(unitpricenum)) {
                rowdata['allprice'] = quantitynum * unitpricenum;
            }
            var summary = 0;
            for (var i = 0; i < data.length; i++) {
                var tempnum = Number(data[i]['allprice']);
                if (!isNaN(tempnum)) {
                    summary = summary + tempnum;
                }
            }
            console.log('cellEditCompleteMethod..' + summary);
            childTableFooterObj['products']['allprice'] = summary;
        }
    },
    importAddQueryCondition(v_listwhere, v_moduleName, v_mainDataObj) {
        if (v_moduleName == 'product') {
            v_listwhere.conditions.push({
                name: 'status',
                value: '0',
                operation: '=',
            });
            return v_listwhere;
        }
        if (v_moduleName == 'quotationproduct') {
            var mainDataObj = v_mainDataObj;
            console.log('importAddQueryCondition...');
            console.log(mainDataObj);
            if (!mainDataObj['factoryid'] || mainDataObj['factoryid'] == '') {
                alert('请先选择工厂');
                v_listwhere.conditions.push({
                    name: 'err',
                    value: '',
                    operation: '',
                });
            } else {
                v_listwhere.conditions.push({
                    name: 'factoryid',
                    value: mainDataObj['factoryid'],
                    operation: '=',
                });
            }
            v_listwhere.conditions.push({
                name: 'importdata',
                value: '',
                operation: '=',
            });
            return v_listwhere;
        }
    },
    productCellEditInit(event, tablename, colconfigs) {
        if (tablename == 'products') {
            var countindex = 0;
            for (var i = 0; i < colconfigs.length; i++) {
                if (colconfigs[i]['field'] == 'unitprice') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (colconfigs[i]['field'] == 'grossweight') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (colconfigs[i]['field'] == 'netweight') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (countindex >= 3) {
                    break;
                }
            }
        }
    },
    loadDataAfter(v_mainelements, v_childelements, v_originalData, currentRow, currencyDict, v_childExpandeConfig) {
        console.log('PurchaseBoxJs--loadDataAfter..');
        console.log(v_mainelements);
        console.log(v_childelements);
        console.log(v_originalData);
        console.log(currentRow);
        for (var k = 0; k < v_mainelements.length; k++) {
            if (v_mainelements[k]['name'] == 'currency') {
                this.currencyChange(v_childelements, v_mainelements[k]['val'], currencyDict, v_childExpandeConfig);
            }
        }
    },
    currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        var loopcnt = 0;
        var isbreak = false;
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'unitprice') {
                    v_childelements[i].columns[i2].header = '单价(' + ename + ')';
                    loopcnt++;
                }
                if (v_childelements[i].columns[i2].field == 'allprice') {
                    v_childelements[i].columns[i2].header = '总价(' + ename + ')';
                    loopcnt++;
                }
                if (loopcnt == 2) {
                    isbreak = true;
                    break;
                }
            }
            if (isbreak) {
                break;
            }
        }
        if (v_childExpandeConfig != null) {
            for (var i4 = 0; i4 < v_childExpandeConfig.products.config2[0].columns.length; i4++) {
                if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'unitprice') {
                    v_childExpandeConfig.products.config2[0].columns[i4].header = '单价(' + ename + ')';
                }
                if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'allprice') {
                    v_childExpandeConfig.products.config2[0].columns[i4].header = '总价(' + ename + ')';
                }
            }
        }
    },
    cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
        childTableFooterObj) {
        console.log('cellEditCompleteExpandeMethod..');
        console.log(tablename);
        console.log(field);
        console.log(currentrow);
        console.log(parentRow);
        console.log(allcurrentdata);
        console.log(allparentdata);
        console.log(childTableFooterObj);
        var price = 0;
        var allprice = 0;
        var grossWeight = 0;
        var netWeight = 0;
        if (!isNaN(Number(parentRow['quantity'])) && !isNaN(Number(currentrow['matching']))) {
            currentrow['quantity'] = Number(currentrow['matching']) * Number(parentRow[
                'quantity']);
        }
        if (!isNaN(Number(parentRow['quantity'])) && !isNaN(Number(currentrow['unitprice'])) && !isNaN(Number(
                currentrow['matching']))) {
            currentrow['allprice'] = Number(currentrow['matching']) * Number(currentrow[
                'unitprice']) * Number(parentRow['quantity']);
        }
        for (var i = 0; i < allcurrentdata.length; i++) {
            if (allcurrentdata[i]['id'] == currentrow['id']) {
                allcurrentdata[i]['quantity'] = currentrow['quantity'];
                allcurrentdata[i]['allprice'] = currentrow['allprice'];
            }
            if (!isNaN(Number(allcurrentdata[i]['unitprice'])) && !isNaN(Number(allcurrentdata[i][
                    'matching'
                ]))) {
                price = price + Number(allcurrentdata[i]['unitprice']) * Number(allcurrentdata[i]['matching']);
            }
            if (!isNaN(Number(allcurrentdata[i]['allprice']))) {
                allprice = allprice + Number(allcurrentdata[i]['allprice']);
            }
            if (!isNaN(Number(allcurrentdata[i]['grossweight'])) && !isNaN(Number(allcurrentdata[i][
                    'matching'
                ]))) {
                grossWeight = grossWeight + Number(allcurrentdata[i]['grossweight']) * Number(allcurrentdata[i][
                    'matching'
                ]);
            }
            if (!isNaN(Number(allcurrentdata[i]['netweight'])) && !isNaN(Number(allcurrentdata[i][
                    'matching'
                ]))) {
                netWeight = netWeight + Number(allcurrentdata[i]['netweight']) * Number(allcurrentdata[i][
                    'matching'
                ]);
            }
        }
        parentRow['grossweight'] = grossWeight;
        parentRow['netweight'] = netWeight;
        parentRow['unitprice'] = price;
        parentRow['allprice'] = allprice;
        price = 0;
        allprice = 0;
        grossWeight = 0;
        netWeight = 0;
        for (var i2 = 0; i2 < allparentdata.length; i2++) {
            if (allparentdata[i2]['id'] == parentRow['id']) {
                allparentdata[i2]['grossweight'] = parentRow['grossweight'];
                allparentdata[i2]['netweight'] = parentRow['netweight'];
                allparentdata[i2]['unitprice'] = parentRow['unitprice'];
                allparentdata[i2]['allprice'] = parentRow['allprice'];
            }
            if (!isNaN(Number(allparentdata[i2]['allprice']))) {
                allprice = allprice + Number(allparentdata[i2]['allprice']);
            }
            if (!isNaN(Number(allparentdata[i2]['grossweight']))) {
                grossWeight = grossWeight + Number(allparentdata[i2]['grossweight']);
            }
            if (!isNaN(Number(allparentdata[i2]['netweight']))) {
                netWeight = netWeight + Number(allparentdata[i2]['netweight']);
            }
        }
        //childTableFooterObj['products']['netweight'] = netWeight;
        //childTableFooterObj['products']['grossweight'] = grossWeight;
        //childTableFooterObj['products']['allprice'] = allprice;
    },
};

export default PurchaseBoxJs;