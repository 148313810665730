<template>
    <div id="layout-config" :class="containerClass">
        <!--  <a href="#" class="layout-config-button" id="layout-config-button" @click="toggleConfigurator">
            <i class="pi pi-cog"></i>
        </a> -->
        <Button class="p-button-danger layout-config-close p-button-rounded p-button-text" icon="pi pi-times"
            @click="hideConfigurator" :style="{'z-index': 1}"></Button>

        <div class="layout-config-content">
            <h5 class="mt-0">缩放</h5>
            <div class="config-scale">
                <Button icon="pi pi-minus" @click="decrementScale()" class="p-button-text"
                    :disabled="scale === scales[0]" />
                <i class="pi pi-circle-on" v-for="s of scales" :class="{'scale-active': s === scale}" :key="s" />
                <Button icon="pi pi-plus" @click="incrementScale()" class="p-button-text"
                    :disabled="scale === scales[scales.length - 1]" />
            </div>

            <h5>输入风格</h5>
            <div class="p-formgroup-inline">
                <div class="field-radiobutton">
                    <RadioButton id="input_outlined" name="inputstyle" value="outlined"
                        :modelValue="$primevue.config.inputStyle" @change="changeInputStyle('outlined')" />
                    <label for="input_outlined">带边框</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton id="input_filled" name="inputstyle" value="filled"
                        :modelValue="$primevue.config.inputStyle" @change="changeInputStyle('filled')" />
                    <label for="input_filled">无边框</label>
                </div>
            </div>

            <!--   <h5>Ripple Effect</h5>
            <InputSwitch :modelValue="rippleActive" @update:modelValue="changeRipple" /> -->

            <!--    <h5>Menu Type</h5>
            <div class="p-formgroup-inline">
                <div class="field-radiobutton">
                    <RadioButton id="static" name="layoutMode" value="static" v-model="d_layoutMode"
                        @change="changeLayout($event, 'static')" />
                    <label for="static">Static</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton id="overlay" name="layoutMode" value="overlay" v-model="d_layoutMode"
                        @change="changeLayout($event, 'overlay')" />
                    <label for="overlay">Overlay</label>
                </div>
            </div> -->

            <h5>主题</h5>
            <h6>当前主题</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <img id="current-themes" src="images/themes/saga-blue.png" />
                </div>
            </div>
            <h6 class="mt-0">风格一</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'bootstrap4-light-blue')">
                        <img id="bootstrap4-light-blue" src="images/themes/bootstrap4-light-blue.svg"
                            alt="Bootstrap Light Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'bootstrap4-light-purple')">
                        <img id="bootstrap4-light-purple" src="images/themes/bootstrap4-light-purple.svg"
                            alt="Bootstrap Light Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'bootstrap4-dark-blue', true)">
                        <img id="bootstrap4-dark-blue" src="images/themes/bootstrap4-dark-blue.svg"
                            alt="Bootstrap Dark Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'bootstrap4-dark-purple', true)">
                        <img id="bootstrap4-dark-purple" src="images/themes/bootstrap4-dark-purple.svg"
                            alt="Bootstrap Dark Blue" />
                    </button>
                </div>
            </div>

            <h6>风格二</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'md-light-indigo')">
                        <img id="md-light-indigo" src="images/themes/md-light-indigo.svg" alt="Material Light Indigo" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'md-light-deeppurple')">
                        <img id="md-light-deeppurple" src="images/themes/md-light-deeppurple.svg"
                            alt="Material Light Deep Purple" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'md-dark-indigo', true)">
                        <img id="md-dark-indigo" src="images/themes/md-dark-indigo.svg" alt="Material Dark Indigo" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'md-dark-deeppurple', true)">
                        <img id="md-dark-deeppurple" src="images/themes/md-dark-deeppurple.svg"
                            alt="Material Dark Deep Purple" />
                    </button>
                </div>
            </div>

            <h6>风格三</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'mdc-light-indigo')">
                        <img id="mdc-light-indigo" src="images/themes/md-light-indigo.svg"
                            alt="Material Compact Light Indigo" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'mdc-light-deeppurple')">
                        <img id="mdc-light-deeppurple" src="images/themes/md-light-deeppurple.svg"
                            alt="Material Compact Deep Purple" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'mdc-dark-indigo', true)">
                        <img id="mdc-dark-indigo" src="images/themes/md-dark-indigo.svg"
                            alt="Material Compact Dark Indigo" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'mdc-dark-deeppurple', true)">
                        <img id="mdc-dark-deeppurple" src="images/themes/md-dark-deeppurple.svg"
                            alt="Material Compact Dark Deep Purple" />
                    </button>
                </div>
            </div>

            <h6>风格四</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'tailwind-light')">
                        <img id="tailwind-light" src="images/themes/tailwind-light.png" alt="Tailwind Light" />
                    </button>
                </div>
            </div>

            <!--   <h6>Fluent UI</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'fluent-light')">
                        <img src="images/themes/fluent-light.png" alt="Fluent Light" />
                    </button>
                </div>
            </div> -->

            <h6>风格五</h6>
            <div class="grid free-themes">
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'saga-blue')">
                        <img id="saga-blue" src="images/themes/saga-blue.png" alt="Saga Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'saga-green')">
                        <img id="saga-green" src="images/themes/saga-green.png" alt="Saga Green" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'saga-orange')">
                        <img id="saga-orange" src="images/themes/saga-orange.png" alt="Saga Orange" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'saga-purple')">
                        <img id="saga-purple" src="images/themes/saga-purple.png" alt="Saga Purple" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'vela-blue', true)">
                        <img id="vela-blue" src="images/themes/vela-blue.png" alt="Vela Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'vela-green', true)">
                        <img id="vela-green" src="images/themes/vela-green.png" alt="Vela Green" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'vela-orange', true)">
                        <img id="vela-orange" src="images/themes/vela-orange.png" alt="Vela Orange" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'vela-purple', true)">
                        <img id="vela-purple" src="images/themes/vela-purple.png" alt="Vela Purple" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'arya-blue', true)">
                        <img id="arya-blue" src="images/themes/arya-blue.png" alt="Arya Blue" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'arya-green', true)">
                        <img id="arya-green" src="images/themes/arya-green.png" alt="Arya Green" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'arya-orange', true)">
                        <img id="arya-orange" src="images/themes/arya-orange.png" alt="Arya Orange" />
                    </button>
                </div>
                <div class="col-3 text-center">
                    <button class="p-link" type="button" @click="changeTheme($event, 'arya-purple', true)">
                        <img id="arya-purple" src="images/themes/arya-purple.png" alt="Arya Purple" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppConfig',
        props: {
            layoutMode: {
                type: String,
                default: null
            },
            layoutColorMode: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                active: false,
                d_layoutMode: this.layoutMode,
                d_layoutColorMode: this.layoutColorMode,
                scale: 16,
                scales: [12, 13, 14, 15, 16]
            }
        },
        watch: {
            $route() {
                if (this.active) {
                    this.active = false;
                    this.unbindOutsideClickListener();
                }
            },
            layoutMode(newValue) {
                this.d_layoutMode = newValue;
            },
            layoutColorMode(newValue) {
                this.d_layoutColorMode = newValue;
            }
        },
        outsideClickListener: null,
        methods: {
            showSetClick(event) {
                setTimeout(() => {
                    this.toggleConfigurator(event);
                }, 50);
            },
            toggleConfigurator(event) {
                console.log(event);
                this.active = !this.active;
                event.preventDefault();
                console.log(this.active);
                if (this.active)
                    this.bindOutsideClickListener();
                else
                    this.unbindOutsideClickListener();
            },
            hideConfigurator(event) {
                this.active = false;
                this.unbindOutsideClickListener();
                event.preventDefault();
            },
            changeInputStyle(value) {
                this.$primevue.config.inputStyle = value;
            },
            changeRipple(value) {
                this.$primevue.config.ripple = value;
            },
            changeLayout(event, layoutMode) {
                this.$emit('layout-change', layoutMode);
                event.preventDefault();
            },
            changeLayoutColor(event, layoutColor) {
                this.$emit('layout-color-change', layoutColor);
                event.preventDefault();
            },
            bindOutsideClickListener() {
                if (!this.outsideClickListener) {
                    this.outsideClickListener = (event) => {
                        if (this.active && this.isOutsideClicked(event)) {
                            this.active = false;
                        }
                    };
                    document.addEventListener('click', this.outsideClickListener);
                }
            },
            unbindOutsideClickListener() {
                if (this.outsideClickListener) {
                    document.removeEventListener('click', this.outsideClickListener);
                    this.outsideClickListener = null;
                }
            },
            isOutsideClicked(event) {
                return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
            },
            decrementScale() {
                this.scale--;
                this.applyScale();
            },
            incrementScale() {
                this.scale++;
                this.applyScale();
            },
            applyScale() {
                console.log(this.scale);
                document.documentElement.style.fontSize = this.scale + 'px';
            },
            changeTheme(event, theme, dark = false) {
                let themeElement = document.getElementById('theme-link');
                themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme,
                    theme));
                this.$appState.theme = theme;
                this.$appState.darkTheme = dark;
                document.getElementById("current-themes").src = document.getElementById(theme).src;
                if (theme.startsWith('md')) {
                    this.$primevue.config.ripple = true;
                }
            }
        },
        computed: {
            containerClass() {
                return ['layout-config', {
                    'layout-config-active': this.active
                }];
            },
            rippleActive() {
                return this.$primevue.config.ripple;
            },
            inputStyle() {
                return this.$appState.inputStyle;
            }
        }
    }
</script>