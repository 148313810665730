<template>
    <Dropdown v-if="sourcetype!='grid'" v-model="selectedItem" :id="id" :filter="true" :loading="loading"
        :options="dictItems" @input="dropinput" @change="dropchange" optionLabel="name" optionValue="code"
        showClear="true" :editable="canEditable">
        <template #option="slotProps">
            <div>
                <div v-if="slotProps.option.comment!=''">{{slotProps.option.name}}--{{slotProps.option.comment}}</div>
                <div v-if="slotProps.option.comment==''">{{slotProps.option.name}}</div>
            </div>
        </template>
    </Dropdown>
    <Dropdown v-if="sourcetype=='grid'" v-model="selectedItem" :id="id" :options="dictItems" @input="dropinput"
        optionLabel="name" optionValue="code" showClear="true" :editable="true" @show="dropshow" @click="toggle">
        <template #option="slotProps">
            <div style="display:none">{{slotProps.option.name}}</div>
        </template>
    </Dropdown>
    <OverlayPanel ref="op">
        <DataTable :value="gridrows" v-model:selection="selectedRow" @row-select="onRowSelect" dataKey="id"
            selectionMode="single" scrollable scrollHeight="350px" tableStyle="min-width:40rem;">
            <Column v-for="col of cols" :field="col.field" :header="col.header" :key="col.field">
            </Column>
        </DataTable>
    </OverlayPanel>
</template>
<script>
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    export default {
        name: 'CalfInputDropdown',
        emits: ['update:selectedValue', 'update:selectedTxt'],
        created() {
            this.loadInit();
        },
        props: ['selectedValue', 'selectedTxt', 'loadAll', 'canEditable', 'dictObjName', 'id', 'selectAfterFun'],
        data() {
            return {
                loading: true,
                selectedItem: null,
                sourcetype: '',
                dictItems: [],
                gridrows: [],
                cols: [],
                inputAllTxt: '',
                selectedRow: {}
            }
        },
        methods: {
            loadInit() {
                this.inputAllTxt = '';
                if (this.loadAll) {
                    this.loadDict('');
                } else {
                    this.initSelect();
                    this.loading = false;
                }
            },
            dropshow() {
                console.log('dropshow...');
                var dropItems = document.getElementsByClassName("p-dropdown-panel");
                console.log(dropItems);
                for (var i = 0; i < dropItems.length; i++) {
                    dropItems[i].setAttribute("style", "display:none");
                }
            },
            toggle(v_event) {
                console.log('toggle...');
                console.log(v_event);
                this.$refs.op.toggle(v_event);
            },
            onRowSelect(v_event) {
                console.log('onRowSelect...');
                console.log(v_event.data);
                this.dictItems = [];
                this.dictItems.push({
                    code: v_event.data["id"],
                    name: v_event.data[this.cols[0]['field']]
                });
                this.selectedItem = v_event.data["id"];
                this.$emit('update:selectedValue', v_event.data["id"]);
                this.$emit('update:selectedTxt', v_event.data[this.cols[0]['field']]);
                if (this.selectAfterFun) {
                    this.selectAfterFun(this.id, v_event.data);
                }
                this.$refs.op.hide();
            },
            dropchange(v_event) {
                this.inputAllTxt = '';
                var txt = '';
                var val = v_event.value;
                if (!val) val = "-1";
                for (var i = 0; i < this.dictItems.length; i++) {
                    if (this.dictItems[i].code == val) {
                        txt = this.dictItems[i].name;
                        break;
                    }
                }
                this.inputAllTxt = txt;
                this.$emit('update:selectedValue', val);
                this.$emit('update:selectedTxt', txt);
                console.log(val);
                console.log(txt);
            },
            dropinput(v_event) {
                console.log(v_event);
                this.loading = true;
                var txt = v_event.data;
                if (v_event['target'] && v_event['target']['_value']) {
                    txt = v_event['target']['_value'];
                }
                setTimeout(() => {
                    this.inputAllTxt = txt;
                    this.loadDict(this.inputAllTxt);
                }, 100);
            },
            findDictExist(v_code) {
                for (var i = 0; i < this.dictItems.length; i++) {
                    if (this.dictItems[i].code == v_code) {
                        return true;
                    }
                }
                return false;
            },
            loadDict(inputTxt) {
                var dictObjarray = this.dictObjName.split('^');
                var dictName = dictObjarray[0];
                var dictIdentify = dictName;
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'name',
                        value: inputTxt,
                        operation: 'like'
                    }]
                };
                if (dictObjarray.length > 1) {
                    dictlistwhere.conditions.push({
                        name: 'Categories',
                        value: dictObjarray[1],
                        operation: '='
                    });
                    dictIdentify = dictName + dictObjarray[1];
                }
                var dictCacheData = this.loadDictFromCache(dictName, dictIdentify);
                if (this.loadAll && this.sourcetype != 'grid' && dictCacheData && dictCacheData.length > 0 && (!
                        inputTxt || inputTxt === '')) {
                    console.log('dictIdentify:' + dictIdentify);
                    this.dictItems = JSON.parse(dictCacheData);
                    console.log(this.dictItems);
                    this.initSelect();
                    this.loading = false;
                } else {
                    var graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                        '){name code comment}}';
                    if (dictName == 'dictionary') {
                        graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                            '){name sourcetype details {name code} gridrows {row {name val} } gridcols {name title} }}';
                    }
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            this.dictItems = [];
                            this.gridrows = [];
                            this.cols = [];
                            var dictData = dataObj[dictName + 'list'];
                            if (dictName == 'dictionary') {
                                this.sourcetype = dictData[0].sourcetype;
                                if (this.sourcetype == 'grid') {
                                    for (var k2 = 0; k2 < dictData[0].gridcols.length; k2++) {
                                        this.cols.push({
                                            field: dictData[0].gridcols[k2]['name'],
                                            header: dictData[0].gridcols[k2]['title'],
                                        });
                                    }
                                    for (var k3 = 0; k3 < dictData[0].gridrows.length; k3++) {
                                        var gridrowobj = {};
                                        for (var k4 = 0; k4 < dictData[0].gridrows[k3].row.length; k4++) {
                                            gridrowobj[dictData[0].gridrows[k3].row[k4]['name']] = dictData[0]
                                                .gridrows[k3].row[k4]['val'];
                                        }
                                        this.gridrows.push(gridrowobj);
                                    }
                                } else {
                                    for (var k1 = 0; k1 < dictData[0].details.length; k1++) {
                                        console.log('dictionary-name1:' + dictData[0].details[k1].name);
                                        // if (!this.findDictExist(dictData[0].details[k1].code)) {
                                        console.log('dictionary-name2:' + dictData[0].details[k1].name);
                                        this.dictItems.push({
                                            name: dictData[0].details[k1].name,
                                            code: dictData[0].details[k1].code,
                                            comment: '',
                                        });
                                        // }
                                    }
                                }
                            } else {
                                this.sourcetype = '';
                                for (var k = 0; k < dictData.length; k++) {
                                    // if (!this.findDictExist(dictData[k].code)) {
                                    this.dictItems.push({
                                        name: dictData[k].name,
                                        code: dictData[k].code,
                                        comment: dictData[k].comment,
                                    });
                                    // }
                                }
                            }
                            if ((!inputTxt || inputTxt && inputTxt === '')) {
                                if (this.sourcetype != 'grid') {
                                    sessionStorage.setItem(dictIdentify, JSON.stringify(this.dictItems));
                                }
                                this.initSelect();
                            }
                            console.log(this.dictItems);
                        } else {
                            MessageTip.warnmsg(dictName + '字典数据加载失败');
                        }
                    });
                }
            },
            initSelect() {
                console.log('initSelect...');
                console.log(this.selectedValue);
                console.log(this.selectedTxt);
                if (this.selectedValue != null && this.selectedValue != '') {
                    if (!this.findDictExist(this.selectedValue)) {
                        this.dictItems.push({
                            name: this.selectedTxt,
                            code: this.selectedValue,
                            comment: ''
                        });
                    }
                    this.selectedItem = this.selectedValue;
                }
                this.loading = false;
            },
            loadDictFromCache(dictName, dictIdentify) {
                var dictCacheData = sessionStorage.getItem(dictIdentify);
                if (!dictCacheData || dictCacheData.length == 0) {
                    dictCacheData = sessionStorage.getItem(dictName);
                }
                return dictCacheData;
            }
        }
    }
</script>