<template>
    <div class="layout-topbar">
        <div class="layout-topbar-logo" style="width:300px">
            <img alt="Logo" :src="topbarImage()" />
            <span style="white-space: nowrap;">易汇</span>
            <Button type="button"
                style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:inline-block;max-width:120px;"
                class="p-button-info p-button-rounded p-button-text" :label="userName" icon="pi pi-user"
                @click="toggle" />
            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 450px"
                :breakpoints="{'960px': '75vw'}">
                <div class="card">
                    <h5>当前用户信息</h5>
                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">用户名：</label>
                        <div style="text-align:left">
                            {{userName}}
                        </div>
                    </div>
                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">所在部门：</label>
                        <div style="text-align:left">
                            {{deptName}}
                        </div>
                    </div>
                    <div class="field grid">
                        <label class="col-fixed" style="width:100px">当前平台：</label>
                        <div style="text-align:left">
                            {{storname}}
                        </div>
                        <!-- <label for="Corp" class="col-fixed" style="width:100px">当前平台：</label>
                        <div class="col">
                            <Dropdown v-model="storeid" id="Corp" :options="storeDict" optionLabel="name"
                                optionValue="code" @change="storeChange" />
                        </div> -->
                    </div>
                    <div class="field grid">
                        <div>
                            <Button type="button" label="主题设置" icon="pi pi-cog" badgeClass="badge-danger"
                                class="p-button-warning" @click="onShowSet" />
                        </div>
                        <div class="col">
                            <Button type="button" label="退出当前用户" @click="exitSys" icon="pi pi-users"
                                class="p-button-warning" badgeClass="badge-danger" />
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            <button class="p-link" @click="onMenuToggle">
                <i class="pi pi-bars"></i>
            </button>
        </div>
        <button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
            <i class="pi pi-ellipsis-v"></i>
        </button>
        <!-- <div class="card" style="min-width:80%"> -->
        <TabMenu :scrollable="true" style="min-width:84%" :model="model" @tab-change="onTabChangeToggle" />
        <!-- </div> -->
        <ul class="layout-topbar-menu hidden lg:flex origin-top">
            <li>
                <button style="width:1.5rem;height:1.5rem" class="p-link layout-topbar-button" @click="exitSys">
                    <i class="pi pi-power-off"></i>
                </button>
            </li>


        </ul>
    </div>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import requests from './service/newaxios';
    import MessageTip from './components/Message';
    export default {
        setup() {
            const op = ref();
            const userName = sessionStorage.getItem("username");
            const deptName = sessionStorage.getItem("deptname");
            const toggle = (event) => {
                op.value.toggle(event);
            };
            return {
                op,
                userName,
                deptName,
                toggle
            }
        },
        created() {
            this.loadData();
        },
        data() {
            return {
                storname: '',
                storeid: '',
                storeDict: [],
            }
        },
        methods: {
            loadData() {
                console.log('App-loadData:');
                console.log(sessionStorage.getItem("storeid"));
                console.log(sessionStorage.getItem("stores"));
                this.storeid = sessionStorage.getItem("storeid");
                var storeData = JSON.parse(sessionStorage.getItem("stores"));
                this.storeDict = [];
                for (var i = 0; i < storeData.length; i++) {
                    this.storeDict.push({
                        name: storeData[i]['Name'],
                        code: storeData[i]['Id'],
                    });
                    if (this.storeid == storeData[i]['Id']) {
                        this.storname = storeData[i]['Name'];
                    }
                }
            },
            onTabChangeToggle(event) {
                this.$emit('tab-change', event);
            },
            onMenuToggle(event) {
                this.$emit('menu-toggle', event);
            },
            onTopbarMenuToggle(event) {
                this.$emit('topbar-menu-toggle', event);
            },
            onShowSet(event) {
                this.$emit('topbar-menu-onShowSet', event);
            },
            topbarImage() {
                /* return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg'; */
                return 'images/logo.png';
            },
            storeChange(v_event) {
                console.log("storeChange...");
                console.log(v_event.value);
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                    '/calf/plat/storechange?appkey=' + sessionStorage.getItem("appkey"), {
                        StoreId: v_event.value
                    }).then(res => {
                    console.log(res);
                    if (res.data.errcode == "0") {
                        sessionStorage.setItem("storeid", v_event.value);
                    } else {
                        MessageTip.warnmsg(res.data);
                    }
                });
            },
            exitSys() {
                sessionStorage.clear();
                location.href = "/login/index.html";
                /* window.router.push({
                    path: '/login/index.html'
                }); */
            }
        },
        computed: {
            darkTheme() {
                return this.$appState.darkTheme;
            }
        },
        props: {
            model: Array,
        }
    }
</script>