<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ProgressBar v-if="showWait" mode="indeterminate" style="height: .5em" />
    <!-- <div class="card"
        v-if="!showWait && pageConfig['region_1'] && (pageConfig['region_1']['left']['element'].length>0 || pageConfig['region_1']['right']['element'].length>0)"> -->
    <div v-if="!showWait && pageConfig['region_1'] && (pageConfig['region_1']['left']['element'].length>0 || pageConfig['region_1']['right']['element'].length>0)"
        id="region_1" class="grid">
        <div id="region_1_left" v-if="pageConfig['region_1']['left']['element'].length>0"
            :class="pageConfig['region_1']['left']['class']">
            <div class="grid">
                <template v-for="item in pageConfig['region_1']['left']['element']" :key="item.name">
                    <div v-if="!isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                        :class="item.col_class">
                        <label :for="item.name">
                            <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                            {{item.label}}
                        </label>
                        <Calendar v-if="item.type=='DateTimeString'" :showTime="true" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <Calendar v-if="item.type=='DateString'" :showTime="false" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <InputText v-if="!notInputElement.includes(item.type)" :id="item.name" v-model="item.val"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" @blur="inputBlur(item.name,item.val)" type="text"
                            :class="getmainedithistorycss(item)" />
                        <CalfAtuNumber v-if="item.type=='AtuNumber'" :id="item.name" v-model:inputValue="item.val"
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <!-- <CalfDropdown
                            v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || dropOtherConfig[item.name]=='') && item.type=='Dictionary'"
                            :showClear="true" :loadAll="true" v-model:selectedValue="item.val"
                            v-model:selectedTxt="noshowelements[item.dictLabel]" :dropchangeAfter="dropchangeAfter"
                            :ref="item.name" :id="item.name" :dictObjName="item.dictName"
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" /> -->
                        <CalfDropdown v-if="item.type=='Dictionary'" :showClear="true" :loadAll="true"
                            v-model:selectedValue="item.val" v-model:selectedTxt="noshowelements[item.dictLabel]"
                            :dropchangeAfter="dropchangeAfter" :id="item.name" :ref="item.name"
                            :dictObjName="item.dictName" @click="maintiptoggle($event,item)"
                            :class="getmainedithistorycss(item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <CalfInputDropdown v-if="item.type=='InputDict'" :loadAll="true"
                            v-model:selectedValue="item.val" v-model:selectedTxt="noshowelements[item.dictLabel]"
                            :id="item.name" :canEditable="true" :dictObjName="item.dictName"
                            :selectAfterFun="inputdropSelectAfter" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <CalfMultiSelect v-if="item.type=='MultiSelect'" v-model:selectedValue="item.val"
                            :id="item.name" :loadAll="true" :dictObjName="item.dictName" dictCategories=""
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <CalfTreeDropdown v-if="item.type=='TreeDict'" v-model:selectedValue="item.val"
                            v-model:selectedTxt="noshowelements[item.dictLabel]" :id="item.name"
                            :dictObjName="item.dictName" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <LinkageDropDown v-if="item.type=='LinkageDict'"
                            v-model:selectedValue1="noshowelements[item.dictCode]"
                            v-model:selectedTxt1="noshowelements[item.dictLabel]"
                            v-model:selectedValue2="noshowelements[item.dictCode2]"
                            v-model:selectedTxt2="noshowelements[item.dictLabel2]"
                            v-model:selectedValue3="noshowelements[item.dictCode3]"
                            v-model:selectedTxt3="noshowelements[item.dictLabel3]"
                            v-model:bindselectedTxt="item.bindselectedTxt" :currentfieldname="item.name"
                            :currentitem="item" :dictItems="getDictData(item.dictName)"
                            @click="maintiptoggle($event,item)" :linkdropchangeAfter="linkdropchangeAfter"
                            :class="getmainedithistorycss(item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <UploadInput v-if="item.type=='UpLoad'" v-model:filePatch="item.val" :ctrlname="item.name"
                            v-model:fileName="noshowelements[item.fileName]" :fileType="item.fileType"
                            :uptype="item.uptype" :attachttype="item.attachttype" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <InputText v-if="item.type=='LabelString'" type="text" :id="item.name" :value="item.val"
                            :class="getmainedithistorycss(item)" readonly @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <small v-if="item['showrequiredtip'] && item['showrequiredtip']=='1'">
                            <font color="red">{{item.requiredtip}}</font>
                        </small>
                    </div>
                    <div v-if="elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                        mainUdefinedAuth[item.name])" :class="item.col_class">
                        <label :for="item.name">
                            <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                            {{item.label}}
                        </label>
                        <CalfAtuNumber v-if="item.type=='AtuNumber'" :id="item.name" v-model:inputValue="item.val"
                            :class='getmainedithistorycss(item)'
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" :readonlyprop="true" />
                        <InputText v-if="item.type!='UpLoad' && item.type!='AtuNumber'" type="text" :id="item.name"
                            :value="formatData(item,item.val,noshowelements,item.name)"
                            :class="getmainedithistorycss(item)" readonly
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" />
                        <Image v-if="item.type=='UpLoad' && item.fileType=='img'" :id="item.name"
                            :src="getFilePatch(item.val)" width="30"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" :class="getmainedithistorycss(item)" preview />
                        <a v-if="item.type=='UpLoad' && item.fileType!='img'"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" :class="getmainedithistorycss(item)" :id="item.name"
                            :href="getFilePatch(item.val)" target="_blank">{{noshowelements[item.fileName]}}</a>
                    </div>
                </template>
            </div>
        </div>
        <div id="region_1_right" v-if="pageConfig['region_1']['right']['element'].length>0"
            :class="pageConfig['region_1']['right']['class']">
            <div class="grid">
                <template v-for="item in pageConfig['region_1']['right']['element']" :key="item.name">
                    <div v-if="!isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                        :class="item.col_class">
                        <label :for="item.name">
                            <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                            {{item.label}}
                        </label>
                        <Calendar v-if="item.type=='DateTimeString'" :showTime="true" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" :class="getmainedithistorycss(item)" />
                        <Calendar v-if="item.type=='DateString'" :showTime="false" :id="item.name" v-model="item.val"
                            hourFormat="24" dateFormat="yy-mm-dd"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            @click="maintiptoggle($event,item)" :class="getmainedithistorycss(item)" />
                        <InputText v-if="!notInputElement.includes(item.type)" :id="item.name" v-model="item.val"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" type="text"
                            @click="maintiptoggle($event,item)" @blur="inputBlur(item.name,item.val)"
                            :class="getmainedithistorycss(item)" />
                        <CalfAtuNumber v-if="item.type=='AtuNumber'" :id="item.name" v-model:inputValue="item.val"
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <!-- <CalfDropdown
                            v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || dropOtherConfig[item.name]=='') && item.type=='Dictionary'"
                            :showClear="true" :loadAll="true" v-model:selectedValue="item.val"
                            v-model:selectedTxt="noshowelements[item.dictLabel]" :ref="item.name" :id="item.name"
                            :dictObjName="item.dictName" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            :class="getmainedithistorycss(item)" /> -->
                        <CalfDropdown v-if="item.type=='Dictionary'" :showClear="true" :loadAll="true"
                            v-model:selectedValue="item.val" v-model:selectedTxt="noshowelements[item.dictLabel]"
                            :dropchangeAfter="dropchangeAfter" :id="item.name" :ref="item.name"
                            :dictObjName="item.dictName" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <CalfInputDropdown v-if="item.type=='InputDict'" :loadAll="true"
                            v-model:selectedValue="item.val" v-model:selectedTxt="noshowelements[item.dictLabel]"
                            :id="item.name" :selectAfterFun="inputdropSelectAfter" :canEditable="true"
                            :dictObjName="item.dictName" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            :class="getmainedithistorycss(item)" />
                        <CalfMultiSelect v-if="item.type=='MultiSelect'" v-model:selectedValue="item.val"
                            :id="item.name" :loadAll="true" :dictObjName="item.dictName" dictCategories=""
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <CalfTreeDropdown v-if="item.type=='TreeDict'" v-model:selectedValue="item.val"
                            v-model:selectedTxt="noshowelements[item.dictLabel]" :id="item.name"
                            :dictObjName="item.dictName" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            :class="getmainedithistorycss(item)" />
                        <LinkageDropDown v-if="item.type=='LinkageDict'"
                            v-model:selectedValue1="noshowelements[item.dictCode]"
                            v-model:selectedTxt1="noshowelements[item.dictLabel]"
                            v-model:selectedValue2="noshowelements[item.dictCode2]"
                            v-model:selectedTxt2="noshowelements[item.dictLabel2]"
                            v-model:selectedValue3="noshowelements[item.dictCode3]"
                            v-model:selectedTxt3="noshowelements[item.dictLabel3]"
                            v-model:bindselectedTxt="item.bindselectedTxt" :currentfieldname="item.name"
                            @click="maintiptoggle($event,item)" :currentitem="item"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }"
                            :dictItems="getDictData(item.dictName)" :linkdropchangeAfter="linkdropchangeAfter"
                            :class="getmainedithistorycss(item)" />
                        <UploadInput v-if="item.type=='UpLoad'" v-model:filePatch="item.val" :ctrlname="item.name"
                            v-model:fileName="noshowelements[item.fileName]" :fileType="item.fileType"
                            :uptype="item.uptype" :attachttype="item.attachttype" @click="maintiptoggle($event,item)"
                            :class="getmainedithistorycss(item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <InputText v-if="item.type=='LabelString'" type="text" :id="item.name" :value="item.val"
                            :class="getmainedithistorycss(item)" readonly @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{ value: getmainelementtip(item), escape:true }" />
                        <small v-if="item['showrequiredtip'] && item['showrequiredtip']=='1'">
                            <font color="red">{{item.requiredtip}}</font>
                        </small>
                    </div>
                    <div v-if="elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                        mainUdefinedAuth[item.name])" :class="item.col_class">
                        <label :for="item.name">
                            <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                            {{item.label}}
                        </label>
                        <CalfAtuNumber v-if="item.type=='AtuNumber'" :id="item.name" v-model:inputValue="item.val"
                            :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }" :readonlyprop="true" />
                        <InputText v-if="item.type!='UpLoad' && item.type!='AtuNumber'" type="text" :id="item.name"
                            :value="formatData(item,item.val,noshowelements,item.name)"
                            :class="getmainedithistorycss(item)" readonly @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }" />
                        <Image v-if="item.type=='UpLoad' && item.fileType=='img'" :id="item.name"
                            :src="getFilePatch(item.val)" width="30" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            :class="getmainedithistorycss(item)" preview />
                        <a v-if="item.type=='UpLoad' && item.fileType!='img'" @click="maintiptoggle($event,item)"
                            v-tooltip.bottom="{value: getmainelementtip(item), escape:true }"
                            :class="getmainedithistorycss(item)" :id="item.name" :href="getFilePatch(item.val)"
                            target="_blank">{{noshowelements[item.fileName]}}</a>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <!-- </div> -->
    <Divider
        v-if="!showWait && pageConfig['region_1'] && (pageConfig['region_1']['left']['element'].length>0 || pageConfig['region_1']['right']['element'].length>0)" />
    <!-- <div class="card"
        v-if="!showWait && pageConfig['region_2'] && (pageConfig['region_2']['left']['element'].length>0 || pageConfig['region_2']['right']['element'].length>0)"> -->
    <div v-if="!showWait && pageConfig['region_2'] && (pageConfig['region_2']['left']['element'].length>0 || pageConfig['region_2']['right']['element'].length>0)"
        id="region_2" class="grid">
        <div id="region_2_left" v-if="pageConfig['region_2']['left']['element'].length>0"
            :class="pageConfig['region_2']['left']['class']">
            <div class="grid">
                <template v-for="item in pageConfig['region_2']['left']['element']" :key="item.name">
                    <div v-if="!isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])"
                        :class="item.col_class">
                        <h5>
                            <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                            {{item.label}}
                        </h5>
                        <Textarea v-if="item.type=='Textarea'" :class="getmainedithistorycss(item)"
                            @click="maintiptoggle($event,item)" class="textarea-inherit3" :id="item.name"
                            v-model="item.val" />
                        <Editor v-if="item.type=='Editor'" :id="item.name" v-model="item.val" @click="maintiptoggle($event,item)" :class="getmainedithistorycss(item)"  editorStyle="height:200px">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button v-tooltip.bottom="'导入'" @click="toggle" aria-haspopup="true"
                                                aria-controls="overlay_menu"><svg viewBox="0 0 18 18">
                                                    <path class="ql-stroke"
                                                        d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                    </path>
                                                    <path class="ql-stroke"
                                                        d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                    </path>
                                                </svg></button>
                                            <Menu id="overlay_menu" ref="editorMenu" :model="editorMenuItems" :popup="true" />
                                        </span>
                                    </template>
                            </Editor>
                            <small v-if="item['showrequiredtip'] && item['showrequiredtip']=='1'">
                                <font color="red">{{item.requiredtip}}</font>
                            </small>
                        </div>
                        <div v-if="elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                        mainUdefinedAuth[item.name])" :class="getmainedithistorycss(item)" @click="maintiptoggle($event,item)">
                           <h5>
                             <font v-if="item['required'] && item['required']=='1'" color="red">*</font>
                             {{item.label}}
                           </h5>
                          <ScrollPanel :id="item.name" style="height:120px">
                             <p v-html="item.val"></p>
                         </ScrollPanel>
                        </div>
                    </template>
                </div>
            </div>
            <div id="region_2_right" v-if="pageConfig['region_2']['right']['element'].length>0"
                :class="pageConfig['region_2']['right']['class']">   
                <div id="region_2_right_grid" class="grid">
                    <div class="flex flex-column col-12">
                         <TabView v-model:activeIndex="activeIndex3">
                             <TabPanel v-for="item in pageConfig['region_2']['right']['element']" :key="item.name" :header="item.header">
                                <Toolbar class="mb-2" v-if="!elementAuthority[item.name] && !isReadOnly && (importItems[item.name] || !childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name])">
                                    <template #start>
                                        <SplitButton v-if="importItems[item.name]" label="导入" class="p-button-success mr-2" :model="importItems[item.name]"></SplitButton>
                                        <Button v-if="!childNewDataConfig || childNewDataConfig[item.name]" label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                                            @click="createInfo(item.name)" />
                                        <Button v-if="!childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name]" label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                                            @click="delInfo(item.name)" />
                                    </template>
                                </Toolbar>
                                <DataTable :id="item.name" :rowClass="rowClassFun" :value="item.rows" v-model:selection="selectedRowData" v-model:expandedRows="expandedRowsData" showGridlines :scrollable="true"
                                    :scrollHeight="item.height" :rowStyle="item.rowstyle" stripedRows scrollDirection="both" dataKey="id"
                                    editMode="cell" class="editable-cells-table" responsiveLayout="scroll" @cell-edit-init="cellEdit" @cell-edit-complete="cellEditComplete($event,item.name,item.rows)">
                                    <Column v-if="!elementAuthority[item.name] && !isReadOnly" selectionMode="multiple" frozen footerStyle="width:45px;" bodyStyle="width:45px;" headerStyle="width:45px;"></Column>
                                    <Column v-if="childExpandeConfig && childExpandeConfig[item.name] && childExpandeConfig[item.name]['visible']" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;">
                                        <template #body="slotProps">
                                            <button v-show="(childExpandeConfig[item.name]['ctrshowcol'] && slotProps.data[childExpandeConfig[item.name]['ctrshowcol']]==childExpandeConfig[item.name]['ctrshowval']) || (childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0)" @click="expandertoggle2($event,slotProps.data)" aria-haspopup="true" :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span class="p-row-toggler-icon pi pi-chevron-right"></span><span class="p-ink"></span></button>
                                            <Dialog header="详情" v-model:visible="slotProps.data['dialogvisible']" @hide="childExpandeDialogHide(item.name,item.rows)" modal :style="{ width: '50vw' }">
                                                <TabView v-if="!childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex2">
                                                    <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config']" :key="expandeconfig.id" :header="expandeconfig.title">
                                                            <DataTable :value="expandedata2[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata2,expandedata2[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                    <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                            :sortable="false">
                                                                        <template #editor="{ data }">
                                                                            <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                            <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                            <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                            <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                            <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                            <div v-if="isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type==''" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                        </template>
                                                                        <template #body="{data}">
                                                                            <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                            </template>
                                                                            <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                <label class="oneline"
                                                                                    v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                            </template>
                                                                        </template>
                                                                    </Column>
                                                            </DataTable>
                                                    </TabPanel>
                                                </TabView>
                                                <TabView v-if="childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0" v-model:activeIndex="expandeactiveIndex2">
                                                    <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]]" :key="expandeconfig.id" :header="expandeconfig.title">
                                                            <DataTable :value="expandedata2[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata2,expandedata2[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                    <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                            :sortable="false" :frozen="col2.frozen">
                                                                        <template #editor="{ data }">
                                                                            <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                            <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                            <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                            <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                            <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                            <div v-if="isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type==''" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                        </template>
                                                                        <template #body="{data}">
                                                                            <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                            </template>
                                                                            <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                <label class="oneline"
                                                                                    v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                            </template>
                                                                        </template>
                                                                    </Column>
                                                            </DataTable>
                                                        </TabPanel>
                                                </TabView>
                                            </Dialog>
                                        </template>
                                    </Column>
                                    <Column :footer="getChildTableFooter(item.name,col.field,item.rows)" v-for="col of item.columns" :field="col.field" :header="col.header" :key="col.field"
                                        :sortable="false" :footerStyle="getChildTableColWidth(item.name,col.field)" :bodyStyle="getChildTableColWidth(item.name,col.field)" :headerClass="getchildTableColClass(col,item)" :headerStyle="getChildTableColWidth(item.name,col.field)" :frozen="col.frozen">
                                        <template v-if="col.type!='DateString' && col.type!='DateTimeString' && col.field!='creater'" #editor="{ data }">
                                            <InputText :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="!notInputElement.includes(col.type) && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model="data[col.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                            <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='DateTimeString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                :showTime="true" />
                                            <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='DateString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                :showTime="false" />
                                            <CalfDropdown :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || !dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]" name="otheredit2" :id="col.field" :loadAll="true" :dictObjName="col.dictName"
                                                placeholder="请选择" />
                                            <CalfDropdown :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="(dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]" name="otheredit2" :id="col.field" :childtablename="item.name" :rowid="data['id']" :currentdata="data" :dropchangeAfter="childElementdropchangeAfter" :loadAll="true" :dictObjName="col.dictName"
                                                placeholder="请选择" />
                                            <CalfInputDropdown :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='InputDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                                :dictObjName="col.dictName" name="otheredit2" :canEditable="true" />
                                            <CalfTreeDropdown :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='TreeDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]"
                                                :dictObjName="col.dictName" />
                                            <LinkageDropDown :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='LinkageDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:selectedValue1="data[col.dictCode]" v-model:selectedTxt1="data[col.dictLabel]" v-model:selectedValue2="data[col.dictCode2]" v-model:selectedTxt2="data[col.dictLabel2]"
                                                v-model:selectedValue3="data[col.dictCode3]" name="otheredit2" v-model:selectedTxt3="data[col.dictLabel3]" v-model:bindselectedTxt="col.bindselectedTxt" :currentfieldname="col.field" :currentitem="col" :dictItems="getDictData(col.dictName)" />
                                            <UploadInput :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }"
                                                v-if="col.type=='UpLoad' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                v-model:filePatch="data[col.field]" ctrlname="otheredit2" v-model:fileName="data[col.fileName]" :fileType="col.fileType" :uptype="col.uptype" :attachttype="col.attachttype" />
                                            <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='LabelString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" class="p-mb-3 p-text-left">
                                                {{data[col.field]}}</div>
                                            <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type!='UpLoad'" class="p-mb-3 p-text-left">
                                                {{formatData(col,data[col.field],data,col.field)}}</div>
                                            <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType=='img'" class="p-mb-3 p-text-left">
                                                <Image :src="getFilePatch(data[col.field])" width="30" preview />
                                            </div>
                                            <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType!='img'" class="p-mb-3 p-text-left">
                                                    <a :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                            </div>
                                        </template>
                                        <template #body="{data}">
                                            <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateTimeString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                            <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                            <template v-if="(col.type=='DateTimeString' || col.type=='DateString') && (isReadOnly || col.field=='createtime' || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field]))">
                                             <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" >{{formatData(col,data[col.field],data,col.field)}}</label>
                                            </template>
                                            <template v-if="col.type=='Dictionary' || col.type=='TreeDict' || col.type=='InputDict' || col.type=='LinkageDict' || col.type=='UpLoad'">
                                              <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type!='UpLoad'">{{formatData(col,data[col.field],data,col.field)}}</label>
                                              <Image :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && col.fileType=='img'" :src="getFilePatch(data[col.field])" width="30" preview />
                                              <a :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && col.fileType!='img'" :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                            </template>
                                            <template v-if="col.type!='DateTimeString' && col.type!='DateString' && col.type!='Dictionary' && col.type!='TreeDict' && col.type!='InputDict' && col.type!='LinkageDict' && col.type!='UpLoad'">
                                                <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{data[col.field]}}</label>
                                            </template>
                                        </template>
                                    </Column>
                                </DataTable>
                             </TabPanel>
                         </TabView>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
     <Divider v-if="!showWait && pageConfig['region_2'] && (pageConfig['region_2']['left']['element'].length>0 || pageConfig['region_2']['right']['element'].length>0)" />
    <!-- <div class="card" v-if="!showWait && pageConfig['region_3'] && (pageConfig['region_3']['left']['element'].length>0 || pageConfig['region_3']['right']['element'].length>0)"> -->
        <div v-if="!showWait && pageConfig['region_3'] && (pageConfig['region_3']['left']['element'].length>0 || pageConfig['region_3']['right']['element'].length>0)" id="region_3" class="grid">
             <div id="region_3_left" v-if="pageConfig['region_3']['left']['element'].length>0"
                :class="pageConfig['region_3']['left']['class']">
                 <div id="region_3_left_grid" class="grid">
                      <div class="flex flex-column col-12">
                           <TabView v-model:activeIndex="activeIndex">
                                    <TabPanel v-for="item in pageConfig['region_3']['left']['element']" :key="item.name" :header="item.header">
                                        <Toolbar class="mb-2" v-if="(!item['type'] || item['type']=='grid') && !elementAuthority[item.name] && !isReadOnly && (importItems[item.name] || !childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name])">
                                            <template #start>
                                                <SplitButton v-if="importItems[item.name]" label="导入" class="p-button-success mr-2" :model="importItems[item.name]"></SplitButton>
                                                <Button v-if="!childNewDataConfig || childNewDataConfig[item.name]" label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                                                    @click="createInfo(item.name)" />
                                                <Button v-if="!childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name]" label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                                                    @click="delInfo(item.name)" />
                                            </template>
                                        </Toolbar>
                                        <DataTable :id="item.name" :rowClass="rowClassFun" v-if="!item['type'] || item['type']=='grid'" :value="item.rows" v-model:selection="selectedRowData" v-model:expandedRows="expandedRowsData3" showGridlines :scrollable="true" :scrollHeight="item.height" stripedRows scrollDirection="both" dataKey="id"
                                            editMode="cell" class="editable-cells-table" :rowStyle="item.rowstyle" responsiveLayout="scroll" @cell-edit-init="cellEdit($event,item.name,item.columns)" @cell-edit-complete="cellEditComplete($event,item.name,item.rows)">
                                            <Column v-if="!elementAuthority[item.name] && !isReadOnly" selectionMode="multiple" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;"></Column>
                                            <Column v-if="childExpandeConfig && childExpandeConfig[item.name] && childExpandeConfig[item.name]['visible']" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;">
                                            <template #body="slotProps">
                                                    <button v-show="(childExpandeConfig[item.name]['ctrshowcol'] && slotProps.data[childExpandeConfig[item.name]['ctrshowcol']]==childExpandeConfig[item.name]['ctrshowval']) || (childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0)" @click="expandertoggle($event,slotProps.data)" aria-haspopup="true" :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span class="p-row-toggler-icon pi pi-chevron-right"></span><span class="p-ink"></span></button>
                                                    <Dialog header="详情" v-model:visible="slotProps.data['dialogvisible']" @hide="childExpandeDialogHide(item.name,item.rows)" modal :style="{ width: '50vw' }">
                                                        <TabView v-if="!childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex">
                                                        <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config']" :key="expandeconfig.id" :header="expandeconfig.title">
                                                                <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                        <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                                :sortable="false">
                                                                            <template #editor="{ data }">
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                                <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                                <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                                <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                                <div v-if="(isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type=='') && !data[col2.field].startsWith('attachment/')" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                                <Image v-if="(isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type=='') && data[col2.field].startsWith('attachment/')" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                            </template>
                                                                            <template #body="{data}">
                                                                                <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                    <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                    <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                    <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                                </template>
                                                                                <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                    <label v-if="!data[col2.field].startsWith('attachment/')" v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                                    <Image v-if="data[col2.field].startsWith('attachment/')" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                </template>
                                                                            </template>
                                                                        </Column>
                                                                </DataTable>
                                                        </TabPanel>
                                                        </TabView>
                                                        <TabView v-if="childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0" v-model:activeIndex="expandeactiveIndex">
                                                        <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]]" :key="expandeconfig.id" :header="expandeconfig.title">
                                                                <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                        <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                                :sortable="false">
                                                                            <template #editor="{ data }">
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                                <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                                <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                                <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                                <div v-if="(isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type=='') && !data[col2.field].startsWith('attachment/')" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                                <Image v-if="(isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type=='') && data[col2.field].startsWith('attachment/')" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                            </template>
                                                                            <template #body="{data}">
                                                                                <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                    <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                    <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                    <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                                </template>
                                                                                <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                     <label v-if="!data[col2.field].startsWith('attachment/')" v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                                    <Image v-if="data[col2.field].startsWith('attachment/')" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                </template>
                                                                            </template>
                                                                        </Column>
                                                                </DataTable>
                                                            </TabPanel>
                                                        </TabView>
                                                    </Dialog>
                                            </template>
                                            </Column>
                                            <Column v-if="childExpandeConfig && childExpandeConfig[item.name+'sys'] && childExpandeConfig[item.name+'sys']['visible']" expander style="width: 5rem" />
                                            <Column :footer="getChildTableFooter(item.name,col.field,item.rows)" v-for="col of item.columns" :field="col.field" :header="col.header" :key="col.field"
                                                :sortable="false" :footerStyle="getChildTableColWidth(item.name,col.field)" :bodyStyle="getChildTableColWidth(item.name,col.field)" :headerClass="getchildTableColClass(col,item)" :headerStyle="getChildTableColWidth(item.name,col.field)" :frozen="col.frozen">
                                                <template v-if="col.type!='DateString' && col.type!='DateTimeString' && col.field!='creater'" #editor="{ data }">
                                                    <InputText
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="!notInputElement.includes(col.type) && !IsChildType(col.type) && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                    <Calendar
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateTimeString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                        :showTime="true" />
                                                    <Calendar
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                        :showTime="false" />
                                                    <CalfDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || !dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :loadAll="true" :dictObjName="col.dictName"
                                                        placeholder="请选择" />
                                                    <CalfDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :childtablename="item.name" :rowid="data['id']" :currentdata="data" :dropchangeAfter="childElementdropchangeAfter" :loadAll="true" :dictObjName="col.dictName"
                                                        placeholder="请选择" />
                                                    <CalfInputDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='InputDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                                        :dictObjName="col.dictName" name="otheredit2" :canEditable="true" />
                                                    <CalfTreeDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='TreeDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                                        :dictObjName="col.dictName" name="otheredit2" />
                                                    <LinkageDropDown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='LinkageDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue1="data[col.dictCode]" v-model:selectedTxt1="data[col.dictLabel]" v-model:selectedValue2="data[col.dictCode2]" v-model:selectedTxt2="data[col.dictLabel2]"
                                                        v-model:selectedValue3="data[col.dictCode3]" name="otheredit2" v-model:selectedTxt3="data[col.dictLabel3]" v-model:bindselectedTxt="col.bindselectedTxt" :currentfieldname="col.field" :currentitem="col" :dictItems="getDictData(col.dictName)" />
                                                    <UploadInput
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:filePatch="data[col.field]" ctrlname="otheredit2" v-model:fileName="data[col.fileName]" :fileType="col.fileType" :uptype="col.uptype" :attachttype="col.attachttype" />
                                                        <div v-if="col.type=='LabelString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" class="p-mb-3 p-text-left">
                                                        {{data[col.field]}}</div>
                                                     <div v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && IsChildType(col.type)" class="p-mb-3 p-text-left">
                                                            <Button icon="pi pi-paperclip" class="p-button-rounded p-button-text" @click="openChildDialog(data[col.field],data)" />
                                                    </div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type!='UpLoad' && !IsChildType(col.type)" class="p-mb-3 p-text-left">
                                                        {{formatData(col,data[col.field],data,col.field)}}</div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType=='img'" class="p-mb-3 p-text-left">
                                                        <Image :src="getFilePatch(data[col.field])" width="30" preview />
                                                    </div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType!='img'" class="p-mb-3 p-text-left">
                                                            <a :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                                    </div>
                                                </template>
                                                <template #body="{data}">
                                                    <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateTimeString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                    <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                    <template v-if="(col.type=='DateTimeString' || col.type=='DateString') && (isReadOnly || col.field=='createtime' || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field]))">
                                                     <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{formatData(col,data[col.field],data,col.field)}}</label>
                                                    </template>
                                                    <template v-if="col.type=='Dictionary' || col.type=='TreeDict' || col.type=='InputDict' || col.type=='LinkageDict' || col.type=='UpLoad' || IsChildType(col.type)">
                                                      <label v-if="col.type!='UpLoad' && !IsChildType(col.type)" :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{formatData(col,data[col.field],data,col.field)}}</label>
                                                      <Image v-if="col.type=='UpLoad' && col.fileType=='img'" :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" :src="getFilePatch(data[col.field])" width="30" preview />
                                                      <a v-if="col.type=='UpLoad' && col.fileType!='img'" :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                                       <div v-if="IsChildType(col.type)" class="p-mb-3 p-text-left">
                                                             <Button icon="pi pi-paperclip" class="p-button-rounded p-button-text" @click="openChildDialog(data[col.field],data)" />
                                                       </div>
                                                    </template>
                                                    <template v-if="col.type!='DateTimeString' && col.type!='DateString' && col.type!='Dictionary' && col.type!='TreeDict' && col.type!='InputDict' && col.type!='LinkageDict' && col.type!='UpLoad' && !IsChildType(col.type)">
                                                      <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{data[col.field]}}</label>
                                                    </template>
                                                </template>
                                            </Column>
                                             <template v-if="childExpandeConfig && childExpandeConfig[item.name+'sys'] && childExpandeConfig[item.name+'sys']['visible']" #expansion="slotProps">
                                                <div style="margin-left:90px">
                                                    <h6>{{ childExpandeConfig[item.name+'sys']['title'] }}</h6>
                                                    <DataTable editMode="cell" class="editable-cells-table" @row-edit-save="childExpande3RowEditSave($event,slotProps.data.rows)" @cell-edit-complete="childExpande3EditComplete($event)" :value="slotProps.data[childExpandeConfig[item.name+'sys']['objname']]" >
                                                        <Column v-for="col of childExpandeConfig[item.name+'sys']['columns']" :field="col.field" :header="col.header" :key="col.field" :bodyStyle="col.width" :headerStyle="col.width">
                                                          <template  #editor="{ data }">
                                                              <label v-if="col.edittype==''" :class="col.cssname" v-tooltip.bottom="{value: data[col.field], escape:true }">{{data[col.field]}}</label>
                                                              <InputText v-if="!isReadOnly && !elementAuthority[childExpandeConfig[item.name+'sys']['authdata']] && col.edittype=='input'" v-model="data[col.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                               <label v-if="(isReadOnly || elementAuthority[childExpandeConfig[item.name+'sys']['authdata']]) && col.edittype=='input'" :class="col.cssname" v-tooltip.bottom="{value: data[col.field], escape:true }">{{data[col.field]}}</label>
                                                          </template>
                                                          <template #body="{data}">
                                                             <label :class="col.cssname" v-tooltip.bottom="{value: data[col.field], escape:true }">{{data[col.field]}}</label>
                                                         </template>
                                                        </Column>
                                                    </DataTable>
                                                </div>
                                            </template>
                                        </DataTable>
                                        <Textarea :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && item['type']=='Textarea' && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])" class="textarea-inherit3" :id="item.name" v-model="item.val" />
                                        <Editor :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && item['type']=='Editor' && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])" :id="item.name" v-model="item.val" editorStyle="height:200px">
                                                <template v-slot:toolbar>
                                                    <span class="ql-formats">
                                                        <button v-tooltip.bottom="'导入'" @click="toggle" aria-haspopup="true"
                                                            aria-controls="overlay_menu"><svg viewBox="0 0 18 18">
                                                                <path class="ql-stroke"
                                                                    d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                                </path>
                                                                <path class="ql-stroke"
                                                                    d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                                </path>
                                                            </svg></button>
                                                        <Menu id="overlay_menu" ref="editorMenu" :model="editorMenuItems" :popup="true" />
                                                    </span>
                                                </template>
                                        </Editor>
                                        <ScrollPanel :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && (item['type']=='Textarea' || item['type']=='Editor') && (elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                                            mainUdefinedAuth[item.name]))" :id="item.name" style="height:120px">
                                                <p v-html="item.val"></p>
                                         </ScrollPanel>
                                          <small v-if="item['showrequiredtip'] && item['showrequiredtip']=='1'">
                                            <font color="red">{{item.requiredtip}}</font>
                                          </small>
                                    </TabPanel>
                            </TabView>
                      </div>
                 </div>
             </div>
             <div id="region_3_right" v-if="pageConfig['region_3']['right']['element'].length>0"
                :class="pageConfig['region_3']['right']['class']"> 
                 <div id="region_3_right_grid" class="grid">
                      <div class="flex flex-column col-12">
                           <TabView v-model:activeIndex="activeIndex2">
                                    <TabPanel v-for="item in pageConfig['region_3']['right']['element']" :key="item.name" :header="item.header">
                                        <Toolbar class="mb-2" v-if="(!item['type'] || item['type']=='grid') && !elementAuthority[item.name] && !isReadOnly && (importItems[item.name] || !childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name])">
                                            <template #start>
                                                <SplitButton v-if="importItems[item.name]" label="导入" class="p-button-success mr-2" :model="importItems[item.name]"></SplitButton>
                                                <Button v-if="!childNewDataConfig || childNewDataConfig[item.name]" label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                                                    @click="createInfo(item.name)" />
                                                <Button v-if="!childNewDataConfig || childNewDataConfig[item.name] || childDelDataConfig && childDelDataConfig[item.name]" label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                                                    @click="delInfo(item.name)" />
                                            </template>
                                        </Toolbar>                                   
                                        <DataTable :id="item.name" :rowClass="rowClassFun" v-if="!item['type'] || item['type']=='grid'" :value="item.rows" v-model:selection="selectedRowData" v-model:expandedRows="expandedRowsData" showGridlines :scrollable="true" :scrollHeight="item.height" stripedRows scrollDirection="both" dataKey="id"
                                            editMode="cell" class="editable-cells-table" :rowStyle="item.rowstyle" responsiveLayout="scroll" @cell-edit-init="cellEdit($event,item.name,item.columns)" @cell-edit-complete="cellEditComplete($event,item.name,item.rows)">
                                            <Column v-if="!elementAuthority[item.name] && !isReadOnly" selectionMode="multiple" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;"></Column>
                                            <Column v-if="childExpandeConfig && childExpandeConfig[item.name] && childExpandeConfig[item.name]['visible']" frozen footerStyle="width:45px;" bodyStyle="width:45px;"  headerStyle="width:45px;">
                                            <template #body="slotProps">
                                                    <button v-show="(childExpandeConfig[item.name]['ctrshowcol'] && slotProps.data[childExpandeConfig[item.name]['ctrshowcol']]==childExpandeConfig[item.name]['ctrshowval']) || (childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0)" @click="expandertoggle($event,slotProps.data)" aria-haspopup="true" :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span class="p-row-toggler-icon pi pi-chevron-right"></span><span class="p-ink"></span></button>
                                                    <Dialog header="详情" v-model:visible="slotProps.data['dialogvisible']" @hide="childExpandeDialogHide(item.name,item.rows)" modal :style="{ width: '50vw' }">
                                                        <TabView v-if="!childExpandeConfig[item.name]['tabcol']" v-model:activeIndex="expandeactiveIndex">
                                                        <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config']" :key="expandeconfig.id" :header="expandeconfig.title">
                                                                <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                        <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                                :sortable="false">
                                                                            <template #editor="{ data }">
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                                <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                                <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                                <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                                <div v-if="isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type==''" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                            </template>
                                                                            <template #body="{data}">
                                                                                <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                    <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                    <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                    <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                                </template>
                                                                                <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                    <label v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                                </template>
                                                                            </template>
                                                                        </Column>
                                                                </DataTable>
                                                        </TabPanel>
                                                        </TabView>
                                                        <TabView v-if="childExpandeConfig[item.name]['tabcol'] && childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]].length>0" v-model:activeIndex="expandeactiveIndex">
                                                        <TabPanel v-for="expandeconfig in childExpandeConfig[item.name]['config'+slotProps.data[childExpandeConfig[item.name]['tabcol']]]" :key="expandeconfig.id" :header="expandeconfig.title">
                                                                <DataTable :value="expandedata[expandeconfig['data']]" dataKey="id" showGridlines :scrollable="true" stripedRows scrollDirection="both" editMode="cell" class="editable-cells-table" @cell-edit-complete="cellEditCompleteExpande(expandeconfig['data'],$event,expandedata,expandedata[expandeconfig['data']],item.rows)" :metaKeySelection="false" responsiveLayout="scroll">
                                                                        <Column v-for="col2 of expandeconfig['columns']" :bodyStyle="col2.style" :headerStyle="col2.style" :field="col2.field"  :header="col2.header" :key="col2.field"
                                                                                :sortable="false">
                                                                            <template #editor="{ data }">
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateTimeString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                                                <Calendar v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='DateString'" v-model="data[col2.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                                                <CalfDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='Dictionary'" v-model:selectedValue="data[col2.field]" name="otheredit2" v-model:selectedTxt="data[col2.dictLabel]" :id="col2.field" :loadAll="true" :dictObjName="col2.dictName" placeholder="请选择" />
                                                                                <CalfInputDropdown v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputDict'" v-model:selectedValue="data[col2.field]" v-model:selectedTxt="data[col2.dictLabel]" :dictObjName="col2.dictName" name="otheredit2" :canEditable="true" />
                                                                                <InputText v-if="!isReadOnly && !elementAuthority[expandeconfig['data']+'.'+col2.field] && col2.type=='InputText'" v-model="data[col2.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                                                <div v-if="isReadOnly || elementAuthority[expandeconfig['data']+'.'+col2.field] || col2.type==''" class="p-mb-3 p-text-left">{{data[col2.field]}}</div>
                                                                            </template>
                                                                            <template #body="{data}">
                                                                                <template  v-if="col2.type=='DateTimeString' || col2.type=='DateString' || col2.type=='Dictionary' || col2.type=='TreeDict' || col2.type=='InputDict' || col2.type=='LinkageDict' || col2.type=='UpLoad'">
                                                                                    <label v-if="col2.type!='UpLoad'">{{formatData(col2,data[col2.field],data,col2.field)}}</label>
                                                                                    <Image v-if="col2.type=='UpLoad' && col2.fileType=='img'" :src="getFilePatch(data[col2.field])" width="30" preview />
                                                                                    <a v-if="col2.type=='UpLoad' && col2.fileType!='img'" :href="getFilePatch(data[col2.field])" target="_blank">{{data[col2.fileName]}}</a>
                                                                                </template>
                                                                                <template v-if="col2.type!='DateTimeString' && col2.type!='DateString' && col2.type!='Dictionary' && col2.type!='TreeDict' && col2.type!='InputDict' && col2.type!='LinkageDict' && col2.type!='UpLoad'">
                                                                                    <label v-tooltip.bottom="{ value: data[col2.field], disabled: false }">{{data[col2.field]}}</label>
                                                                                </template>
                                                                            </template>
                                                                        </Column>
                                                                </DataTable>
                                                            </TabPanel>
                                                        </TabView>
                                                    </Dialog>
                                            </template>
                                            </Column>
                                            <Column :footer="getChildTableFooter(item.name,col.field,item.rows)" v-for="col of item.columns" :field="col.field" :header="col.header" :key="col.field"
                                                :sortable="false" :footerStyle="getChildTableColWidth(item.name,col.field)" :bodyStyle="getChildTableColWidth(item.name,col.field)" :headerClass="getchildTableColClass(col,item)" :headerStyle="getChildTableColWidth(item.name,col.field)" :frozen="col.frozen">
                                                <template v-if="col.type!='DateString' && col.type!='DateTimeString' && col.field!='creater'" #editor="{ data }">
                                                    <InputText
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="!notInputElement.includes(col.type) && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" style="width:100%" name="otheredit2" id="input2" autofocus />
                                                    <Calendar
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateTimeString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                        :showTime="true" />
                                                    <Calendar
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24"
                                                        :showTime="false" />
                                                    <CalfDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(!dropOtherConfig || !dropOtherConfig[item.name] || !dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :loadAll="true" :dictObjName="col.dictName"
                                                        placeholder="请选择" />
                                                    <CalfDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(dropOtherConfig && dropOtherConfig[item.name] && dropOtherConfig[item.name][col.field]) && col.type=='Dictionary' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" name="otheredit2" v-model:selectedTxt="data[col.dictLabel]" :id="col.field" :childtablename="item.name" :rowid="data['id']" :currentdata="data" :dropchangeAfter="childElementdropchangeAfter" :loadAll="true" :dictObjName="col.dictName"
                                                        placeholder="请选择" />
                                                    <CalfInputDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='InputDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                                        :dictObjName="col.dictName" name="otheredit2" :canEditable="true" />
                                                    <CalfTreeDropdown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='TreeDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue="data[col.field]" v-model:selectedTxt="data[col.dictLabel]"
                                                        :dictObjName="col.dictName" name="otheredit2" />
                                                    <LinkageDropDown
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='LinkageDict' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:selectedValue1="data[col.dictCode]" v-model:selectedTxt1="data[col.dictLabel]" v-model:selectedValue2="data[col.dictCode2]" v-model:selectedTxt2="data[col.dictLabel2]"
                                                        v-model:selectedValue3="data[col.dictCode3]" name="otheredit2" v-model:selectedTxt3="data[col.dictLabel3]" v-model:bindselectedTxt="col.bindselectedTxt" :currentfieldname="col.field" :currentitem="col" :dictItems="getDictData(col.dictName)" />
                                                    <UploadInput
                                                       :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])"
                                                        v-model:filePatch="data[col.field]" ctrlname="otheredit2" v-model:fileName="data[col.fileName]" :fileType="col.fileType" :uptype="col.uptype" :attachttype="col.attachttype" />
                                                        <div v-if="col.type=='LabelString' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" class="p-mb-3 p-text-left">
                                                        {{data[col.field]}}</div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type!='UpLoad'" class="p-mb-3 p-text-left">
                                                        {{formatData(col,data[col.field],data,col.field)}}</div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType=='img'" class="p-mb-3 p-text-left">
                                                        <Image :src="getFilePatch(data[col.field])" width="30" preview />
                                                    </div>
                                                    <div :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="(isReadOnly || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field])) && col.type=='UpLoad' && col.fileType!='img'" class="p-mb-3 p-text-left">
                                                            <a :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                                    </div>
                                                </template>
                                                <template #body="{data}">
                                                    <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateTimeString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="true" />
                                                    <Calendar :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='DateString' && col.field!='createtime' && !elementAuthority[item.name] && !elementAuthority[item.name+'.'+col.field] && !isReadOnly && (!childUdefinedAuth || !childUdefinedAuth[item.name+'.'+col.field])" v-model="data[col.field]" name="otheredit2" dateFormat="yy-mm-dd" hourFormat="24" :showTime="false" />
                                                    <template v-if="(col.type=='DateTimeString' || col.type=='DateString') && (isReadOnly || col.field=='createtime' || elementAuthority[item.name] ||  elementAuthority[item.name+'.'+col.field] || (childUdefinedAuth && childUdefinedAuth[item.name+'.'+col.field]))">
                                                     <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{formatData(col,data[col.field],data,col.field)}}</label>
                                                    </template>
                                                    <template v-if="col.type=='Dictionary' || col.type=='TreeDict' || col.type=='InputDict' || col.type=='LinkageDict' || col.type=='UpLoad'">
                                                    <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type!='UpLoad'">{{formatData(col,data[col.field],data,col.field)}}</label>
                                                    <Image :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && col.fileType=='img'" :src="getFilePatch(data[col.field])" width="30" preview />
                                                    <a :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }" v-if="col.type=='UpLoad' && col.fileType!='img'" :href="getFilePatch(data[col.field])" target="_blank">{{data[col.fileName]}}</a>
                                                    </template>
                                                    <template v-if="col.type!='DateTimeString' && col.type!='DateString' && col.type!='Dictionary' && col.type!='TreeDict' && col.type!='InputDict' && col.type!='LinkageDict' && col.type!='UpLoad'">
                                                    <label :class="getchildedithistorycss(data,item,col.field)" @click="childtiptoggle($event,data,col)" v-tooltip.bottom="{value: getchildelementtip(data,col), escape:true }">{{data[col.field]}}</label>
                                                    </template>
                                                </template>
                                            </Column>
                                        </DataTable>
                                        <Textarea :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && item['type']=='Textarea' && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])" class="textarea-inherit3" :id="item.name" v-model="item.val" />
                                        <Editor :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && item['type']=='Editor' && !isReadOnly && !elementAuthority[item.name] && (!mainUdefinedAuth || !mainUdefinedAuth[item.name])" :id="item.name" v-model="item.val" editorStyle="height:200px">
                                                <template v-slot:toolbar>
                                                    <span class="ql-formats">
                                                        <button v-tooltip.bottom="'导入'" @click="toggle" aria-haspopup="true"
                                                            aria-controls="overlay_menu"><svg viewBox="0 0 18 18">
                                                                <path class="ql-stroke"
                                                                    d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z">
                                                                </path>
                                                                <path class="ql-stroke"
                                                                    d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z">
                                                                </path>
                                                            </svg></button>
                                                        <Menu id="overlay_menu" ref="editorMenu" :model="editorMenuItems" :popup="true" />
                                                    </span>
                                                </template>
                                        </Editor>
                                         <ScrollPanel :class='getmainedithistorycss(item)' @click="maintiptoggle($event,item)" v-if="item['type'] && (item['type']=='Textarea' || item['type']=='Editor') && (elementAuthority[item.name] || isReadOnly || (mainUdefinedAuth &&
                                            mainUdefinedAuth[item.name]))" :id="item.name" style="height:120px">
                                                <p v-html="item.val"></p>
                                         </ScrollPanel>
                                         <small v-if="item['showrequiredtip'] && item['showrequiredtip']=='1'">
                                            <font color="red">{{item.requiredtip}}</font>
                                          </small>
                                    </TabPanel>
                            </TabView>
                      </div>
                 </div>
             </div>
        </div>
    <!-- </div> -->
    <div class="card" v-if="!showWait && !isReadOnly && !elementAuthority[approvalresultproname] && approvalresult.details">
        <h5>处理结果</h5>
        <div class="grid" style="width:200px">
            <div v-for="approvaldict of approvalresult.dict" :key="approvaldict.value" class="col-12 md:col-6">
                <div class="field-radiobutton">
                    <RadioButton :id="approvaldict.value" name="approvalcategory" :value="approvaldict.value"
                        v-model="approvalresultValue" />
                    <label :for="approvaldict.value">{{approvaldict.label}}</label>
                </div>
            </div>
        </div>
        <h5 v-if="viewdata.categoryname && viewdata.details.length>0">{{viewdata.categoryname}}</h5>
        <Textarea v-if="viewdata.details.length==1" v-model="viewdata.details[0].content" class="textarea-inherit2"/>
        <TabView v-if="viewdata.details.length>1" @tab-click="viewTabClick" class="tabview-custom">
            <TabPanel v-for="item in viewdata.details" :key="item.id">
                <template #header>
                    <Checkbox :id="item.id" :name="item.id" :value="item.uniqueid" v-model="selectViews" />
                    <span>{{item.uniquename}}</span>
                </template>
                <Textarea v-model="item.content" class="textarea-inherit2"/>
            </TabPanel>
        </TabView>
    </div>
    <Dialog header="流转信息" v-model:visible="showFlowDialog" :style="{width: '70vw'}"
        :maximizable="false" :modal="true">
        <p class="p-m-0">
            <FlowEngine ref="flowengine" :id="id" :boxid="boxid" :workflowid="workflowid" :flowFreshListMethod="freshListMethod" :closeWinowMehod="closeParentMehod"  />
        </p>
    </Dialog>
    <Dialog header="数据导入" v-model:visible="showImportDataDialog" :style="{width:'80vw'}" :maximizable="false"
        :modal="true">
        <p class="p-m-0">
            <DataImport ref="dataimport" :targetid="currenttargetid" :freshParentData="importfreshParentData" :getNewId="getNewId" :addQueryCondition="importAddQueryConditionMethod" :addCols="importAddColsMethod" v-model:showImportDataDialog="showImportDataDialog" />
        </p>
    </Dialog>
     <OverlayPanel ref="op" id="edithistorypanel" style="width:250px;height:220px">
       <ScrollPanel style="width:100%;height:210px">
           <div v-html="tipcontent"></div>
       </ScrollPanel>
    </OverlayPanel>
</template>
<style type="text/css">
    h6 {
        margin-top:0;
        margin-bottom:0;
        padding-bottom:0;
    }
    .oneline {
        width: 170px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import commonJs from '../js/Common';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    import Image from 'primevue/image';
    import Editor from 'primevue/editor';
    import ScrollPanel from 'primevue/scrollpanel';
    import CalfAtuNumber from './CalfAtuNumber.vue';
    import CalfDropdown from './CalfDropdown.vue';
    import CalfInputDropdown from './CalfInputDropdown.vue';
    import CalfMultiSelect from './CalfMultiSelect.vue';
    import CalfTreeDropdown from '../components/CalfTreeDropdown.vue';
    import LinkageDropDown from '../components/LinkageDropDown.vue';
    import DataImport from '../components/DataImport.vue';
    import FlowEngine from '../components/FlowEngine.vue';
    import UploadInput from '../components/UploadInput.vue';
    export default {
        name: 'NewUniversalPage',
        setup() {
            const op = ref();
            const pageConfig = ref();
            const childExpandeConfig=ref();
            const editorMenu = ref();
            const editorMenuItems = [];
            const toggle = (event) => {
                editorMenu.value.toggle(event);
            };
            return {
                pageConfig,
                childExpandeConfig,
                editorMenuItems,
                editorMenu,
                toggle,
                op
            };
        },
        data() {
            return {
                newid:1,
                openedithistory:false,
                tipcontent:'',
                editHistoryObj:{},
                showWait:true,
                currenttargetid:'',
                currenttargetname:'',
                showImportDataDialog:false,
                showFlowDialog:false,
                startEdit:{},
                expandeactiveIndex:0,
                expandeactiveIndex2:0,
                butLoading: false,
                modulename: '',
                id: '',
                workflowid: '',
                boxid: '',
                flowid: '',
                stateid: '',
                flowguid: '',
                preViewTabIndex: 0,
                originalData: null,
                elementTypeData: {},
                selectedRowData: ref(),
                expandedRowsData:ref([]),
                expandedRowsData3:ref([]),
                selectViews: ref([]),
                saveloading: false,
                dicts: {},
                approvalresultValue: 1,
                expandedata:{},
                expandedata2:{},
                childImportDataConfig:{},
                childMoudleGraphqlmaps: [],
                elementauthoritymaps:[],
                childModuleStructure: [],
                elementVisible: {},
                flowhistory: null,
                approvalresult: {},
                approvalresultproname: '',
                viewdata: {},
                childTableFooterObj:ref(),
                importItems:{},
                activeIndex: 0,
                activeIndex2: 0,
                activeIndex3: 0,
                noshowelements: {},
                elementAuthority: {},
                notInputElement: ['DateString', 'LabelString', 'DateTimeString', 'Dictionary', 'AtuNumber', 'TreeDict',
                    'MultiSelect', 'InputDict', 'LinkageDict', 'UpLoad'
                ],
                timeType: ['DateString', 'DateTimeString'],
                flowInfoType: ['flowhistorygroup', 'approvalresult'],
                flowViewType: ['shenpiview', 'tuihuiview', 'shenheview', 'banliview'],
                baseType: ['String', 'ID', 'Decimal', 'Int', 'Long', 'DateString', 'DateTimeString',
                    'Dictionary', 'AtuNumber', 'LabelString', 'TreeDict', 'MultiSelect', 'InputDict', 'Textarea',
                    'LinkageDict', 'UpLoad', 'Editor'
                ],
                excludeElements: ['boxid', 'ischeck', 'isdel', 'workflowid']
            }
        },
        created() {
            this.newid=1;
            this.openedithistory=false;
            this.tipcontent='';
            this.expandedRowsData=ref([]);
            this.expandedRowsData3=ref([]);
           if(this.pageJsonConfig){
              this.pageConfig=JSON.parse(JSON.stringify(this.pageJsonConfig));
           }
           console.log('created...childExpandePageConfig...');
           if(this.childExpandePageConfig){
             this.childExpandeConfig=JSON.parse(JSON.stringify(this.childExpandePageConfig));           
             console.log(this.childExpandeConfig);
           }
           this.clearRequired();
           this.loadModuleStructure();
        },
        props: ['pageJsonConfig','currentRow', 'handleDataBeforeMethod', 'loadDataAfterMethod',
            'initDetailMethod', 'mainelementCss', 'CellEditCompleteMethod',
            'CellEditCompleteExpandeMethod', 'childTableFooter', 'dropOtherConfig', 'noLoadChild','inputConfig',
            'mainUdefinedAuth', 'childExpandePageConfig', 'childNewDataConfig', 'childDelDataConfig',
            'childUdefinedAuth', 'isReadOnly', 'closeButLoadingMethod', 'freshListMethod', 'saveBeforeMethod','openChildDialogMethod',
            'importAddQueryConditionMethod', 'importAddColsMethod', 'closeParentMehod', 'CellEditInit','childCreateBeforeMethod','inputdropSelectAfterMethod'
        ],
        methods: {
            expandertoggle2(event,data){
               console.log(event);
               console.log(data);
               this.expandedata2=data;
               this.expandeactiveIndex2=0;
               data['dialogvisible']=true;
               //this.$refs.op2.toggle(event);
            },
            expandertoggle(event,data){
              console.log(event);
              console.log(data);
              this.expandedata=data;
              this.expandeactiveIndex=0;
              data['dialogvisible']=true;
              //this.$refs.op.toggle(event);
            },
            loadModuleStructure() {
                console.log('pageConfig:');
                console.log(this.pageConfig);
                console.log('dropOtherConfig:');
                console.log(this.dropOtherConfig);
                console.log('UniversalPage--loadModuleStructure');
                console.log(this.currentRow);
                this.elementauthoritymaps=[];
                if(this.initDetailMethod){
                    this.editorMenuItems=[];
                    this.initDetailMethod(this.editorMenuItems);
                }
                this.childTableFooterObj=this.childTableFooter;
                this.butLoading = false;
                this.showFlowDialog=false;
                this.activeIndex = 0;
                this.startEdit={'startedit':false};
                this.modulename = this.currentRow.bindpage;
                this.id = this.currentRow.id;
                this.workflowid = this.currentRow.workflowid;
                this.flowguid = this.currentRow.flowguid;
                if (this.currentRow.boxid) {
                    this.boxid = this.currentRow.boxid;
                } else {
                    this.boxid = '-1';
                }
                if (this.currentRow.stateid) {
                    this.stateid = this.currentRow.stateid;
                }
                if (this.currentRow.flowid) {
                    this.flowid = this.currentRow.flowid;
                }
                if (this.id == '') this.id = '-1';
                if (this.workflowid == '') this.workflowid = '-1';
                if (this.boxid == '') this.boxid = '-1';
               
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    '{__type(name: "' + this.modulename +
                    '"){name description dictconfiginfo fields { name description dictconfiginfo type { name description }} } }'
                ).then(res => {
                    if (res.errcode == "0") {
                        var mainModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data
                            .__type;
                        this.loadModuleData(mainModuleObj);
                    } else {
                        MessageTip.warnmsg('类型加载失败');
                    }
                });
            },
            loadModuleData(v_mainModuleObj) {
                var graphqlFeild = {};
                var childStructure = [];
                for (var i = 0; i < v_mainModuleObj.fields.length; i++) {
                    if (this.baseType.includes(v_mainModuleObj.fields[i].type.name) && !this.excludeElements
                        .includes(
                            v_mainModuleObj.fields[i].name)) {
                        graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:''};
                        if (v_mainModuleObj.fields[i].type.name == 'MultiSelect'){
                            graphqlFeild[v_mainModuleObj.fields[i].name]['val']='{id code name parentid}';
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'Dictionary' || v_mainModuleObj.fields[i].type.name == 'MultiSelect' || v_mainModuleObj.fields[i].type.name == 'LinkageDict') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]['dictconfiginfo']=v_mainModuleObj.fields[i].dictconfiginfo;
                        }
                    } else {
                        if (v_mainModuleObj.fields[i].type.name.substr(0, 1) == '[' && v_mainModuleObj.fields[i].type.name != '[flowhistorygroup]') {
                            var childStructureobj={name:v_mainModuleObj.fields[i].name,typename:v_mainModuleObj.fields[i].type.name.replace('[', '').replace(']', '')};
                            childStructure.push(childStructureobj);
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'flowhistorygroup') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{uniqueid uniquename details {approver approvetime nodename approvecontent viewcontent status}}'};
                        }
                        if (v_mainModuleObj.fields[i].type.name == '[flowhistorygroup]') {
                             graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{uniqueid uniquename details {approver approvetime nodename approvecontent viewcontent status}}'};
                        }
                        if (v_mainModuleObj.fields[i].type.name == 'approvalresult') {
                            graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{column table dict {label value} details {id ischeck resultvalue uniqueid uniquename}}'};
                            this.approvalresultproname = v_mainModuleObj.fields[i].name;
                        }
                        if (this.flowViewType.includes(v_mainModuleObj.fields[i].type.name)) {
                             graphqlFeild[v_mainModuleObj.fields[i].name]={type:v_mainModuleObj.fields[i].type.name,val:'{categoryid categoryname details {id ischeck content uniqueid uniquename}}'};
                        }
                    }
                }
                if (childStructure.length > 0) {
                    for(var k=0;k<childStructure.length;k++){
                        this.loadChildStructure(childStructure[k],childStructure,graphqlFeild,v_mainModuleObj);
                    }
                } else {
                    this.loadElementAuthority(childStructure, graphqlFeild, v_mainModuleObj);
                }
                this.loadChildExpandObjAth();
            },
            inputBlur(v_elementName,v_value){
               console.log('inputBlur....');
               console.log(v_elementName);
               console.log(v_value);
               if(this.inputConfig && this.inputConfig[v_elementName+'fun']){
                    var allchildelements=this.getAllChildElements();
                    var allmainelements=this.getAllMainElements();
                    this.inputConfig[v_elementName+'fun'](allmainelements,allchildelements,v_value);
                }
            },
            linkdropchangeAfter(v_elementName,v_txt,v_value,v_dictsource){
                console.log('linkdropchangeAfter....');
                console.log(v_txt);
                console.log(v_value);
                console.log(v_dictsource);
                 if(this.dropOtherConfig && this.dropOtherConfig[v_elementName+'fun']){
                    var allmainelements=this.getAllMainElements();
                    this.dropOtherConfig[v_elementName+'fun'](v_elementName,v_txt,v_value,v_dictsource,allmainelements);
                }
            },
            dropchangeAfter(v_elementName,v_othervalue,v_childtablename,v_currentdata,v_value,v_dictsource){
                console.log('dropchangeAfter....');
                console.log(v_elementName);
                console.log(v_othervalue);
                console.log(v_childtablename);
                console.log(v_currentdata);
                console.log(v_value);
                console.log(v_dictsource);
                if(this.dropOtherConfig && this.dropOtherConfig[v_elementName] && this.dropOtherConfig[v_elementName]!=''){
                  this.modifyElementsVal(this.dropOtherConfig[v_elementName],v_othervalue);
                }
                if(this.dropOtherConfig && this.dropOtherConfig[v_elementName+'fun']){
                    var allchildelements=this.getAllChildElements();
                    var allmainelements=this.getAllMainElements();
                    this.dropOtherConfig[v_elementName+'fun'](allchildelements,v_value,v_dictsource,allmainelements);
                    this.setAllChildElements(allchildelements);
                }
            },
            childElementdropchangeAfter(v_elementName,v_value,v_childtablename,v_currentdata){
               if(this.dropOtherConfig && this.dropOtherConfig[v_childtablename]){
                    if(this.dropOtherConfig[v_childtablename][v_elementName] && this.dropOtherConfig[v_childtablename][v_elementName]!=''){
                         var setcolname= this.dropOtherConfig[v_childtablename][v_elementName];
                         v_currentdata[setcolname]=v_value;
                    }
               }
            },
            loadData2(graphqlFeild, v_mainModuleObj){
                console.log('loadData2...');
                var graphql = '{' + this.modulename +
                    '(id:"' + this.id +
                    '",workflowid:"' + this.workflowid +
                    '",boxid:"' + this.boxid +
                    '",flowguid:"' + this.flowguid + '"){' + graphqlFeild + '}}';
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var childObjData = [];
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        if(this.handleDataBeforeMethod){
                            dataObj=this.handleDataBeforeMethod(dataObj);
                        }
                        this.originalData = dataObj;
                        console.log('dataObj....');
                        console.log(dataObj);
                        this.removeMainElementFromPageConfig();
                        for (var mainpro in dataObj) {
                            var mainData = dataObj[mainpro];
                            console.log(mainData);
                            for (var pro in mainData) {
                                if (mainData[pro] != null && typeof (mainData[pro]) !=
                                    'undefined') {
                                    var findResult = this.findElementName(pro, v_mainModuleObj);
                                    if (findResult.labelName != '' && !findResult.isChildObj) {
                                        if (this.timeType.includes(findResult.ctrType)) {
                                            if (mainData[pro] == '') {
                                                var mainelement1={
                                                    label: findResult.labelName,
                                                    val: '',
                                                    dictName: '',
                                                    dictCode:'',
                                                    dictLabel: '',
                                                    dictCode2: '',
                                                    dictLabel2: '',
                                                    dictCode3: '',
                                                    dictLabel3: '',
                                                    bindselectedTxt:false,
                                                    name: pro,
                                                    type: findResult.ctrType,
                                                    class:'',
                                                    tip:'',
                                                };
                                                this.addMainElementToPageConfig(mainelement1);
                                            } else {
                                                var mainelement2={
                                                    label: findResult.labelName,
                                                    val: new Date(mainData[pro]),
                                                    dictName: '',
                                                    dictCode:'',
                                                    dictLabel: '',
                                                    dictCode2: '',
                                                    dictLabel2: '',
                                                    dictCode3: '',
                                                    dictLabel3: '',
                                                    bindselectedTxt:false,
                                                    name: pro,
                                                    type: findResult.ctrType,
                                                    class:'',
                                                    tip:mainData[pro],
                                                };
                                                this.addMainElementToPageConfig(mainelement2);
                                            }
                                        } else {
                                            if (!this.flowInfoType.includes(findResult.ctrType) && !
                                                this
                                                .flowViewType.includes(findResult.ctrType) &&
                                                findResult
                                                .ctrType != '[flowhistorygroup]') {
                                                if (findResult.ctrType == 'Dictionary' || findResult.ctrType =='TreeDict' || findResult.ctrType =='MultiSelect' || findResult.ctrType =='InputDict' || findResult.ctrType =='LinkageDict') {
                                                    if(findResult.ctrType =='MultiSelect'){
                                                        var mainelement3={
                                                            label: findResult.labelName,
                                                            val: mainData[pro],
                                                            dictName: findResult.dictName,
                                                            dictCode: findResult.dictCode,
                                                            dictLabel: findResult.dictLabel,
                                                            dictCode2: findResult.dictCode2,
                                                            dictLabel2: findResult.dictLabel2,
                                                            dictCode3: findResult.dictCode3,
                                                            dictLabel3: findResult.dictLabel3,
                                                            bindselectedTxt:findResult.bindselectedTxt,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            class:'',
                                                            tip:'',
                                                        };
                                                       this.addMainElementToPageConfig(mainelement3);
                                                    }else{
                                                        var mainelement4={
                                                            label: findResult.labelName,
                                                            val: mainData[pro].toString(),
                                                            dictName: findResult.dictName,
                                                            dictCode: findResult.dictCode,
                                                            dictLabel: findResult.dictLabel,
                                                            dictCode2: findResult.dictCode2,
                                                            dictLabel2: findResult.dictLabel2,
                                                            dictCode3: findResult.dictCode3,
                                                            dictLabel3: findResult.dictLabel3,
                                                            bindselectedTxt:findResult.bindselectedTxt,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            class:'',
                                                            tip:mainData[pro].toString(),
                                                        };
                                                        this.addMainElementToPageConfig(mainelement4);
                                                    }
                                                    if(findResult.ctrType =='LinkageDict'){
                                                      this.noshowelements[pro] = mainData[pro];
                                                    }
                                                } else {
                                                    if (findResult.ctrType == 'Textarea') {
                                                        var mainelement5={
                                                            label: findResult.labelName,
                                                            val: mainData[pro],
                                                            dictName: '',
                                                            dictCode:'',
                                                            dictLabel: '',
                                                            dictCode2: '',
                                                            dictLabel2: '',
                                                            dictCode3: '',
                                                            dictLabel3: '',
                                                            bindselectedTxt:false,
                                                            name: pro,
                                                            type: findResult.ctrType,
                                                            tip:'',
                                                        };
                                                        this.addMainElementToPageConfig(mainelement5);
                                                    } else {
                                                        if(findResult.ctrType =='UpLoad'){
                                                           var mainelement6={
                                                                label: findResult.labelName,
                                                                val: mainData[pro].toString(),
                                                                fileType: findResult.fileType,
                                                                uptype: findResult.uptype,
                                                                attachttype: findResult.attachttype,
                                                                name: pro,
                                                                type: findResult.ctrType,
                                                                fileName:findResult.fileName,
                                                                class:'',
                                                                tip:'',
                                                           };
                                                           this.addMainElementToPageConfig(mainelement6);
                                                        }
                                                        else{
                                                            var mainelement7={
                                                                label: findResult.labelName,
                                                                val: mainData[pro],
                                                                dictName: '',
                                                                dictCode:'',
                                                                dictLabel: '',
                                                                dictCode2: '',
                                                                dictLabel2: '',
                                                                dictCode3: '',
                                                                dictLabel3: '',
                                                                bindselectedTxt:false,
                                                                name: pro,
                                                                type: findResult.ctrType,
                                                                class:'',
                                                                tip:mainData[pro],
                                                            };
                                                            this.addMainElementToPageConfig(mainelement7);
                                                        }
                                                    }
                                                }
                                            } else {
                                                if (findResult.ctrType == 'flowhistorygroup') {
                                                    this.flowhistory = mainData[pro];
                                                }
                                                if (findResult.ctrType == '[flowhistorygroup]') {
                                                    this.flowhistory = mainData[pro];
                                                }
                                                if (findResult.ctrType == 'approvalresult') {
                                                    this.approvalresult = mainData[pro];
                                                }
                                                if (this.flowViewType.includes(findResult
                                                        .ctrType)) {
                                                    this.viewdata = mainData[pro];
                                                    if (this.viewdata.details) {
                                                        for (var i = 0; i < this.viewdata.details
                                                            .length; i++) {
                                                            this.selectViews.push(this.viewdata
                                                                .details[i]
                                                                .uniqueid);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        if (findResult.isChildObj) {
                                            childObjData.push({
                                                name: pro,
                                                data: mainData[pro],
                                                description: findResult.labelName
                                            });
                                        } else {
                                            this.noshowelements[pro] = mainData[pro];
                                        }
                                    }
                                }
                            }
                        }
                        this.addChildElements(childObjData);
                        if(this.loadDataAfterMethod){
                            var allElememts=this.getAllMainElements();
                            var allchildelements=this.getAllChildElements();
                            this.loadDataAfterMethod(allElememts,allchildelements,this.originalData,this.noshowelements,this.childExpandeConfig);
                            console.log("loadDataAfterMethod....");
                            console.log(allElememts);
                            console.log(allchildelements);
                            this.setAllMainElements(allElememts);
                            this.setAllChildElements(allchildelements);
                        }
                        console.log('pageConfig....');
                        console.log(this.pageConfig);
                        this.showWait=false;

                    } else {
                        MessageTip.warnmsg('数据加载失败');
                    }
                });
            },
            getelementAuthority(){
              return this.elementAuthority;
            },
            loadData(v_graphqlFeild, v_mainModuleObj) {
                console.log('loadData:....');
                console.log(v_graphqlFeild);
                console.log(this.elementVisible);
                console.log(this.elementAuthority);
                var graphqlFeild='';
                for(var gpro in v_graphqlFeild){
                    if(this.elementVisible[gpro]){
                        if(graphqlFeild==''){
                          graphqlFeild=gpro;
                        }else{
                          graphqlFeild=graphqlFeild+' '+gpro;
                        }
                        if(v_graphqlFeild[gpro]['type']=='MultiSelect'){
                           graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if(v_graphqlFeild[gpro]['type']=='Dictionary' || v_graphqlFeild[gpro]['type'] == 'MultiSelect' || v_graphqlFeild[gpro]['type'] == 'LinkageDict'){
                           this.addDictCategories(v_graphqlFeild[gpro]['dictconfiginfo']);
                        }                      
                        if (v_graphqlFeild[gpro]['type'] == 'flowhistorygroup') {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (v_graphqlFeild[gpro]['type'] == '[flowhistorygroup]') {
                              graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (v_graphqlFeild[gpro]['type'] == 'approvalresult') {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if (this.flowViewType.includes(v_graphqlFeild[gpro]['type'])) {
                             graphqlFeild=graphqlFeild+' '+v_graphqlFeild[gpro]['val'];
                        }
                        if(v_graphqlFeild[gpro]['type']=='child'){
                            graphqlFeild=graphqlFeild+'{';
                            for(var n2=0;n2<v_graphqlFeild[gpro]['val'].length;n2++){
                                 var childInfo=v_graphqlFeild[gpro]['val'][n2];
                                 if(this.elementVisible[gpro+'.'+childInfo['name']]){
                                      if (childInfo.type =='childExpandeConfig') {
                                           graphqlFeild=graphqlFeild+' '+childInfo['val'];
                                      }else{
                                        graphqlFeild=graphqlFeild+' '+childInfo['name'];
                                      }
                                      if (childInfo.type =='Dictionary' || childInfo.type =='LinkageDict') {
                                        this.addDictCategories(childInfo['dictconfiginfo']);
                                      }
                                 }
                            }
                            graphqlFeild=graphqlFeild+'  isdel ischeck }';
                        }
                    }
                }
                this.loadDict(graphqlFeild,v_mainModuleObj);
            },
            loadElementAuthority(v_childStructure, v_graphqlFeild, v_mainModuleObj) {
                var idval=this.id;
                if(this.currentRow.isCopy){
                    idval='';
                }
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'schemaname',
                        value: this.modulename,
                        operation: '='
                    }, {
                        name: 'id',
                        value: idval,
                        operation: '='
                    }, {
                        name: 'searchstateid',
                        value: this.stateid,
                        operation: '='
                    }, {
                        name: 'searchflowid',
                        value: this.flowid,
                        operation: '='
                    }]
                };
                var graphql = '{elementauthoritylist(where:' + JSON.stringify(dictlistwhere) +
                    '){modulename feildname readonly visible}}';
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql).then(
                    res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            this.elementVisible['createrid']=true;
                            this.elementVisible['creater']=true;
                            this.elementVisible['createtime']=true;
                            this.elementVisible['id']=true;
                            this.elementVisible['parentid']=true;
                            for (var i = 0; i < dataObj.elementauthoritylist.length; i++) {
                                this.elementAuthority[dataObj.elementauthoritylist[i].feildname] =
                                    dataObj
                                    .elementauthoritylist[i].readonly;
                                this.elementVisible[dataObj.elementauthoritylist[i].feildname] =
                                    dataObj
                                    .elementauthoritylist[i].visible;
                            }
                            if (v_childStructure.length == 0) {
                                console.log('loadData...1');
                                this.loadData(v_graphqlFeild, v_mainModuleObj);
                            }else{
                                for(var k=0;k<v_childStructure.length;k++){
                                    this.loadChildObjAth(v_childStructure[k].name,v_graphqlFeild,v_mainModuleObj,v_childStructure);
                                }
                            }
                        } else {
                            MessageTip.warnmsg('权限数据加载失败');
                        }
                    });
            },
            loadChildObjAth(v_name,v_graphqlFeild, v_mainModuleObj,v_childStructure) {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'schemaname',
                        value: v_name,
                        operation: '='
                    }, {
                        name: 'parentschemaname',
                        value: this.modulename,
                        operation: '='
                    }, {
                        name: 'id',
                        value: '',
                        operation: '='
                    }, {
                        name: 'searchstateid',
                        value: this.stateid,
                        operation: '='
                    }, {
                        name: 'searchflowid',
                        value: this.flowid,
                        operation: '='
                    }]
                };
                var graphql = '{elementauthoritylist(where:' + JSON.stringify(dictlistwhere) +
                    '){modulename feildname readonly visible}}';
                requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"), graphql).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        if (dataObj && dataObj.elementauthoritylist) {
                            for (var i = 0; i < dataObj.elementauthoritylist.length; i++) {
                                this.elementAuthority[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.' + dataObj
                                        .elementauthoritylist[i]
                                        .feildname] = dataObj.elementauthoritylist[i].readonly;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.' + dataObj
                                        .elementauthoritylist[i]
                                        .feildname] = dataObj.elementauthoritylist[i].visible;
                                
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.createrid']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.creater']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.createtime']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.id']=true;
                                this.elementVisible[dataObj
                                        .elementauthoritylist[i]
                                        .modulename + '.parentid']=true;
                            }
                            this.elementauthoritymaps.push(v_name);
                            if(this.elementauthoritymaps.length==v_childStructure.length){
                               this.loadData(v_graphqlFeild, v_mainModuleObj);
                            }
                        } else {
                            MessageTip.warnmsg('子权限数据加载异常');
                        }
                    } else {
                        MessageTip.warnmsg('子对象权限信息加载失败');
                    }
                });
            },
            loadChildExpandObjAth(){
                for(var expro in this.childExpandeConfig){
                    if(this.childExpandeConfig[expro]['expandobjath']){
                        var dictlistwhere = {
                            pageindex: 1,
                            pagesize: 50,
                            conditions: [{
                                name: 'schemaname',
                                value: this.childExpandeConfig[expro]['expandobjath']['schemaname'],
                                operation: '='
                            }, {
                                name: 'parentschemaname',
                                value: this.childExpandeConfig[expro]['expandobjath']['parentschemaname'],
                                operation: '='
                            }, {
                                name: 'id',
                                value: '',
                                operation: '='
                            }, {
                                name: 'searchstateid',
                                value: this.stateid,
                                operation: '='
                            }, {
                                name: 'searchflowid',
                                value: this.flowid,
                                operation: '='
                            }]
                        };
                        var graphql = '{elementauthoritylist(where:' + JSON.stringify(dictlistwhere) +
                            '){modulename feildname readonly visible}}';
                        requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"), graphql).then(res => {
                            if (res.errcode == "0") {
                                var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                                console.log(dataObj);
                                if (dataObj && dataObj.elementauthoritylist) {
                                    for (var i = 0; i < dataObj.elementauthoritylist.length; i++) {
                                        this.elementAuthority[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.' + dataObj
                                                .elementauthoritylist[i]
                                                .feildname] = dataObj.elementauthoritylist[i].readonly;
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.' + dataObj
                                                .elementauthoritylist[i]
                                                .feildname] = dataObj.elementauthoritylist[i].visible;
                                        
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.createrid']=true;
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.creater']=true;
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.createtime']=true;
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.id']=true;
                                        this.elementVisible[dataObj
                                                .elementauthoritylist[i]
                                                .modulename + '.parentid']=true;
                                    }
                                    console.log('loadChildExpandObjAth....');
                                    console.log(this.elementAuthority);
                                    console.log(this.elementVisible);
                                } else {
                                    MessageTip.warnmsg('子权限数据加载异常');
                                }
                            } else {
                                MessageTip.warnmsg('子对象权限信息加载失败');
                            }
                        });
                    }
                }
            },
            loadChildStructure(v_childStructureObj,v_childStructure,v_graphqlFeild,v_mainModuleObj){                           
                    this.loadImportConfig(v_childStructureObj.typename,v_childStructureObj.name);                    
                    requests.graphql_requests.post('/graphql/?appkey=' + sessionStorage.getItem("appkey"),'{__type(name: "' + v_childStructureObj.typename +
                                    '"){name description fields { name dictconfiginfo description type { name description }} } }').then(res => {
                    if (res.errcode == "0") {
                        var childModuleObj = JSON.parse(unescape(Base64.decode(res.data))).data.__type;
                        console.log('childModuleObj-1');
                        console.log(childModuleObj);
                        console.log(v_childStructureObj);
                        console.log(v_childStructure);
                        console.log(v_graphqlFeild);
                        childModuleObj.name=v_childStructureObj.name;
                        this.childModuleStructure.push(childModuleObj);
                        v_graphqlFeild[v_childStructureObj.name]={type:'child',val:[]};
                        var hasAddchildExpande={};
                        for (var i = 0; i < childModuleObj.fields.length; i++) {
                            if (this.baseType.includes(childModuleObj.fields[i].type.name) && !this.excludeElements.includes(childModuleObj.fields[i].name)) {
                                var childInfo1={type:childModuleObj.fields[i].type.name,name:childModuleObj.fields[i].name};
                                if (childModuleObj.fields[i].type.name =='Dictionary' || childModuleObj.fields[i].type.name =='LinkageDict') {
                                    childInfo1['dictconfiginfo']=childModuleObj.fields[i].dictconfiginfo;
                                }
                                v_graphqlFeild[v_childStructureObj.name]['val'].push(childInfo1);
                            }else{
                                if(!hasAddchildExpande[v_childStructureObj.name] && this.childExpandeConfig && this.childExpandeConfig[v_childStructureObj.name]){
                                    for(var i2=0;i2<this.childExpandeConfig[v_childStructureObj.name]['config'].length;i2++){
                                         var childExpandeConfigObj=this.childExpandeConfig[v_childStructureObj.name]['config'][i2];
                                         var childInfo2={type:'childExpandeConfig',val:childExpandeConfigObj['feild'],name:childExpandeConfigObj['data']};
                                         v_graphqlFeild[v_childStructureObj.name]['val'].push(childInfo2);
                                    }
                                    hasAddchildExpande[v_childStructureObj.name]="1";
                                }
                                if(!hasAddchildExpande[v_childStructureObj.name+'sys'] && this.childExpandeConfig && this.childExpandeConfig[v_childStructureObj.name+'sys']){
                                     var childInfo3={type:'childExpandeConfig',val:this.childExpandeConfig[v_childStructureObj.name+'sys']['feild'],name:this.childExpandeConfig[v_childStructureObj.name+'sys']['objname']};
                                     v_graphqlFeild[v_childStructureObj.name]['val'].push(childInfo3);
                                     hasAddchildExpande[v_childStructureObj.name+'sys']="1";
                                }
                            }
                        }
                        this.childMoudleGraphqlmaps.push({name:v_childStructureObj.name});
                        if(this.childMoudleGraphqlmaps.length==v_childStructure.length){
                            this.loadElementAuthority(v_childStructure, v_graphqlFeild,v_mainModuleObj);
                        }
                    } else {
                        MessageTip.warnmsg(v_childStructureObj.typename+'类型加载失败');
                    }
                });
            },
            findElementName(v_name, v_moduleObj) {
                var result = {
                    labelName: '',
                    isChildObj: false,
                    dictName: '',
                    dictCode:'',
                    dictLabel: '',
                    ctrType: '',
                    dictCode2: '',
                    dictLabel2: '',
                    dictCode3: '',
                    dictLabel3: '',
                    bindselectedTxt:false,
                    fileName:'',
                    fileType:'',
                    uptype:'',
                    attachttype:'',
                };
                if (v_name != "id") {
                    //var regx = /^[^A-Za-z0-9]*$/;
                    //var regx=/^[^0-9]*$/;
                    var regx=/\d$/;
                    for (var i = 0; i < v_moduleObj.fields.length; i++) {
                        if (v_moduleObj.fields[i].name == v_name) {
                            if (v_moduleObj.fields[i].type.name != '[flowhistorygroup]') {
                                if (!regx.test(v_moduleObj.fields[i].description) && v_moduleObj.fields[i].description!='GraphQL.Types.FieldType') {
                                    result.labelName = v_moduleObj.fields[i].description;
                                    result.ctrType = v_moduleObj.fields[i].type.name;
                                    var dictArray;
                                    if (v_moduleObj.fields[i].type.name == 'Dictionary' || v_moduleObj.fields[i].type.name == 'MultiSelect' || v_moduleObj.fields[i].type.name == 'InputDict' || v_moduleObj.fields[i].type.name == 'TreeDict') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result.dictName = dictArray[0];
                                        result.dictLabel = dictArray[1];
                                    }
                                    if (v_moduleObj.fields[i].type.name == 'UpLoad') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result['fileName'] = dictArray[0];
                                        result['fileType'] = dictArray[1];
                                        result['uptype'] = dictArray[2];
                                        result['attachttype'] = dictArray[3];
                                    }
                                    if (v_moduleObj.fields[i].type.name == 'LinkageDict') {
                                        dictArray = v_moduleObj.fields[i].dictconfiginfo.split('|');
                                        result.dictName = dictArray[0];
                                        result.dictCode = dictArray[1];
                                        result.dictLabel = dictArray[2];
                                        if(dictArray.length>3){
                                         result.dictCode2 = dictArray[3];
                                        }
                                        if(dictArray.length>4)
                                         result.dictLabel2 = dictArray[4];
                                        if(dictArray.length>5)
                                         result.dictCode3 = dictArray[5];
                                        if(dictArray.length>6)
                                         result.dictLabel3 = dictArray[6];
                                    }
                                    if (v_moduleObj.fields[i].type.name.substr(0, 1) == '[') {
                                        result.isChildObj = true;
                                    }
                                    this.elementTypeData[v_name] = {
                                        ctrType: result.ctrType,
                                        isChildObj: result.isChildObj
                                    };
                                }
                            } else {
                                result.ctrType = v_moduleObj.fields[i].type.name;
                                result.labelName = v_moduleObj.fields[i].description;
                                this.elementTypeData[v_name] = {
                                    ctrType: result.ctrType,
                                    isChildObj: result.isChildObj
                                };
                            }
                            break;
                        }
                    }
                }
                if(result.dictLabel!='' || result.dictLabel2!='' || result.dictLabel3!=''){
                    result.bindselectedTxt=true;
                }
                return result;
            },
            addChildElements(v_childObjData) {
                console.log('addChildElements:');
                console.log(v_childObjData);
                console.log(this.elementVisible);
                this.removeChildElementFromPageConfig(v_childObjData);
                for (var i = 0; i < v_childObjData.length; i++) {
                    var childObj = {
                        name: v_childObjData[i].name,
                        header: v_childObjData[i].description,
                        columns: [],
                        rows: [],
                        templaterows: [],
                        delrows: []
                    };
                    var childStructure = null;
                    for (var n = 0; n < this.childModuleStructure.length; n++) {
                        if (this.childModuleStructure[n].name == v_childObjData[i].name) {
                            childStructure = this.childModuleStructure[n];
                            break;
                        }
                    }
                    var timeCol = [];
                    var templateRow={};
                    for(var pro2=0; pro2 < childStructure.fields.length; pro2++){
                        var findResult = this.findElementName(childStructure.fields[pro2].name , childStructure);
                        if (findResult.labelName != '') {
                            var isfrozen = false;
                            if (childObj.columns.length == 0) {
                                isfrozen = true;
                            }
                            if (this.timeType.includes(findResult.ctrType)) {
                                timeCol.push(childStructure.fields[pro2].name);
                            }
                            if(this.elementVisible[childObj.name+'.'+childStructure.fields[pro2].name]){
                                var childcolumnelement={
                                    field: childStructure.fields[pro2].name,
                                    header: findResult.labelName,
                                    frozen: isfrozen,
                                    type: findResult.ctrType,
                                    dictName: findResult.dictName,
                                    dictCode: findResult.dictCode,
                                    dictLabel: findResult.dictLabel,
                                    dictCode2: findResult.dictCode2,
                                    dictLabel2: findResult.dictLabel2,
                                    dictCode3: findResult.dictCode3,
                                    dictLabel3: findResult.dictLabel3,
                                    bindselectedTxt:findResult.bindselectedTxt,
                                    fileName:findResult.fileName,
                                    fileType:findResult.fileType,
                                    uptype:findResult.uptype,
                                    attachttype:findResult.attachttype,
                                };
                                childObj.columns.push(childcolumnelement);
                            }
                        }
                        templateRow[childStructure.fields[pro2].name]='';
                    }
                    if (childObj.templaterows.length == 0) {
                        childObj.templaterows.push(templateRow);
                    }
                    for (var j = 0; j < v_childObjData[i].data.length; j++) {
                        var row = v_childObjData[i].data[j];
                        for (var k = 0; k < timeCol.length; k++) {
                            if (row[timeCol[k]] != '') {
                                row[timeCol[k]] = new Date(row[timeCol[k]]);
                            }
                        }
                        childObj.rows.push(row);
                    } 
                    if(this.noLoadChild && this.noLoadChild.length>0){
                       var noadd=false;
                       for(var k2=0;k2<this.noLoadChild.length;k2++){
                          if(childObj.name==this.noLoadChild[k2]){
                             noadd=true;
                             break;
                          }
                       }
                       if(!noadd){
                        this.addChildElementToPageConfig(childObj);
                        console.log('addChildElements:---2');
                        console.log(childObj);
                       }
                    }else{
                       this.addChildElementToPageConfig(childObj);
                       console.log('addChildElements:---3');
                       console.log(childObj);
                    }
                }
                this.removeChildElementColFromPageConfig();
                var region_2_right_element= this.pageConfig['region_2']['right']['element'];
                var region_3_left_element= this.pageConfig['region_3']['left']['element'];
                var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                this.initSumInfo(region_2_right_element);
                this.initSumInfo(region_3_left_element);
                this.initSumInfo(region_3_right_element);
            },
            addDictCategories(v_dictName) {
                if (v_dictName != '') {
                    console.log('addDictCategories:'+v_dictName);
                    var array=v_dictName.split('|');
                    var dictObjarray = array[0].split('^');
                    if(dictObjarray.length>1){
                       this.dicts[dictObjarray[1]] = [];
                    }else{
                       this.dicts[dictObjarray[0]] = [];
                    }                  
                }
            },
            loadDict(graphqlFeild,v_mainModuleObj) {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                for (var proname in this.dicts) {
                    if (proname != '') {
                        var dictObjarray = proname.split('^');
                         if (dictObjarray.length > 1) {
                              dictlistwhere.conditions.push({
                               name: 'Categories',
                               value: dictObjarray[1],
                               operation: ''
                             });
                         }else{
                             dictlistwhere.conditions.push({
                               name: 'Categories',
                               value: dictObjarray[0],
                               operation: ''
                             });
                         } 
                    }
                }
                if (dictlistwhere.conditions.length > 0) {
                    var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                        '){name details {name code ename other child {name code child {name code ename other child} } }}}';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            for (var k = 0; k < dataObj.dictionarylist.length; k++) {
                                this.dicts[dataObj.dictionarylist[k].name] = dataObj.dictionarylist[
                                    k].details;
                            }
                            this.loadData2(graphqlFeild,v_mainModuleObj);
                        } else {
                            MessageTip.warnmsg('字典数据加载失败');
                        }
                    });
                }else{
                    this.loadData2(graphqlFeild,v_mainModuleObj);
                }
            },
            getDictData(v_dictName) {
                console.log('getDictData_1:'+v_dictName);
                console.log(this.dicts);
                var dictName='';
                var array=v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if(dictObjarray.length>1){
                    dictName=dictObjarray[1];
                }else{
                    dictName=dictObjarray[0];
                }
                 console.log('getDictData_2:'+dictName);
                var dictData = [];
                if (this.dicts[dictName]) {
                    dictData = this.dicts[dictName];
                }
                console.log('getDictData_3:'+v_dictName);
                console.log(dictData);
                return dictData;
            },
            findLinkageDictValue(v_fieldname){
              return this.findElementsVal(v_fieldname);
            },
            getAllChildElements(){
               var allchildelements=[];
               var region_2_right_element= this.pageConfig['region_2']['right']['element'];
               for(var i=0;i<region_2_right_element.length;i++){
                   allchildelements.push(region_2_right_element[i]);
               }
               var region_3_element= this.pageConfig['region_3']['left']['element'];
               for(var i2=0;i2<region_3_element.length;i2++){
                   if(!region_3_element[i2]['type'] || region_3_element[i2]['type']=='grid'){
                      allchildelements.push(region_3_element[i2]);
                   }
               }
               region_3_element= this.pageConfig['region_3']['right']['element'];
               for(var i3=0;i3<region_3_element.length;i3++){
                   if(!region_3_element[i3]['type'] || region_3_element[i3]['type']=='grid'){
                     allchildelements.push(region_3_element[i3]);
                   }
               }
               return allchildelements;
            },
            setAllChildElements(v_allchildelements){
                for(var k=0;k<v_allchildelements.length;k++){
                    var hashFind=false;
                    var region_2_right_element= this.pageConfig['region_2']['right']['element'];
                    for(var i=0;i<region_2_right_element.length;i++){
                        if(v_allchildelements[k]['name']==region_2_right_element[i]['name']){
                            region_2_right_element[i]=v_allchildelements[k];
                            hashFind=true;
                            break;
                        }
                    }
                    if(!hashFind){
                       var region_3_element= this.pageConfig['region_3']['left']['element'];
                       for(var i2=0;i2<region_3_element.length;i2++){
                          if(v_allchildelements[k]['name']==region_3_element[i2]['name']){
                            region_3_element[i2]=v_allchildelements[k];
                            hashFind=true;
                            break;
                          }
                       }
                    }
                    if(!hashFind){
                       var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                       for(var i3=0;i3<region_3_right_element.length;i3++){
                          if(v_allchildelements[k]['name']==region_3_right_element[i3]['name']){
                            region_3_right_element[i3]=v_allchildelements[k];
                            hashFind=true;
                            break;
                          }
                       }
                    }
                }
            },
            getAllMainElements(){
               var elements=[];
               var region_1_left_element= this.pageConfig['region_1']['left']['element'];
               for(var i=0;i<region_1_left_element.length;i++){
                   elements.push(region_1_left_element[i]);
               }
               var region_1_right_element= this.pageConfig['region_1']['right']['element'];
               for(var i2=0;i2<region_1_right_element.length;i2++){
                   elements.push(region_1_right_element[i2]);
               }
               var region_2_left_element= this.pageConfig['region_2']['left']['element'];
               for(var i3=0;i3<region_2_left_element.length;i3++){
                   elements.push(region_2_left_element[i3]);
               }
               var region_3_left_element= this.pageConfig['region_3']['left']['element'];
               for(var i4=0;i4<region_3_left_element.length;i4++){
                   if(region_3_left_element[i4]['type'] && region_3_left_element[i4]['type']!='grid'){
                      elements.push(region_3_left_element[i4]);
                   }
               }
               var region_3_right_element= this.pageConfig['region_3']['right']['element'];
               for(var i5=0;i5<region_3_right_element.length;i5++){
                   if(region_3_right_element[i5]['type'] && region_3_right_element[i5]['type']!='grid'){
                      elements.push(region_3_right_element[i5]);
                   }
               }
               return elements;
            },
            setAllMainElements(v_elements){
               for(var k=0;k<v_elements.length;k++){
                  var hashFind=false;
                  var region_1_left_element= this.pageConfig['region_1']['left']['element'];
                  for(var i=0;i<region_1_left_element.length;i++){
                     if(v_elements[k]['name']==region_1_left_element[i]['name']){
                        region_1_left_element[i]=v_elements[k];
                        hashFind=true;
                        break;
                     }
                  }
                  if(!hashFind){
                      var region_1_right_element= this.pageConfig['region_1']['right']['element'];
                      for(var i2=0;i2<region_1_right_element.length;i2++){
                          if(v_elements[k]['name']==region_1_right_element[i2]['name']){
                                region_1_right_element[i2]=v_elements[k];
                                hashFind=true;
                                break;
                          }
                      }
                  }
                  if(!hashFind){
                     var region_2_left_element= this.pageConfig['region_2']['left']['element'];
                     for(var i3=0;i3<region_2_left_element.length;i3++){
                        if(v_elements[k]['name']==region_2_left_element[i3]['name']){
                                region_2_left_element[i3]=v_elements[k];
                                hashFind=true;
                                break;
                        }
                     }
                  }
                  if(!hashFind){
                     var region_3_left_element= this.pageConfig['region_3']['left']['element'];
                     for(var i4=0;i4<region_3_left_element.length;i4++){
                        if(region_3_left_element[i4]['type'] && region_3_left_element[i4]['type']!='grid' && v_elements[k]['name']==region_3_left_element[i4]['name']){
                                region_3_left_element[i4]=v_elements[k];
                                hashFind=true;
                                break;
                        }
                     }
                  }
                  if(!hashFind){
                     var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                     for(var i5=0;i5<region_3_right_element.length;i5++){
                        if(region_3_right_element[i5]['type'] && region_3_right_element[i5]['type']!='grid' && v_elements[k]['name']==region_3_right_element[i5]['name']){
                                region_3_right_element[i5]=v_elements[k];
                                hashFind=true;
                                break;
                        }
                     }
                  }
               }
            },
            findElementsVal(v_fieldname,v_exclusionType){
                console.log("findElementsVal....");
                console.log(v_fieldname);
                var region_1_left_element= this.pageConfig['region_1']['left']['element'];
                for(var i=0;i<region_1_left_element.length;i++){
                    if(region_1_left_element[i].name==v_fieldname && (!v_exclusionType || v_exclusionType!=region_1_left_element[i].type)){
                        return region_1_left_element[i].val;
                    }
                }
                var region_1_right_element= this.pageConfig['region_1']['right']['element'];
                for(var i2=0;i2<region_1_right_element.length;i2++){
                    if(region_1_right_element[i2].name==v_fieldname && (!v_exclusionType || v_exclusionType!=region_1_right_element[i2].type)){
                        return region_1_right_element[i2].val;
                    }
                }
                var region_2_left_element= this.pageConfig['region_2']['left']['element'];
                for(var i3=0;i3<region_2_left_element.length;i3++){
                    if(region_2_left_element[i3].name==v_fieldname && (!v_exclusionType || v_exclusionType!=region_2_left_element[i3].type)){
                        return region_2_left_element[i3].val;
                    }
                }
                var region_3_left_element= this.pageConfig['region_3']['left']['element'];
                for(var i4=0;i4<region_3_left_element.length;i4++){
                    if(region_3_left_element[i4]['type'] && region_3_left_element[i4]['type']!='grid' && region_3_left_element[i4].name==v_fieldname && (!v_exclusionType || v_exclusionType!=region_3_left_element[i4]['type'])){
                        return region_3_left_element[i4].val;
                    }
                }
                var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                for(var i5=0;i5<region_3_right_element.length;i5++){
                    if(region_3_right_element[i5]['type'] && region_3_right_element[i5]['type']!='grid' && region_3_right_element[i5].name==v_fieldname && (!v_exclusionType || v_exclusionType!=region_3_right_element[i5]['type'])){
                        return region_3_right_element[i5].val;
                    }
                }
                return '';
            },
            findTextAreVal(v_fieldname){
               var region_2_left_element= this.pageConfig['region_2']['left']['element'];
               for(var i=0;i<region_2_left_element.length;i++){
                    if(region_2_left_element[i].name==v_fieldname){
                        return region_2_left_element[i].val;
                    }
                }
                var region_3_left_element= this.pageConfig['region_3']['left']['element'];
                for(var i4=0;i4<region_3_left_element.length;i4++){
                    if(region_3_left_element[i4]['type'] && (region_3_left_element[i4]['type']=='Editor' || region_3_left_element[i4]['type']=='Textarea') && region_3_left_element[i4].name==v_fieldname){
                        return region_3_left_element[i4].val;
                    }
                }
                var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                for(var i5=0;i5<region_3_right_element.length;i5++){
                    if(region_3_right_element[i5]['type'] && (region_3_right_element[i5]['type']=='Editor' || region_3_right_element[i5]['type']=='Textarea') && region_3_right_element[i5].name==v_fieldname){
                        return region_3_right_element[i5].val;
                    }
                }
                return '';
            },
            modifyElementsVal(v_fieldname,v_val){
                  var hashFind=false;
                  var region_1_left_element= this.pageConfig['region_1']['left']['element'];
                  for(var i=0;i<region_1_left_element.length;i++){
                     if(region_1_left_element[i]['name']==v_fieldname){
                        region_1_left_element[i]['val']=v_val;
                        hashFind=true;
                        break;
                     }
                  }
                  if(!hashFind){
                      var region_1_right_element= this.pageConfig['region_1']['right']['element'];
                      for(var i2=0;i2<region_1_right_element.length;i2++){
                          if(region_1_right_element[i2]['name']==v_fieldname){
                                region_1_right_element[i2]['val']=v_val;
                                hashFind=true;
                                break;
                          }
                      }
                  }
                  if(!hashFind){
                     this.modifyTextareaVal(v_fieldname,v_val);
                  }
            },
            modifyTextareaVal(v_fieldname,v_val){
                var region_2_left_element= this.pageConfig['region_2']['left']['element'];
                for(var i3=0;i3<region_2_left_element.length;i3++){
                    if(region_2_left_element[i3]['name']==v_fieldname){
                        region_2_left_element[i3]['val']=v_val;
                        break;
                    }
                }
                var region_3_left_element= this.pageConfig['region_3']['left']['element'];
                for(var i4=0;i4<region_3_left_element.length;i4++){
                    if(region_3_left_element[i4]['type'] && (region_3_left_element[i4]['type']=='Editor' || region_3_left_element[i4]['type']=='Textarea') && region_3_left_element[i4]['name']==v_fieldname){
                        region_3_left_element[i4]['val']=v_val;
                        break;
                    }
                }
                var region_3_right_element= this.pageConfig['region_3']['right']['element'];
                for(var i5=0;i5<region_3_right_element.length;i5++){
                    if(region_3_right_element[i5]['type'] && (region_3_right_element[i5]['type']=='Editor' || region_3_right_element[i5]['type']=='Textarea') && region_3_right_element[i5]['name']==v_fieldname){
                        region_3_right_element[i5]['val']=v_val;
                        break;
                    }
                }
            },
            formatData(v_colobj, value, v_data,v_currentfieldname) {
                if(!v_colobj || !v_colobj['type']) return value;
                console.log('formatData:' + v_colobj['type']);
                var retval=value;
                if (v_colobj.type == 'Dictionary') {
                    retval= this.formatDict(v_colobj.dictName, value);
                }
                if (v_colobj.type == 'MultiSelect') {
                    retval= this.formatMultiSelect(value);
                }
                if (v_colobj.type == 'InputDict' || v_colobj.type == 'TreeDict') {
                    retval= this.formatInputDict(v_colobj.dictLabel, v_data);
                }
                if (v_colobj.type == 'LinkageDict') {
                    retval= this.formatLinkageDictionary(v_colobj, v_data,v_currentfieldname);
                }
                if (v_colobj.type == 'DateTimeString' || v_colobj.type == 'DateString') {
                    retval= commonJs.formatDate(v_colobj.type, value);
                }
                if(v_colobj['tip'] && !v_colobj['differencetip']){
                    v_colobj['tip']=retval;
                }
                return retval;
            },
            formatInputDict(v_dictLabel, v_data) {
                console.log('formatInputDict:' + v_dictLabel);
                console.log(v_data);
                if (v_data == null) {
                    var txt = this.findElementsVal(v_dictLabel);
                    return txt;
                } else {
                    if (v_data[v_dictLabel]) {
                        return v_data[v_dictLabel];
                    } else {
                        return '';
                    }
                }
            },
            formatMultiSelect(value) {
                var name='';
                if(value && value.length>0){
                    for(var i=0;i<value.length;i++){
                        if(name==''){
                          name=value[i]['name'];
                        }else{
                          name=name+","+ value[i]['name'];
                        }
                    }
                }
                return name;
            },
            formatDict(v_dictName, value) {
                console.log('formatDict....');
                console.log(v_dictName);
                console.log(value);
                var name = value;
                var isFind = false;
                var dictName = '';
                var array = v_dictName.split('|');
                var dictObjarray = array[0].split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                console.log(dictName);
                console.log(this.dicts);
                console.log(this.dicts[dictName]);
                if (this.dicts[dictName]) {
                    for (var i = 0; i < this.dicts[dictName].length; i++) {
                        if (this.dicts[dictName][i].code == value) {
                            name = this.dicts[dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            formatLinkageDictionary(v_colobj,v_data,v_currentfieldname) {
                var firstname='';
                var secondname='';
                var thirdname='';
                var firstvalue='';
                if(v_colobj.dictCode && v_colobj.dictCode!=''){
                    firstvalue=v_data[v_colobj.dictCode];
                }
                var secondvalue='';
                if(v_colobj.dictCode2 && v_colobj.dictCode2!=''){
                    secondvalue=v_data[v_colobj.dictCode2];
                }
                var thirdvalue='';
                if(v_colobj.dictCode3 && v_colobj.dictCode3!=''){
                    thirdvalue=v_data[v_colobj.dictCode3];
                }
                var isFind = false;
                var dictName = '';
                var dictObjarray = v_colobj.dictName.split('^');
                if (dictObjarray.length > 1) {
                    dictName = dictObjarray[1];
                } else {
                    dictName = dictObjarray[0];
                }
                if (this.dicts[dictName]) {
                    for (var k = 0; k < this.dicts[dictName].length; k++) {
                        if (this.dicts[dictName][k].code == firstvalue) {
                            firstname = this.dicts[dictName][k].name;
                            if(secondvalue!=''){
                                for(var k2=0;k2<this.dicts[dictName][k].child.length;k2++){
                                    if(this.dicts[dictName][k].child[k2].code == secondvalue){
                                        secondname=this.dicts[dictName][k].child[k2].name;
                                        if(thirdvalue!=''){
                                            for(var k3=0;k3<this.dicts[dictName][k].child[k2].child.length;k3++){
                                                if(this.dicts[dictName][k].child[k2].child[k3].code == thirdvalue){
                                                    thirdname=this.dicts[dictName][k].child[k2].child[k3].name;
                                                    break;
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    if(v_colobj.dictCode==v_currentfieldname){
                        return firstname;
                    }
                    if(v_colobj.dictCode2==v_currentfieldname){
                        return secondname;
                    }
                     if(v_colobj.dictCode3==v_currentfieldname){
                        return thirdname;
                    }
                } else {
                    return '';
                }
            },
            getFilePatch(v_patch){
                 if (v_patch == '' || !v_patch) {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/'+v_patch+'?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            viewTabClick(event) {
                console.log(event);
                if (this.preViewTabIndex == event.index) {
                    if (this.selectViews.includes(this.viewdata.details[event.index].uniqueid)) {
                        for (var i = 0; i < this.selectViews.length; i++) {
                            if (this.selectViews[i] == this.viewdata.details[event.index].uniqueid) {
                                this.selectViews.splice(i, 1);
                                break;
                            }
                        }
                    } else {
                        this.selectViews.push(this.viewdata.details[event.index].uniqueid);
                    }
                }
                this.preViewTabIndex = event.index;
            },
            saveInfoSyn(v_nocallflow){
                this.clearRequired();
                this.clearEditHistory();
                if(!this.checkRequired()){
                    MessageTip.warnmsg('请检查必填项!');
                    this.closeButLoadingMethod();
                    return;
                }
                if (this.viewdata.details && this.viewdata.details.length > 0) {
                    if (this.viewdata.details.length > 1) {
                        for (var j1 = 0; j1 < this.viewdata.details.length; j1++) {
                            this.viewdata.details[j1].ischeck = '0';
                            this.approvalresult.details[j1].ischeck = '0';
                        }
                        for (var i = 0; i < this.selectViews.length; i++) {
                            for (var j = 0; j < this.viewdata.details.length; j++) {
                                if (this.selectViews[i] == this.viewdata.details[j].uniqueid) {
                                    this.viewdata.details[j].ischeck = '1';
                                }
                                if (this.selectViews[i] == this.approvalresult.details[j].uniqueid) {
                                    this.approvalresult.details[j].ischeck = '1';
                                    this.approvalresult.details[j].resultvalue = this.approvalresultValue;
                                }
                            }
                        }
                    } else {
                        if(this.viewdata && this.viewdata.details && this.viewdata.details.length>0){
                           this.viewdata.details[0].ischeck = '1';
                        }
                        if(this.approvalresult && this.approvalresult.details && this.approvalresult.details.length>0){
                            this.approvalresult.details[0].ischeck = '1';
                            this.approvalresult.details[0].resultvalue = this.approvalresultValue;
                        }
                    }
                }
                for (var mainpro in this.originalData) {
                    var mainData = this.originalData[mainpro];
                    console.log(mainpro);
                    console.log(mainData);
                    for (var pro in mainData) {
                        if (mainData[pro] != null && typeof (mainData[pro]) != 'undefined') {
                            if (this.elementTypeData[pro] && (this.elementTypeData[pro].ctrType ==
                                    'approvalresult' ||
                                    this.flowViewType.includes(this.elementTypeData[pro].ctrType))) {
                                if (this.elementTypeData[pro].ctrType == 'approvalresult') {
                                    mainData[pro] = this.approvalresult;
                                }
                                if (this.flowViewType.includes(this.elementTypeData[pro].ctrType)) {
                                    mainData[pro] = this.viewdata;
                                }
                            } else {
                                if (this.elementTypeData[pro] && this.elementTypeData[pro].isChildObj) {
                                    var allchildelements=this.getAllChildElements();
                                    for (var n = 0; n <allchildelements.length; n++) {
                                        if (allchildelements[n].name == pro) {
                                             if(this.currentRow.isCopy){
                                               for(var k5=0;k5<allchildelements[n].rows.length;k5++){
                                                   allchildelements[n].rows[k5]['id']='-1';
                                                   allchildelements[n].rows[k5]['parentid']='-1';
                                                   for(var childpro in allchildelements[n].rows[k5]){
                                                       if(Array.isArray(allchildelements[n].rows[k5][childpro])){
                                                           for(var p1=0;p1<allchildelements[n].rows[k5][childpro].length;p1++){
                                                               allchildelements[n].rows[k5][childpro][p1]['id']='-1';
                                                               allchildelements[n].rows[k5][childpro][p1]['parentid']='-1';
                                                           }
                                                       }
                                                   }
                                               }
                                            }
                                            mainData[pro] =JSON.parse(JSON.stringify(allchildelements[n].rows));
                                            for (var m = 0; m < allchildelements[n].delrows.length; m++) {
                                                allchildelements[n].delrows[m]["isdel"] = "1";
                                                mainData[pro].push(allchildelements[n].delrows[m]);
                                            }
                                            break;
                                        }
                                    }
                                } else {
                                    if (this.noshowelements[pro]) {
                                        mainData[pro] = this.noshowelements[pro];
                                    }else{
                                       mainData[pro] =this.findElementsVal(pro);
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.originalData[this.modulename].flowhistory) {
                    delete this.originalData[this.modulename].flowhistory;
                }
                if(this.currentRow.isCopy){
                    this.originalData[this.modulename]['id']='-1';
                }else{
                     this.originalData[this.modulename]['id']=this.id;
                }
                console.log(this.originalData[this.modulename]);
                if(this.saveBeforeMethod){
                   this.originalData[this.modulename] =this.saveBeforeMethod(this.originalData[this.modulename]);
                }
                var graphql = `mutation{
        ` + this.modulename + `(flowguid:"` + this.flowguid + `",workflowid:"` + this.workflowid +
                    `",boxid:"` +
                    this.boxid + `",o:` +
                    JSON.stringify(this.originalData[this.modulename]) + `){id workflowid boxid errmsg }}`;
                console.log('graphql:' + graphql);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        this.freshListMethod();
                        this.closeButLoadingMethod(true);
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        if (!v_nocallflow && dataObj[this.modulename].workflowid && parseInt(dataObj[this.modulename]
                                .workflowid) > 0) {
                            this.submitFlow(dataObj[this.modulename].id, dataObj[this
                                .modulename].workflowid, dataObj[this.modulename].boxid);
                        } else {
                            this.id=dataObj[this.modulename].id;
                            MessageTip.successmsg('保存成功');
                            if(this.closeParentMehod){
                                this.closeButLoadingMethod();
                                this.closeParentMehod();
                            }
                        }
                    } else {
                        this.closeButLoadingMethod();
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            saveInfo(v_nocallflow) {
               setTimeout(() => {
                   this.saveInfoSyn(v_nocallflow);
               }, 50);
            },
            submitFlow(v_id, v_workflowid, v_boxid) {
                this.id=v_id;
                this.workflowid=v_workflowid;
                this.boxid=v_boxid;
                this.showFlowDialog=true;
            },
            modifyElements(v_elements){
              console.log('modifyElements...');
              console.log(v_elements);
              for(var i=0;i<v_elements.length;i++){
                var hasFind=false;
                var region_1_left_element=this.pageConfig['region_1']['left']['element'];
                for(var j=0;j<region_1_left_element.length;j++){
                    if(v_elements[i]['name']==region_1_left_element[j]['name']){
                        region_1_left_element[j]['class']=v_elements[i]['class'];
                        region_1_left_element[j]['tip']=v_elements[i]['tip'];
                        if(v_elements[i]['differencetip']){
                          region_1_left_element[j]['differencetip']=v_elements[i]['differencetip'];
                        }
                        hasFind=true;
                        break;
                    }
                }
                var region_1_right_element=this.pageConfig['region_1']['right']['element'];
                if(!hasFind)
                {
                    for(var j2=0;j2<region_1_right_element.length;j2++){
                        if(v_elements[i]['name']==region_1_right_element[j2]['name']){
                            region_1_right_element[j2]['class']=v_elements[i]['class'];
                            region_1_right_element[j2]['tip']=v_elements[i]['tip'];
                            if(v_elements[i]['differencetip']){
                              region_1_right_element[j2]['differencetip']=v_elements[i]['differencetip'];
                            }
                            hasFind=true;
                            break;
                        }
                    }
                }
              }
            },
            getNoShowElements(){
               return this.noshowelements;
            },
            getoriginalData(){
               return this.originalData;
            },
            createInfo(v_childname) {
                console.log("createInfo:" + v_childname);
                console.log(this.childNewDataConfig);
                if(this.childNewDataConfig && this.childNewDataConfig[v_childname+'fun']){
                   this.childNewDataConfig[v_childname+'fun'](v_childname);
                }else{
                    var allchildelements=this.getAllChildElements();
                    for(var i=0;i<allchildelements.length;i++) {
                        if(allchildelements[i]['name']==v_childname){
                            var tableObj = allchildelements[i];
                            if (tableObj.templaterows.length > 0) {
                                var newRow = {};
                                for (var pro in tableObj.templaterows[0]) {
                                    if (pro == 'id') {
                                        newRow[pro] = this.getNewId();
                                    } else {
                                        newRow[pro] = null;
                                    }
                                }
                                if(this.childCreateBeforeMethod){
                                    newRow=this.childCreateBeforeMethod(v_childname,newRow);
                                }
                                tableObj.rows.push(newRow);
                            }
                        }
                    }
                    this.setAllChildElements(allchildelements);
                }
            },
            delInfo(v_childname) {
                console.log(this.selectedRowData);
                if(!this.selectedRowData || this.selectedRowData.length==0){
                   MessageTip.warnmsg('请选择移除的数据');
                }
                var allchildelements=this.getAllChildElements();
                for(var i2=0;i2<allchildelements.length;i2++) {
                    if(allchildelements[i2]['name']==v_childname){
                        var tableObj = allchildelements[i2];
                        for(var k2=0;k2<this.selectedRowData.length;k2++){
                          for (var i = 0; i < tableObj.rows.length; i++) {
                            if (parseInt(tableObj.rows[i]["id"]) == parseInt(this.selectedRowData[k2]["id"])) {
                                if (parseInt(this.selectedRowData[k2]["id"]) < 0) {
                                    tableObj.rows.splice(i, 1);
                                    console.log('del--' + i);
                                } else {
                                    tableObj.rows[i]["isdel"] = '1';
                                    tableObj.delrows.push(tableObj.rows[i]);
                                    tableObj.rows.splice(i, 1);
                                    console.log('setdel--' + i);
                                }
                                break;
                            }
                          }
                        }
                        break;
                    }
                }
                this.setAllChildElements(allchildelements);
                this.selectedRowData=null;
                document.body.click();
                console.log('delInfo---finish');
                console.log(allchildelements);
            },
            getNewId(){
              //var rid= Date.now() + Math.floor(Math.random()*10+1);
              var rid= Date.now() + this.newid;
              var id= -1 * rid;
              this.newid=this.newid+1;
              return id;
            },
            importInfo(v_childDataObjName,v_targetid){
                console.log(v_childDataObjName);
                console.log(v_targetid);
                this.currenttargetid=v_targetid;
                this.currenttargetname=v_childDataObjName;
                this.showImportDataDialog=true;
            },
            loadImportConfig(v_targetobjname,v_childname) {
                var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'targetschemaeq',
                            value: v_targetobjname,
                            operation: '=',
                        }],
                        child: []
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{dataimportconfigsetlist(where: ' + JSON.stringify(listwhere) +
                        '){id targetschema targetschemacname describe source { sourceschema condition {sourcename field} relation { sourcename sourcefield targetname targetfield }} } }'
                    ).then(res => {
                        if (res.errcode == "0") {
                            var configObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                .dataimportconfigsetlist;
                            this.childImportDataConfig[v_childname]=v_targetobjname;
                            if(configObj.length>0){
                                this.importItems[v_childname]=[];
                            }
                            for(var i=0;i<configObj.length;i++){
                                if(configObj[i].source.length > 0){
                                    this.importItems[v_childname].push({  
                                        label: configObj[i]['describe'],
                                        icon: 'pi pi-upload',
                                        targetobjname: v_childname,
                                        importsetid:configObj[i]['id'],
                                        command: (v_obj) => {
                                            console.log(v_obj.item['targetobjname']);
                                            console.log(v_obj.item['importsetid']);
                                            this.importInfo(v_obj.item['targetobjname'],v_obj.item['importsetid']);
                                        }
                                    });
                                }
                            }
                            console.log('loadImportConfig');
                            console.log(this.childImportDataConfig);
                        } else {
                            MessageTip.warnmsg('导入配置加载失败');
                        }
                    });
            },
            importfreshParentData(v_data){
               console.log('importfreshParentData');
               console.log(v_data);
               this.selectedRowData=null;
               var allchildelements=this.getAllChildElements();
               this.importfreshParentDataSet(v_data,allchildelements);
               this.setAllChildElements(allchildelements);
            },
            importfreshParentDataSet(v_data,v_allchildelements){
                console.log("currenttargetname:"+this.currenttargetname);
                for(var i=0;i<v_allchildelements.length;i++){
                   if(v_allchildelements[i].name==this.currenttargetname){
                       for(var k=0;k<v_data.length;k++){
                          var isadd=true;
                          for(var k1=0;k1<v_allchildelements[i].rows.length;k1++){
                             if(v_allchildelements[i].rows[k1]['id']==v_data[k]['id']){
                                 isadd=false;
                                 break;
                             }
                          }
                          if(isadd){
                              console.log("importfreshParentDataSet:add:"+this.currenttargetname);
                              if(this.CellEditCompleteMethod){
                                  var allelements=this.getAllMainElements();
                                  this.CellEditCompleteMethod(this.currenttargetname,'',v_allchildelements[i].rows,v_data[k],this.childTableFooterObj,v_allchildelements,[],allelements,this.noshowelements);
                                  this.setAllMainElements(allelements);
                              }
                              v_allchildelements[i].rows.push(v_data[k]);
                              console.log(v_allchildelements[i]);
                          }
                       }
                       break;
                   }
                }
            },
            cellEdit(event,tablename,colconfigs) {
                console.log('cellEdit...');
                console.log(event);
                console.log(tablename);
                console.log(colconfigs);
                console.log(this.startEdit);
                if(this.CellEditInit){
                    console.log('CellEditInit...');
                    this.CellEditInit(event,tablename,colconfigs);
                    console.log(colconfigs);
                }
                setTimeout(() => {
                    var input=document.getElementById("input2");
                    if (input && input!=document.activeElement){
                        console.log('cellEdit-2');
                        input.focus();
                    }
                    var input2=document.getElementsByName("otheredit2");
                    if (input2){
                        console.log('cellEdit-input2.length:');
                        console.log(input2.length);
                        if(input2.length>0) {
                          this.startEdit={'startedit':true};
                        }else{
                          this.startEdit={'startedit':false};
                        }
                        console.log(this.startEdit);
                    }else{
                         console.log('cellEdit-input2 is null:');
                         this.startEdit={'startedit':false};
                         console.log(this.startEdit);
                    }
                }, 10);
            },
            cellEditCompleteExpande(tablename,event,parentRow,allcurrentdata,allparentdata){
                console.log('cellEditCompleteExpande..');
                console.log(tablename);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                let { field } = event;
                for(var pro in event.newData){
                    event.data[pro] = event.newData[pro];
                }
                console.log(field);
                if(this.CellEditCompleteExpandeMethod){
                    setTimeout(() => {
                        console.log('CellEditCompleteExpandeMethod..');
                        var allelements=this.getAllMainElements();
                        var allchildelements=this.getAllChildElements();
                        console.log(allelements);
                        console.log(allchildelements);
                        this.CellEditCompleteExpandeMethod(tablename,field,event.data,parentRow,allcurrentdata,allparentdata,this.childTableFooterObj,allelements,[],allchildelements);
                        this.setAllMainElements(allelements);
                        this.setAllChildElements(allchildelements);
                    }, 50);
                }
            },
            childExpandeDialogHide(tablename,v_alldata){
               console.log('childExpandeDialogHide..');
               console.log(tablename);
               console.log(v_alldata);
               this.sumAllField(tablename,v_alldata);
            },
            cellEditComplete(event,tablename,v_alldata){
                console.log('cellEditComplete..');
                let { data,newValue, field,newData } = event;
                console.log("event:");
                console.log(event);
                console.log("field:");
                console.log(field);
                console.log("tablename:");
                console.log(tablename);
                console.log("v_alldata:");
                console.log(v_alldata);
                console.log("childTableFooterObj:");
                console.log(this.childTableFooterObj);
                console.log("data:");
                console.log(data);
                console.log("newData:");
                console.log(newData);
                console.log("newValue:");
                console.log(newValue);
                console.log("startEdit:");
                console.log(this.startEdit);
                if(!this.startEdit['startedit']){
                   return;
                }
                this.startEdit['startedit']=false;
                console.log('cellEditComplete..continue....');
                if(event.data['id']!=event.newData['id']){
                    console.log('cellEditComplete..id不相符....');
                    return;
                }
                for(var pro in event.newData){
                    event.data[pro] = event.newData[pro];
                }
                var allchildelements=this.getAllChildElements();
                if(this.CellEditCompleteMethod){
                    var allelements=this.getAllMainElements();
                    this.CellEditCompleteMethod(tablename,field,v_alldata,event.data,this.childTableFooterObj,allchildelements,[],allelements,this.noshowelements);
                    this.setAllMainElements(allelements);
                    console.log('cellEditComplete..3');
                    console.log(data);
                    console.log(allelements);
                    console.log(this.pageConfig);
                }
                for(var n2=0;n2<allchildelements.length;n2++){
                  this.sumAllField(allchildelements[n2]['name'],allchildelements[n2]['rows']);
                }
                console.log('cellEditComplete..5');
                this.setAllChildElements(allchildelements);
            },
            sumAllField(tablename,v_alldata){
                if(this.childTableFooterObj && this.childTableFooterObj[tablename]){
                   var sumData={};
                   for(var i=0;i<v_alldata.length;i++){
                       for(var field in this.childTableFooterObj[tablename]){
                            if(!isNaN(Number(this.childTableFooterObj[tablename][field]))){
                                var tempnum=Number(v_alldata[i][field]);
                                if(sumData[field] || sumData[field]==0){
                                   if(!isNaN(tempnum)){
                                       sumData[field]=sumData[field]+tempnum;
                                   }
                                }else{
                                    if(!isNaN(tempnum)){
                                       sumData[field]=tempnum;
                                    }else{
                                        sumData[field]=0;
                                    }
                                }
                            }
                       }
                   }
                   for(var sumfield in sumData){
                       console.log('sumAllField..'+sumfield+'='+sumData[sumfield]);
                       if(sumfield.toLowerCase().indexOf("volume")>=0){
                          this.childTableFooterObj[tablename][sumfield]=commonJs.moneySwitch(sumData[sumfield],4);
                       }else{
                          this.childTableFooterObj[tablename][sumfield]=commonJs.moneySwitch(sumData[sumfield],3);
                       }
                   }
                }
            },
            getChildTableFooter(v_tablename,v_field,v_data){
               console.log('getChildTableFooter:');
               console.log(v_tablename);
               console.log(v_field);
               console.log(v_data);
               if(this.childTableFooterObj && this.childTableFooterObj[v_tablename] && (this.childTableFooterObj[v_tablename][v_field] || this.childTableFooterObj[v_tablename][v_field]==0)){
                   return this.childTableFooterObj[v_tablename][v_field];
               }
               return '';
            },
            initSumInfo(v_allchildelements){
               for(var k1=0;k1<v_allchildelements.length;k1++){
                   var item=v_allchildelements[k1];
                   if(this.childTableFooterObj && this.childTableFooterObj[item.name] && (!item['type'] || item['type']=='grid')){
                       for(var feild in this.childTableFooterObj[item.name]){
                           if(!isNaN(Number(this.childTableFooterObj[item.name][feild]))){
                               var summary=0;
                               for(var k2=0;k2<item.rows.length;k2++){
                                   var tempnum=Number(item.rows[k2][feild]);
                                   if(!isNaN(tempnum)){
                                        summary=summary+tempnum;
                                    }
                               }
                                if(feild.toLowerCase().indexOf("volume")>=0){
                                    this.childTableFooterObj[item.name][feild]=commonJs.moneySwitch(summary,4);
                                }else{
                                  this.childTableFooterObj[item.name][feild]=commonJs.moneySwitch(summary,3);
                                }
                           }
                       }
                   }
               }
            },
            getMainelementCss(elementname){
               var cssname='field col-12 md:col-2';
               if(this.mainelementCss && this.mainelementCss[elementname]){
                  cssname=this.mainelementCss[elementname];
               }
               return cssname;
            },
            setEditorValue(event){
                console.log('setEditorValue:');
                let { item } = event;
                console.log(item);
                this.modifyTextareaVal(item.colname,item.content);
            },
            getChildTableColWidth(v_childname,v_fieldname){
               console.log('getChildTableColWidth.....');
               console.log(v_childname);
               console.log(v_fieldname);
               return this.getChildTableColWidthMehod(v_childname,v_fieldname);
            },
            getChildTableColWidthMehod(v_childname,v_fieldname){
                var hasFind=false;
                var region_3_element=this.pageConfig['region_3']['left']['element'];
                for(var k=0;k<region_3_element.length;k++){
                    if(region_3_element[k]['name']==v_childname){
                        if(region_3_element[k]['colwidth'][v_fieldname]){
                            return 'width:'+region_3_element[k]['colwidth'][v_fieldname]+';';
                        }
                        hasFind=true;
                        break;
                    }
                }
                if(hasFind) return 'width:160px;';
                region_3_element=this.pageConfig['region_3']['right']['element'];
                for(var k2=0;k2<region_3_element.length;k2++){
                    if(region_3_element[k2]['name']==v_childname){
                        if(region_3_element[k2]['colwidth'][v_fieldname]){
                            return 'width:'+region_3_element[k2]['colwidth'][v_fieldname]+';';
                        }
                        hasFind=true;
                        break;
                    }
                }
                if(hasFind) return 'width:160px;';
                var region_2_right_element=this.pageConfig['region_2']['right']['element'];
                for(var k1=0;k1<region_2_right_element.length;k1++){
                    if(region_2_right_element[k1]['name']==v_childname){
                        if(region_2_right_element[k1]['colwidth'][v_fieldname]){
                            return 'width:'+region_2_right_element[k1]['colwidth'][v_fieldname]+';';
                        }
                        hasFind=true;
                        break;
                    }
                }
                return 'width:160px;';
            },
            addMainElementToPageConfig(v_element){
                var hasFind=false;
                var originaltype="";
                var region_1_left_element=this.pageConfig['region_1']['left']['element'];
                for(var k=0;k<region_1_left_element.length;k++){
                   if(region_1_left_element[k]['name']==v_element['name']){
                        originaltype=region_1_left_element[k]['type'];
                        if(region_1_left_element[k]['label'] && region_1_left_element[k]['label']!=''){
                            v_element['label']=region_1_left_element[k]['label'];
                        }
                        region_1_left_element[k]=Object.assign(region_1_left_element[k],v_element);
                        if(originaltype && originaltype!=''){
                           region_1_left_element[k]['type']=originaltype;
                        }
                        hasFind=true;
                        break;
                   }
                }
                if(hasFind) return;
                var region_1_right_element=this.pageConfig['region_1']['right']['element'];
                for(var k1=0;k1<region_1_right_element.length;k1++){
                   if(region_1_right_element[k1]['name']==v_element['name']){
                        if(region_1_right_element[k1]['label'] && region_1_right_element[k1]['label']!=''){
                            v_element['label']=region_1_right_element[k1]['label'];
                        }
                        region_1_right_element[k1]=Object.assign(region_1_right_element[k1],v_element);
                        hasFind=true;
                        break;
                   }
                }
                if(hasFind) return;
                var region_2_left_element=this.pageConfig['region_2']['left']['element'];
                 for(var k2=0;k2<region_2_left_element.length;k2++){
                   if(region_2_left_element[k2]['name']==v_element['name']){
                        originaltype=region_2_left_element[k2]['type'];
                        if(region_2_left_element[k2]['label'] && region_2_left_element[k2]['label']!=''){
                            v_element['label']=region_2_left_element[k2]['label'];
                        }
                        region_2_left_element[k2]=Object.assign(region_2_left_element[k2],v_element);
                        if(originaltype=='Editor')region_2_left_element[k2]['type']=originaltype;
                        hasFind=true;
                        break;
                   }
                }
                if(hasFind) return;
                 var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                 for(var k3=0;k3<region_3_left_element.length;k3++){
                   if(region_3_left_element[k3]['type'] && region_3_left_element[k3]['type']!='grid' && region_3_left_element[k3]['name']==v_element['name']){
                        originaltype=region_3_left_element[k3]['type'];
                        if(region_3_left_element[k3]['label'] && region_3_left_element[k3]['label']!=''){
                            v_element['label']=region_3_left_element[k3]['label'];
                        }
                        region_3_left_element[k3]=Object.assign(region_3_left_element[k3],v_element);
                        if(originaltype=='Editor')region_3_left_element[k3]['type']=originaltype;
                        hasFind=true;
                        break;
                   }
                }
                if(hasFind) return;
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var k4=0;k4<region_3_right_element.length;k4++){
                   if(region_3_right_element[k4]['type'] && region_3_right_element[k4]['type']!='grid' && region_3_right_element[k4]['name']==v_element['name']){
                        originaltype=region_3_right_element[k4]['type'];
                        if(region_3_right_element[k4]['label'] && region_3_right_element[k4]['label']!=''){
                            v_element['label']=region_3_right_element[k4]['label'];
                        }
                        region_3_right_element[k4]=Object.assign(region_3_right_element[k4],v_element);
                        if(originaltype=='Editor')region_3_right_element[k4]['type']=originaltype;
                        hasFind=true;
                        break;
                   }
                }
            },
            removeMainElementFromPageConfig(){
               var region_1_left_element=this.pageConfig['region_1']['left']['element'];
               for(var k=region_1_left_element.length-1;k>=0;k--){
                   if(!this.elementVisible[region_1_left_element[k]['name']]){
                       region_1_left_element.splice(k,1);
                   }
                }
                var region_1_right_element=this.pageConfig['region_1']['right']['element'];
                for(var k2=region_1_right_element.length-1;k2>=0;k2--){
                   if(!this.elementVisible[region_1_right_element[k2]['name']]){
                       region_1_right_element.splice(k2,1);
                   }
                }
                var region_2_left_element=this.pageConfig['region_2']['left']['element'];
                for(var k3=region_2_left_element.length-1;k3>=0;k3--){
                   if(!this.elementVisible[region_2_left_element[k3]['name']]){
                       region_2_left_element.splice(k3,1);
                   }
                }
                var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                for(var k4=region_3_left_element.length-1;k4>=0;k4--){
                   if(region_3_left_element[k4]['type'] && region_3_left_element[k4]['type']!='grid' && !this.elementVisible[region_3_left_element[k4]['name']]){
                       region_3_left_element.splice(k4,1);
                   }
                }
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var k5=region_3_right_element.length-1;k5>=0;k5--){
                   if(region_3_right_element[k5]['type'] && region_3_right_element[k5]['type']!='grid' && !this.elementVisible[region_3_right_element[k5]['name']]){
                       region_3_right_element.splice(k5,1);
                   }
                }
            },
            removeChildElementColFromPageConfig(){
                var hidecols={};
                var columnObj={};
                var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                for(var k1=0;k1<region_3_left_element.length;k1++){
                    if((!region_3_left_element[k1]['type'] || region_3_left_element[k1]['type']=='grid') && region_3_left_element[k1]['hidecols']){
                       for(var k2=region_3_left_element[k1]['columns'].length-1;k2>=0;k2--){
                           hidecols=region_3_left_element[k1]['hidecols'];
                           columnObj=region_3_left_element[k1]['columns'][k2];
                           if(hidecols[columnObj.field]){
                              region_3_left_element[k1]['columns'].splice(k2,1);
                           }
                       }
                    }
                }
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var k3=0;k3<region_3_right_element.length;k3++){
                    if((!region_3_right_element[k3]['type'] || region_3_right_element[k3]['type']=='grid') && region_3_right_element[k3]['hidecols']){
                       for(var k4=region_3_right_element[k3]['columns'].length-1;k4>=0;k4--){
                           hidecols=region_3_right_element[k3]['hidecols'];
                           columnObj=region_3_right_element[k3]['columns'][k4];
                           if(hidecols[columnObj.field]){
                              region_3_right_element[k3]['columns'].splice(k4,1);
                           }
                       }
                    }
                }
                var region_2_right_element=this.pageConfig['region_2']['right']['element'];
                for(var k5=0;k5<region_2_right_element.length;k5++){
                    if((!region_2_right_element[k5]['type'] || region_2_right_element[k5]['type']=='grid') && region_2_right_element[k5]['hidecols']){
                       for(var k6=region_2_right_element[k5]['columns'].length-1;k6>=0;k6--){
                           hidecols=region_2_right_element[k5]['hidecols'];
                           columnObj=region_2_right_element[k5]['columns'][k6];
                           if(hidecols[columnObj.field]){
                              region_2_right_element[k5]['columns'].splice(k6,1);
                           }
                       }
                    }
                }
            },
            removeChildElementFromPageConfig(v_allchild){
                var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                for(var k=region_3_left_element.length-1;k>=0;k--){
                    var hasFind=false;
                    for(var f1=0;f1<v_allchild.length;f1++){
                       if(region_3_left_element[k]['name']==v_allchild[f1]['name']){
                          hasFind=true;
                          break;
                       }
                    }
                    if(!hasFind && (!region_3_left_element[k]['type'] || region_3_left_element[k]['type']=='grid')){
                         region_3_left_element.splice(k,1);
                    }
                }
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var k2=region_3_right_element.length-1;k2>=0;k2--){
                   var hasFind2=false;
                    for(var f2=0;f2<v_allchild.length;f2++){
                       if(region_3_right_element[k2]['name']==v_allchild[f2]['name']){
                          hasFind2=true;
                          break;
                       }
                    }
                    if(!hasFind2 && (!region_3_right_element[k2]['type'] || region_3_right_element[k2]['type']=='grid')){
                         region_3_right_element.splice(k2,1);
                    }
                }
                var region_2_right_element=this.pageConfig['region_2']['right']['element'];
                for(var k1=region_2_right_element.length-1;k1>=0;k1--){
                    var hasFind3=false;
                    for(var f3=0;f3<v_allchild.length;f3++){
                       if(region_2_right_element[k1]['name']==v_allchild[f3]['name']){
                          hasFind3=true;
                          break;
                       }
                    }
                    if(!hasFind3 && (!region_2_right_element[k1]['type'] || region_2_right_element[k1]['type']=='grid')){
                         region_2_right_element.splice(k1,1);
                    }
                }
            },
            addChildElementToPageConfig(v_element){
              console.log('addChildElementToPageConfig...');
              var hasFind=false;
              var region_3_left_element=this.pageConfig['region_3']['left']['element'];
              for(var k=0;k<region_3_left_element.length;k++){
                  if(region_3_left_element[k]['name']==v_element['name']){
                     this.childElementColsSet(region_3_left_element[k],v_element);
                     region_3_left_element[k]=Object.assign(region_3_left_element[k],v_element);
                     hasFind=true;
                     console.log('addChildElementToPageConfig...1...');
                     console.log(this.pageConfig);
                     break;
                  }
              }
              if(hasFind) return;
              var region_3_right_element=this.pageConfig['region_3']['right']['element'];
              for(var k2=0;k2<region_3_right_element.length;k2++){
                  if(region_3_right_element[k2]['name']==v_element['name']){
                     this.childElementColsSet(region_3_right_element[k2],v_element);
                     region_3_right_element[k2]=Object.assign(region_3_right_element[k2],v_element);
                     hasFind=true;
                     console.log('addChildElementToPageConfig...2...');
                     console.log(this.pageConfig);
                     break;
                  }
              }
              if(hasFind) return;
              var region_2_right_element=this.pageConfig['region_2']['right']['element'];
              for(var k1=0;k1<region_2_right_element.length;k1++){
                  if(region_2_right_element[k1]['name']==v_element['name']){
                     this.childElementColsSet(region_2_right_element[k1],v_element);
                     region_2_right_element[k1]=Object.assign(region_2_right_element[k1],v_element);
                     hasFind=true;
                     console.log('addChildElementToPageConfig...3...');
                     console.log(this.pageConfig);
                     break;
                  }
              }
              if(hasFind) return;
            },
            childElementColsSet(v_pageConfigElement,v_element){
              console.log('childElementColsSet....1..');
              console.log(v_pageConfigElement);
              console.log(v_element);
              if(v_pageConfigElement['columns'] && v_pageConfigElement['columns'].length>0 && v_element['columns'] && v_element['columns'].length>0){
                  for(var i=0;i<v_pageConfigElement['columns'].length;i++){
                      if(v_pageConfigElement['columns'][i]['header'] && v_pageConfigElement['columns'][i]['header']!=''){
                          for(var i2=0;i2<v_element['columns'].length;i2++){
                              if(v_pageConfigElement['columns'][i]['field']==v_element['columns'][i2]['field']){
                                  v_element['columns'][i2]['header']=v_pageConfigElement['columns'][i]['header'];
                                  break;
                              }
                          }
                      }
                  }
              }
            },
            checkRequired(){
                var isOk=true;
                console.log('checkRequired...');
                var region_1_left_element=this.pageConfig['region_1']['left']['element'];
                for(var i=0;i<region_1_left_element.length;i++){
                    if(region_1_left_element[i]['required'] && region_1_left_element[i]['required']=="1"){
                        if(this.elementAuthority[region_1_left_element[i]['name']] || this.isReadOnly || (this.mainUdefinedAuth && this.mainUdefinedAuth[region_1_left_element[i]['name']])){ 
                           continue;
                        }
                        region_1_left_element[i]['showrequiredtip']="0";
                        var val1=region_1_left_element[i]['val'];
                        if(this.noshowelements[region_1_left_element[i]['name']]){
                            val1=this.noshowelements[region_1_left_element[i]['name']];
                        }
                        if(region_1_left_element[i]['requiredruler'] && region_1_left_element[i]['requiredruler']!=''){
                             var reg1 = new RegExp(region_1_left_element[i]['requiredruler'].replace(";","\\")) 
                             if (!reg1.test(val1)){
                                  region_1_left_element[i]['showrequiredtip']="1";
                                  console.log(region_1_left_element[i]['label']);
                                  isOk=false;
                             }
                        }else{
                            if(val1==''){
                                region_1_left_element[i]['showrequiredtip']="1";
                                 console.log(region_1_left_element[i]['label']);
                                isOk=false;
                            }
                        }
                    }
                }
                var region_1_right_element=this.pageConfig['region_1']['right']['element'];
                for(var i2=0;i2<region_1_right_element.length;i2++){
                    if(region_1_right_element[i2]['required'] && region_1_right_element[i2]['required']=="1"){
                        if(this.elementAuthority[region_1_right_element[i2]['name']] || this.isReadOnly || (this.mainUdefinedAuth && this.mainUdefinedAuth[region_1_right_element[i2]['name']])){ 
                           continue;
                        }
                        region_1_right_element[i2]['showrequiredtip']="0";
                        var val2=region_1_right_element[i2]['val'];
                        if(this.noshowelements[region_1_right_element[i2]['name']]){
                            val2=this.noshowelements[region_1_right_element[i2]['name']];
                        }
                        if(region_1_right_element[i2]['requiredruler'] && region_1_right_element[i2]['requiredruler']!=''){
                             var reg2 = new RegExp(region_1_right_element[i2]['requiredruler'].replace(";","\\")) 
                             if (!reg2.test(val2)){
                                  region_1_right_element[i2]['showrequiredtip']="1";
                                  console.log(region_1_right_element[i2]['label']);
                                  isOk=false;
                             }
                        }else{
                            if(val2==''){
                                region_1_right_element[i2]['showrequiredtip']="1";
                                console.log(region_1_right_element[i2]['label']);
                                isOk=false;
                            }
                        }
                    }
                }
                var region_2_left_element=this.pageConfig['region_2']['left']['element'];
                for(var i3=0;i3<region_2_left_element.length;i3++){
                    if(region_2_left_element[i3]['required'] && region_2_left_element[i3]['required']=="1"){
                        if(this.elementAuthority[region_2_left_element[i3]['name']] || this.isReadOnly || (this.mainUdefinedAuth && this.mainUdefinedAuth[region_2_left_element[i3]['name']])){ 
                           continue;
                        }
                        region_2_left_element[i3]['showrequiredtip']="0";
                        var val3=region_2_left_element[i3]['val'];
                        if(this.noshowelements[region_2_left_element[i3]['name']]){
                            val3=this.noshowelements[region_2_left_element[i3]['name']];
                        }
                        if(region_2_left_element[i3]['requiredruler'] && region_2_left_element[i3]['requiredruler']!=''){
                             var reg3 = new RegExp(region_2_left_element[i3]['requiredruler'].replace(";","\\")) 
                             if (!reg3.test(val3)){
                                  region_2_left_element[i3]['showrequiredtip']="1";
                                  console.log(region_2_left_element[i3]['label']);
                                  isOk=false;
                             }
                        }else{
                            if(val3==''){
                                region_2_left_element[i3]['showrequiredtip']="1";
                                console.log(region_2_left_element[i3]['label']);
                                isOk=false;
                            }
                        }
                    }
                }
                var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                for(var i4=0;i4<region_3_left_element.length;i4++){
                    if(region_3_left_element[i4]['type'] && region_3_left_element[i4]['type']!='grid' && region_3_left_element[i4]['required'] && region_3_left_element[i4]['required']=="1"){
                        if(this.elementAuthority[region_3_left_element[i4]['name']] || this.isReadOnly || (this.mainUdefinedAuth && this.mainUdefinedAuth[region_3_left_element[i4]['name']])){ 
                           continue;
                        }
                        region_3_left_element[i4]['showrequiredtip']="0";
                        var val4=region_3_left_element[i4]['val'];
                        if(this.noshowelements[region_3_left_element[i4]['name']]){
                            val4=this.noshowelements[region_3_left_element[i4]['name']];
                        }
                         if(region_3_left_element[i4]['requiredruler'] && region_3_left_element[i4]['requiredruler']!=''){
                             var reg4 = new RegExp(region_3_left_element[i4]['requiredruler'].replace(";","\\")) 
                             if (!reg4.test(val4)){
                                  region_3_left_element[i4]['showrequiredtip']="1";
                                  console.log(region_3_left_element[i4]['name']);
                                  isOk=false;
                             }
                        }else{
                            if(val4==''){
                                region_3_left_element[i4]['showrequiredtip']="1";
                                console.log(region_3_left_element[i4]['name']);
                                isOk=false;
                            }
                        }
                    }
                }
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var i5=0;i5<region_3_right_element.length;i5++){
                    if(region_3_right_element[i5]['type'] && region_3_right_element[i5]['type']!='grid' && region_3_right_element[i5]['required'] && region_3_right_element[i5]['required']=="1"){
                        if(this.elementAuthority[region_3_right_element[i5]['name']] || this.isReadOnly || (this.mainUdefinedAuth && this.mainUdefinedAuth[region_3_right_element[i5]['name']])){ 
                           continue;
                        }
                        region_3_right_element[i5]['showrequiredtip']="0";
                        var val5=region_3_right_element[i5]['val'];
                        if(this.noshowelements[region_3_right_element[i5]['name']]){
                            val5=this.noshowelements[region_3_right_element[i5]['name']];
                        }
                         if(region_3_right_element[i5]['requiredruler'] && region_3_right_element[i5]['requiredruler']!=''){
                             var reg5 = new RegExp(region_3_right_element[i5]['requiredruler'].replace(";","\\")) 
                             if (!reg5.test(val5)){
                                  region_3_right_element[i5]['showrequiredtip']="1";
                                  console.log(region_3_right_element[i5]['name']);
                                  isOk=false;
                             }
                        }else{
                            if(val5==''){
                                region_3_right_element[i5]['showrequiredtip']="1";
                                console.log(region_3_right_element[i5]['name']);
                                isOk=false;
                            }
                        }
                    }
                }
                var region_2_right_element=this.pageConfig['region_2']['right']['element'];
                for(var i6=0;i6<region_2_right_element.length;i6++){
                    for(var i7=0;i7<region_2_right_element[i6]['rows'].length;i7++){
                        if(region_2_right_element[i6]['required']){
                             for(var p1 in region_2_right_element[i6]['required']){
                                 if(this.elementAuthority[region_2_right_element[i6]['name']] ||  this.elementAuthority[region_2_right_element[i6]['name']+'.'+p1] || this.isReadOnly || (this.childUdefinedAuth && this.childUdefinedAuth[region_2_right_element[i6]['name']+'.'+p1])){ 
                                     continue;
                                 }
                                 //region_2_right_element[i6]['showrequiredtip'][p1]="";
                                 var val6="";
                                 if(region_2_right_element[i6]['rows'][i7][p1]){
                                    val6=region_2_right_element[i6]['rows'][i7][p1];
                                 }
                                  if(region_2_right_element[i6]['requiredruler'] && region_2_right_element[i6]['requiredruler'][p1] && region_2_right_element[i6]['requiredruler'][p1]!=''){
                                        var reg6 = new RegExp(region_2_right_element[i6]['requiredruler'][p1].replace(";","\\")) 
                                        if (!reg6.test(val6)){
                                            region_2_right_element[i6]['showrequiredtip'][p1]="requirederr";
                                            console.log(region_2_right_element[i6]['name']);
                                            isOk=false;
                                        }
                                  }else{
                                        if(val6==''){
                                            region_2_right_element[i6]['showrequiredtip'][p1]="requirederr";
                                            console.log(region_2_right_element[i6]['name']);
                                            isOk=false;
                                        }
                                  }
                             }
                        }
                    }
                }
                for(var i9=0;i9<region_3_left_element.length;i9++){
                     if(!region_3_left_element[i9]['type'] || region_3_left_element[i9]['type']=='grid'){
                         for(var i10=0;i10<region_3_left_element[i9]['rows'].length;i10++){
                            if(region_3_left_element[i9]['required']){
                                for(var p11 in region_3_left_element[i9]['required']){
                                    if(this.elementAuthority[region_3_left_element[i9]['name']] ||  this.elementAuthority[region_3_left_element[i9]['name']+'.'+p11] || this.isReadOnly || (this.childUdefinedAuth && this.childUdefinedAuth[region_3_left_element[i9]['name']+'.'+p11])){ 
                                       continue;
                                    }
                                    //region_3_left_element[i9]['showrequiredtip'][p11]="";
                                    var val8="";
                                    if(region_3_left_element[i9]['rows'][i10][p11]){
                                        val8=region_3_left_element[i9]['rows'][i10][p11];
                                    }
                                    if(region_3_left_element[i9]['required'][p11]=="1"){
                                        if(region_3_left_element[i9]['requiredruler'] && region_3_left_element[i9]['requiredruler'][p11] && region_3_left_element[i9]['requiredruler'][p11]!=''){
                                            var reg7 = new RegExp(region_3_left_element[i9]['requiredruler'][p11].replace(";","\\")) 
                                            if (!reg7.test(val8)){
                                                region_3_left_element[i9]['showrequiredtip'][p11]="requirederr";
                                                console.log(region_3_left_element[i9]['name']);
                                                isOk=false;
                                            }
                                        }else{
                                            if(val8==''){
                                                console.log(p11);
                                                region_3_left_element[i9]['showrequiredtip'][p11]="requirederr";
                                                console.log(region_3_left_element[i9]);
                                                isOk=false;
                                            }
                                        }
                                    }
                               }
                            }
                        }
                     }
                }
                for(var i12=0;i12<region_3_right_element.length;i12++){
                     if(!region_3_right_element[i12]['type'] || region_3_right_element[i12]['type']=='grid'){
                         for(var i13=0;i13<region_3_right_element[i12]['rows'].length;i13++){
                             if(region_3_right_element[i12]['required']){
                                 for(var p12 in region_3_right_element[i12]['required']){
                                    if(this.elementAuthority[region_3_right_element[i12]['name']] ||  this.elementAuthority[region_3_right_element[i12]['name']+'.'+p12] || this.isReadOnly || (this.childUdefinedAuth && this.childUdefinedAuth[region_3_right_element[i12]['name']+'.'+p12])){ 
                                       continue;
                                     }
                                     //region_3_right_element[i12]['showrequiredtip'][p12]="";
                                     var val9="";
                                    if(region_3_right_element[i12]['rows'][i13][p12]){
                                        val9=region_3_right_element[i12]['rows'][i13][p12];
                                    }
                                    if(region_3_right_element[i12]['requiredruler'] && region_3_right_element[i12]['requiredruler'][p12] && region_3_right_element[i12]['requiredruler'][p12]!=''){
                                        var reg8 = new RegExp(region_3_right_element[i12]['requiredruler'][p12].replace(";","\\")) 
                                        if (!reg8.test(val9)){
                                            region_3_right_element[i12]['showrequiredtip'][p12]="requirederr";
                                            console.log(region_3_right_element[i12]['name']);
                                            isOk=false;
                                        }
                                    }else{
                                        if(val9==''){
                                            region_3_right_element[i12]['showrequiredtip'][p12]="requirederr";
                                            console.log(region_3_right_element[i12]['name']);
                                            isOk=false;
                                        }
                                    }
                                 }
                             }
                        }
                     }
                }
                console.log('checkRequired...');
                console.log(this.pageConfig);
                if(!isOk){
                    setTimeout(() => {
                        this.setRequiredTableCellCss();
                    }, 20);
                }
                return isOk;
            },
            getchildTableColClass(col,item){
               console.log('getchildTableColClass...');
               console.log(col);
               console.log(item);
               if(item['required'] && item['required'][col.field] && item['required'][col.field]=='1'){
                  return "xrequired";
               }else{
                   return "";
               }
            },
            clearRequired(){
                var region_1_left_element=this.pageConfig['region_1']['left']['element'];
                for(var i=0;i<region_1_left_element.length;i++){
                    if(region_1_left_element[i]['required'] && region_1_left_element[i]['required']=="1"){
                        region_1_left_element[i]['showrequiredtip']="0";
                    }
                }
                var region_1_right_element=this.pageConfig['region_1']['right']['element'];
                for(var i2=0;i2<region_1_right_element.length;i2++){
                    if(region_1_right_element[i2]['required'] && region_1_right_element[i2]['required']=="1"){
                        region_1_right_element[i2]['showrequiredtip']="0";
                    }
                }
                var region_2_left_element=this.pageConfig['region_2']['left']['element'];
                for(var i3=0;i3<region_2_left_element.length;i3++){
                    if(region_2_left_element[i3]['required'] && region_2_left_element[i3]['required']=="1"){
                        region_2_left_element[i3]['showrequiredtip']="0";
                    }
                }
                var region_3_left_element=this.pageConfig['region_3']['left']['element'];
                for(var i4=0;i4<region_3_left_element.length;i4++){
                    if(region_3_left_element[i4]['type'] && region_3_left_element[i4]['type']!='grid' && region_3_left_element[i4]['required'] && region_3_left_element[i4]['required']=="1"){
                        region_3_left_element[i4]['showrequiredtip']="0";
                    }
                }
                var region_3_right_element=this.pageConfig['region_3']['right']['element'];
                for(var i5=0;i5<region_3_right_element.length;i5++){
                    if(region_3_right_element[i5]['type'] && region_3_right_element[i5]['type']!='grid' && region_3_right_element[i5]['required'] && region_3_right_element[i5]['required']=="1"){
                        region_3_right_element[i5]['showrequiredtip']="0";
                    }
                }
                var region_2_right_element=this.pageConfig['region_2']['right']['element'];
                for(var i6=0;i6<region_2_right_element.length;i6++){
                    for(var i7=0;i7<region_2_right_element[i6]['rows'].length;i7++){
                        for(var i8=0;i8<region_2_right_element[i6]['columns'].length;i8++){
                          var cloname1=region_2_right_element[i6]['columns'][i8]['field'];
                          if(region_2_right_element[i6]['required'] && region_2_right_element[i6]['required'][cloname1] && region_2_right_element[i6]['required'][cloname1]=="1"){
                              region_2_right_element[i6]['showrequiredtip'][cloname1]="";
                          }
                        }
                    }
                }
                for(var i9=0;i9<region_3_left_element.length;i9++){
                     if(!region_3_left_element[i9]['type'] || region_3_left_element[i9]['type']=='grid'){
                         for(var i10=0;i10<region_3_left_element[i9]['rows'].length;i10++){
                            for(var i11=0;i11<region_3_left_element[i9]['columns'].length;i11++){
                                var cloname2=region_3_left_element[i9]['columns'][i11]['field'];
                                if(region_3_left_element[i9]['required'] && region_3_left_element[i9]['required'][cloname2] && region_3_left_element[i9]['required'][cloname2]=="1"){
                                    region_3_left_element[i9]['showrequiredtip'][cloname2]="";
                                }
                            }
                        }
                     }
                }
                for(var i12=0;i12<region_3_right_element.length;i12++){
                     if(!region_3_right_element[i12]['type'] || region_3_right_element[i12]['type']=='grid'){
                         for(var i13=0;i13<region_3_right_element[i12]['rows'].length;i13++){
                            for(var i14=0;i14<region_3_right_element[i12]['columns'].length;i14++){
                                var cloname3=region_3_right_element[i12]['columns'][i14]['field'];
                                if(region_3_right_element[i12]['required'] && region_3_right_element[i12]['required'][cloname3] && region_3_right_element[i12]['required'][cloname3]=="1"){
                                    region_3_right_element[i12]['showrequiredtip'][cloname3]="";
                                }
                            }
                        }
                     }
                }
            },
            setRequiredTableCellCss(){
               var elements= document.getElementsByClassName('p-editable-column');
               for(var i=0;i<elements.length;i++){
                   console.log('setRequiredTableCellCss1...');
                   var tdstyle=elements[i].getAttribute('style').replace("border: 1px solid #ff0000;","");
                   console.log(tdstyle);
                   elements[i].setAttribute("style",tdstyle);
                   elements[i].className=elements[i].className.replace(" requiredTd","");
               }
               elements= document.getElementsByClassName('requirederr');
               for(var i2=0;i2<elements.length;i2++){
                   console.log('setRequiredTableCellCss2...');
                   console.log(elements[i2].parentNode.getAttribute('style'));
                   var tdstyle2=elements[i2].parentNode.getAttribute('style').replace("border: 1px solid #ff0000;","");
                   elements[i2].parentNode.className=elements[i2].parentNode.className.replace(" requiredTd","")+" requiredTd";
                   elements[i2].parentNode.setAttribute("style","border: 1px solid #ff0000;"+tdstyle2);
               }
            },
            setEditHistoryTableCellCss(){
               var elements= document.getElementsByClassName('edithistorycss');
               for(var i2=0;i2<elements.length;i2++){
                   console.log('setEditHistoryTableCellCss...');
                   console.log(elements[i2].parentNode.getAttribute('style'));
                   if(elements[i2].parentNode.getAttribute('style')){
                      var tdstyle2=elements[i2].parentNode.getAttribute('style').replace("border: 2px solid #FF8800;","");
                      elements[i2].parentNode.setAttribute("style","border: 2px solid #FF8800;"+tdstyle2);
                   }else{
                      elements[i2].parentNode.setAttribute("style","border: 2px solid #FF8800;");
                   }
               }
               elements= document.getElementsByClassName('mainedithistorycss');
               for(var i1=0;i1<elements.length;i1++){
                   console.log(elements[i1]);
                   if(elements[i1].getAttribute('style')){
                      var tdstyle1=elements[i1].getAttribute('style').replace("border: 2px solid #FF8800;","");
                      elements[i1].setAttribute("style","border: 2px solid #FF8800;"+tdstyle1);
                   }else{
                      elements[i1].setAttribute("style","border: 2px solid #FF8800;");
                   }
               }
               for(var deltablename in this.editHistoryObj['listchilddel']){
                   var childdel = this.editHistoryObj['listchilddel'][deltablename];
                      for(var rowid in childdel){
                         var trTitle=childdel[rowid]['editer']+childdel[rowid]['edittime']+'删除';
                         var trelements= document.getElementsByClassName('-del'+rowid+'tr');
                         for(var i5=0;i5<trelements.length;i5++){
                            if(trelements[i5].getAttribute('style')){
                                var trstyle3=trelements[i5].getAttribute('style').replace("border: 2px dotted #E41B17;","");
                                trelements[i5].setAttribute("style","border: 2px dotted #E41B17;"+trstyle3);
                                trelements[i5].setAttribute("title",trTitle);
                            }
                            else{
                                trelements[i5].setAttribute("style","border: 2px dotted #E41B17;");
                                trelements[i5].setAttribute("title",trTitle);
                            }
                         }
                      }
                }
            },
            clearEditHistoryTableCellCss(){
               var elements= document.getElementsByClassName('edithistorycss');
               for(var i2=0;i2<elements.length;i2++){
                   console.log('clearEditHistoryTableCellCss...');
                   console.log(elements[i2].parentNode.getAttribute('style'));
                   if(elements[i2].parentNode.getAttribute('style')){
                      var tdstyle2=elements[i2].parentNode.getAttribute('style').replace("border: 2px solid #FF8800;","");
                      elements[i2].parentNode.setAttribute("style",tdstyle2);
                   }
               }
               elements= document.getElementsByClassName('mainedithistorycss');
               for(var i1=0;i1<elements.length;i1++){
                   console.log(elements[i1]);
                   if(elements[i1].getAttribute('style')){
                      var tdstyle1=elements[i1].getAttribute('style').replace("border: 2px solid #FF8800;","");
                      elements[i1].setAttribute("style",tdstyle1);
                   }
               }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            inputdropSelectAfter(v_id,v_data){
               if(this.inputdropSelectAfterMethod){
                  this.inputdropSelectAfterMethod(v_id,v_data);
               }
            },
            setchildExpandeConfig(v_childExpandeConfig){
                this.childExpandeConfig=JSON.parse(JSON.stringify(v_childExpandeConfig));
            },
            openChildDialog(v_childdata,v_parentdata){
               if(this.openChildDialogMethod){
                  this.openChildDialogMethod(v_childdata,v_parentdata);
               }
            },
            IsChildType(v_val){//[purchaseinspectionfile]
              if (typeof v_val === 'string') {
                  console.log(v_val);
                  var regx1=/^\[/g;
                  var regx2 = /\]$/g;
                  if(regx1.test(v_val) && regx2.test(v_val)){
                      return true;
                  }else{
                      return false;
                  }
              }else{
                  return false;
              }
            },
            showEditHistory(v_seteditHistoryButTxtFun) {
                requests.plat_requests.post(
                    '/calf/plat/GetEditHistory?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        id: this.currentRow['id']
                    }).then(res => {
                    console.log('showEditHistory...');
                    console.log(res);
                    if (res.errcode == "0" && res.data != '') {
                        this.editHistoryObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(this.editHistoryObj);
                        var allMainElements = this.getAllMainElements();
                        var allChildElements = this.getAllChildElements();
                        var hasedithistory=false;
                        for (var k1 = 0; k1 < allMainElements.length; k1++) {
                            if (this.editHistoryObj['main'][allMainElements[k1]['name']]) {
                                var mainFeildObj = this.editHistoryObj['main'][allMainElements[k1]['name']];
                                var mainFeild = '';
                                for (var k2 = 0; k2 < mainFeildObj.length; k2++) {
                                    var oldvalue=this.formatData(allMainElements[k1],mainFeildObj[k2]['oldvalue'],this.noshowelements,allMainElements[k1]['name']);
                                    var newvalue=this.formatData(allMainElements[k1],mainFeildObj[k2]['newvalue'],this.noshowelements,allMainElements[k1]['name']);
                                    mainFeild = mainFeild + '<h6>' + mainFeildObj[k2]['editer'] + mainFeildObj[k2]['edittime'] + '修改</h6><h6>原内容:<font color="red">' + oldvalue + '</font></h6><h6>修改后内容:<font color="red">' + newvalue + '</font></h6><br>';
                                }
                                if (mainFeild != '') {
                                    allMainElements[k1]['edithistory'] =mainFeild;
                                    hasedithistory=true;
                                }
                            }
                        }
                        for (var i1 = 0; i1 < allChildElements.length; i1++) {
                            if (this.editHistoryObj['listchildedit'][allChildElements[i1]['name']]) {
                                var childdata = allChildElements[i1]['rows'];
                                var childedit = this.editHistoryObj['listchildedit'][allChildElements[i1][
                                    'name'
                                ]];
                                for (var i2 = 0; i2 < childdata.length; i2++) {
                                    if (childedit[childdata[i2]['id']]) {
                                        for (var col in childdata[i2]) {
                                            if (childedit[childdata[i2]['id']][col]) {
                                                var colobj=this.findChildTableCol(allChildElements[i1]['columns'],col);
                                                var childFeild = '';
                                                var childFeildObj = childedit[childdata[i2]['id']][col];
                                                for (var i3 = 0; i3 < childFeildObj.length; i3++) {
                                                    var oldvalue2=this.formatData(colobj,childFeildObj[i3]['oldvalue'],childFeildObj[i3],col);
                                                    var newvalue2=this.formatData(colobj,childFeildObj[i3]['newvalue'],childFeildObj[i3],col);
                                                    childFeild = childFeild + '<h6>' + childFeildObj[i3]['editer'] + childFeildObj[i3]['edittime'] +'修改</h6><h6>原内容:<font color="red">' + oldvalue2 +'</font></h6><h6>修改后内容:<font color="red">' + newvalue2 +'</font></h6><br>';
                                                }
                                                if (childFeild != '') {
                                                    if (!childdata[i2]['edithistory']) {
                                                        childdata[i2]['edithistory'] = {};
                                                    }
                                                    childdata[i2]['edithistory'][col] = childFeild;
                                                    hasedithistory=true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(this.editHistoryObj['listchildinsert'][allChildElements[i1]['name']]){
                                 var childinsert = this.editHistoryObj['listchildinsert'][allChildElements[i1]['name']];
                                 for(var rowid in childinsert){
                                        hasedithistory=true;
                                        var trTitle=childinsert[rowid]['editer']+childinsert[rowid]['edittime']+'新增';
                                        var trelements= document.getElementsByClassName(rowid+'tr');
                                        for(var i5=0;i5<trelements.length;i5++){
                                            console.log(trelements[i5].getAttribute('style'));//dotted
                                            if(trelements[i5].getAttribute('style')){
                                                var trstyle=trelements[i5].getAttribute('style').replace("border: 2px solid #5FFB17;","");
                                                trelements[i5].setAttribute("style","border: 2px solid #5FFB17;"+trstyle);
                                                trelements[i5].setAttribute("title",trTitle);
                                            }else{
                                                trelements[i5].setAttribute("style","border: 2px solid #5FFB17;");
                                                trelements[i5].setAttribute("title",trTitle);
                                            }
                                        }
                                 }
                            }
                            if(this.editHistoryObj['listchilddel'][allChildElements[i1]['name']]){
                                 var childdel = this.editHistoryObj['listchilddel'][allChildElements[i1]['name']];
                                 for(var delrowid in childdel){
                                      hasedithistory=true;
                                     var childdata2 = allChildElements[i1]['rows'];
                                     var delrowObj2={};
                                     for(var delrowObj in childdel[delrowid]['rowdata']){
                                         if(delrowObj.toLowerCase()=="id"){
                                            delrowObj2[delrowObj.toLowerCase()]="-del"+childdel[delrowid]['rowdata'][delrowObj];
                                         }else{
                                            delrowObj2[delrowObj.toLowerCase()]=childdel[delrowid]['rowdata'][delrowObj];
                                         }
                                     }
                                     childdata2.push(delrowObj2);
                                 }
                            }
                        }
                        if(v_seteditHistoryButTxtFun){
                            v_seteditHistoryButTxtFun();
                        }
                        console.log(allMainElements);
                        console.log(allChildElements);
                        this.openedithistory=true;
                        setTimeout(() => {
                            this.setEditHistoryTableCellCss();
                        }, 50);
                        if(!hasedithistory){
                            MessageTip.infomsg('该单据无修改痕迹');
                        }
                    }else{
                        if(res.errcode == "0"){
                            MessageTip.infomsg('该单据无修改痕迹');
                        }
                    }
                });
            },
            clearEditHistory() {
                this.openedithistory=false;
                this.clearEditHistoryTableCellCss();
                if (this.editHistoryObj && this.editHistoryObj['main']) {
                    var allMainElements = this.getAllMainElements();
                    var allChildElements = this.getAllChildElements();
                    for (var k1 = 0; k1 < allMainElements.length; k1++) {
                        if (this.editHistoryObj['main'] && this.editHistoryObj['main'][allMainElements[k1]['name']] && allMainElements[k1][
                                'edithistory'
                            ]) {
                            delete allMainElements[k1]['edithistory'];
                        }
                    }
                    for (var i1 = 0; i1 < allChildElements.length; i1++) {
                        if (this.editHistoryObj['listchildedit'] && this.editHistoryObj['listchildedit'][allChildElements[i1]['name']]) {
                            var childdata = allChildElements[i1]['rows'];
                            var childedit = this.editHistoryObj['listchildedit'][allChildElements[i1][
                                'name'
                            ]];
                            for (var i2 = 0; i2 < childdata.length; i2++) {
                                if (childedit[childdata[i2]['id']]) {
                                    for (var col in childdata[i2]) {
                                        if (childedit[childdata[i2]['id']][col] && childdata[i2]['edithistory']) {
                                            delete childdata[i2]['edithistory'];
                                        }
                                    }
                                }
                            }
                        }
                        if(this.editHistoryObj['listchildinsert'] && this.editHistoryObj['listchildinsert'][allChildElements[i1]['name']]){
                            var childinsert = this.editHistoryObj['listchildinsert'][allChildElements[i1]['name']];
                            for(var rowid in childinsert){
                                var trelements= document.getElementsByClassName(rowid+'tr');
                                for(var i5=0;i5<trelements.length;i5++){
                                    console.log(trelements[i5].getAttribute('style'));
                                    if(trelements[i5].getAttribute('style')){
                                        var trstyle=trelements[i5].getAttribute('style').replace("border: 2px solid #5FFB17;","");
                                        trelements[i5].setAttribute("style",trstyle);
                                        trelements[i5].setAttribute("title","");
                                    }else{
                                        trelements[i5].setAttribute("title","");
                                    }
                                }
                            }
                        }
                        if(this.editHistoryObj['listchilddel'] && this.editHistoryObj['listchilddel'][allChildElements[i1]['name']]){
                            var childdata2 = allChildElements[i1]['rows'];
                            for(var i6=childdata2.length-1;i6>=0;i6--){
                                var idstr="";
                                if(childdata2[i6]['id']) idstr=childdata2[i6]['id']+'';
                                if(idstr.indexOf("-del")>=0){
                                    childdata2.splice(i6,1);
                                }
                            }
                        }
                    }
                    console.log(allMainElements);
                    console.log(allChildElements);
                }
            },
            findChildTableCol(v_columns,v_colname){
              for(var i=0;i<v_columns.length;i++){
                if(v_columns[i]['field']==v_colname){
                    return v_columns[i];
                }
              }
            },
            rowClassFun(v_row){
               console.log('rowClassFun...');
               console.log(v_row);
               return v_row['id']+"tr";
            },
            getmainedithistorycss(v_item){
                if(v_item['edithistory']){
                    if(v_item['class']){
                      return "mainedithistorycss "+v_item['class'];
                    }else{
                       return "mainedithistorycss";
                    }
                }
                else{
                    if(v_item['class']){
                      return v_item['class'];
                    }else{
                       return "";
                    }
                }
            },
            getmainelementtip(v_item){
             if(!this.openedithistory && v_item['tip']){
                  return v_item['tip'];
              }else{
                  return '';
              }
            },
            getchildedithistorycss(v_data,v_item,v_field){
                var cssname="";
                if(v_data['edithistory'] && v_data['edithistory'][v_field]){
                    cssname= "edithistorycss";
                }
                if(v_item['showrequiredtip'] && v_item['showrequiredtip'][v_field] && v_item['showrequiredtip'][v_field]!=''){
                   cssname=cssname+" requirederr"
                }
                if(!v_item['closeoneline']){
                   cssname=cssname+" oneline"
                }
                return cssname;
            },
            getchildelementtip(v_data,v_col){
              if(!this.openedithistory){
                  var tip=this.formatData(v_col,v_data[v_col.field],v_data,v_col.field);
                  if (typeof tip === "undefined") {
                    return '';
                  }else{
                    return tip;
                  }
              }else{
                  return '';
              }
            },
            maintiptoggle(v_event,v_item){
               console.log('maintiptoggle....');
               if(this.openedithistory && v_item['edithistory']){
                 this.tipcontent=v_item['edithistory'];
                 this.$refs.op.toggle(v_event);
                 setTimeout(() => {
                     var divobj=document.getElementById("edithistorypanel");
                     var stylearray=divobj.getAttribute('style').split(';');
                     var stylestr=''
                     for(var i=0;i<stylearray.length;i++){
                         if(stylearray[i].indexOf("z-index")>=0){
                             stylestr=stylestr+"z-index:99999;";
                         }else{
                             stylestr=stylestr+stylearray[i]+";";
                         }
                     }
                     divobj.setAttribute("style",stylestr);
                 }, 10);
               }
            },
            childtiptoggle(v_event,v_data,v_col){
               console.log('childtiptoggle....');
               console.log(v_data);
               console.log(v_col);
               //console.log(v_field);
               if(this.openedithistory && v_data['edithistory'] && v_data['edithistory'][v_col.field]){
                   this.tipcontent=v_data['edithistory'][v_col.field];
                   this.$refs.op.toggle(v_event);
                   setTimeout(() => {
                        var divobj=document.getElementById("edithistorypanel");
                        var stylearray=divobj.getAttribute('style').split(';');
                        var stylestr=''
                        for(var i=0;i<stylearray.length;i++){
                            if(stylearray[i].indexOf("z-index")>=0){
                                stylestr=stylestr+"z-index:99999;";
                            }else{
                                stylestr=stylestr+stylearray[i]+";";
                            }
                        }
                        divobj.setAttribute("style",stylestr);
                   }, 10);
               }
            },
            setAllExpandedRowsData(v_name){
                //this.expandedRowsData.push(v_obj);
                this.expandedRowsData3 = [];
                var allchildelements = this.getAllChildElements();
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == v_name) {
                        for(var i2=0;i2<allchildelements[i]['rows'].length;i2++){
                          this.expandedRowsData3.push(allchildelements[i]['rows'][i2]);
                        }
                        break;
                    }
                }
            },
            childExpande3RowEditSave(event, v_data){
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            childExpande3EditComplete(event){
                 for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            }
        },
        components: {
            CalfDropdown,
            CalfInputDropdown,
            CalfTreeDropdown,
            LinkageDropDown,
            DataImport,
            UploadInput,
            CalfMultiSelect,
            CalfAtuNumber,
            Editor,
            FlowEngine,
            Image,
            ScrollPanel
        }
    }
</script>