const QuotationBoxJs = {
    initDetail() {
        console.log('QuotationBoxJs---initDetail...');
        this.childNewDataConfig = {
            products: false,
        };
        this.dropOtherConfig = {};
        this.childTableFooter = {
            products: {
                url: '合计',
                loadingqty: 0,
                price: 0,
                grossweight: 0,
                netweight: 0
            }
        };
        this.childaloneshowConfig = {};
        return {
            'childNewDataConfig': this.childNewDataConfig,
            'dropOtherConfig': this.dropOtherConfig,
            'childTableFooter': this.childTableFooter,
            'childaloneshowConfig': this.childaloneshowConfig
        }
    },
    cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
        v_alonechildelements, v_elements) {
        console.log('QuotationBoxJs--cellEditCompleteMethod..');
        console.log(tablename);
        console.log(field);
        console.log(data);
        console.log(rowdata);
        console.log(childTableFooterObj);
        console.log(v_childelements);
        console.log(v_alonechildelements);
        console.log(v_elements);
    },
    importAddQueryCondition(v_listwhere, v_moduleName) {
        console.log(v_moduleName);
        v_listwhere.conditions.push({
            name: 'status',
            value: '0',
            operation: '=',
        });
        return v_listwhere;
    },
    productCellEditInit(event, tablename, colconfigs) {
        if (tablename == 'products') {
            var countindex = 0;
            for (var i = 0; i < colconfigs.length; i++) {
                if (colconfigs[i]['field'] == 'price') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (colconfigs[i]['field'] == 'grossweight') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (colconfigs[i]['field'] == 'netweight') {
                    if (event.data['producttype'] && event.data['producttype'] == '2') {
                        colconfigs[i]['type'] = 'LabelString';
                    } else {
                        colconfigs[i]['type'] = 'Decimal';
                    }
                    countindex = countindex + 1;
                }
                if (countindex >= 3) {
                    break;
                }
            }
        }
    },
    loadDataAfter(v_mainelements, v_childelements, v_originalData, currentRow, currencyDict) {
        console.log('QuotationBoxJs--loadDataAfter..');
        console.log(v_mainelements);
        console.log(v_childelements);
        console.log(v_originalData);
        console.log(currentRow);
        console.log(currencyDict);
    },
};

export default QuotationBoxJs;