<template>
    <Toast />
    <div class="p-inputgroup">
        <div v-if="fileType=='img'" class="p-mb-3 p-text-left">
            <Image :src="getFilePatch(filePatch)" width="20" preview />
        </div>
        <div v-if="fileType!='img'" class="p-mb-3 p-text-left">
            <a :href="getFilePatch(filePatch)" v-tooltip.top="{ value: fileName, disabled: false }"
                style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;" target="_blank">{{fileName}}</a>
        </div>&nbsp;&nbsp;
        <input type="hidden" :name="ctrlname">
        <label v-if="upstart">上传中...</label>
        <FileUpload v-if="!upstart" mode="basic" :url="uploadurl" :accept="acceptFile" :auto="true"
            :maxFileSize="20971520" @upload="onImportUpload" @before-upload="beforeImportUpload"
            invalidFileSizeMessage="{0}: 文件大小必须小于20M." invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件"
            chooseLabel="选择" />
    </div>
</template>
<style lang="scss" scoped>
    .product-smallimage {
        width: 20px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
</style>
<script>
    import * as Base64 from 'js-base64';
    import Image from 'primevue/image';
    import MessageTip from './Message';
    export default {
        name: 'UploadInput',
        emits: ['update:filePatch', 'update:fileName'],
        created() {
            this.loadInit();
        },
        props: ['filePatch', 'fileName', 'fileType', 'uptype', 'attachttype', 'ctrlname'],
        data() {
            return {
                upstart: false,
                importDisplay: false,
                uploadurl: '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey"),
                acceptFile: null,
                fileNameValue: '',
                uptypeValue: '',
                attachttypeValue: '',
            }
        },
        methods: {
            loadInit() {
                console.log('UploadInput....');
                console.log(this.fileName);
                this.fileNameValue = this.fileName;
                this.importDisplay = false;
                this.upstart = false;
                if (!this.uptype || this.uptype == '') {
                    this.uptypeValue = '1';
                } else {
                    this.uptypeValue = this.uptype;
                }
                if (!this.attachttype || this.attachttype == '') {
                    this.attachttypeValue = '1';
                } else {
                    this.attachttypeValue = this.attachttype;
                }
                if (this.fileType == 'img') {
                    this.acceptFile = 'image/*';
                    this.uptypeValue = "2";
                }
            },
            onImportUpload(event) {
                console.log(event);
                this.upstart = false;
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    this.fileNameValue = retObj.filename;
                    if (this.fileType == 'img') {
                        this.$emit('update:filePatch', retObj.smallpatch);
                    } else {
                        this.$emit('update:filePatch', retObj.patch);
                    }
                    this.$emit('update:fileName', retObj.filename);
                } else {
                    this.importDisplay = false;
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                this.upstart = true;
                console.log('beforeImportUpload--' + this.uploadurl);
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', this.uptypeValue);
                event.formData.append('attachttype', this.attachttypeValue);
                if (this.fileType == 'img') {
                    console.log('imgsize....0.5*0.5');
                    event.formData.append('imgsize', "0.5*0.5");
                }
            },
            closeUpload() {
                this.importDisplay = false;
                this.upstart = false;
            },
            showUpload() {
                console.log('showUpload--' + this.uploadurl);
                this.importDisplay = true;
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
        },
        components: {
            Image
        }
    }
</script>