<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <input type="password" style="display:none">
    <input id="username" type="text" style="display:none">
    <ConfirmDialog></ConfirmDialog>
    <div class="grid">
        <div class="col-4">
            <div class="card" :style="minHeight">
                <div>
                    <Menubar :model="items">
                    </Menubar>
                </div>
                <Tree :value="nodes" :loading="organizationalloading" :filter="true" v-model:selectionKeys="selectedKey"
                    selectionMode="single" @nodeExpand="onNodeExpand" @nodeSelect="onNodeSelect" filterMode="lenient"
                    :scrollHeight="sHeight">
                </Tree>
            </div>
        </div>
        <div class="col-8">
            <div v-if="!isShowUserInfo && !isShowDeptnfo && !isShowUnitinfo" class="card p-fluid" :style="minHeight">
                <ProgressBar mode="indeterminate" />
            </div>
            <div v-show="isShowUserInfo" class="card p-fluid" :style="minHeight">
                <div class="field">
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel header="基本信息">
                            <div class="card">
                                <div class="field">
                                    <label for="un">姓名</label>
                                    <InputText id="un" type="text" v-model="userInfo.name" />
                                </div>
                                <div class="field">
                                    <label for="ulogn">登陆名</label>
                                    <InputText id="ulogn" type="text" v-model="userInfo.logname" />
                                </div>
                                <div class="field">
                                    <label for="pssword">密码</label>
                                    <Password id="pssword" v-model="userInfo.psd" toggleMask></Password>
                                </div>
                                <div class="field">
                                    <label for="mobile">手机</label>
                                    <InputText id="mobile" type="text" v-model="userInfo.mobile" />
                                </div>
                                <div class="field">
                                    <label for="needsms">是否开启短信验证</label>
                                    <Dropdown id="needsms" v-model="userInfo.needsms" :options="booldict"
                                        optionLabel="name" optionValue="code" placeholder="" />
                                </div>
                                <div class="field">
                                    <label for="storeid">平台</label>
                                    <MultiSelect id="storeid" v-model="selectedStore" :options="storeDict"
                                        optionLabel="name" optionValue="code" />
                                </div>
                                <div class="field">
                                    <label for="dept">部门</label>
                                    <TreeSelect id="dept" v-model="selectedDeptKey" :options="nodes"
                                        :loading="deptloading" @nodeExpand="onDeptNodeExpand"
                                        @nodeSelect="onUserDeptSelect" placeholder="请选择部门">
                                    </TreeSelect>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="权限信息">
                            <div class="field">
                                <Tree :value="competencenodes" :loading="competenceloading" selectionMode="checkbox"
                                    :scrollHeight="competenceHeight" @nodeSelect="onCompetenceNodeSelect"
                                    @nodeUnselect="onCompetenceNodeUnselect" v-model:selectionKeys="selectedAuthority">
                                </Tree>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
                <div class="field">
                    <Button label="保存" @click="saveUserInfo" :loading="saveloading" class="p-button-raised" />
                </div>
            </div>
            <div v-show="isShowDeptnfo" class="card p-fluid" :style="minHeight">
                <h5>基本信息</h5>
                <div class="field">
                    <label for="undept">名称</label>
                    <InputText id="undept" type="text" v-model="deptInfo.label" />
                </div>
                <div class="field">
                    <label for="deptparent">父部门</label>
                    <TreeSelect id="deptparent" v-model="selectedParentDeptKey" :options="nodes" :loading="deptloading"
                        @nodeExpand="onDeptNodeExpand" @nodeSelect="onParentDeptSelect" placeholder="请选择部门">
                    </TreeSelect>
                </div>
                <div class="field">
                    <Button label="保存" @click="saveDeptInfo" :loading="saveloading" class="p-button-raised" />
                </div>
            </div>
            <div v-show="isShowUnitinfo" class="card p-fluid" :style="minHeight">
                <div class="field">
                    <TabView v-model:activeIndex="unitactiveIndex">
                        <TabPanel header="基本信息">
                            <div class="card">
                                <div class="field">
                                    <label for="undept">名称</label>
                                    <InputText id="undept" type="text" v-model="deptInfo.label" />
                                </div>
                                <div v-if="deptInfo.connectstr!='no'" class="field">
                                    <label for="connectstr">数据库地址</label>
                                    <InputText id="connectstr" type="text" v-model="deptInfo.connectstr" />
                                </div>
                                <div v-if="deptInfo.connectstr!='no'" class="field">
                                    <label for="usercnt">用户数</label>
                                    <InputText id="usercnt" type="text" v-model="deptInfo.usercnt" />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel v-if="deptInfo.connectstr!='no'" header="平台">
                            <div class="card">
                                <Toolbar class="mb-2">
                                    <template #start>
                                        <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2"
                                            :loading="butLoading" @click="createStore" />
                                        <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2"
                                            :loading="butLoading" @click="delStore" />
                                    </template>
                                </Toolbar>
                                <DataTable :value="deptInfo.stores" v-model:selection="selectedRowData" showGridlines
                                    :scrollable="true" scrollHeight="300px" stripedRows selectionMode="single"
                                    scrollDirection="both" dataKey="id" editMode="cell"
                                    @cell-edit-complete="onCellEditComplete" class="editable-cells-table"
                                    responsiveLayout="scroll">
                                    <Column field="name" header="平台名称" key="name" :sortable="false"
                                        style="flex-grow:1; flex-basis:200px">
                                        <template #editor="{ data }">
                                            <InputText v-model="data['name']" autofocus />
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
                <div class="field">
                    <Button label="保存" @click="saveDeptInfo" :loading="saveloading" class="p-button-raised" />
                </div>
                <div v-if="deptInfo.connectstr!='no'" class="field">
                    <Button label="同步到目标库" @click="SyncInfo" :loading="saveloading" class="p-button-raised" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        ref
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from '../../components/Message';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm
            };
        },
        data() {
            return {
                corpid: sessionStorage.getItem("corpid"),
                newId: 0,
                activeIndex: 0,
                unitactiveIndex: 0,
                saveloading: false,
                butLoading: false,
                isShowUserInfo: false,
                isShowDeptnfo: false,
                isShowUnitinfo: false,
                deptloading: false,
                organizationalloading: true,
                competenceloading: true,
                nodes: ref(null),
                competencenodes: ref(null),
                selectedDeptKey: ref(null),
                selectedParentDeptKey: ref(null),
                selectedKey: ref(null),
                selectedAuthority: ref(null),
                selectedStore: ref(null),
                prenode: ref(null),
                selectedRowData: ref(null),
                sHeight: '600px',
                competenceHeight: '500px',
                minHeight: 'min-height:600px',
                storeDict: [],
                booldict: [{
                    name: "是",
                    code: "1"
                }, {
                    name: "否",
                    code: "0"
                }],
                items: [{
                        label: '部门',
                        icon: 'pi pi-fw pi-home',
                        items: [{
                                label: '新建部门',
                                icon: 'pi pi-fw pi-plus',
                                command: () => {
                                    this.createDept();
                                }
                            },
                            {
                                separator: true
                            },
                            {
                                label: '删除',
                                icon: 'pi pi-fw pi-trash',
                                command: () => {
                                    this.delDept();
                                }
                            }
                        ]
                    },
                    {
                        label: '人员',
                        icon: 'pi pi-fw pi-user',
                        items: [{
                                label: '新建',
                                icon: 'pi pi-fw pi-user-plus',
                                command: () => {
                                    this.createUser();
                                }
                            },
                            {
                                label: '删除',
                                icon: 'pi pi-fw pi-user-minus',
                                command: () => {
                                    this.delUser();
                                }
                            }
                        ]
                    }
                ],
                userInfo: {
                    id: '',
                    name: '',
                    organizationalid: '',
                    logname: '',
                    psd: '',
                    usertype: '',
                    mobile: '',
                    needsms: '',
                    selectedauth: '',
                    competences: [],
                    stores: [],
                },
                userInfoClone: {
                    id: '',
                    name: '',
                    organizationalid: '',
                    logname: '',
                    psd: '',
                    usertype: '',
                    mobile: '',
                    needsms: '',
                    selectedauth: '',
                    competences: [],
                    stores: [],
                },
                deptInfo: {
                    key: '',
                    parentid: '',
                    label: '',
                    ordernum: 0,
                    connectstr: '',
                    usercnt: ''
                },
                deptInfoClone: {
                    key: '',
                    parentid: '',
                    label: '',
                    ordernum: 0,
                    connectstr: '',
                    usercnt: ''
                }
            }
        },
        created() {
            MessageTip.initMsg();
            if (this.corpid == 1001) {
                this.items = [{
                        label: '部门',
                        icon: 'pi pi-fw pi-home',
                        items: [{
                                label: '新建公司',
                                icon: 'pi pi-fw pi-plus',
                                command: () => {
                                    this.createUnit();
                                }
                            },
                            {
                                label: '新建部门',
                                icon: 'pi pi-fw pi-plus',
                                command: () => {
                                    this.createDept();
                                }
                            },
                            {
                                separator: true
                            },
                            {
                                label: '删除',
                                icon: 'pi pi-fw pi-trash',
                                command: () => {
                                    this.delDept();
                                }
                            }
                        ]
                    },
                    {
                        label: '人员',
                        icon: 'pi pi-fw pi-user',
                        items: [{
                                label: '新建',
                                icon: 'pi pi-fw pi-user-plus',
                                command: () => {
                                    this.createUser();
                                }
                            },
                            {
                                label: '新建管理员',
                                icon: 'pi pi-fw pi-user-plus',
                                command: () => {
                                    this.createAdminUser();
                                }
                            },
                            {
                                label: '删除',
                                icon: 'pi pi-fw pi-user-minus',
                                command: () => {
                                    this.delUser();
                                }
                            }
                        ]
                    }
                ];
            }
            this.sHeight = (window.innerHeight - 300) + "px";
            this.competenceHeight = (window.innerHeight - 400) + "px";
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
            this.loadOrganizationalData();
            this.loadCompetenceData();
        },
        methods: {
            loadOrganizationalData() {
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{organizationallist{key label parentid icon data leaf ordernum connectstr usercnt stores {id name warehouseid} children {key label parentid icon data leaf ordernum connectstr usercnt}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.nodes = JSON.parse(unescape(Base64.decode(res.data))).data.organizationallist;
                        console.log(this.nodes);
                        this.organizationalloading = false;
                        if (this.nodes.length > 0) {
                            this.selectedKey = JSON.parse("{\"" + this.nodes[0].key +
                                "\":true}");
                            this.showUnitInfo(this.nodes[0]);
                        }
                    }
                });
            },
            loadCompetenceData() {
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{competencesourcelist{key label parentid icon data leaf ordernum children {key label parentid icon data leaf ordernum children {key label parentid icon data leaf ordernum children {key label parentid icon data leaf ordernum}}}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.competencenodes = JSON.parse(unescape(Base64.decode(res.data))).data
                            .competencesourcelist;
                        console.log('loadCompetenceData');
                        console.log(this.nodes);
                        this.competenceloading = false;
                    }
                });
            },
            onNodeExpand(node) {
                console.log(node);
                if (!node.leaf && !node.children) {
                    this.organizationalloading = true;
                    var organizationallistwhere = {
                        pageindex: 1,
                        pagesize: 200,
                        conditions: [{
                            name: 'parentid',
                            value: node.key,
                            operation: '=',
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{organizationallist(where:" + JSON.stringify(organizationallistwhere) +
                        "){key label parentid icon data leaf ordernum connectstr usercnt}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            var childnodes = JSON.parse(unescape(Base64.decode(res.data))).data
                                .organizationallist;
                            node.children = [];
                            for (let i = 0; i < childnodes.length; i++) {
                                node.children.push(childnodes[i]);
                            }
                            this.organizationalloading = false;
                        }
                    });
                }
            },
            onDeptNodeExpand(node) {
                if (!node.leaf && !node.children) {
                    this.deptloading = true;
                    var organizationallistwhere = {
                        pageindex: 1,
                        pagesize: 200,
                        conditions: [{
                            name: 'parentid',
                            value: node.key,
                            operation: '=',
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{organizationallist(where:" + JSON.stringify(organizationallistwhere) +
                        "){key label parentid icon data leaf ordernum connectstr usercnt}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            var childnodes = JSON.parse(unescape(Base64.decode(res.data))).data
                                .organizationallist;
                            node.children = [];
                            for (let i = 0; i < childnodes.length; i++) {
                                node.children.push(childnodes[i]);
                            }
                            this.deptloading = false;
                        }
                    });
                }
            },
            onNodeSelect(node) {
                console.log('userInfo...');
                console.log(JSON.stringify(this.userInfo).replace("userInfo", ""));
                console.log('userInfoClone...');
                console.log(JSON.stringify(this.userInfoClone).replace(
                    "userInfoClone", ""));
                console.log('deptInfo...');
                console.log(JSON.stringify(this.deptInfo).replace("deptInfo", ""));
                console.log('deptInfoClone...');
                console.log(JSON.stringify(
                    this.deptInfoClone).replace(
                    "deptInfoClone", ""));
                if (JSON.stringify(this.userInfo).replace("userInfo", "") != JSON.stringify(this.userInfoClone).replace(
                        "userInfoClone", "") || JSON.stringify(this.deptInfo).replace("deptInfo", "") != JSON.stringify(
                        this.deptInfoClone).replace(
                        "deptInfoClone", "")) {
                    this.confirm.require({
                        message: '数据已经修改，是否要保存?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '去保存',
                        rejectLabel: '不保存',
                        accept: () => {
                            setTimeout(() => {
                                if (this.prenode != null) {
                                    this.selectedKey = JSON.parse("{\"" + this.prenode.key +
                                        "\":true}");
                                } else {
                                    this.selectedKey = ref(null);
                                }
                            }, 1);
                        },
                        reject: () => {
                            this.prenode = node;
                            if (node.data == '0') {
                                this.isShowUserInfo = false;
                                this.isShowDeptnfo = false;
                                this.showUnitInfo(node);
                                this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                            }
                            if (node.data == '2') {
                                this.isShowUserInfo = false;
                                this.isShowUnitinfo = false;
                                this.showDeptInfo(node);
                                this.userInfoClone = JSON.parse(JSON.stringify(this.userInfo));
                            }
                            if (node.data == '1' || node.data == '8') {
                                this.isShowDeptnfo = false;
                                this.isShowUnitinfo = false;
                                this.showUserInfo(node.key);
                                this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                            }
                        }
                    });
                } else {
                    this.prenode = node;
                    if (node.data == '0') {
                        this.isShowUserInfo = false;
                        this.isShowDeptnfo = false;
                        this.showUnitInfo(node);
                    }
                    if (node.data == '2') {
                        this.isShowUserInfo = false;
                        this.isShowUnitinfo = false;
                        this.showDeptInfo(node);
                    }
                    if (node.data == '1' || node.data == '8') {
                        this.isShowDeptnfo = false;
                        this.isShowUnitinfo = false;
                        this.showUserInfo(node.key);
                    }
                }
            },
            onCompetenceNodeSelect(node) {
                console.log(node);
                console.log(this.selectedAuthority);
                this.userInfo.selectedauth = JSON.stringify(this.selectedAuthority);
                this.removeCompetence(node);
                this.addCompetence(node);
                /* if (node.parentid != '-1') {
                    this.userInfo.competences.push({
                        id: '',
                        functionalcategoriesid: node.parentid,
                        functionalid: node.key,
                        userid: this.userInfo.id,
                        sysid: ''
                    });
                }
                if (node.children) {
                    for (var i = 0; i < node.children.length; i++) {
                        if (node.children[i].parentid != '-1') {
                            this.userInfo.competences.push({
                                id: '',
                                functionalcategoriesid: node.children[i].parentid,
                                functionalid: node.children[i].key,
                                userid: this.userInfo.id,
                                sysid: ''
                            });
                        }
                    }
                } */
                console.log('selectedauth:' + this.userInfo.selectedauth);
            },
            onCompetenceNodeUnselect(node) {
                this.userInfo.selectedauth = JSON.stringify(this.selectedAuthority);
                console.log(this.userInfo.selectedauth);
                this.removeCompetence(node);
            },
            onUserDeptSelect(node) {
                if (node.data == '1') {
                    setTimeout(() => {
                        var userDeptId = this.userInfo.organizationalid;
                        if (userDeptId != '') {
                            this.selectedDeptKey = JSON.parse("{\"" + userDeptId + "\":true}");
                        } else {
                            this.selectedDeptKey = ref(null);
                        }
                    }, 1);
                    MessageTip.warnmsg('请选择部门');
                } else {
                    this.userInfo.organizationalid = node.key;
                }
            },
            onParentDeptSelect(node) {
                if (node.data == '1') {
                    setTimeout(() => {
                        var parentDeptId = this.deptInfo.parentid;
                        if (parentDeptId != '') {
                            this.selectedParentDeptKey = JSON.parse("{\"" + parentDeptId + "\":true}");
                        } else {
                            this.selectedParentDeptKey = ref(null);
                        }
                    }, 1);
                    MessageTip.warnmsg('请选择部门');
                } else {
                    this.deptInfo.parentid = node.key;
                }
            },
            showUserInfo(v_id) {
                if (parseInt(v_id) > 0) {
                    this.isShowUserInfo = false;
                    this.isShowDeptnfo = false;
                    this.isShowUnitinfo = false;
                    var userlistwhere = {
                        pageindex: 1,
                        pagesize: 200,
                        conditions: [{
                            name: 'id',
                            value: v_id,
                            operation: '=',
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{userlist(where:" + JSON.stringify(userlistwhere) +
                        "){id organizationalid name logname psd usertype mobile needsms selectedauth stores {id name warehouseid checked} competences {id functionalcategoriesid functionalid userid sysid}}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.userInfo = JSON.parse(unescape(Base64.decode(res.data))).data.userlist[0];
                            this.userInfoClone = JSON.parse(JSON.stringify(this.userInfo));
                            var userDeptId = this.userInfo.organizationalid;
                            this.selectedDeptKey = JSON.parse("{\"" + userDeptId + "\":true}");
                            if (this.userInfo.selectedauth != '') {
                                this.selectedAuthority = JSON.parse(this.userInfo.selectedauth);
                            } else {
                                this.selectedAuthority = null;
                            }
                            console.log(this.selectedAuthority);
                            this.storeDict = [];
                            this.selectedStore = [];
                            for (var i = 0; i < this.userInfo.stores.length; i++) {
                                this.storeDict.push({
                                    name: this.userInfo.stores[i].name,
                                    code: this.userInfo.stores[i].id
                                });
                                if (this.userInfo.stores[i].checked == 1) {
                                    this.selectedStore.push(this.userInfo.stores[i].id + '');
                                }
                            }
                            console.log("selectedStore");
                            console.log(this.selectedStore);
                            this.isShowUserInfo = true;
                            this.isShowDeptnfo = false;
                            this.isShowUnitinfo = false;
                        }
                    });
                } else {
                    this.userInfo = {
                        id: v_id,
                        name: this.prenode.label,
                        organizationalid: this.prenode.parentid,
                        logname: '',
                        psd: '',
                        usertype: this.prenode.data,
                        mobile: '',
                        needsms: '',
                        selectedauth: '',
                        competences: [],
                        stores: [],
                    };
                    this.selectedStore = [];
                    this.userInfoClone = JSON.parse(JSON.stringify(this.userInfo));
                    this.selectedAuthority = null;
                    var userDeptId = this.userInfo.organizationalid;
                    this.selectedDeptKey = JSON.parse("{\"" + userDeptId + "\":true}");
                    this.isShowUserInfo = true;
                    this.isShowDeptnfo = false;
                    this.isShowUnitinfo = false;
                }
            },
            showDeptInfo(node) {
                this.deptInfo = {
                    key: node.key,
                    label: node.label,
                    parentid: node.parentid,
                    ordernum: node.ordernum,
                    connectstr: node.connectstr,
                    usercnt: node.usercnt,
                };
                this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                this.selectedParentDeptKey = JSON.parse("{\"" + node.parentid + "\":true}");
                this.isShowDeptnfo = true;
                this.isShowUserInfo = false;
                this.isShowUnitinfo = false;
            },
            showUnitInfo(node) {
                this.deptInfo = {
                    key: node.key,
                    label: node.label,
                    parentid: node.parentid,
                    ordernum: node.ordernum,
                    connectstr: node.connectstr,
                    usercnt: node.usercnt,
                    stores: node.stores,
                };
                this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                this.selectedParentDeptKey = null;
                this.isShowUnitinfo = true;
                this.isShowUserInfo = false;
                this.isShowDeptnfo = false;
            },
            saveUserInfo() {
                if (!this.userInfo.organizationalid || parseInt(this.userInfo.organizationalid) <= 0) {
                    MessageTip.warnmsg('所属部门信息不正确');
                    console.log(this.userInfo.organizationalid);
                    return;
                }
                if (this.userInfo.logname.trim() == '') {
                    MessageTip.warnmsg('登陆名不能为空');
                    return;
                }
                if (this.userInfo.needsms.trim() == '') {
                    MessageTip.warnmsg('请选择是否开启短信验证');
                    return;
                }
                this.userInfo.stores = [];
                for (var m = 0; m < this.storeDict.length; m++) {
                    this.userInfo.stores.push({
                        id: this.storeDict[m]['code'],
                        name: this.storeDict[m]['name'],
                        checked: 0
                    });
                }
                if (this.userInfo.stores.length == 0) {
                    MessageTip.warnmsg('平台信息异常');
                    return;
                }
                console.log("selectedStore");
                console.log(this.selectedStore);
                var checkselectedStore = false;
                for (var i = 0; i < this.userInfo.stores.length; i++) {
                    this.userInfo.stores[i].checked = 0;
                    for (var j = 0; j < this.selectedStore.length; j++) {
                        if (this.userInfo.stores[i].id == this.selectedStore[j]) {
                            this.userInfo.stores[i].checked = 1;
                            checkselectedStore = true;
                            break;
                        }
                    }
                }
                if (!checkselectedStore) {
                    MessageTip.warnmsg('请选择平台');
                    return;
                }
                this.saveloading = true;
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{user(o:" + JSON.stringify(this.userInfo) +
                    "){id}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.userInfo.id = JSON.parse(unescape(Base64.decode(res.data))).data.user.id;
                        this.userInfoClone = JSON.parse(JSON.stringify(this.userInfo));
                        this.prenode.key = this.userInfo.id;
                        this.prenode.label = this.userInfo.name;
                        this.prenode.parentid = this.userInfo.organizationalid;
                        MessageTip.successmsg('保存成功');
                    }
                    this.saveloading = false;
                });
            },
            saveDeptInfo() {
                this.saveloading = true;
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{organizational(o:" + JSON.stringify(this.deptInfo) +
                    "){key}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.deptInfo.key = JSON.parse(unescape(Base64.decode(res.data))).data.organizational
                            .key;
                        this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                        this.prenode.key = this.deptInfo.key;
                        this.prenode.label = this.deptInfo.label;
                        this.prenode.parentid = this.deptInfo.parentid;
                        this.prenode.connectstr = this.deptInfo.connectstr;
                        this.prenode.usercnt = this.deptInfo.usercnt;
                        MessageTip.successmsg('保存成功');
                    }
                    this.saveloading = false;
                });
            },
            SyncInfo() {
                if (!this.deptInfo.key || parseInt(this.deptInfo.key) <= 0) {
                    MessageTip.warnmsg('请先保存数据');
                    return;
                }
                this.saveloading = true;
                requests.plat_requests.post(
                    '/calf/plat/SyncUser?appkey=' + sessionStorage.getItem("appkey"), {
                        corpid: this.deptInfo.key
                    }
                ).then(res => {
                    if (res.errcode == "0") {
                        MessageTip.successmsg('同步成功');
                    } else {
                        console.log("res---");
                        console.log(res);
                        MessageTip.errmsg('同步失败:' + res);
                    }
                    this.saveloading = false;
                });
            },
            removeCompetence(node) {
                console.log("removeCompetence--");
                console.log(node);
                console.log(this.userInfo.competences);
                for (var i = this.userInfo.competences.length - 1; i >= 0; i--) {
                    if (this.userInfo.competences[i].functionalcategoriesid == node.parentid && this.userInfo
                        .competences[i].functionalid == node.key) {
                        console.log("1--functionalcategoriesid--" + this.userInfo.competences[i]
                            .functionalcategoriesid +
                            "--functionalid--" +
                            this
                            .userInfo
                            .competences[i].functionalid);
                        this.userInfo.competences.splice(i, 1);
                    }
                }
                if (node.children) {
                    for (var j = 0; j < node.children.length; j++) {
                        this.removeCompetence(node.children[j]);
                        /*   for (var k = this.userInfo.competences.length - 1; k >= 0; k--) {
                              if (this.userInfo.competences[k].functionalcategoriesid == node.children[j].parentid && this
                                  .userInfo
                                  .competences[k].functionalid == node.children[j].key) {
                                  console.log("2--functionalcategoriesid--" + this.userInfo.competences[k]
                                      .functionalcategoriesid +
                                      "--functionalid--" +
                                      this
                                      .userInfo
                                      .competences[k].functionalid);
                                  this.userInfo.competences.splice(k, 1);
                              }
                          } */
                    }
                }
                console.log("this.userInfo.competences--");
                console.log(this.userInfo.competences);
            },
            addCompetence(node) {
                if (node.parentid != '-1') {
                    this.userInfo.competences.push({
                        id: '',
                        functionalcategoriesid: node.parentid,
                        functionalid: node.key,
                        userid: this.userInfo.id,
                        sysid: ''
                    });
                }
                if (node.children) {
                    for (var i = 0; i < node.children.length; i++) {
                        this.addCompetence(node.children[i]);
                    }
                }
            },
            createUnit() {
                var order = 0;
                if (this.nodes) {
                    order = this.nodes.length;
                    this.newId = this.newId + 1;
                    var id = this.newId * -1;
                    this.nodes.push({
                        key: "" + id,
                        label: '新公司',
                        parentid: '-1',
                        icon: 'pi pi-fw pi-home',
                        data: '0',
                        leaf: false,
                        ordernum: order + 1,
                        children: [],
                        stores: []
                    });
                }
            },
            createDept() {
                if (this.prenode) {
                    if (this.prenode.data == '1' || this.prenode.data == '8') {
                        MessageTip.warnmsg('只能在部门或公司下创建部门');
                    } else {
                        this.newId = this.newId + 1;
                        var id = this.newId * -1;
                        this.prenode.children.push({
                            key: "" + id,
                            label: '新部门',
                            parentid: "" + this.prenode.key,
                            icon: 'pi pi-fw pi-users',
                            data: '2',
                            leaf: false,
                            ordernum: this.prenode.children.length + 1,
                            children: []
                        });
                    }
                }
            },
            createUser() {
                console.log('createUser...');
                console.log(this.prenode);
                if (this.prenode) {
                    if (this.prenode.data == '1' || this.prenode.data == '8') {
                        MessageTip.warnmsg('只能在部门或公司下创建人员');
                    } else {
                        var storeobjs = this.findunitstores(this.prenode.key);
                        console.log('findunitstores...end...');
                        console.log(storeobjs);
                        this.storeDict = [];
                        this.selectedStore = [];
                        for (var i = 0; i < storeobjs.length; i++) {
                            this.storeDict.push({
                                name: storeobjs[i].name,
                                code: storeobjs[i].id
                            });
                            storeobjs[i]['checked'] = 0;
                        }
                        this.newId = this.newId + 1;
                        var id = this.newId * -1;
                        this.prenode.children.push({
                            key: "" + id,
                            label: '新人员',
                            parentid: "" + this.prenode.key,
                            icon: 'pi pi-fw pi-user',
                            data: '1',
                            leaf: true,
                            ordernum: this.prenode.children.length + 1,
                            stores: storeobjs
                        });
                    }
                }
            },
            findunitstores(v_id) {
                console.log('findunitstores...');
                console.log(v_id);
                console.log(this.nodes);
                for (var i = 0; i < this.nodes.length; i++) {
                    if (this.nodes[i].key == v_id) {
                        return this.nodes[i].stores;
                    } else {
                        if (this.nodes[i].children && this.nodes[i].children.length > 0) {
                            var storeobjs = this.findunitstores2(v_id, this.nodes[i], this.nodes[i].stores);
                            if (storeobjs) return storeobjs;
                        }
                    }
                }
                return null;
            },
            findunitstores2(v_id, v_parentnode, v_stores) {
                console.log('findunitstores2...');
                console.log(v_parentnode);
                console.log(v_stores);
                for (var i = 0; i < v_parentnode.children.length; i++) {
                    if (v_parentnode.children[i].key == v_id) {
                        return v_stores;
                    } else {
                        if (v_parentnode.children[i].children && v_parentnode.children[i].children.length > 0) {
                            var storeobjs = this.findunitstores2(v_id, v_parentnode.children[i], v_stores);
                            if (storeobjs) return storeobjs;
                        }
                    }
                }
                return null;
            },
            createAdminUser() {
                if (this.prenode) {
                    if (this.prenode.data == '1' || this.prenode.data == '8' || this.prenode.data == '2') {
                        MessageTip.warnmsg('只能在公司下创建管理员');
                    } else {
                        var storeobjs = this.findunitstores(this.prenode.key);
                        console.log('findunitstores...end...');
                        console.log(storeobjs);
                        this.storeDict = [];
                        this.selectedStore = [];
                        for (var i = 0; i < storeobjs.length; i++) {
                            this.storeDict.push({
                                name: storeobjs[i].name,
                                code: storeobjs[i].id
                            });
                            storeobjs[i]['checked'] = 0;
                        }
                        this.newId = this.newId + 1;
                        var id = this.newId * -1;
                        this.prenode.children.push({
                            key: "" + id,
                            label: '管理员',
                            parentid: "" + this.prenode.key,
                            icon: 'pi pi-fw pi-user',
                            data: '8',
                            leaf: true,
                            ordernum: this.prenode.children.length + 1,
                            stores: storeobjs
                        });
                    }
                }
            },
            delDept() {
                if (this.prenode) {
                    if (this.prenode.data == '1') {
                        MessageTip.warnmsg('请选择部门或单位');
                    } else {
                        this.confirm.require({
                            message: '确认要删除' + this.prenode.label + '?',
                            header: '确认',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: '删除',
                            rejectLabel: '放弃',
                            accept: () => {
                                requests.graphql_requests.post(
                                    '/graphql/?appkey=' + sessionStorage.getItem(
                                        "appkey"),
                                    "delete{organizational(o:" + JSON.stringify(this.deptInfo) +
                                    "){key}}"
                                ).then(res => {
                                    if (res.errcode == "0") {
                                        this.removeNodeFromeTree(this.prenode.key);
                                        this.clearCurrentDeptInfo();
                                        MessageTip.successmsg('删除成功');
                                    }
                                });
                            },
                            reject: () => {

                            }
                        });
                    }
                }
            },
            delUser() {
                if (this.prenode) {
                    if (this.prenode.data == '2') {
                        MessageTip.warnmsg('请选择人员');
                    } else {
                        this.confirm.require({
                            message: '确认要删除人员' + this.prenode.label + '?',
                            header: '确认',
                            icon: 'pi pi-exclamation-triangle',
                            acceptLabel: '删除',
                            rejectLabel: '放弃',
                            accept: () => {
                                requests.graphql_requests.post(
                                    '/graphql/?appkey=' + sessionStorage.getItem(
                                        "appkey"),
                                    "delete{user(o:" + JSON.stringify(this.userInfo) +
                                    "){id}}"
                                ).then(res => {
                                    if (res.errcode == "0") {
                                        this.removeNodeFromeTree(this.prenode.key);
                                        this.clearCurrentUserInfo();
                                        MessageTip.successmsg('删除成功');
                                    }
                                });
                            },
                            reject: () => {}
                        });
                    }
                }
            },
            removeNodeFromeTree(v_id) {
                for (var i = 0; i < this.nodes.length; i++) {
                    if (this.nodes[i].key == v_id) {
                        this.nodes.splice(i, 1);
                        break;
                    } else {
                        this.removeNodeFromeChildTree(v_id, this.nodes[i]);
                    }
                }
            },
            removeNodeFromeChildTree(v_id, v_parentNode) {
                if (v_parentNode.children) {
                    for (var i = 0; i < v_parentNode.children.length; i++) {
                        if (v_parentNode.children[i].key == v_id) {
                            v_parentNode.children.splice(i, 1);
                            break;
                        } else {
                            this.removeNodeFromeChildTree(v_id, v_parentNode.children[i]);
                        }
                    }
                }
            },
            clearCurrentUserInfo() {
                this.userInfo = {
                    id: '',
                    name: '',
                    organizationalid: '',
                    logname: '',
                    psd: '',
                    usertype: '',
                    mobile: '',
                    needsms: '',
                    selectedauth: '',
                    competences: [],
                    stores: []
                };
                this.userInfoClone = JSON.parse(JSON.stringify(this.userInfo));
                this.prenode = null;
                this.selectedAuthority = null;
                this.selectedParentDeptKey = null;
                this.selectedDeptKey = null;
                this.selectedKey = null;
            },
            clearCurrentDeptInfo() {
                this.deptInfo = {
                    key: '',
                    parentid: '',
                    label: '',
                    ordernum: 0,
                    connectstr: '',
                    usercnt: ''
                };
                this.deptInfoClone = JSON.parse(JSON.stringify(this.deptInfo));
                this.prenode = null;
                this.selectedAuthority = null;
                this.selectedParentDeptKey = null;
                this.selectedDeptKey = null;
                this.selectedKey = null;
            },
            createStore() {
                if (this.deptInfo.stores) {
                    this.newId = this.newId + 1;
                    var id = this.newId * -1;
                    this.deptInfo.stores.push({
                        id: '' + id,
                        name: '',
                        warehouseid: ''
                    });
                }
            },
            delStore() {
                if (this.deptInfo.stores) {
                    console.log(this.selectedRowData);
                    for (var i = 0; i < this.deptInfo.stores.length; i++) {
                        if (this.deptInfo.stores[i]["id"] == this.selectedRowData["id"]) {
                            this.deptInfo.stores.splice(i, 1);
                            console.log('del--' + i);
                            break;
                        }
                    }
                }
            },
            onCellEditComplete(event) {
                let {
                    data,
                    newValue,
                    field
                } = event;
                data[field] = newValue;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        }
    }
</script>