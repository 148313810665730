// import axios from 'axios';
import requests from './newaxios';
import * as Base64 from 'js-base64';

export default class ConfigService {
    getCustom(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'customConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
        // return axios.get('data/mycustomConfig.json?appkey=' + appkey).then(res => res.data);
    }
    getfactoryEdit(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'factoryEditConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfactory(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'factoryConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getproduct(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'productConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getquotation(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'quotationConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getpurchase(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'purchaseConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getshipment(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'shipmentConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getputinstorage(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'putinstorageConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getorder(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'orderConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getoutstock(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'outstockConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getclaimSettlement(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'claimSettlementConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getinventoryoutstock(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'inventoryoutstockConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getinventoryinstorage(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'inventoryinstorageConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getstorecostrule(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'storecostruleConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getproductsample(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'productSampleConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfinancialitem(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'financialitemConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfinancialpayaccount(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'financialpayaccountConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfinancialcollectionaccount(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'financialcollectionaccountConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfinancialbox(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'financialboxConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getfinancialexpensepay(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'financialexpensepayConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getproductCost(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'productCostConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getinspectionmain(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'inspectionmainConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getcostverification(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'costverificationConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getpurchasefollowup(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'purchasefollowupConfig'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getpurchasefollowup1(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'purchasefollowupConfig1'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
    getpurchasefollowup2(appkey) {
        return requests.plat_requests.post(
            '../calf/plat/GetPageConfig?appkey=' + appkey, {
                ConfigName: 'purchasefollowupConfig2'
            }
        ).then(res => JSON.parse(unescape(Base64.decode(res.data))));
    }
}