import './DateEx'
import requests from '../service/newaxios';
import * as Base64 from 'js-base64';

import {
    FilterMatchMode,
    FilterOperator
} from 'primevue/api';

const CommonJs = {
    GetnodeStructure: () => {
        var obj = {
            "properties": {
                "baseinfo": {
                    "bindpage": "",
                    "guid": "",
                    "sendbutname": ""
                },
                "operator": {
                    "operatortype": 0,
                    "operatornumtype": 0,
                    "fixuser": [],
                    "approvalsetmapp": "",
                    "approvalsetcategory": 0,
                    "approvalsetlevel": 0,
                    "uprecursive": 0,
                    "inheritance": [],
                    "dynamicsql": []
                },
                "wait": {
                    "waitrelation": 0,
                    "ishidden": 0,
                    "staydays": 0,
                    "stayhours": 0,
                    "stayminutes": 0,
                    "isincludeholidays": 0,
                    "waitstateid": []
                },
                "sendinfo": {
                    "sendtype": 0,
                    "ismanualintervention": 0,
                    "forcefinish": 0,
                    "staticinfo": {
                        "sendselectrelation": 0,
                        "isatu": 0
                    },
                    "ctrlinfo": []
                },
                "notifyinfo": []
            }
        };
        return obj;
    },
    GetCurrentDate: () => {
        return new Date().format("yyyy-MM-dd");
    },
    GetIntId: () => {
        var randnum = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
        var myDate = new Date();
        console.log('GetIntId...');
        console.log(myDate.getFullYear() + '+' + myDate.getMonth() + '+' + myDate.getDate() + '+' + myDate.getHours() + '+' + myDate.getMinutes() + '+' + myDate.getSeconds() + '+' + myDate.getMilliseconds() + '+' + randnum);
        var newid = myDate.getFullYear() + myDate.getMonth() + myDate.getDate() + myDate.getHours() + myDate.getMinutes() + myDate.getSeconds() + myDate.getMilliseconds() + randnum;
        console.log(newid);
        return newid;
    },
    vsOptionFun: (v_pagesize, v_totalRecords) => {
        if (v_pagesize >= 300 && (v_totalRecords >= 300 || v_totalRecords == -1)) {
            return {
                itemSize: 50
            };
        }
        return {};
    },
    SaveDefaultPageSize: (v_pagesize, v_callerobj) => {
        console.log('SaveDefaultPageSize...');
        console.log(v_callerobj);
        var pagesizeval = sessionStorage.getItem("pagesize");
        if (!pagesizeval || pagesizeval != v_pagesize) {
            sessionStorage.setItem("pagesize", v_pagesize);
            v_callerobj.$cookies.set('pagesize', v_pagesize, 6 * 365 * 24 * 60 * 60);
            console.log('cookies....');
            console.log(v_callerobj.$cookies);
            console.log('pagesize....');
            console.log(v_callerobj.$cookies.get('pagesize'));
        }
    },
    GetDefaultPageSize: (v_callerobj) => {
        console.log('GetDefaultPageSize...');
        console.log(v_callerobj);
        var pagesizeval = sessionStorage.getItem("pagesize");
        if (!pagesizeval) {
            console.log('GetDefaultPageSize...cookies...');
            pagesizeval = v_callerobj.$cookies.get('pagesize');
        }
        if (!pagesizeval) {
            console.log('GetDefaultPageSize...const...');
            pagesizeval = 50;
        }
        console.log('pagesizeval....');
        console.log(pagesizeval);
        return Number(pagesizeval);
    },
    formatDate: (v_type, value) => {
        if (v_type == 'DateString') {
            if (value == '' || value == null || value == 'Invalid Date') {
                console.log('formatDate1 null');
                return '';
            } else {
                return new Date(value).format("yyyy-MM-dd");
            }
        }
        if (v_type == 'DateTimeString') {
            if (value == '' || value == null || value == 'Invalid Date') {
                console.log('formatDate2 null');
                return '';
            } else {
                return new Date(value).format("yyyy-MM-dd hh:mm");
            }
        }
    }, //业务最多精确到分，也就是两位小数，最少就是取整，不留小数
    moneySwitch: (v_money, precision) => { //precision是需要精确的位数，如百分位就是2
        return Math.round(v_money * Math.pow(10, precision)) / Math.pow(10, precision);
        // var result = 0;
        // //先进行一个千分位的四舍五入，保证3.0999这种情况在保留一位小数的时候能是对的，这一位可以这么做没什么问题
        // var money = parseFloat(v_money).toFixed(3);
        // try {
        //     var int_part = money.split(".")[0], //小数点前的整数
        //         point_num = money.split(".")[1], //取小数点后面的小数
        //         precision_num = point_num[3 - precision];
        //     if (precision_num > 4) { //五入的情况
        //         if (precision == 1) {
        //             point_num = parseInt(point_num) + 10 + "";
        //             if (point_num.length > 3) { //说明往整数位进1
        //                 int_part = parseInt(int_part) + 1 + "";
        //                 point_num = point_num[1] + point_num[2];
        //             } else {
        //                 point_num = point_num[0] + point_num[1];
        //             }
        //             result = parseFloat(int_part + "." + point_num);
        //         } else if (precision == 2) {
        //             point_num = parseInt(point_num) + 100 + "";
        //             if (point_num.length > 3) { //说明往整数位进1
        //                 int_part = parseInt(int_part) + 1 + "";
        //                 point_num = point_num[1];
        //             } else {
        //                 point_num = point_num[0];
        //             }
        //             result = parseFloat(int_part + "." + point_num);
        //         } else if (precision == 3) {
        //             int_part = parseInt(int_part) + 1 + "";
        //             point_num = 0;
        //         }
        //         result = parseFloat(int_part + "." + point_num);
        //     } else { //四舍的情况
        //         if (precision == 1) {
        //             point_num = point_num[0] + point_num[1];
        //         } else if (precision == 2) {
        //             point_num = point_num[0];
        //         } else if (precision == 3) {
        //             point_num = 0;
        //         }
        //         result = parseFloat(int_part + "." + point_num);
        //     }
        // } catch (e) {
        //     return parseFloat(money).toFixed(2); //如果过程中有出错就tofixed代替为解决
        // }
        // return result;
    },
    getUrlParam: (name) => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    loadDictFromCache: (v_categories, v_dictIdentify, v_currencyDict, v_loadDictFromCacheCallFun) => {
        var dictCacheData = sessionStorage.getItem(v_dictIdentify);
        if (dictCacheData && dictCacheData.length > 0) {
            v_currencyDict = JSON.parse(dictCacheData);
            v_loadDictFromCacheCallFun(v_currencyDict);
        } else {
            var dictlistwhere = {
                pageindex: 1,
                pagesize: 50,
                conditions: [{
                    name: 'Categories',
                    value: v_categories,
                    operation: '='
                }]
            };
            var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                '){name details {name code other ename}}}';
            requests.graphql_requests.post(
                '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                graphql
            ).then(res => {
                if (res.errcode == "0") {
                    v_currencyDict = [];
                    var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                    console.log(dataObj);
                    var dictData = dataObj['dictionarylist'];
                    for (var k1 = 0; k1 < dictData[0].details.length; k1++) {
                        v_currencyDict.push({
                            name: dictData[0].details[k1].name,
                            code: dictData[0].details[k1].code,
                            other: dictData[0].details[k1].other,
                            ename: dictData[0].details[k1].ename,
                            comment: '',
                        });
                    }
                    sessionStorage.setItem(v_dictIdentify, JSON.stringify(v_currencyDict));
                    v_loadDictFromCacheCallFun(v_currencyDict);
                }
            });
        }
    },
    saveQueryPlan: (v_queryPlanObj, v_nodes, v_MessageTip, v_butloadinngFun, v_closeQueryPlanFun, v_saveQueryPlanCallFun) => {
        requests.graphql_requests.post(
            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
            "mutation{queryplan(o:" + JSON.stringify(v_queryPlanObj) +
            "){id name parentid queryconfig}}"
        ).then(res => {
            v_butloadinngFun(false);
            if (res.errcode == "0") {
                var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                if (parseInt(v_queryPlanObj.id) > 0) {
                    v_queryPlanObj = jsonData.data.queryplan;
                    var isFind = false;
                    for (var i = 0; i < v_nodes.length; i++) {
                        for (var j = 0; j < v_nodes[i].children.length; j++) {
                            if (v_nodes[i].children[j].key == v_queryPlanObj.id) {
                                isFind = true;
                                v_nodes[i].children[j].data = v_queryPlanObj.queryconfig;
                                break;
                            }
                        }
                        if (isFind) {
                            break;
                        }
                    }
                } else {
                    v_queryPlanObj = jsonData.data.queryplan;
                    for (var k = 0; k < v_nodes.length; k++) {
                        if (v_nodes[k].key == v_queryPlanObj.parentid) {
                            v_nodes[k].children.push({
                                key: v_queryPlanObj.id,
                                label: v_queryPlanObj.name,
                                data: v_queryPlanObj.queryconfig,
                                nodetype: '2',
                                parentid: v_queryPlanObj.parentid,
                                parenturl: v_nodes[k].data,
                                icon: 'pi pi-fw pi-search',
                                leaf: true
                            });
                            break;
                        }
                    }
                }
                v_saveQueryPlanCallFun(v_queryPlanObj, v_nodes);
                v_MessageTip.successmsg('保存成功');
            } else {
                v_MessageTip.warnmsg('保存失败');
            }
            v_closeQueryPlanFun(false);
        });
    },
    initFilters: (v_filters1, v_listcolumns, v_initFiltersCallFun) => {
        v_filters1 = {
            'global': {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };
        for (var i = 0; i < v_listcolumns.length; i++) {
            v_filters1[v_listcolumns[i]['field']] = {
                operator: FilterOperator.AND,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                }]
            };
            if (v_listcolumns[i]['istime']) {
                v_filters1[v_listcolumns[i]['field']] = {
                    operator: FilterOperator.AND,
                    constraints: [{
                        value: null,
                        matchMode: FilterMatchMode.DATE_BEFORE
                    }, {
                        value: null,
                        matchMode: FilterMatchMode.DATE_AFTER
                    }]
                };
            }
            if (v_listcolumns[i]['isnum']) {
                v_filters1[v_listcolumns[i]['field']] = {
                    operator: FilterOperator.AND,
                    constraints: [{
                        value: null,
                        matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    }, {
                        value: null,
                        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    }]
                };
            }
        }
        v_initFiltersCallFun(v_filters1);
    },
    loadMenuTree: (v_parentid, v_currentMenuId, v_route, v_loadDataFun, v_setlistTitleFun, v_loadMenuTreeCallFun, v_otherconditions) => {
        var listwhere = {
            pageindex: 1,
            pagesize: 50,
            conditions: [{
                name: 'parentid',
                value: v_parentid,
                operation: '='
            }]
        };
        if (v_otherconditions) {
            for (var i2 = 0; i2 < v_otherconditions.length; i2++) {
                listwhere.conditions.push(v_otherconditions[i2]);
            }
        }
        var nodes = {};
        var selectedKey = {};
        var expandedKey = {};
        var queryPlanObj = {};
        var filters = {};
        selectedKey[v_currentMenuId] = true;
        expandedKey[v_currentMenuId] = true;
        requests.graphql_requests.post(
            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
            "{usertreemenulist(where:" + JSON.stringify(listwhere) +
            "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
        ).then(res => {
            if (res.errcode == "0") {
                var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                nodes = jsonData.data.usertreemenulist;
                if (v_route.query.nodeid && v_route.query.nodeid != '' && nodes) {
                    selectedKey = {};
                    selectedKey[v_route.query.nodeid] = true;
                    expandedKey = {};
                    expandedKey[v_currentMenuId] = true;
                    var isFind = false;
                    for (var i = 0; i < nodes.length; i++) {
                        for (var j = 0; j < nodes[i].children.length; j++) {
                            if (nodes[i].children[j].key == v_route.query.nodeid) {
                                isFind = true;
                                queryPlanObj = {
                                    name: nodes[i].children[j].label,
                                    id: nodes[i].children[j].key,
                                    parentid: v_currentMenuId,
                                    queryconfig: nodes[i].children[j].data,
                                };
                                if (queryPlanObj.queryconfig && queryPlanObj.queryconfig !=
                                    '') {
                                    filters = JSON.parse(queryPlanObj.queryconfig);
                                }
                                v_setlistTitleFun(nodes[i].children[j].label);
                                break;
                            }
                        }
                        if (isFind) {
                            break;
                        }
                    }
                }
                v_loadMenuTreeCallFun(nodes, selectedKey, expandedKey, queryPlanObj, filters);
                v_loadDataFun(1);
                console.log('loadMenuTree...');
                console.log(nodes);
            }
        });
    },
    onNodeSelect: (node, v_selectedKey, v_currentMenuId, v_currentMenuName, v_loadDataFun, v_setlistTitleFun, v_setQueryPlanFun, v_setFiltersCallFun, v_clearFilterFun) => {
        console.log(node);
        setTimeout(() => {
            var currentKey = '';
            if (v_selectedKey) {
                for (var key in v_selectedKey) {
                    currentKey = key;
                    break;
                }
            }
            var queryPlanObj = {};
            if (node.nodetype == '1') {
                queryPlanObj = {
                    name: '',
                    id: '-1',
                    parentid: v_currentMenuId,
                    queryconfig: '',
                };
                console.log('node.key:' + node.key);
                console.log('selectedKey:');
                console.log(v_selectedKey);
                console.log('currentKey:' + currentKey);
                if (node.key != v_currentMenuId) {
                    window.location.href = node.data;
                } else {
                    v_clearFilterFun();
                    v_setlistTitleFun(v_currentMenuName);
                }
                v_setQueryPlanFun(queryPlanObj);
            }
            if (node.nodetype == '2') {
                if (v_currentMenuId != node.parentid) {
                    window.location.href = node.parenturl + "?nodeid=" + node.key;
                } else {
                    queryPlanObj = {
                        name: node.label,
                        id: node.key,
                        parentid: v_currentMenuId,
                        queryconfig: node.data,
                    };
                    if (queryPlanObj.queryconfig && queryPlanObj.queryconfig != '') {
                        console.log('queryconfig:');
                        console.log(queryPlanObj.queryconfig);
                        v_setFiltersCallFun(JSON.parse(queryPlanObj.queryconfig));
                        v_loadDataFun(1);
                        v_setlistTitleFun(node.label);
                    }
                }
                v_setQueryPlanFun(queryPlanObj);
            }
        }, 20);
    },
    selfmaximize() {
        setTimeout(() => {
            console.log('selfmaximize.....');
            var mainDialogEl = document.getElementById("mainDialog");
            var classVal = mainDialogEl.getAttribute("class");
            console.log(classVal);
            var matchArray = classVal.match(/p-dialog-maximized/g);
            console.log(matchArray);
            if (matchArray && matchArray.length > 1) {
                console.log('remove....');
                mainDialogEl.setAttribute("class", classVal.replace("p-dialog-maximized", "").replace(
                    "p-dialog-maximized", "").replace("p-dialog-maximized", ""));
            }
        }, 50);
    },
    userOwerAppId(v_usermoduleauth, v_appid) {
        for (var i = 0; i < v_usermoduleauth.length; i++) {
            if (v_usermoduleauth[i].appid.toLowerCase() == v_appid.toLowerCase() && v_usermoduleauth[i].own) {
                return true;
            }
        }
        return false;
    }
};

export default CommonJs;