<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ScrollPanel :style="stylecs" class="custombar1">
        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">订单</span>
                            <div class="text-900 font-medium text-xl">{{this.orders}}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">最近14天 </span>
                    <span class="text-500">最新订单数</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">采购</span>
                            <div class="text-900 font-medium text-xl">{{this.purchasemoney}}(￥)</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">最近14天 </span>
                    <span class="text-500">采购总金额</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">客户</span>
                            <div class="text-900 font-medium text-xl">{{this.customcnt}}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">最近一月 </span>
                    <span class="text-500">新注册的客户数量</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">库存</span>
                            <div class="text-900 font-medium text-xl">{{this.stockmoney}}(￥)</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-comment text-purple-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">当前 </span>
                    <span class="text-500">库存总金额</span>
                </div>
            </div>

            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5>我的任务</h5>
                    <DataTable :value="tasklist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
                        :scrollable="true" stripedRows :loading="loading" responsiveLayout="scroll"
                        scrollDirection="both" :paginator="true" :rows="5" :totalRecords="totalRecords" :first="first"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                        @page="taskPage($event)">
                        <Column field="subject" header="标题" style="flex-grow:1; flex-basis:300px" frozen>
                            <template #body="slotProps">
                                <Button :label="slotProps.data.subject" @click="opendetail(slotProps.data)"
                                    class="p-button-link" />
                            </template>
                        </Column>
                        <Column field="statename" header="环节" style="flex-grow:1; flex-basis:200px"></Column>
                        <Column field="flowname" header="流程" style="flex-grow:1; flex-basis:150px"></Column>
                        <Column field="createtime" header="创建时间" style="flex-grow:1; flex-basis:160px"></Column>
                        <Column field="creater" header="创建人" style="flex-grow:1; flex-basis:150px"></Column>
                    </DataTable>
                </div>
            </div>

            <div class="col-12 xl:col-6">
                <div class="card">
                    <h5>Top 产品</h5>
                    <DataTable :value="products" :rows="5" :paginator="true" responsiveLayout="scroll">
                        <Column style="width:15%">
                            <template #header>
                                图片
                            </template>
                            <template #body="slotProps">
                                <Image :src="getFilePatch(slotProps.data.url)" width="50" class="shadow-2" preview />
                            </template>
                        </Column>
                        <Column field="name" header="名称" style="width:35%">
                            <template #body="slotProps">
                                <label class="oneline"
                                    v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}
                                </label>
                            </template>
                        </Column>
                        <Column field="mpn" header="MPN" style="width:25%">
                        </Column>
                        <Column field="price" header="单价" style="width:25%">
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.price)}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="card">
                    <div class="flex justify-content-between align-items-center mb-5">
                        <h5>最近半年订单数量</h5>
                    </div>
                    <ul class="list-none p-0 m-0">
                        <Chart type="bar" :data="orderStatData" :options="orderStatOptions" />
                    </ul>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div class="card">
                    <h5>近半年销售额</h5>
                    <Chart type="line" :data="salesData" />
                </div>
                <div class="card">
                    <h5>近一月出库Top5</h5>
                    <div style="width:310px;height:310px;margin:auto;">
                        <Chart type="doughnut" :data="stockStatData" :options="stockStatOptions" />
                    </div>
                </div>
            </div>
        </div>
    </ScrollPanel>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :childNewDataConfig="childNewDataConfig" :dropOtherConfig="dropOtherConfig"
                :childTableFooter="childTableFooter" :loadDataAfterMethod="loadDataAfter"
                :CellEditCompleteMethod="cellEditComplete" :CellEditInit="cellEditInit"
                :inputdropSelectAfterMethod="inputdropSelectAfter" :importAddColsMethod="importAddCols"
                :importAddQueryConditionMethod="importAddQueryCondition" :childExpandePageConfig="childExpandeConfig"
                :childCreateBeforeMethod="childCreateBefore" :CellEditCompleteExpandeMethod="cellEditCompleteExpande"
                :pageJsonConfig="pageJsonConfig" :openChildDialogMethod="openinspectionfileDialog"
                :isReadOnly="false" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button :label="editHistoryButTxt" icon="pi pi-tags" class="ml-auto" @click="showEditHistory" />
                <Button label="关闭" icon="pi pi-power-off" @click="closeMaximizable" />
                <Button label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="验货文件" @hide="closeInspectionFile" v-model:visible="displayInspectionFile" :style="{width: '40vw'}"
        :modal="true">
        <Toolbar v-if="!readOnly && !elementAuthority('childs.inspectionfile')" class="mb-2">
            <template #start>
                <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                    @click="createInspectionFiles2()" />
                <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                    @click="removeInspectionFiles2()" />
            </template>
        </Toolbar>
        <DataTable :value="inspectionfiledata['inspectionfile']" v-model:selection="selectedInspectionFileRow"
            dataKey="id" :metaKeySelection="false" scrollHeight="300px" selectionMode="multiple" scrollDirection="both"
            editMode="cell" @row-edit-save="inspectionfileonRowEditSave2($event,slotProps.data.rows)"
            @cell-edit-complete="inspectionfilecellEditComplete2($event)" class="editable-cells-table"
            responsiveLayout="scroll">
            <Column v-if="!readOnly && !elementAuthority('childs.inspectionfile')" selectionMode="multiple"
                headerStyle="width: 3em">
            </Column>
            <Column field="filename" header="文件">
                <template v-if="!readOnly && !elementAuthority('childs.inspectionfile')" #editor="{ data }">
                    <UploadInput v-model:filePatch="data['filepatch']" v-model:fileName="data['filename']"
                        fileType="" />
                </template>
                <template #body="slotProps">
                    <a :href="getFilePatch(slotProps.data.filepatch)" target="_blank">{{slotProps.data.filename}}</a>
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="确认" icon="pi pi-power-off" class="ml-auto" @click="closeInspectionFile" />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 130px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import CommonJs from '../js/Common.js';
    import FactoryInfoBoxJs from '../js/FactoryInfoBox.js';
    import QuotationBoxJs from '../js/QuotationBox';
    import PurchaseBoxJs from '../js/PurchaseBox';
    import ShipmentBoxJs from '../js/ShipmentBox';
    import PutInStorageBoxJs from '../js/PutInStorageBox';
    import ExpensePayJs from '../js/ExpensePay.js';
    import Image from 'primevue/image';
    import MessageTip from './Message';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import NewUniversalPage from '../components/NewUniversalPage.vue';
    import FlowHistory from '../components/FlowHistory.vue';
    import ConfigService from '../service/ConfigService';
    import UploadInput from '../components/UploadInput.vue';
    export default {
        data() {
            return {
                editHistoryButTxt: '显示修改痕迹',
                stylecs: 'width: 100%; height: 600px',
                products: [],
                selectedInspectionFileRow: [],
                salesData: {
                    labels: [],
                    datasets: [{
                        label: '销售额(￥)',
                        data: [],
                        fill: false,
                        backgroundColor: '#00bb7e',
                        borderColor: '#00bb7e',
                        tension: 0.4
                    }]
                },
                items: [{
                        label: 'Add New',
                        icon: 'pi pi-fw pi-plus'
                    },
                    {
                        label: 'Remove',
                        icon: 'pi pi-fw pi-minus'
                    }
                ],
                orderStatOptions: {
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        },
                        y: {
                            ticks: {
                                color: '#495057'
                            },
                            grid: {
                                color: '#ebedef'
                            }
                        }
                    }
                },
                orderStatData: {
                    labels: [],
                    datasets: [{
                        label: '订单数量',
                        backgroundColor: '#42A5F5',
                        data: []
                    }]
                },
                stockStatOptions: {
                    plugins: {
                        legend: {
                            labels: {
                                color: '#495057'
                            }
                        }
                    }
                },
                stockStatData: {
                    labels: [],
                    datasets: [{
                        data: [],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FFAE56", "#36B2EB"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FFAE56", "#36B2EB"]
                    }]
                },
                inspectionfiledata: {
                    "inspectionfile": []
                },
                inspectionfiledeldata: [],
                displayInspectionFile: false,
                pageJsonConfig: {},
                orders: 0,
                purchasemoney: 0,
                customcnt: 0,
                stockmoney: 0,
                selectedData: ref(),
                currentRow: null,
                flowhistoryJson: null,
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                recordsubject: '',
                currentState: '',
                totalRecords: 0,
                first: 0,
                tasklist: [],
                currencyDict: [],
                childNewDataConfig: {},
                dropOtherConfig: {},
                childTableFooter: {},
                childExpandeConfig: {},
                volumeratio: 100000,
            }
        },
        configService: null,
        created() {
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.stylecs = 'width: 100%; height: ' + (window.innerHeight - 80) + 'px';
            this.loadData();
            CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this.setloadDictFromCache);
            this.freshList();
        },
        mounted() {

        },
        methods: {
            getNewId() {
                return this.$refs.universalpage.getNewId();
            },
            elementAuthority(v_name) {
                var authorityobj = this.$refs.universalpage.getelementAuthority();
                console.log('elementAuthority....');
                console.log(authorityobj);
                return authorityobj[v_name];
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            loadData() {
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                    '/calf/plat/crmhome?appkey=' + sessionStorage.getItem("appkey"), {}).then(
                    res => {
                        console.log(res);
                        if (res.data.errcode == "0") {
                            var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                            console.log('loadData:');
                            console.log(retObj);
                            this.orders = retObj.orders;
                            this.purchasemoney = retObj.purchasemoney;
                            this.customcnt = retObj.customcnt;
                            this.stockmoney = retObj.stockmoney;
                            this.products = retObj.products;
                            this.orderStatData.labels = retObj.order.labels;
                            this.orderStatData.datasets[0].data = retObj.order.data;
                            this.stockStatData.labels = retObj.stock.labels;
                            this.stockStatData.datasets[0].data = retObj.stock.data;
                            this.salesData.labels = retObj.sales.labels;
                            this.salesData.datasets[0].data = retObj.sales.data;
                            console.log(this.salesData);
                        } else {
                            if (res.data.err)
                                MessageTip.warnmsg(res.data.err);
                            else
                                MessageTip.warnmsg(res.data);
                        }
                    });
            },
            formatCurrency(value) {
                return value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            taskPage(event) {
                var page = event.page;
                console.log(page);
                this.loadTaskData(page + 1);
            },
            loadTaskData(v_page) {
                this.loading = true;
                var taskboxlistwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'sysid',
                        value: '',
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{taskboxlist(where:" + JSON.stringify(taskboxlistwhere) +
                    "){id workflowid rootworkflowid boxid flowid stateid sysid isopen subject statename flowname bindpage flowguid createtime creater}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.tasklist = jsonData.data.taskboxlist;
                        console.log(this.tasklist);
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            opendetail(v_row) {
                this.editHistoryButTxt = '显示修改痕迹';
                this.currentRow = v_row;
                this.recordsubject = v_row.subject;
                this.currentState = '流程信息-当前环节:' + v_row.statename;
                if (v_row.bindpage != '') {
                    this.initDetail(v_row.bindpage)
                } else {
                    MessageTip.warnmsg('类型名称不能为空');
                }
            },
            freshList() {
                this.loadTaskData(1);
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                this.butLoading = true;
                if (this.currentRow['bindpage'] == 'shipment') {
                    this.freshcalculate_shipment();
                }
                if (this.currentRow['bindpage'] == 'putinstorage') {
                    this.freshcalculate_putinstorage();
                }
                this.$refs.universalpage.saveInfo();
                this.editHistoryButTxt = '显示修改痕迹';
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            initDetail(v_bindpage) {
                console.log('initDetail...' + v_bindpage);
                if (v_bindpage == 'purchase') {
                    this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData1 = PurchaseBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig2'
                        ];
                        this.childNewDataConfig = intData1.childNewDataConfig;
                        this.dropOtherConfig = intData1.dropOtherConfig;
                        this.childTableFooter = intData1.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'inspectionmain') {
                    this.configService.getinspectionmain(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'shipment') {
                    this.configService.getshipment(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData2 = ShipmentBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        if (data.page['region_3']['left']['element'][0]['volumeratio']) {
                            this.volumeratio = data.page['region_3']['left']['element'][0]['volumeratio'];
                        }
                        this.childNewDataConfig = intData2.childNewDataConfig;
                        this.dropOtherConfig = {
                            purchasecurrency: 'purchaseexchangerate',
                            purchasecurrencyfun: (childelements, v_val, v_dictsource) => {
                                this.purchasecurrencyChange_shipment(childelements, v_val,
                                    v_dictsource);
                            },
                            fobcurrency: 'fobexchangerate',
                            fobcurrencyfun: (childelements, v_val, v_dictsource) => {
                                this.fobcurrencyChange_shipment(childelements, v_val, v_dictsource);
                            },
                            cost: {
                                currency: 'exchangerate'
                            },
                            sharingcostcurrency: 'sharingcostexchangerate',
                        };
                        this.childTableFooter = intData2.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'quotation') {
                    this.configService.getquotation(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData3 = QuotationBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childNewDataConfig = intData3.childNewDataConfig;
                        this.dropOtherConfig = intData3.dropOtherConfig;
                        this.childTableFooter = intData3.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'putinstorage') {
                    this.configService.getputinstorage(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        var intData4 = PutInStorageBoxJs.initDetail();
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                            'childExpandeConfig'
                        ];
                        this.childNewDataConfig = intData4.childNewDataConfig;
                        this.dropOtherConfig = {
                            instoragecurrency: 'instorageexchangerate',
                            instoragecurrencyfun: (childelements, v_val, v_dictsource) => {
                                this.inStorageCurrencyChange_putinstorage(childelements, v_val,
                                    v_dictsource);
                            },
                            cost: {
                                currency: 'exchangerate'
                            },
                            sharingcostcurrency: 'sharingcostexchangerate',
                        };
                        this.childTableFooter = intData4.childTableFooter;
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'factoryedithistory') {
                    this.configService.getfactoryEdit(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'claimsettlement') {
                    this.configService.getclaimSettlement(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'productsample') {
                    this.configService.getproductsample(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.productpage;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'financialcollectionaccount') {
                    this.configService.getfinancialbox(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.collectionaccountpage;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {};
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'financialexpensepay') {
                    this.configService.getfinancialexpensepay(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page;
                        this.childExpandeConfig = {};
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {
                            currency: 'exchangerate',
                            currencyfun: (childelements, v_val, v_dictsource, v_allmainelements) => {
                                ExpensePayJs.currencyChange(childelements, v_val, v_dictsource,
                                    v_allmainelements);
                            },
                        };
                        this.childTableFooter = {
                            paydetail: {
                                datano: '合计',
                                paymoney: 0,
                                invoiceamount: 0,
                            },
                        };
                        this.displayMaximizable = true;
                    });
                }
                if (v_bindpage == 'purchaseedit') {
                    this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                        console.log(data);
                        this.pageJsonConfig = data.page2;
                        this.childExpandeConfig = data.page2['region_3']['left']['element'][0][
                            'childExpandeConfig2'
                        ];
                        this.childNewDataConfig = {};
                        this.dropOtherConfig = {};
                        this.childTableFooter = {
                            products: {
                                mpn: '合计',
                                quantity: 0,
                                allprice: 0,
                                grossweight: 0,
                                netweight: 0
                            }
                        };
                        this.displayMaximizable = true;
                    });
                }
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                if (this.currentRow.bindpage == 'shipment') {
                    ShipmentBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'quotation') {
                    QuotationBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                    for (var k2 = 0; k2 < v_mainelements.length; k2++) {
                        if (v_mainelements[k2]['name'] == 'currency') {
                            this.quotation_currencyChange(v_childelements, v_mainelements[k2]['val'], this.currencyDict,
                                v_childExpandeConfig);
                            break;
                        }
                    }
                }
                if (this.currentRow.bindpage == 'purchase') {
                    PurchaseBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                    setTimeout(() => {
                        this.$refs.universalpage.setAllExpandedRowsData('deliverys');
                    }, 20);
                }
                if (this.currentRow.bindpage == 'putinstorage') {
                    PutInStorageBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this.currentRow,
                        this.currencyDict, v_childExpandeConfig);
                }
                if (this.currentRow.bindpage == 'factoryedithistory') {
                    FactoryInfoBoxJs.loadDataAfter(v_mainelements, v_childelements, v_originalData, this
                        .setrecordsubjectfun,
                        this.modifyelementsfun);
                }
                if (this.currentRow.bindpage == 'financialexpensepay') {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'currency') {
                            ExpensePayJs.currencyChange(v_childelements, v_mainelements[k]['val'], this
                                .currencyDict, v_mainelements);
                        }
                        if (v_mainelements[k]['name'] == 'allpaymoneyhc') {
                            var allpaymoneyhc = Number(v_mainelements[k]['val']);
                            if (!isNaN(allpaymoneyhc)) {
                                v_mainelements[k]['val'] = allpaymoneyhc.toFixed(2);
                            }
                        }
                    }
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (this.currentRow.bindpage == 'shipment') {
                    ShipmentBoxJs.cellEditComplete(tablename, field, data, rowdata, childTableFooterObj,
                        v_childelements, v_alonechildelements, v_elements, this.volumeratio);
                }
                if (this.currentRow.bindpage == 'quotation') {
                    QuotationBoxJs.cellEditComplete(tablename, field, data, rowdata, childTableFooterObj,
                        v_childelements,
                        v_alonechildelements, v_elements);
                }
                if (this.currentRow.bindpage == 'purchase') {
                    PurchaseBoxJs.cellEditComplete(tablename, field, data, rowdata, childTableFooterObj,
                        v_childelements,
                        v_alonechildelements, v_elements);
                }
                if (this.currentRow.bindpage == 'putinstorage') {
                    PutInStorageBoxJs.cellEditComplete(tablename, field, data, rowdata, childTableFooterObj,
                        v_childelements,
                        v_alonechildelements, v_elements);
                }
                if (this.currentRow.bindpage == 'financialexpensepay') {
                    if (tablename != 'paydetail') {
                        return;
                    }
                    ExpensePayJs.costcalculate(rowdata);
                    var allPayMoney = ExpensePayJs.getAllCost(v_childelements);
                    var exchangeRate = 1;
                    for (var k2 = 0; k2 < v_elements.length; k2++) {
                        if (v_elements[k2]['name'] == 'exchangerate') {
                            exchangeRate = Number(v_elements[k2]['val']);
                            break;
                        }
                    }
                    for (var k1 = 0; k1 < v_elements.length; k1++) {
                        if (v_elements[k1]['name'] == 'allpaymoney') {
                            v_elements[k1]['val'] = allPayMoney;
                        }
                        if (v_elements[k1]['name'] == 'allpaymoneyhc') {
                            if (!isNaN(exchangeRate)) {
                                v_elements[k1]['val'] = (allPayMoney * exchangeRate).toFixed(2);
                            }
                        }
                    }
                }
            },
            importAddQueryCondition(v_listwhere, v_moduleName) {
                if (this.currentRow.bindpage == 'purchase') {
                    return PurchaseBoxJs.importAddQueryCondition(v_listwhere, v_moduleName, this.$refs.universalpage
                        .getNoShowElements());
                }
                if (this.currentRow.bindpage == 'shipment') {
                    return ShipmentBoxJs.importAddQueryCondition(v_listwhere);
                }
                if (this.currentRow.bindpage == 'putinstorage') {
                    return PutInStorageBoxJs.importAddQueryCondition(v_listwhere);
                }
                if (this.currentRow.bindpage == 'quotation') {
                    return QuotationBoxJs.importAddQueryCondition(v_listwhere, v_moduleName);
                }
                return v_listwhere;
            },
            importAddCols(v_cols, v_graphqlFeild) {
                if (this.currentRow.bindpage == 'shipment') {
                    return ShipmentBoxJs.importAddCols(v_cols, v_graphqlFeild);
                }
                if (this.currentRow.bindpage == 'putinstorage') {
                    return PutInStorageBoxJs.importAddCols(v_cols, v_graphqlFeild);
                }
                return v_graphqlFeild;
            },
            cellEditInit(event, tablename, colconfigs) {
                if (this.currentRow.bindpage == 'purchase') {
                    return PurchaseBoxJs.productCellEditInit(event, tablename, colconfigs);
                }
                if (this.currentRow.bindpage == 'quotation') {
                    return QuotationBoxJs.productCellEditInit(event, tablename, colconfigs);
                }
            },
            cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
                childTableFooterObj) {
                console.log('cellEditCompleteExpandeMethod..');
                if (this.currentRow.bindpage == 'purchase') {
                    return PurchaseBoxJs.cellEditCompleteExpande(tablename, field, currentrow, parentRow,
                        allcurrentdata, allparentdata,
                        childTableFooterObj);
                }
            },
            inputdropSelectAfter(v_id, v_data) {
                if (this.currentRow.bindpage == 'financialexpensepay') {
                    var allelements = this.$refs.universalpage.getAllMainElements();
                    if (v_id == 'accountid') {
                        for (var k1 = 0; k1 < allelements.length; k1++) {
                            if (allelements[k1]['name'] == 'bank') {
                                allelements[k1]['val'] = v_data['bank'];
                            }
                            if (allelements[k1]['name'] == 'bankaccountnum') {
                                allelements[k1]['val'] = v_data['bankaccountnum'];
                            }
                            if (allelements[k1]['name'] == 'accountname') {
                                allelements[k1]['val'] = v_data['accountname'];
                            }
                            if (allelements[k1]['name'] == 'collectionunitno') {
                                allelements[k1]['val'] = v_data['unitno'];
                            }
                        }
                    }
                    if (v_id == 'payinfoid') {
                        for (var k2 = 0; k2 < allelements.length; k2++) {
                            if (allelements[k2]['name'] == 'paybank') {
                                allelements[k2]['val'] = v_data['bank'];
                            }
                            if (allelements[k2]['name'] == 'paybankaccountnum') {
                                allelements[k2]['val'] = v_data['bankaccountnum'];
                            }
                            if (allelements[k2]['name'] == 'payaccountname') {
                                allelements[k2]['val'] = v_data['accountname'];
                            }
                        }
                    }
                }
            },
            setrecordsubjectfun(v_val) {
                this.recordsubject = v_val;
            },
            modifyelementsfun(v_elements) {
                this.$refs.universalpage.modifyElements(v_elements);
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            freshcalculate_shipment() {
                var allelements = this.$refs.universalpage.getAllMainElements();
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                var productsdata = [];
                var purchaseExchangeRate = 0;
                var rateProfit = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < allelements.length; k1++) {
                    if (allelements[k1]['name'] == 'purchaseexchangerate') {
                        purchaseExchangeRate = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'rateprofit') {
                        rateProfit = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(allelements[k1]['val']);
                    }
                }
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'products') {
                        productsdata = allchildelements[i]['rows'];
                        break;
                    }
                }
                var volumesummary = ShipmentBoxJs.getAllVolume(productsdata);
                var allcost = ShipmentBoxJs.getAllCost(allchildelements);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        ShipmentBoxJs.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    ShipmentBoxJs.productsfobpricecalculate(productsdata[k4], purchaseExchangeRate, rateProfit,
                        fOBExchangeRate);
                }
                ShipmentBoxJs.calculateCostProductPart(productsdata);
                for (var n2 = 0; n2 < allchildelements.length; n2++) {
                    this.$refs.universalpage.sumAllField(allchildelements[n2]['name'], allchildelements[n2]['rows']);
                }
            },
            fobcurrencyChange_shipment(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'fobprice') {
                            v_childelements[i].columns[i2].header = 'FOB单价(' + ename + ')';
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'fobprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }

                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'fobprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate_shipment();
            },
            purchasecurrencyChange_shipment(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                var loopindex = 0;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'sharingcost') {
                            v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                            loopindex++;
                        }
                        if (loopindex == 2) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
                        }
                    }

                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'unitprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '产品单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'sharingcost') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '分摊费用(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate_shipment();
            },
            freshcalculate_putinstorage() {
                var allelements = this.$refs.universalpage.getAllMainElements();
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                var productsdata = [];
                var inStorageExchangeRate = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < allelements.length; k1++) {
                    if (allelements[k1]['name'] == 'instorageexchangerate') {
                        inStorageExchangeRate = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(allelements[k1]['val']);
                    }
                }
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'products') {
                        productsdata = allchildelements[i]['rows'];
                        break;
                    }
                }
                var volumesummary = PutInStorageBoxJs.getAllVolume(productsdata);
                var costdata;
                for (var i2 = 0; i2 < allchildelements.length; i2++) {
                    if (allchildelements[i2]['name'] == 'cost') {
                        costdata = allchildelements[i2]['rows'];
                        break;
                    }
                }
                console.log('costdata....');
                console.log(costdata);
                var allcost = PutInStorageBoxJs.getAllCost(costdata);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        PutInStorageBoxJs.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    PutInStorageBoxJs.productsfobpricecalculate(productsdata[k4], inStorageExchangeRate,
                        fOBExchangeRate);
                }
                PutInStorageBoxJs.calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate);
                for (var n2 = 0; n2 < allchildelements.length; n2++) {
                    this.$refs.universalpage.sumAllField(allchildelements[n2]['name'], allchildelements[n2]['rows']);
                }
            },
            inStorageCurrencyChange_putinstorage(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                var loopindex = 0;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'instorageprice') {
                            v_childelements[i].columns[i2].header = '入库单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'sharingcost') {
                            v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                            loopindex++;
                        }
                        if (loopindex == 3) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'instorageprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '入库单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
                        }
                    }

                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'unitprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '产品单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'instorageprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '入库单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'sharingcost') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '分摊费用(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate_putinstorage();
            },
            childCreateBefore(v_childname, v_newRow) {
                console.log('childCreateBefore....');
                console.log(v_childname);
                console.log(v_newRow);
                if (this.currentRow['bindpage'] == 'shipment') {
                    if (v_childname == 'cost') {
                        var allelements = this.$refs.universalpage.getAllMainElements();
                        var noshowelements = this.$refs.universalpage.getNoShowElements();
                        console.log(allelements);
                        console.log(noshowelements);
                        var purchaseExchangeRate = 0;
                        var purchaseCurrency = -1;
                        for (var k1 = 0; k1 < allelements.length; k1++) {
                            if (allelements[k1]['name'] == 'purchaseexchangerate') {
                                purchaseExchangeRate = Number(allelements[k1]['val']);
                            }
                            if (allelements[k1]['name'] == 'purchasecurrency') {
                                purchaseCurrency = Number(allelements[k1]['val']);
                            }
                        }
                        if (noshowelements['purchasecurrencyname']) {
                            v_newRow['currencyname'] = noshowelements['purchasecurrencyname'];
                        }
                        v_newRow['currency'] = purchaseCurrency + "";
                        v_newRow['exchangerate'] = purchaseExchangeRate;
                    }
                }
                return v_newRow;
            },
            quotation_currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'price') {
                            v_childelements[i].columns[i2].header = '价格(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 1) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'price') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '价格(' + ename + ')';
                        }
                    }
                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'price') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '价格(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
            },
            dialogClose() {
                this.butLoading = false;
            },
            openinspectionfileDialog(v_childdata, v_parentdata) {
                console.log('openinspectionfileDialog...');
                console.log(v_childdata);
                console.log(v_parentdata);
                this.inspectionfiledata = v_parentdata;
                this.inspectionfiledeldata = [];
                this.selectedInspectionFileRow = null;
                for (var i = this.inspectionfiledata['inspectionfile'].length - 1; i >= 0; i--) {
                    if (this.inspectionfiledata['inspectionfile'][i]['isdel'] == '1') {
                        this.inspectionfiledeldata.push(this.inspectionfiledata['inspectionfile'][i]);
                        this.inspectionfiledata['inspectionfile'].splice(i, 1);
                    }
                }
                this.displayInspectionFile = true;
            },
            createInspectionFiles2() {
                this.inspectionfiledata['inspectionfile'].unshift({
                    id: this.getNewId(),
                    filename: '',
                    filepatch: '',
                    parentid: '',
                    purchaseid: this.inspectionfiledata['purchaseid']
                });
                this.selectedInspectionFileRow = null;
            },
            removeInspectionFiles2() {
                setTimeout(() => {
                    console.log(this.selectedInspectionFileRow);
                    if (!this.selectedInspectionFileRow || this.selectedInspectionFileRow.length == 0) {
                        MessageTip.warnmsg('请选择要移除的验货文件');
                        return;
                    }
                    var datasource = this.inspectionfiledata['inspectionfile'];
                    for (var i2 = 0; i2 < this.selectedInspectionFileRow.length; i2++) {
                        for (var i = 0; i < datasource.length; i++) {
                            if (datasource[i]['id'] == this.selectedInspectionFileRow[i2]['id']) {
                                if (parseInt(this.selectedInspectionFileRow[i2]['id']) < 0) {
                                    datasource.splice(i, 1);
                                } else {
                                    datasource[i]["isdel"] = '1';
                                    this.inspectionfiledeldata.push(datasource[i]);
                                    datasource.splice(i, 1);
                                }
                                break;
                            }
                        }
                    }
                    this.inspectionfiledata['inspectionfile'] = datasource;
                    this.selectedInspectionFileRow = null;
                }, 50);
            },
            inspectionfileonRowEditSave2(event, v_data) {
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            inspectionfilecellEditComplete2(event) {
                console.log("event:");
                console.log(event);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            closeInspectionFile() {
                for (var i = 0; i < this.inspectionfiledeldata.length; i++) {
                    this.inspectionfiledata['inspectionfile'].push(this.inspectionfiledeldata[i]);
                }
                this.inspectionfiledata = {};
                this.inspectionfiledeldata = [];
                this.displayInspectionFile = false;
            },
            showEditHistory() {
                if (this.editHistoryButTxt == '隐藏修改痕迹') {
                    this.$refs.universalpage.clearEditHistory();
                    this.editHistoryButTxt = '显示修改痕迹';
                    return;
                }
                this.$refs.universalpage.showEditHistory(this.seteditHistoryButTxt);
            },
            seteditHistoryButTxt() {
                this.editHistoryButTxt = '隐藏修改痕迹';
            }
        },
        components: {
            Image,
            NewUniversalPage,
            FlowHistory,
            UploadInput
        }
    }
</script>