const PutInStorageBoxJs = {
    initDetail() {
        console.log('PutInStorageBoxJs---initDetail...');
        this.childNewDataConfig = {
            products: false,
            files: false,
            cost: true,
        };
        this.dropOtherConfig = {
            cost: {
                currency: 'exchangerate'
            },
        };
        this.childTableFooter = {
            cost: {
                costtype: '合计',
                costamount: 0,
                invoiceamount: 0,
            },
            products: {
                platformsku: '合计',
                expectedinstoragequantity: 0,
                actualinstoragequantity: 0,
                difference: 0,
                allweight: 0,
                allvolume: 0,
                sharingcost: 0,
            }
        };
        this.childaloneshowConfig = {
            cost: '1'
        };
        return {
            'childNewDataConfig': this.childNewDataConfig,
            'dropOtherConfig': this.dropOtherConfig,
            'childTableFooter': this.childTableFooter,
            'childaloneshowConfig': this.childaloneshowConfig
        }
    },
    calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate) {
        console.log('calculateCostProductPart..');
        console.log(productsdata);
        console.log(inStorageExchangeRate);
        console.log(fOBExchangeRate);
        for (var k = 0; k < productsdata.length; k++) {
            if (productsdata[k]['parts']) {
                var volumenum = Number(productsdata[k]['volume']);
                var sharingcostnum = Number(productsdata[k]['sharingcost']);
                var instoragepricenum = Number(productsdata[k]['instorageprice']);
                if (!isNaN(volumenum) && !isNaN(inStorageExchangeRate) && inStorageExchangeRate > 0 && !isNaN(sharingcostnum)) {
                    var sharingcostnum1 = 0;
                    for (var k1 = 0; k1 < productsdata[k]['parts'].length; k1++) {
                        var volumenum1 = Number(productsdata[k]['parts'][k1]['volume']);
                        var matchingnum1 = Number(productsdata[k]['parts'][k1]['matching']);
                        var quantitynum = Number(productsdata[k]['parts'][k1]['actualinstoragequantity']);
                        if (productsdata[k]['parts'].length == 1) {
                            productsdata[k]['parts'][k1]['sharingcost'] = (sharingcostnum / matchingnum1).toFixed(2);
                            if (!isNaN(instoragepricenum)) {
                                productsdata[k]['parts'][k1]['instorageprice'] = (instoragepricenum / matchingnum1).toFixed(2);
                            }
                        } else {
                            if (k1 != productsdata[k]['parts'].length - 1) {
                                if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(sharingcostnum)) {
                                    var sharingcostnum2 = (sharingcostnum * ((volumenum1 *
                                        matchingnum1) / volumenum)).toFixed(2);
                                    productsdata[k]['parts'][k1]['sharingcost'] = sharingcostnum2;
                                    sharingcostnum1 = sharingcostnum1 + Number(sharingcostnum2);
                                }
                            } else {
                                productsdata[k]['parts'][k1]['sharingcost'] = sharingcostnum - sharingcostnum1;
                            }
                            var currentsharingcostnum = Number(productsdata[k]['parts'][k1]['sharingcost']);
                            var currentfobpricenum = Number(productsdata[k]['parts'][k1]['fobprice']);
                            if (isNaN(currentfobpricenum)) {
                                currentfobpricenum = 0;
                            }
                            if (!isNaN(quantitynum) && quantitynum > 0 && !isNaN(currentfobpricenum) && !isNaN(currentsharingcostnum) && currentsharingcostnum > 0) {
                                console.log("(" + currentfobpricenum + "*" + fOBExchangeRate + "/" + inStorageExchangeRate + "+" + currentsharingcostnum + "/" + quantitynum + ")");
                                var instoragepricenum2 = (currentfobpricenum * fOBExchangeRate / inStorageExchangeRate + currentsharingcostnum / quantitynum).toFixed(2);
                                if (k1 != productsdata[k]['parts'].length - 1) {
                                    productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum2;
                                    if (!isNaN(instoragepricenum))
                                        instoragepricenum = instoragepricenum - Number(instoragepricenum2);
                                } else {
                                    if (!isNaN(instoragepricenum)) {
                                        productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum;
                                    } else {
                                        productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum2;
                                    }
                                }
                            }
                        }
                    }
                    console.log(productsdata[k]);
                }
            }
        }
    },
    cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
        v_alonechildelements, v_elements, v_noshowelements) {
        console.log('PutInStorageBoxJs--cellEditCompleteMethod..');
        console.log(field);
        console.log(data);
        console.log(rowdata);
        console.log(childTableFooterObj);
        console.log(v_childelements);
        console.log(v_alonechildelements);
        console.log(v_elements);
        if (tablename != 'products' && tablename != 'cost') {
            return;
        }
        var productsdata = [];
        var inStorageExchangeRate = 0;
        var fOBExchangeRate = 0;
        for (var k1 = 0; k1 < v_elements.length; k1++) {
            if (v_elements[k1]['name'] == 'instorageexchangerate') {
                inStorageExchangeRate = Number(v_elements[k1]['val']);
            }
            if (v_elements[k1]['name'] == 'fobexchangerate') {
                fOBExchangeRate = Number(v_elements[k1]['val']);
            }
        }
        console.log('noshowElements...');
        console.log(v_noshowelements);
        for (var i = 0; i < v_childelements.length; i++) {
            if (v_childelements[i]['name'] == 'products') {
                productsdata = v_childelements[i]['rows'];
                break;
            }
        }
        if (tablename == 'products' && field == 'actualinstoragequantity' && rowdata['parts']) {
            var actualinstoragequantitynum = Number(rowdata['actualinstoragequantity']);
            if (!isNaN(actualinstoragequantitynum)) {
                for (var i1 = 0; i1 < rowdata['parts'].length; i1++) {
                    var matchingnum1 = Number(rowdata['parts'][i1]['matching']);
                    if (!isNaN(matchingnum1)) {
                        rowdata['parts'][i1]['actualinstoragequantity'] = matchingnum1 *
                            actualinstoragequantitynum;
                    }
                }
            }
        }
        if (tablename == 'products') {
            this.productsvolumecalculate(rowdata);
            this.productsweightcalculate(rowdata);
            this.productsdifferencecalculate(rowdata);
        }
        if (tablename == 'cost') {
            this.costcalculate(rowdata);
        }
        var volumesummary = 0;
        for (var k2 = 0; k2 < productsdata.length; k2++) {
            if (tablename == 'cost') {
                this.productsvolumecalculate(productsdata[k2]);
                this.productsweightcalculate(productsdata[k2]);
            }
            var tempnum2 = Number(productsdata[k2]['volume']);
            var quantitynum2 = Number(productsdata[k2]['actualinstoragequantity']);
            if (!isNaN(tempnum2) && !isNaN(quantitynum2)) {
                volumesummary = volumesummary + tempnum2 * quantitynum2;
            }
        }
        var costdata;
        for (var i2 = 0; i2 < v_childelements.length; i2++) {
            if (v_childelements[i2]['name'] == 'cost') {
                costdata = v_childelements[i2]['rows'];
                break;
            }
        }
        var allcost = 0;
        for (var k = 0; k < costdata.length; k++) {
            var costamount = Number(costdata[k]['costamount']);
            //var exchangerate = Number(costdata[k]['exchangerate']);
            if (!isNaN(costamount)) {
                allcost = allcost + costamount;
            }
        }
        var sharingcostsum = 0;
        for (var k4 = 0; k4 < productsdata.length; k4++) {
            if (k4 == productsdata.length - 1) {
                productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
            } else {
                this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
            }
            this.productsfobpricecalculate(productsdata[k4], inStorageExchangeRate, fOBExchangeRate);
        }
        this.calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate);
    },
    getAllVolume(productsdata) {
        var volumesummary = 0;
        for (var k2 = 0; k2 < productsdata.length; k2++) {
            var tempnum2 = Number(productsdata[k2]['volume']);
            var quantitynum2 = Number(productsdata[k2]['actualinstoragequantity']);
            if (!isNaN(tempnum2) && !isNaN(quantitynum2)) {
                volumesummary = volumesummary + tempnum2 * quantitynum2;
            }
        }
        return volumesummary;
    },
    getAllCost(costdata) {
        var allcost = 0;
        for (var k = 0; k < costdata.length; k++) {
            var costamount = Number(costdata[k]['costamount']);
            //var exchangerate = Number(costdata[k]['exchangerate']);
            if (!isNaN(costamount)) {
                allcost = allcost + costamount;
            }
        }
        return allcost;
    },
    productsdifferencecalculate(rowdata) {
        var expectedinstoragequantitynum = Number(rowdata['expectedinstoragequantity']);
        var actualinstoragequantitynum = Number(rowdata['actualinstoragequantity']);
        if (!isNaN(expectedinstoragequantitynum) && !isNaN(actualinstoragequantitynum)) {
            rowdata['difference'] = actualinstoragequantitynum - expectedinstoragequantitynum;
        }
    },
    productsvolumecalculate(rowdata) {
        var volumenum = Number(rowdata['volume']);
        var quantitynum = Number(rowdata['actualinstoragequantity']);
        if (!isNaN(quantitynum) && !isNaN(volumenum))
            rowdata['allvolume'] = (quantitynum * volumenum).toFixed(4);
        console.log('productsvolumecalculate..' + rowdata['allvolume']);
    },
    productsweightcalculate(rowdata) {
        var weightnum = Number(rowdata['weight']);
        var quantitynum = Number(rowdata['actualinstoragequantity']);
        if (!isNaN(quantitynum) && !isNaN(weightnum))
            rowdata['allweight'] = (quantitynum * weightnum).toFixed(2);
        console.log('productsweightcalculate..' + rowdata['allweight']);
    },
    productssharingcostcalculate(rowdata, volumesummary, allcost) {
        var volume = Number(rowdata['volume']);
        var quantitynum = Number(rowdata['actualinstoragequantity']);
        rowdata['sharingcost'] = 0;
        if (!isNaN(volume) && !isNaN(quantitynum) && !isNaN(allcost)) {
            if (quantitynum > 0 && volumesummary > 0 && allcost > 0) {
                rowdata['sharingcost'] = (((volume * quantitynum) / volumesummary) * allcost).toFixed(2);
            }
        }
        console.log('productssharingcostcalculate..' + rowdata['sharingcost']);
    },
    costcalculate(rowdata) {
        var taxratenum = Number(rowdata['taxrate']);
        var invoiceamountnum = Number(rowdata['invoiceamount']);
        if (!isNaN(taxratenum) && !isNaN(invoiceamountnum)) {
            rowdata['costamount'] = invoiceamountnum * (1 - taxratenum).toFixed(2);
        }
        console.log('costcalculate..' + rowdata['costamount']);
    },
    productsfobpricecalculate(rowdata, inStorageExchangeRate, fOBExchangeRate) {
        var quantitynum = Number(rowdata['actualinstoragequantity']);
        var fobprice = Number(rowdata['fobprice']);
        var sharingcost = Number(rowdata['sharingcost']);
        console.log('quantitynum..' + quantitynum);
        console.log('sharingcost..' + sharingcost);
        console.log('inStorageExchangeRate..' + inStorageExchangeRate);
        console.log('fOBExchangeRate..' + fOBExchangeRate);
        rowdata['instorageprice'] = 0;
        if (isNaN(fobprice)) {
            fobprice = 0;
        }
        if (!isNaN(sharingcost) && !isNaN(quantitynum) && !isNaN(inStorageExchangeRate) && !isNaN(
                fOBExchangeRate) && quantitynum > 0) {
            console.log("(" + fobprice + "*" + fOBExchangeRate + "/" + inStorageExchangeRate + sharingcost +
                "/" + quantitynum + ")");
            rowdata['instorageprice'] = (fobprice * fOBExchangeRate / inStorageExchangeRate + sharingcost / quantitynum).toFixed(2);
        }
        console.log('productsfobpricecalculate..' + rowdata['instorageprice']);
    },
    loadDataAfter(v_mainelements, v_childelements, v_originalData, currentRow, currencyDict, v_childExpandeConfig) {
        console.log('PutInStorageBoxJs--loadDataAfter..');
        console.log(v_mainelements);
        console.log(v_childelements);
        console.log(v_originalData);
        if (currentRow && currentRow['id'] != '-1') {
            for (var k = 0; k < v_mainelements.length; k++) {
                if (v_mainelements[k]['name'] == 'instoragecurrency') {
                    this.inStorageCurrencyChange(v_childelements, v_mainelements[k]['val'], currencyDict, v_childExpandeConfig);
                }
            }
            for (var pro in v_originalData.putinstorage) {
                if (pro == 'fobcurrency') {
                    this.fobcurrencyChange(v_childelements, v_originalData.putinstorage[pro], currencyDict, v_childExpandeConfig);
                    break;
                }
            }
        }
    },
    inStorageCurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        var isbreak = false;
        var loopindex = 0;
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'unitprice') {
                    v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                    loopindex++;
                }
                if (v_childelements[i].columns[i2].field == 'instorageprice') {
                    v_childelements[i].columns[i2].header = '入库单价(' + ename + ')';
                    loopindex++;
                }
                if (v_childelements[i].columns[i2].field == 'sharingcost') {
                    v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                    loopindex++;
                }
                if (loopindex == 3) {
                    isbreak = true;
                    break;
                }
            }
            if (isbreak) {
                break;
            }
        }
        for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
            }
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'instorageprice') {
                v_childExpandeConfig.products.config[0].columns[i4].header = '入库单价(' + ename + ')';
            }
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
            }
        }
    },
    fobcurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
        console.log('PutInStorageBoxJs--fobcurrencyChange..');
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        var isbreak = false;
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'fobprice') {
                    v_childelements[i].columns[i2].header = 'FOB单价(' + ename + ')';
                    isbreak = true;
                    break;
                }
            }
            if (isbreak) {
                break;
            }
        }
        for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'fobprice') {
                v_childExpandeConfig.products.config[0].columns[i4].header = 'FOB单价(' + ename + ')';
                break;
            }
        }
    },
    importAddQueryCondition(v_listwhere) {
        v_listwhere.conditions.push({
            name: 'shiptoinstorage',
            value: '',
            operation: '=',
        });
        return v_listwhere;
    },
    importAddCols(v_cols, v_graphqlFeild) {
        console.log('importAddCols:1');
        console.log(v_cols);
        v_graphqlFeild = v_graphqlFeild + ' factoryname purchaseno ';
        v_cols.unshift({
            name: 'shipmentno',
            label: '入库单号',
            type: 'LabelString',
            dictName: '',
            dictLabel: '',
            dictCode: '',
            dictCode2: '',
            dictCode3: '',
        });
        console.log('importAddCols:2');
        console.log(v_cols);
        return v_graphqlFeild;
    },
};

export default PutInStorageBoxJs;