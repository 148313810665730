import requests from '../service/newaxios';
import * as Base64 from 'js-base64';

const FactoryInfoBoxJs = {
    loadDataAfter(v_mainelements, v_childelements, v_originalData, v_setrecordsubjectfun, v_modifyelementsfun) {
        console.log(v_mainelements);
        console.log(v_childelements);
        console.log(v_originalData);
        console.log(v_setrecordsubjectfun);
        console.log(v_modifyelementsfun);
        this.setDifference(v_originalData, v_setrecordsubjectfun, v_modifyelementsfun);
    },
    setDifference(v_originalData, v_setrecordsubjectfun, v_modifyelementsfun) {
        requests.graphql_requests.post(
            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
            "{factoryinfo(id:\"" + v_originalData.factoryedithistory['relationid'] +
            "\"){id name fullname code mainproducts city cooperationtime fulladdress settlementperiod remark tel contactperson contactpersontel factorytypename factorytype}}"
        ).then(res => {
            if (res.errcode == "0") {
                var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                var factoryobj = jsonData.data.factoryinfo;
                if (factoryobj['code'] && factoryobj['code'] != '') {
                    v_setrecordsubjectfun("修改" + factoryobj['name']);
                    var elements = [];
                    for (var pro in factoryobj) {
                        for (var originalpro in v_originalData.factoryedithistory) {
                            if (pro == originalpro && v_originalData.factoryedithistory[pro] !=
                                factoryobj[pro]) {
                                var tip = factoryobj[pro];
                                if (tip == '') {
                                    tip = '未填写';
                                }
                                var elementobj = {
                                    "name": "",
                                    "class": "p-invalid",
                                    "tip": "原值:" + tip,
                                    "differencetip": "原值:" + tip,
                                };
                                elementobj['name'] = pro;
                                elements.push(elementobj);
                                break;
                            }
                        }
                    }
                    v_modifyelementsfun(elements);
                } else {
                    v_setrecordsubjectfun("新建" + v_originalData.factoryedithistory['name']);
                }
            }
        });
    },
};
export default FactoryInfoBoxJs;