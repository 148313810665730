<template>
    <div class="card">
        <ScrollPanel style="width:100%; height:700px" class="custombar1">
            <p align="left">
                <OrganizationChart :value="treedata" :collapsible="true" class="company" selectionMode="single">
                    <template #currentnode="slotProps">
                        <Card style="min-width:200px">
                            <template #title>
                                {{slotProps.node.data.nodename}}
                            </template>
                            <template #subtitle>
                                {{slotProps.node.data.approvetime}}
                            </template>
                            <template #content>
                                <div class="field grid">
                                    <label class="col-fixed"> {{slotProps.node.data.approver}}</label>
                                    <div class="col" style="text-align:left;">
                                        正在处理中.....
                                    </div>
                                </div>
                            </template>
                        </Card>
                    </template>
                    <template #othernode="slotProps">
                        <Card style="min-width:200px">
                            <template #title>
                                {{slotProps.node.data.nodename}}
                            </template>
                            <template #subtitle>
                                {{slotProps.node.data.approvetime}}
                            </template>
                            <template #content>
                                <div class="field grid">
                                    <label class="col-fixed">处理人:</label>
                                    <div class="col" style="text-align:left;">
                                        {{slotProps.node.data.approver}}
                                    </div>
                                </div>
                                <div class="field grid" v-if="slotProps.node.data.approvecontent!=''">
                                    <label v-if="slotProps.node.data.approvecontent!=''" class="col-fixed">审批结果:</label>
                                    <div class="col" style="text-align:left;"
                                        v-if="slotProps.node.data.approvecontent!=''">
                                        {{slotProps.node.data.approvecontent}}
                                    </div>
                                </div>
                                <div class="field grid" v-if="slotProps.node.data.viewcontent!=''">
                                    <label v-if="slotProps.node.data.viewcontent!=''" class="col-fixed">意见: </label>
                                    <div class="col" style="text-align:left;"
                                        v-if="slotProps.node.data.viewcontent!=''">
                                        {{slotProps.node.data.viewcontent}}
                                    </div>
                                </div>
                            </template>
                        </Card>
                    </template>
                    <template #multnode="slotProps">
                        <div class="node-header ui-corner-top"></div>
                        <div class="node-content">
                            <div>
                                <label>{{slotProps.node.data.label}}</label>
                            </div>
                        </div>
                    </template>
                    <template #default="slotProps">
                        <div class="node-header ui-corner-top">{{slotProps.node.data.label}}</div>
                        <div class="node-content">
                            <div>
                                <label></label>
                            </div>
                        </div>
                    </template>
                </OrganizationChart>
            </p>
        </ScrollPanel>
    </div>
</template>

<script>
    export default {
        name: 'Organization',
        data() {
            return {
                newId: 1,
                treedata: {
                    "id": "0",
                    "key": "0",
                    "type": "othernode",
                    "styleClass": "p-person",
                    "data": {
                        "label": "",
                        "approver": "",
                        "approvetime": "",
                        "nodename": "",
                        "approvecontent": "",
                        "viewcontent": "",
                        "status": -1
                    },
                    "children": []
                }
            }
        },
        props: ['flowHistoryData', 'title'],
        created() {
            console.log('flowHistory--created');
            this.onLoad();
        },
        methods: {
            getNewId() {
                let id = this.newId;
                this.newId = id + 1;
                return id;
            },
            onLoad() {
                console.log("onLoad--child");
                console.log(this.flowHistoryData);
                if (Array.isArray(this.flowHistoryData)) {
                    this.treedata.type = '';
                    this.treedata.data.label = this.title;
                    for (var i = 0; i < this.flowHistoryData.length; i++) {
                        let childId = this.getNewId();
                        let childObj = {
                            "id": childId,
                            "key": childId,
                            "type": 'multnode',
                            "styleClass": "p-person",
                            "data": {
                                "label": this.flowHistoryData[i].uniquename,
                                "approver": '',
                                "approvetime": '',
                                "nodename": '',
                                "approvecontent": '',
                                "viewcontent": '',
                                "status": -1
                            },
                            "children": []
                        };
                        this.addChildNode(0, this.flowHistoryData[i].details, childObj);
                        this.treedata.children.push(childObj);
                    }

                } else {
                    if (this.flowHistoryData && this.flowHistoryData.details && this.flowHistoryData.details.length >
                        0) {
                        this.treedata.data.approver = this.flowHistoryData.details[0].approver;
                        this.treedata.data.approvetime = this.flowHistoryData.details[0].approvetime;
                        this.treedata.data.nodename = this.flowHistoryData.details[0].nodename;
                        this.treedata.data.approvecontent = this.flowHistoryData.details[0].approvecontent;
                        this.treedata.data.viewcontent = this.flowHistoryData.details[0].viewcontent;
                        if (this.flowHistoryData.details.length > 1) {
                            this.addChildNode(1, this.flowHistoryData.details, this.treedata);
                        }
                    }
                }
                console.log(this.treedata);
            },
            addChildNode(v_index, v_alldata, v_parentObj) {
                let data = v_alldata[v_index];
                let childId = this.getNewId();
                let nodeType = 'othernode';
                if (data.status != -1) {
                    nodeType = 'currentnode';
                }
                let childObj = {
                    "id": childId,
                    "key": childId,
                    "type": nodeType,
                    "styleClass": "p-person",
                    "data": {
                        "label": "",
                        "approver": data.approver,
                        "approvetime": data.approvetime,
                        "nodename": data.nodename,
                        "approvecontent": data.approvecontent,
                        "viewcontent": data.viewcontent,
                        "status": data.status
                    },
                    "children": []
                };
                if (v_index + 1 < v_alldata.length) {
                    this.addChildNode(v_index + 1, v_alldata, childObj);
                }
                v_parentObj.children.push(childObj);
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep(.p-organizationchart) {
        .p-person {
            padding: 0;
            border: 0 none;
        }

        .node-header,
        .node-content {
            padding: .5em .7rem;
            min-width: 300px;
        }

        .node-header {
            background-color: #495ebb;
            color: #ffffff;
        }

        .node-content {
            text-align: center;
            border: 1px solid #495ebb;
        }

        .node-content img {
            border-radius: 50%;
        }

        .department-cfo {
            background-color: #7247bc;
            color: #ffffff;
        }

        .department-coo {
            background-color: #a534b6;
            color: #ffffff;
        }

        .department-cto {
            background-color: #e9286f;
            color: #ffffff;
        }
    }
</style>
<style lang="scss" scoped>
    ::v-deep(.p-scrollpanel) {
        p {
            padding: .5rem;
            line-height: 1.5;
            margin: 0;
        }

        &.custombar1 {
            .p-scrollpanel-wrapper {
                border-right: 9px solid var(--surface-b);
            }

            .p-scrollpanel-bar {
                background-color: var(--primary-color);
                opacity: 1;
                transition: background-color .2s;

                &:hover {
                    background-color: #007ad9;
                }
            }
        }
    }
</style>