<template>
    <Toast />
    <div class="grid ai-center vertical-container" style="padding:20% 0;">
        <div class="col">
            <div class="box">&nbsp;</div>
        </div>
        <div class="col">
            <div class="box">
                <div class="field grid">
                    <label for="Corp" class="col-fixed" style="width:100px">公司</label>
                    <div class="col">
                        <Dropdown v-model="corpid" id="Corp" @change="corpchange" :options="corpDict" optionLabel="name"
                            optionValue="code" />
                    </div>
                </div>
                <div class="field grid">
                    <label for="Corp" class="col-fixed" style="width:100px">平台</label>
                    <div class="col">
                        <Dropdown v-model="storeid" id="Corp" :options="storeDict" optionLabel="name"
                            optionValue="code" />
                    </div>
                </div>
                <div class="field grid">
                    <label for="logName" class="col-fixed" style="width:100px">登陆名</label>
                    <div class="col">
                        <InputText id="logName" style="width:224px" v-model="v$.logName.$model" autofocus />
                    </div>
                </div>
                <div class="field grid">
                    <small v-if="v$.logName.$invalid && isSubmit" class="p-error">请输入登陆名</small>
                </div>
                <div class="field grid">
                    <label for="pssword" class="col-fixed" style="width:100px">密码</label>
                    <div class="col">
                        <Password id="pssword" ref="psswordtxt" @keydown="passwordinput" v-model="v$.pssword.$model"
                            toggleMask>
                        </Password>
                    </div>
                </div>
                <div class="field grid">
                    <small v-if="v$.pssword.$invalid && isSubmit" class="p-error">请输入密码</small>
                </div>
                <div class="field grid">
                    <label for="logBut" class="col-fixed" style="width:100px">&nbsp;</label>
                    <div class="col">
                        <Button id="logBut" label="登陆" :loading="butLoading" icon="pi pi-check" @click="loginSys" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="box">&nbsp;</div>
        </div>
    </div>
</template>

<script>
    import {
        ref,
        reactive
    } from 'vue';
    import MessageTip from './components/Message';
    import requests from './service/newaxios';
    import * as Base64 from 'js-base64';
    import {
        required
    } from "@vuelidate/validators";
    import {
        useVuelidate
    } from "@vuelidate/core";

    export default {
        setup() {
            const butLoading = ref(false);
            const isSubmit = ref(false);

            const state = reactive({
                logName: '',
                pssword: '',
            });
            const rules = {
                logName: {
                    required
                },
                pssword: {
                    required
                },
            };
            const v$ = useVuelidate(rules, state);

            return {
                state,
                v$,
                isSubmit,
                butLoading,
            }
        },
        data() {
            return {
                corpid: '',
                storeid: '',
                storeDict: [],
                corpDict: [{
                    name: '设置专用',
                    code: '1001'
                }, {
                    name: '新公司',
                    code: '169756774957125'
                }]
            }
        },
        created() {
            document.documentElement.style.fontSize = '12px';
            sessionStorage.clear();
        },
        methods: {
            loginSys() {
                this.isSubmit = true;
                if (this.v$.$invalid) return;
                this.butLoading = true;
                requests.create_plat_requests(this.corpid, this.storeid).post(
                    '/calf/global/applicense', {
                        logname: this.state.logName,
                        psd: this.state.pssword,
                    }).then(res => {
                    console.log(res);
                    if (res.data.errcode == "0") {
                        sessionStorage.clear();
                        var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                        sessionStorage.setItem("appkey", retObj.appkey);
                        sessionStorage.setItem("username", retObj.username);
                        sessionStorage.setItem("userid", retObj.userid);
                        sessionStorage.setItem("deptid", retObj.deptid);
                        sessionStorage.setItem("deptname", retObj.deptname);
                        sessionStorage.setItem("stores", JSON.stringify(retObj.stores));
                        sessionStorage.setItem("corpid", this.corpid);
                        sessionStorage.setItem("storeid", this.storeid);
                        //sessionStorage.setItem("usertoken", retObj.accesstoken);
                        console.log(retObj.appkey);
                        //console.log(retObj.accesstoken);
                        this.butLoading = false;
                        this.$router.push('/home/myhome');
                    } else {
                        this.butLoading = false;
                        MessageTip.warnmsg(res.data);
                    }
                });
            },
            passwordinput(v_event) {
                if (v_event.keyCode == 13) {
                    this.loginSys();
                }
            },
            corpchange(v_event) {
                this.storeDict = [];
                requests.create_plat_requests(v_event.value).put(
                    '/calf/global/applicense', {}).then(res => {
                    console.log(res);
                    if (res.data.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                        this.storeDict = retObj;
                        console.log(retObj);
                    } else {
                        MessageTip.warnmsg(res.data);
                    }
                });
            },
        }
    }
</script>

<style lang="scss">
    @import './App.scss';
</style>