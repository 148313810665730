<template>
    <Toast />
    <!-- {{treedata}} -->
    <div class="card">
        <ScrollPanel style="width:100%; height:420px" class="custombar1">
            <p style="width:800px;margin:auto;">
                <OrganizationChart :value="treedata" :collapsible="true" class="company" selectionMode="single">
                    <template #currentnode="slotProps">
                        <div class="node-header ui-corner-top">{{slotProps.node.data.label}}</div>
                        <div class="node-content">
                            <img src="../assets/placeholder.png" width="32">
                            <div>
                                <label>{{slotProps.node.data.name}}</label>
                            </div>
                        </div>
                    </template>
                    <template #recordnode="slotProps">
                        <div class="node-header ui-corner-top">{{slotProps.node.data.label}}</div>
                        <div class="node-content">
                            <img src="../assets/placeholder.png" width="32">
                        </div>
                    </template>
                    <template #nextnode_operation="slotProps">
                        <div class="node-header ui-corner-top">
                            <Checkbox :name="slotProps.node.data.ctlname" :id="slotProps.node.data.id"
                                :value="slotProps.node.data.id" v-if="slotProps.node.data.checkedshow"
                                v-model="slotProps.node.data.ischecked" :binary="true" />
                            <label
                                :for="slotProps.node.data.id">{{slotProps.node.data.label}}{{slotProps.node.data.name}}</label>
                        </div>
                        <div class="node-content" style="text-align:center;">
                            <img src="../assets/placeholder.png" width="32">
                            <table style="margin: auto;">
                                <tbody>
                                    <tr>
                                        <td v-for="child of slotProps.node.data.users" :key="child.id">
                                            <Checkbox :id="child.id" :name="child.ctlname" :value="child.id"
                                                v-if="child.checkedshow" v-model="child.ischecked" :binary="true" />
                                            <label :for="child.id">{{child.label}}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template #nextnode_operationradio="slotProps">
                        <div class="node-header ui-corner-top">
                            <Checkbox :name="slotProps.node.data.ctlname" :id="slotProps.node.data.id"
                                :value="slotProps.node.data.id" v-if="slotProps.node.data.checkedshow"
                                v-model="slotProps.node.data.ischecked" :binary="true" />
                            <label
                                :for="slotProps.node.data.id">{{slotProps.node.data.label}}{{slotProps.node.data.name}}</label>
                        </div>
                        <div class="node-content" style="text-align:center;">
                            <img src="../assets/placeholder.png" width="32">
                            <table style="margin: auto;">
                                <tbody>
                                    <tr>
                                        <td v-for="child of slotProps.node.data.users" :key="child.id">
                                            <RadioButton :id="child.id" :name="child.ctlname" :value="child.id"
                                                v-if="child.checkedshow" v-model="slotProps.node.data.childselect" />
                                            <label :for="child.id">{{child.label}}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template #nextnoderadio_operation="slotProps">
                        <div class="node-header ui-corner-top">
                            <Checkbox :name="slotProps.node.data.ctlname" :id="slotProps.node.data.id"
                                :value="slotProps.node.data.id" v-if="slotProps.node.data.checkedshow"
                                v-model="slotProps.node.data.ischecked"
                                @change="onCheckBoxChange(slotProps.node.data.id)" :binary="true" />
                            <label
                                :for="slotProps.node.data.id">{{slotProps.node.data.label}}{{slotProps.node.data.name}}</label>
                        </div>
                        <div class="node-content" style="text-align:center;">
                            <img src="../assets/placeholder.png" width="32">
                            <table style="margin: auto;">
                                <tbody>
                                    <tr>
                                        <td v-for="child of slotProps.node.data.users" :key="child.id">
                                            <Checkbox :id="child.id" :name="child.ctlname" :value="child.id"
                                                v-if="child.checkedshow" v-model="child.ischecked" :binary="true" />
                                            <label :for="child.id">{{child.label}}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template #nextnoderadio_operationradio="slotProps">
                        <div class="node-header ui-corner-top">
                            <Checkbox :name="slotProps.node.data.ctlname" :id="slotProps.node.data.id"
                                :value="slotProps.node.data.id" v-if="slotProps.node.data.checkedshow"
                                v-model="slotProps.node.data.ischecked"
                                @change="onCheckBoxChange(slotProps.node.data.id)" :binary="true" />
                            <label
                                :for="slotProps.node.data.id">{{slotProps.node.data.label}}{{slotProps.node.data.name}}</label>
                        </div>
                        <div class="node-content" style="text-align:center;">
                            <img src="../assets/placeholder.png" width="32">
                            <table>
                                <tbody style="margin: auto;">
                                    <tr>
                                        <td v-for="child of slotProps.node.data.users" :key="child.id">
                                            <RadioButton :id="child.id" :name="child.ctlname" :value="child.id"
                                                v-if="child.checkedshow" v-model="slotProps.node.data.childselect" />
                                            <label :for="child.id">{{child.label}}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template #err="slotProps">
                        <div class="node-header ui-corner-top">{{slotProps.node.data.label}}流转异常</div>
                        <div class="node-content">
                            <div>
                                <label>{{slotProps.node.data.name}}</label>
                            </div>
                        </div>
                    </template>
                    <template #default="slotProps">
                        <Checkbox :name="slotProps.node.data.ctlname" :id="slotProps.node.data.id"
                            :value="slotProps.node.data.id" v-if="slotProps.node.data.checkedshow"
                            v-model="slotProps.node.data.checked" :binary="true" />
                        <label :for="slotProps.node.data.id">{{slotProps.node.data.label}}</label>
                    </template>
                </OrganizationChart>
            </p>
        </ScrollPanel>
    </div>
    <div class="grid">
        <div class="col-5"></div>
        <div class="col"><Button label="提交" v-if="butShow" :loading="butLoading" @click="postData()" /></div>
        <div class="col-5"></div>
    </div>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import MessageTip from './Message';
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        name: 'FlowEngine',
        props: ['id', 'boxid', 'workflowid', 'flowFreshListMethod', 'closeWinowMehod'],
        created() {
            this.onLoad();
        },
        data() {
            return {
                butLoading: false,
                butShow: false,
                flowinfo: {
                    "id": "",
                    "boxid": "",
                    "workflowid": "",
                    "appkey": "",
                    "unique": ""
                },
                treedata: {
                    "id": "0",
                    "key": "0",
                    "type": "currentnode",
                    "styleClass": "p-person",
                    "operationtype": "0",
                    "data": {
                        "id": "0",
                        "ctlname": "",
                        "stateid": "",
                        "label": "加载中...",
                        "name": "加载中..",
                        "avatar": "",
                        "ischecked": false,
                        "checkedshow": false
                    },
                    "children": []
                }
            }
        },
        methods: {
            onCheckBoxChange(v_id) {
                console.log(v_id);
                var obj = this.findTreeParent(this.treedata, v_id);
                if (obj !== null) {
                    for (var i = 0; i < obj.children.length; i++) {
                        if (obj.children[i].data.id !== v_id) {
                            obj.children[i].data.ischecked = false;
                        }
                    }
                }
            },
            findTreeParent(v_obj, v_id) {
                for (var i = 0; i < v_obj.children.length; i++) {
                    if (v_obj.children[i].data.id === v_id) {
                        return v_obj;
                    } else {
                        var obj = this.findTreeParent(v_obj.children[i], v_id);
                        if (obj !== null) {
                            return obj;
                        }
                    }
                }
                return null;
            },
            postData() {
                console.log("postData");
                this.butLoading = true;
                if (this.treedata.needselect) {
                    requests.plat_requests.post(
                            '/calf/plat/NewFlowEngineFeedBack?appkey=' + sessionStorage.getItem(
                                "appkey"), {
                                unique: this.flowinfo.unique,
                                workflowid: this.flowinfo.workflowid,
                                paraStr: escape(JSON.stringify(
                                    this.treedata))
                            }).then(res => {
                            this.butLoading = false;
                            console.log(res);
                            if (res.errcode == "0") {
                                MessageTip.successmsg('提交成功');
                                console.log("callreturn--ok");
                                this.flowFreshListMethod();
                                this.butShow = false;
                                this.closeWinowMehod();
                            } else {
                                this.butShow = true;
                            }
                        })
                        .catch(function () {
                            this.butLoading = false;
                            this.butShow = true;
                        });
                } else {
                    requests.plat_requests.post(
                            '/calf/plat/FlowSubmitEngine?appkey=' + sessionStorage.getItem(
                                "appkey"), {
                                unique: this.flowinfo.unique,
                                workflowid: this.flowinfo.workflowid,
                            }).then(res => {
                            this.butLoading = false;
                            console.log(res);
                            if (res.errcode == "0") {
                                MessageTip.successmsg('提交成功');
                                console.log("callreturn--ok");
                                this.flowFreshListMethod();
                                this.butShow = false;
                                this.closeWinowMehod();
                            } else {
                                this.butShow = true;
                            }
                        })
                        .catch(function () {
                            this.butLoading = false;
                            this.butShow = true;
                        });
                }
            },
            onLoad() {
                console.log("onLoad--child");
                var id = this.id;
                var boxid = this.boxid;
                var workflowid = this.workflowid;
                this.flowinfo.id = id;
                this.flowinfo.boxid = boxid;
                this.flowinfo.workflowid = workflowid;

                requests.plat_requests.post(
                    '/calf/plat/FlowEngineMvc?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        id: this.flowinfo.id,
                        boxid: this.flowinfo.boxid,
                        workflowid: this.flowinfo.workflowid,
                    }).then(res => {
                    console.log('FlowEngineMvc...');
                    if (res.data) {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        if (retObj.errcode == "0") {
                            this.flowinfo.unique = retObj.unique;
                            this.treedata = ref(retObj.data);
                            this.butShow = retObj.data.butshow;
                            console.log(retObj.data);
                        } else {
                            MessageTip.errmsg(retObj.err);
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep(.p-organizationchart) {
        .p-person {
            padding: 0;
            border: 0 none;
        }

        .node-header,
        .node-content {
            padding: .5em .7rem;
            min-width: 200px;
        }

        .node-header {
            background-color: #495ebb;
            color: #ffffff;
        }

        .node-content {
            text-align: center;
            border: 1px solid #495ebb;
        }

        .node-content img {
            border-radius: 50%;
        }

        .department-cfo {
            background-color: #7247bc;
            color: #ffffff;
        }

        .department-coo {
            background-color: #a534b6;
            color: #ffffff;
        }

        .department-cto {
            background-color: #e9286f;
            color: #ffffff;
        }
    }
</style>
<style lang="scss" scoped>
    ::v-deep(.p-scrollpanel) {
        p {
            padding: .5rem;
            line-height: 1.5;
            margin: 0;
        }

        &.custombar1 {
            .p-scrollpanel-wrapper {
                border-right: 9px solid var(--surface-b);
            }

            .p-scrollpanel-bar {
                background-color: var(--primary-color);
                opacity: 1;
                transition: background-color .2s;

                &:hover {
                    background-color: #007ad9;
                }
            }
        }
    }
</style>