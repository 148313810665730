<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" @topbar-menu-onShowSet="onShowSetToggle" @tab-change="onMoudleTabChange"
            :model="tabdata" />
        <div v-if="menutype!='1'" class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>
        <div v-if="menutype!='1'" class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <div v-if="menutype=='1'">
            <router-view />
        </div>

        <AppConfig ref="appconfig" :layoutMode="layoutMode" :layoutColorMode="layoutColorMode"
            @layout-change="onLayoutChange" @layout-color-change="onLayoutColorChange" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
    import requests from './service/newaxios';
    import * as Base64 from 'js-base64';
    import AppTopBar from './AppTopbar.vue';
    import AppMenu from './AppMenu.vue';
    import AppConfig from './AppConfig.vue';
    import AppFooter from './AppFooter.vue';

    export default {
        data() {
            return {
                layoutMode: 'static',
                layoutColorMode: 'light',
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
                activeIndex: 0,
                tabdata: [],
                tabdataclone: [],
                menu: [],
                menutype: '',
            }
        },
        created() {
            document.documentElement.style.fontSize = '12px';
            this.loadMoudleData();
            const body = window.document.getElementsByTagName('body')[0];
            body.style.overflow = 'hidden';
        },
        watch: {
            $route() {
                if (this.tabdata.length > 0) {
                    console.log('fullPath:' + this.$route.fullPath);
                    if (this.$route.fullPath.indexOf('/crmproduct/productlist') >= 0) {
                        this.staticMenuInactive = false;
                        this.onMenuToggle();
                    } else {
                        this.staticMenuInactive = true;
                        this.onMenuToggle();
                    }
                    this.tabdata[this.activeIndex].to = this.$route.fullPath;
                }
                this.menuActive = false;
                this.$toast.removeAllGroups();
            }
        },
        methods: {
            loadMoudleData() {
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermodulelist{id label to icon menutype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.tabdata = JSON.parse(unescape(Base64.decode(res.data))).data.usermodulelist;
                        this.tabdataclone = JSON.parse(JSON.stringify(this.tabdata));
                        this.loadMenuData();
                    }
                });
            },
            loadMenuData() {
                /* var routerArray = window.router.currentRoute._rawValue.path.split('/');
                var router = '';
                if (routerArray.length >= 2) {
                    router = '/' + routerArray[1];
                } */
                var menulistwhere = {
                    pageindex: 1,
                    pagesize: 200,
                    conditions: [{
                        name: 'parentid',
                        value: this.tabdata[this.activeIndex].id,
                        operation: '=',
                    }]
                };
                console.log("menutype:" + this.tabdata[this.activeIndex].menutype);
                this.menutype = this.tabdata[this.activeIndex].menutype;
                if (this.menutype != "1") {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{usermenulist(where:" + JSON.stringify(menulistwhere) +
                        "){id label to icon items {id label to icon}}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.menu = JSON.parse(unescape(Base64.decode(res.data))).data.usermenulist;
                            console.log(this.menu);
                        }
                    });
                }
            },
            onMoudleTabChange(event) {
                this.tabdata[this.activeIndex].to = this.tabdataclone[this.activeIndex].to;
                this.activeIndex = event.index;
                this.loadMenuData();
            },
            onWrapperClick() {
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }

                this.menuClick = false;
            },
            onShowSetToggle(event) {
                console.log(this.$refs.appconfig);
                this.$refs.appconfig.showSetClick(event);
            },
            onMenuToggle(event) {
                console.log('onMenuToggle...');
                this.menuClick = true;

                if (this.isDesktop()) {
                    if (this.layoutMode === 'overlay') {
                        if (this.mobileMenuActive === true) {
                            this.overlayMenuActive = true;
                        }

                        this.overlayMenuActive = !this.overlayMenuActive;
                        this.mobileMenuActive = false;
                    } else if (this.layoutMode === 'static') {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                } else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }
                console.log(this.overlayMenuActive);
                if (event)
                    event.preventDefault();
            },
            onSidebarClick() {
                this.menuClick = true;
            },
            onMenuItemClick(event) {
                if (event.item && !event.item.items) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }
            },
            onLayoutChange(layoutMode) {
                this.layoutMode = layoutMode;
            },
            onLayoutColorChange(layoutColorMode) {
                this.layoutColorMode = layoutColorMode;
            },
            addClass(element, className) {
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            },
            removeClass(element, className) {
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join(
                        '|') + '(\\b|$)', 'gi'), ' ');
            },
            isDesktop() {
                return window.innerWidth >= 992;
            },
            isSidebarVisible() {
                if (this.isDesktop()) {
                    if (this.layoutMode === 'static')
                        return !this.staticMenuInactive;
                    else if (this.layoutMode === 'overlay')
                        return this.overlayMenuActive;
                }

                return true;
            }
        },
        computed: {
            containerClass() {
                return ['layout-wrapper', {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                    'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': this.mobileMenuActive,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                    'layout-theme-light': this.$appState.theme.startsWith('saga')
                }];
            },
            logo() {
                return (this.layoutColorMode === 'dark') ? "images/logo-white.svg" : "images/logo.svg";
            }
        },
        beforeUpdate() {
            if (this.mobileMenuActive)
                this.addClass(document.body, 'body-overflow-hidden');
            else
                this.removeClass(document.body, 'body-overflow-hidden');
        },
        components: {
            'AppTopBar': AppTopBar,
            'AppMenu': AppMenu,
            'AppConfig': AppConfig,
            'AppFooter': AppFooter,
        }
    }
</script>

<style lang="scss">
    @import './App.scss';
</style>