const ShipmentBoxJs = {
    initDetail() {
        console.log('ShipmentBoxJs---initDetail...');
        this.childNewDataConfig = {
            products: false,
            files: false,
            cost: true,
        };
        this.dropOtherConfig = {};
        this.childTableFooter = {
            cost: {
                costtype: '合计',
                costamount: 0,
                invoiceamount: 0,
            },
            products: {
                factoryname: '合计',
                quantity: 0,
                sharingcost: 0,
            }
        };
        this.childaloneshowConfig = {
            cost: '1'
        };
        return {
            'childNewDataConfig': this.childNewDataConfig,
            'dropOtherConfig': this.dropOtherConfig,
            'childTableFooter': this.childTableFooter,
            'childaloneshowConfig': this.childaloneshowConfig
        }
    },
    cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
        v_alonechildelements, v_elements, v_volumeratio) {
        console.log('ShipmentBoxJs--cellEditCompleteMethod..');
        console.log(field);
        console.log(data);
        console.log(rowdata);
        console.log(childTableFooterObj);
        console.log(v_childelements);
        console.log(v_alonechildelements);
        console.log(v_elements);
        console.log(v_volumeratio);
        if (tablename != 'products' && tablename != 'cost') {
            return;
        }
        var productsdata = [];
        var purchaseExchangeRate = 0;
        var rateProfit = 0;
        var fOBExchangeRate = 0;
        for (var k1 = 0; k1 < v_elements.length; k1++) {
            if (v_elements[k1]['name'] == 'purchaseexchangerate') {
                purchaseExchangeRate = Number(v_elements[k1]['val']);
            }
            if (v_elements[k1]['name'] == 'rateprofit') {
                rateProfit = Number(v_elements[k1]['val']);
            }
            if (v_elements[k1]['name'] == 'fobexchangerate') {
                fOBExchangeRate = Number(v_elements[k1]['val']);
            }
        }
        for (var i = 0; i < v_childelements.length; i++) {
            if (v_childelements[i]['name'] == 'products') {
                productsdata = v_childelements[i]['rows'];
                break;
            }
        }
        if (tablename == 'products') {
            this.productsvolumecalculate(rowdata, v_volumeratio);
        }
        if (tablename == 'cost') {
            this.costcalculate(rowdata);
        }
        var volumesummary = this.getAllVolume(productsdata);
        var allcost = this.getAllCost(v_childelements);
        var sharingcostsum = 0;
        for (var k4 = 0; k4 < productsdata.length; k4++) {
            if (k4 == productsdata.length - 1) {
                productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
            } else {
                this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
            }
            this.productsfobpricecalculate(productsdata[k4], purchaseExchangeRate, rateProfit,
                fOBExchangeRate);
        }
        this.calculateCostProductPart(productsdata);
    },
    getAllVolume(productsdata) {
        var volumesummary = 0;
        for (var k2 = 0; k2 < productsdata.length; k2++) {
            var tempnum2 = Number(productsdata[k2]['volume']);
            var quantitynum2 = Number(productsdata[k2]['quantity']);
            if (!isNaN(tempnum2) && !isNaN(quantitynum2)) {
                volumesummary = volumesummary + tempnum2 * quantitynum2;
            }
        }
        return volumesummary;
    },
    getAllCost(v_allchildements) {
        var allcost = 0;
        for (var k = 0; k < v_allchildements.length; k++) {
            if (v_allchildements[k]['name'] == 'cost') {
                for (var k1 = 0; k1 < v_allchildements[k].rows.length; k1++) {
                    var costamount = Number(v_allchildements[k].rows[k1]['costamount']);
                    //var exchangerate = Number(v_allchildements[k].rows[k1]['exchangerate']);
                    if (!isNaN(costamount)) {
                        allcost = allcost + costamount;
                    }
                }
                break;
            }
        }
        return allcost;
    },
    productsvolumecalculate(rowdata, v_volumeratio) {
        var volumeratio = 100000;
        if (v_volumeratio) {
            volumeratio = Number(v_volumeratio);
        }
        var lengthnum = Number(rowdata['length']);
        var heightnum = Number(rowdata['height']);
        var widthnum = Number(rowdata['width']);
        if (!isNaN(lengthnum) && !isNaN(heightnum) && !isNaN(widthnum)) {
            rowdata['volume'] = (lengthnum * heightnum * widthnum / volumeratio).toFixed(4);
        }
        console.log('productsvolumecalculate..' + rowdata['volume']);
    },
    costcalculate(rowdata) {
        var taxratenum = Number(rowdata['taxrate']);
        var invoiceamountnum = Number(rowdata['invoiceamount']);
        if (!isNaN(taxratenum) && !isNaN(invoiceamountnum)) {
            rowdata['costamount'] = invoiceamountnum * (1 - taxratenum).toFixed(2);
        }
        console.log('costcalculate..' + rowdata['costamount']);
    },
    productssharingcostcalculate(rowdata, volumesummary, allcost) {
        var volume = Number(rowdata['volume']);
        var quantitynum = Number(rowdata['quantity']);
        rowdata['sharingcost'] = 0;
        if (!isNaN(volume) && !isNaN(quantitynum) && !isNaN(allcost)) {
            if (quantitynum > 0 && volumesummary > 0 && allcost > 0) {
                rowdata['sharingcost'] = (((volume * quantitynum) / volumesummary) * allcost).toFixed(2);
            }
        }
        console.log('productssharingcostcalculate..' + rowdata['sharingcost']);
    },
    calculateCostProductPart(productsdata) {
        console.log('calculateCostProductPart..');
        console.log(productsdata);
        for (var k = 0; k < productsdata.length; k++) {
            if (productsdata[k]['parts']) {
                var volumenum = Number(productsdata[k]['volume']);
                var sharingcostnum = Number(productsdata[k]['sharingcost']);
                var fobpricenum = Number(productsdata[k]['fobprice']);
                if (isNaN(sharingcostnum)) sharingcostnum = 0;
                if (!isNaN(volumenum) && (!isNaN(sharingcostnum) || !isNaN(fobpricenum))) {
                    var sharingcostnum1 = 0;
                    var fobpricenum1 = 0;
                    for (var k1 = 0; k1 < productsdata[k]['parts'].length; k1++) {
                        var volumenum1 = Number(productsdata[k]['parts'][k1]['volume']);
                        var matchingnum1 = Number(productsdata[k]['parts'][k1]['matching']);
                        if (k1 != productsdata[k]['parts'].length - 1) {
                            if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(sharingcostnum)) {
                                var sharingcostnum2 = (sharingcostnum * ((volumenum1 *
                                    matchingnum1) / volumenum)).toFixed(2);
                                productsdata[k]['parts'][k1]['sharingcost'] = sharingcostnum2;
                                sharingcostnum1 = sharingcostnum1 + Number(sharingcostnum2);
                            }
                            if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(fobpricenum)) {
                                var fobpricenum2 = (fobpricenum * ((volumenum1 *
                                    matchingnum1) / volumenum)).toFixed(2);
                                productsdata[k]['parts'][k1]['fobprice'] = fobpricenum2;
                                fobpricenum1 = fobpricenum1 + Number(fobpricenum2);
                            }

                        } else {
                            if (k1 == productsdata[k]['parts'].length - 1) {
                                productsdata[k]['parts'][k1]['sharingcost'] = (sharingcostnum - sharingcostnum1).toFixed(2);
                                productsdata[k]['parts'][k1]['fobprice'] = (fobpricenum - fobpricenum1).toFixed(2);
                            }
                        }
                    }
                }
            }
        }
    },
    productsfobpricecalculate(rowdata, purchaseExchangeRate, rateProfit, fOBExchangeRate) {
        var quantitynum = Number(rowdata['quantity']);
        var unitprice = Number(rowdata['unitprice']);
        var sharingcost = Number(rowdata['sharingcost']);
        var taxrebates = Number(rowdata['taxrebates']);
        if (isNaN(taxrebates) || taxrebates == -1) {
            taxrebates = 0;
        }
        if (isNaN(sharingcost)) {
            sharingcost = 0;
        }
        if (isNaN(rateProfit)) {
            rateProfit = 0;
        }
        console.log('quantitynum..' + quantitynum);
        console.log('unitprice..' + unitprice);
        console.log('sharingcost..' + sharingcost);
        console.log('purchaseExchangeRate..' + purchaseExchangeRate);
        console.log('rateProfit..' + rateProfit);
        console.log('fOBExchangeRate..' + fOBExchangeRate);
        rowdata['fobprice'] = 0;
        if (!isNaN(unitprice) && !isNaN(sharingcost) && !isNaN(quantitynum) && !isNaN(
                purchaseExchangeRate) && !isNaN(rateProfit) && !isNaN(fOBExchangeRate)) {
            if (fOBExchangeRate > 0 && quantitynum > 0) {
                console.log("(((" + unitprice + "*" + purchaseExchangeRate + "/(" + taxrebates + "1)+" + sharingcost + "*" + purchaseExchangeRate + "/" +
                    quantitynum +
                    ")" + "*" + "(1" + "+" + rateProfit + "))/" + fOBExchangeRate + ")");
                rowdata['fobprice'] = (((unitprice * purchaseExchangeRate / (taxrebates + 1) + sharingcost * purchaseExchangeRate / quantitynum) * (1 +
                    rateProfit)) / fOBExchangeRate).toFixed(2);
            }
        }
        console.log('productsfobpricecalculate..' + rowdata['fobprice']);
    },
    loadDataAfter(v_mainelements, v_childelements, v_originalData, currentRow, currencyDict, v_childExpandeConfig) {
        console.log('ShipmentBoxJs--loadDataAfter..');
        console.log(v_mainelements);
        console.log(v_childelements);
        console.log(v_originalData);
        if (currentRow && currentRow['id'] != '-1') {
            for (var k = 0; k < v_mainelements.length; k++) {
                if (v_mainelements[k]['name'] == 'purchasecurrency') {
                    this.purchasecurrencyChange(v_childelements, v_mainelements[k]['val'], currencyDict, v_childExpandeConfig);
                }
                if (v_mainelements[k]['name'] == 'fobcurrency') {
                    this.fobcurrencyChange(v_childelements, v_mainelements[k]['val'], currencyDict, v_childExpandeConfig);
                }
            }
        }
    },
    importAddQueryCondition(v_listwhere) {
        v_listwhere.conditions.push({
            name: 'purchasetoship',
            value: '',
            operation: '=',
        });
        return v_listwhere;
    },
    importAddCols(v_cols, v_graphqlFeild) {
        console.log('importAddCols:1');
        console.log(v_cols);
        v_graphqlFeild = v_graphqlFeild + ' factoryname purchaseno currency';
        v_cols.unshift({
            name: 'factoryname',
            label: '工厂简称',
            type: 'LabelString',
            dictName: '',
            dictLabel: '',
            dictCode: '',
            dictCode2: '',
            dictCode3: '',
        });
        v_cols.unshift({
            name: 'purchaseno',
            label: '采购编号',
            type: 'LabelString',
            dictName: '',
            dictLabel: '',
            dictCode: '',
            dictCode2: '',
            dictCode3: '',
        });
        console.log('importAddCols:2');
        console.log(v_cols);
        return v_graphqlFeild;
    },
    fobcurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        var isbreak = false;
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'fobprice') {
                    v_childelements[i].columns[i2].header = 'FOB单价(' + ename + ')';
                    isbreak = true;
                    break;
                }
            }
            if (isbreak) {
                break;
            }
        }
        for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'fobprice') {
                v_childExpandeConfig.products.config[0].columns[i4].header = 'FOB单价(' + ename + ')';
                break;
            }
        }
    },
    purchasecurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
        var ename = '';
        for (var i3 = 0; i3 < v_dictsource.length; i3++) {
            if (v_dictsource[i3].code == v_val) {
                ename = v_dictsource[i3].ename;
                break;
            }
        }
        console.log('purchasecurrencyChange....1..');
        console.log(v_childelements);
        var isbreak = false;
        var loopindex = 0;
        for (var i = 0; i < v_childelements.length; i++) {
            for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                if (v_childelements[i].columns[i2].field == 'unitprice') {
                    v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                    loopindex++;
                }
                if (v_childelements[i].columns[i2].field == 'sharingcost') {
                    v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                    loopindex++;
                }
                if (loopindex == 2) {
                    isbreak = true;
                    break;
                }
            }
            if (isbreak) {
                break;
            }
        }
        for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
            }
            if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
            }
        }
    },
};

export default ShipmentBoxJs;