<template>
    <div>
        <Toast />
    </div>
</template>
<script>
    import {
        useToast
    } from 'primevue/usetoast';
    export default {
        name: 'messageTipVue',
        setup() {
            const dialogConfig = {};
            const toast = useToast();
            const errmsg = (v_message) => {
                toast.add({
                    severity: 'error',
                    summary: '异常消息',
                    detail: v_message,
                    life: 60000,
                });
                console.log("errmsg:" + v_message);
            };
            const warnmsg = (v_message) => {
                toast.add({
                    severity: 'warn',
                    summary: '警告消息',
                    detail: v_message,
                    life: 8000,
                });
            };
            const infomsg = (v_message) => {
                toast.add({
                    severity: 'info',
                    summary: '提示消息',
                    detail: v_message,
                    life: 5000,
                });
            };
            const successmsg = (v_message) => {
                toast.add({
                    severity: 'success',
                    summary: '成功消息',
                    detail: v_message,
                    life: 3000,
                });
            };
            const dialogmsg = (v_message, v_closeAfterMethod) => {
                if (!dialogConfig['showrun']) {
                    dialogConfig['showrun'] = true;
                    dialogConfig['closeAfterMethod'] = v_closeAfterMethod;
                    console.log('dialogmsg...');
                    console.log(dialogConfig['showBut']);
                    console.log(dialogConfig['closeAfterMethod']);
                    toast.add({
                        severity: 'info',
                        summary: '提示消息',
                        detail: v_message,
                        closable: false,
                        group: 'dialogtoast'
                    });
                }
            };
            const onClose = () => {
                dialogConfig['showrun'] = false;
                toast.removeAllGroups();
                if (dialogConfig['closeAfterMethod']) {
                    dialogConfig['closeAfterMethod']();
                }
            };
            const initMsg = () => {
                dialogConfig['showrun'] = false;
                toast.removeAllGroups();
            };
            return {
                errmsg,
                warnmsg,
                infomsg,
                successmsg,
                dialogmsg,
                onClose,
                initMsg
            }
        },
    }
</script>