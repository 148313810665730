<template>
    <Dropdown v-model="selectedItem" :id="id" :filter="true" :loading="loading" :options="dictItems"
        @filter="dropFilter" @change="dropchange" optionLabel="name" optionValue="code" showClear="true">
        <template #option="slotProps">
            <div>
                <div v-if="slotProps.option.comment!=''">{{slotProps.option.name}}--{{slotProps.option.comment}}</div>
                <div v-if="slotProps.option.comment==''">{{slotProps.option.name}}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script>
    import requests from '../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from './Message';
    export default {
        name: 'CalfDropdown',
        emits: ['update:selectedValue', 'update:selectedTxt'],
        created() {
            this.loadInit();
        },
        props: ['selectedValue', 'selectedTxt', 'dropchangeAfter', 'dictObjName', 'id', 'loadAll', 'childtablename',
            'rowid', 'currentdata'
        ],
        data() {
            return {
                loading: true,
                selectedItem: null,
                dictItems: []
            }
        },
        methods: {
            loadInit() {
                this.loadDict();
            },
            dropchange(v_event) {
                this.dropchangeval(v_event.value);
            },
            dropchangeval(v_val) {
                console.log('dropchangeval...');
                console.log(v_val);
                if (!v_val) v_val = "-1";
                var txt = '';
                var other = '';
                for (var i = 0; i < this.dictItems.length; i++) {
                    if (this.dictItems[i].code == v_val) {
                        txt = this.dictItems[i].name;
                        if (this.dictItems[i]['other']) {
                            other = this.dictItems[i]['other'];
                        }
                        break;
                    }
                }
                this.$emit('update:selectedValue', v_val);
                this.$emit('update:selectedTxt', txt);
                if (this.dropchangeAfter) {
                    console.log('dropchangeAfter:');
                    console.log(this.dictItems);
                    this.dropchangeAfter(this.id, other, this.childtablename, this.currentdata, v_val, this.dictItems);
                }
                console.log(txt);
                console.log('other:' + other);
                console.log(this.otherValue);
            },
            dropFilter(v_event) {
                console.log(v_event);
                if (!this.loadAll) {
                    this.loading = false;
                    var inputTxt = v_event.value;
                    if (inputTxt != '' && !this.loadAll) {
                        this.loadDict(inputTxt);
                    }
                }
            },
            findDictExist(v_code) {
                for (var i = 0; i < this.dictItems.length; i++) {
                    if (this.dictItems[i].code == v_code) {
                        return true;
                    }
                }
                return false;
            },
            loadDict(inputTxt) {
                var dictObjarray = this.dictObjName.split('^');
                var dictName = dictObjarray[0];
                var dictIdentify = dictName;
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                if (!this.loadAll && inputTxt && inputTxt != '') {
                    dictlistwhere.conditions.push({
                        name: 'name',
                        value: inputTxt,
                        operation: 'like'
                    });
                }
                if (dictObjarray.length > 1) {
                    dictlistwhere.conditions.push({
                        name: 'Categories',
                        value: dictObjarray[1],
                        operation: '='
                    });
                    dictIdentify = dictName + dictObjarray[1];
                }
                var dictCacheData = this.loadDictFromCache(dictName, dictIdentify);
                if (this.loadAll && dictCacheData && dictCacheData.length > 0) {
                    console.log('dictIdentify:' + dictIdentify);
                    this.dictItems = JSON.parse(dictCacheData);
                    console.log(this.dictItems);
                    this.initSelect();
                    this.loading = false;
                } else {
                    var graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                        '){name code comment }}';
                    if (dictName == 'dictionary') {
                        graphql = '{' + dictName + 'list(where:' + JSON.stringify(dictlistwhere) +
                            '){name details {name code other ename child {name code other ename}}}}';
                    }
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            var dictData = dataObj[dictName + 'list'];
                            if (dictName == 'dictionary') {
                                for (var k1 = 0; k1 < dictData[0].details.length; k1++) {
                                    console.log('dictionary-name1:' + dictData[0].details[k1].name);
                                    if (!this.findDictExist(dictData[0].details[k1].code)) {
                                        console.log('dictionary-name2:' + dictData[0].details[k1].name);
                                        this.dictItems.push({
                                            name: dictData[0].details[k1].name,
                                            code: dictData[0].details[k1].code,
                                            other: dictData[0].details[k1].other,
                                            ename: dictData[0].details[k1].ename,
                                            comment: '',
                                            child: dictData[0].details[k1]['child']
                                        });
                                    }
                                }
                            } else {
                                for (var k = 0; k < dictData.length; k++) {
                                    if (!this.findDictExist(dictData[k].code)) {
                                        this.dictItems.push({
                                            name: dictData[k].name,
                                            code: dictData[k].code,
                                            comment: dictData[k].comment,
                                            other: '',
                                        });
                                    }
                                }
                            }
                            sessionStorage.setItem(dictIdentify, JSON.stringify(this.dictItems));
                            console.log(this.dictItems);
                            this.initSelect();
                        } else {
                            MessageTip.warnmsg(dictName + '字典数据加载失败');
                        }
                    });
                }
            },
            initSelect() {
                console.log('initSelect:' + this.dictObjName);
                if (this.selectedValue != null && this.selectedValue != '') {
                    if (this.selectedTxt && this.selectedTxt != '') {
                        if (!this.findDictExist(this.selectedValue)) {
                            this.dictItems.push({
                                name: this.selectedTxt,
                                code: this.selectedValue,
                                comment: '',
                                other: '',
                            });
                        }
                    }
                    console.log('initSelect:selectedValue--');
                    console.log(this.selectedValue);
                    this.selectedItem = this.selectedValue;
                }
            },
            freshSelect(v_val) {
                console.log('freshSelect:' + v_val);
                this.selectedItem = v_val;
                this.dropchangeval(v_val);
            },
            loadDictFromCache(dictName, dictIdentify) {
                var dictCacheData = sessionStorage.getItem(dictIdentify);
                if (!dictCacheData || dictCacheData.length == 0) {
                    dictCacheData = sessionStorage.getItem(dictName);
                }
                return dictCacheData;
            }
        }
    }
</script>