import {
    createRouter,
    createWebHashHistory
} from 'vue-router';
import Dashboard from './components/Dashboard.vue';
import UserManaged from './pages/home/UserManaged.vue';
import mapp from './MainApp.vue';
import login from './Login.vue';
import universalPageList from './components/UniversalPageList.vue';

const routes = [{
        path: '/home',
        name: 'home',
        component: mapp,
        children: [{
            path: 'usermanagement',
            name: 'usermanagement',
            component: UserManaged
        }, {
            path: 'setpassword',
            name: 'setpassword',
            component: () => import('./pages/home/ModifyPsd.vue')
        }, {
            path: 'permissionsetting',
            name: 'permissionsetting',
            component: () => import('./pages/home/PermissionSetting.vue')
        }, {
            path: 'generalapprovalset',
            name: 'generalapprovalset',
            component: () => import('./pages/home/GeneralApprovalSet.vue')
        }, {
            path: 'exceltemplateset',
            name: 'exceltemplateset',
            component: () => import('./pages/home/ExcelTemplateSet.vue')
        }, {
            path: 'wordtemplateset',
            name: 'wordtemplateset',
            component: () => import('./pages/home/WordTemplateSet.vue')
        }, {
            path: 'numberruler',
            name: 'numberruler',
            component: () => import('./pages/home/AutoNumberSet.vue')
        }, {
            path: 'generaldictset',
            name: 'generaldictset',
            component: () => import('./pages/home/GeneralDictSet.vue')
        }, {
            path: 'flowmoniterlist',
            name: 'flowmoniterlist',
            component: () => import('./pages/home/ProcessMonitor.vue')
        }, {
            path: 'dataimportconfigset',
            name: 'dataimportconfigset',
            component: () => import('./pages/home/DataImportConfigSet.vue')
        }, {
            path: 'hometodo',
            name: 'hometodo',
            component: () => import('./pages/TaskBox.vue')
        }, {
            path: 'homehistory',
            name: 'homehistory',
            component: () => import('./pages/HistoryBox.vue')
        }, {
            path: 'storecostrule',
            name: 'storecostrule',
            component: () => import('./pages/crm/basedata/StoreCostRule.vue')
        }, {
            path: 'flowdefine',
            name: 'flowdefine',
            component: () => import('./pages/home/FlowDefine.vue')
        }, {
            path: 'myhome',
            name: 'myhome',
            component: Dashboard
        }]
    },
    {
        path: '/renshi',
        name: 'renshi',
        component: mapp,
        children: [{
            path: 'renshibox',
            name: 'renshibox',
            component: () => import('./pages/TaskBox.vue')
        }, {
            path: 'renshihistory',
            name: 'renshihistory',
            component: () => import('./pages/HistoryBox.vue')
        }, {
            path: 'leaveinfolist',
            name: 'leaveinfolist',
            component: universalPageList
        }, {
            path: 'changepostlist',
            name: 'changepostlist',
            component: universalPageList
        }]
    },
    {
        path: '/caiwu',
        name: 'caiwu',
        component: mapp,
        children: [{
            path: 'caiwubox',
            name: 'caiwubox',
            component: () => import('./pages/TaskBox.vue')
        }, {
            path: 'caiwuhistory',
            name: 'caiwuhistory',
            component: () => import('./pages/HistoryBox.vue')
        }, {
            path: 'purchaselist',
            name: 'purchaselist',
            component: () => import('./components/UniversalPageList.vue')
        }, {
            path: 'internalaccountlist',
            name: 'internalaccountlist',
            component: () => import('./components/UniversalPageList.vue')
        }, {
            path: 'budgetlist',
            name: 'budgetlist',
            component: () => import('./components/UniversalPageList.vue')
        }, {
            path: 'budgetchangelist',
            name: 'budgetchangelist',
            component: () => import('./components/UniversalPageList.vue')
        }, {
            path: 'budgetcontrollist',
            name: 'budgetcontrollist',
            component: () => import('./components/UniversalPageList.vue')
        }, ]
    },
    {
        path: '/zhaopin',
        name: 'zhaopin',
        component: mapp,
        children: [{
            path: 'zhaopinbox',
            name: 'zhaopinbox',
            component: () => import('./pages/TaskBox.vue')
        }, {
            path: 'zhaopinhistory',
            name: 'zhaopinhistory',
            component: () => import('./pages/HistoryBox.vue')
        }]
    },
    {
        path: '/pingu',
        name: 'pingu',
        component: mapp,
        children: [{
            path: 'pingubox',
            name: 'pingubox',
            component: () => import('./pages/TaskBox.vue')
        }, {
            path: 'pinguhistory',
            name: 'pinguhistory',
            component: () => import('./pages/HistoryBox.vue')
        }]
    },
    {
        path: '/crmcustom',
        name: 'crmcustom',
        component: mapp,
        children: [{
            path: 'publiccustomlist',
            name: 'publiccustomlist',
            component: () => import('./pages/crm/custom/PublicCustom.vue')
        }, {
            path: 'othercustomlist',
            name: 'othercustomlist',
            component: () => import('./pages/crm/custom/OtherCustom.vue')
        }, {
            path: 'mycustomlist',
            name: 'mycustomlist',
            component: () => import('./pages/crm/custom/MyCustom.vue')
        }, {
            path: 'sharecustomlist',
            name: 'sharecustomlist',
            component: () => import('./pages/crm/custom/ShareCustom.vue')
        }]
    },
    {
        path: '/crmproduct',
        name: 'crmproduct',
        component: mapp,
        children: [{
            path: 'productlist',
            name: 'productlist',
            component: () => import('./pages/crm/product/Product.vue')
        }, {
            path: 'productsamplelist',
            name: 'productsamplelist',
            component: () => import('./pages/crm/productsample/ProductSample.vue')
        }]
    },
    {
        path: '/crmpurchase',
        name: 'crmpurchase',
        component: mapp,
        children: [{
            path: 'purchasedraftbox',
            name: 'purchasedraftbox',
            component: () => import('./pages/crm/purchase/PurchaseDraftBox.vue')
        }, {
            path: 'purchasebox',
            name: 'purchasebox',
            component: () => import('./pages/crm/purchase/PurchaseBox.vue')
        }, {
            path: 'purchaseapprovedbox',
            name: 'purchaseapprovedbox',
            component: () => import('./pages/crm/purchase/PurchaseApprovedBox.vue')
        }, {
            path: 'purchaserubbishbox',
            name: 'purchaserubbishbox',
            component: () => import('./pages/crm/purchase/PurchaseRubbishBox.vue')
        }, {
            path: 'waitpurchasebox',
            name: 'waitpurchasebox',
            component: () => import('./pages/crm/purchase/WaitPurchaseBox.vue')
        }, {
            path: 'purchasehistorybox',
            name: 'purchasehistorybox',
            component: () => import('./pages/crm/purchase/PurchaseHistoryBox.vue')
        }, {
            path: 'inproductionpurchase',
            name: 'inproductionpurchase',
            component: () => import('./pages/crm/purchase/InProductionPurchase.vue')
        }, {
            path: 'enroutepurchase',
            name: 'enroutepurchase',
            component: () => import('./pages/crm/purchase/EnroutePurchase.vue')
        }, {
            path: 'putawaypurchase',
            name: 'putawaypurchase',
            component: () => import('./pages/crm/purchase/PutAwayPurchase.vue')
        }]
    },
    {
        path: '/crmshipment',
        name: 'crmshipment',
        component: mapp,
        children: [{
            path: 'shipmentdraftbox',
            name: 'shipmentdraftbox',
            component: () => import('./pages/crm/shipment/ShipmentDraftBox.vue')
        }, {
            path: 'shipmentbox',
            name: 'shipmentbox',
            component: () => import('./pages/crm/shipment/ShipmentBox.vue')
        }, {
            path: 'shipmentapprovedbox',
            name: 'shipmentapprovedbox',
            component: () => import('./pages/crm/shipment/ShipmentApprovedBox.vue')
        }, {
            path: 'shipmentrubbishbox',
            name: 'shipmentrubbishbox',
            component: () => import('./pages/crm/shipment/ShipmentRubbishBox.vue')
        }, {
            path: 'shipmenthistorybox',
            name: 'shipmenthistorybox',
            component: () => import('./pages/crm/shipment/ShipmentHistoryBox.vue')
        }]
    },
    {
        path: '/crmputinstorage',
        name: 'crmputinstorage',
        component: mapp,
        children: [{
            path: 'putinstoragebox',
            name: 'putinstoragebox',
            component: () => import('./pages/crm/putinstorage/PutInStorageBox.vue')
        }, {
            path: 'putinstorageapprovedbox',
            name: 'putinstorageapprovedbox',
            component: () => import('./pages/crm/putinstorage/PutInStorageApprovedBox.vue')
        }, {
            path: 'putinstoragedraftbox',
            name: 'putinstoragedraftbox',
            component: () => import('./pages/crm/putinstorage/PutInStorageDraftBox.vue')
        }, {
            path: 'putinstoragerubbishbox',
            name: 'putinstoragerubbishbox',
            component: () => import('./pages/crm/putinstorage/PutInStorageRubbishBox.vue')
        }, {
            path: 'warehouselist',
            name: 'warehouselist',
            component: () => import('./pages/crm/putinstorage/WareHouseList.vue')
        }, {
            path: 'putinstoragehistorybox',
            name: 'putinstoragehistorybox',
            component: () => import('./pages/crm/putinstorage/PutInStorageHistoryBox.vue')
        }]
    },
    {
        path: '/crmorder',
        name: 'crmorder',
        component: mapp,
        children: [{
            path: 'orderlist',
            name: 'orderlist',
            component: () => import('./pages/crm/order/OrderList.vue')
        }, {
            path: 'finishorderlist',
            name: 'finishorderlist',
            component: () => import('./pages/crm/order/FinishOrderList.vue')
        }, {
            path: 'orderrubbishlist',
            name: 'orderrubbishlist',
            component: () => import('./pages/crm/order/OrderRubbishList.vue')
        }]
    },
    {
        path: '/crmoutstock',
        name: 'crmoutstock',
        component: mapp,
        children: [{
            path: 'outstocklist',
            name: 'outstocklist',
            component: () => import('./pages/crm/outstock/OutStockList.vue')
        }, {
            path: 'outstockrubbishlist',
            name: 'outstockrubbishlist',
            component: () => import('./pages/crm/outstock/OutStockRubbishList.vue')
        }]
    },
    {
        path: '/crmclaimsettlement',
        name: 'crmclaimsettlement',
        component: mapp,
        children: [{
            path: 'claimsettlementbox',
            name: 'claimsettlementbox',
            component: () => import('./pages/crm/claimsettlement/ClaimSettlementBox.vue')
        }, {
            path: 'claimsettlementapprovedbox',
            name: 'claimsettlementapprovedbox',
            component: () => import('./pages/crm/claimsettlement/ClaimSettlementApprovedBox.vue')
        }, {
            path: 'claimsettlementdraftbox',
            name: 'claimsettlementdraftbox',
            component: () => import('./pages/crm/claimsettlement/ClaimSettlementDraftBox.vue')
        }, {
            path: 'claimsettlementrubbishbox',
            name: 'claimsettlementrubbishbox',
            component: () => import('./pages/crm/claimsettlement/ClaimSettlementRubbishBox.vue')
        }, {
            path: 'claimsettlementhistorybox',
            name: 'claimsettlementhistorybox',
            component: () => import('./pages/crm/claimsettlement/ClaimSettlementHistoryBox.vue')
        }]
    },
    {
        path: '/crminventory',
        name: 'crminventory',
        component: mapp,
        children: [{
            path: 'inventoryputstorage',
            name: 'inventoryputstorage',
            component: () => import('./pages/crm/inventory/PutStorage.vue')
        }, {
            path: 'inventoryoutstock',
            name: 'inventoryoutstock',
            component: () => import('./pages/crm/inventory/OutStock.vue')
        }]
    },
    {
        path: '/crmdecisionanalysis',
        name: 'crmdecisionanalysis',
        component: mapp,
        children: [{
            path: 'orderoutstat',
            name: 'orderoutstat',
            component: () => import('./pages/crm/decisionanalysis/OrderOutStat.vue')
        }, {
            path: 'channelsalestat',
            name: 'channelsalestat',
            component: () => import('./pages/crm/decisionanalysis/ChannelSaleStat.vue')
        }, {
            path: 'skustat',
            name: 'skustat',
            component: () => import('./pages/crm/decisionanalysis/SkuStat.vue')
        }, {
            path: 'orderprofitsstat',
            name: 'orderprofitsstat',
            component: () => import('./pages/crm/decisionanalysis/OrderProfitsStat.vue')
        }, {
            path: 'daysaleskustat',
            name: 'daysaleskustat',
            component: () => import('./pages/crm/decisionanalysis/DaySaleSkuStat.vue')
        }, {
            path: 'inventoryvaluestat',
            name: 'inventoryvaluestat',
            component: () => import('./pages/crm/decisionanalysis/InventoryValueStat.vue')
        }, {
            path: 'storecostdaystat',
            name: 'storecostdaystat',
            component: () => import('./pages/crm/decisionanalysis/StoreCostDayStat.vue')
        }, {
            path: 'stockamountstat',
            name: 'stockamountstat',
            component: () => import('./pages/crm/decisionanalysis/StockAmountStat.vue')
        }, {
            path: 'shipamountstat',
            name: 'shipamountstat',
            component: () => import('./pages/crm/decisionanalysis/ShipAmountStat.vue')
        }, {
            path: 'salespeedstat',
            name: 'salespeedstat',
            component: () => import('./pages/crm/decisionanalysis/SaleSpeedStat.vue')
        }]
    },
    {
        path: '/crmquotation',
        name: 'crmquotation',
        component: mapp,
        children: [{
            path: 'quotationbox',
            name: 'quotationbox',
            component: () => import('./pages/crm/quotation/QuotationBox.vue')
        }, {
            path: 'quotationapprovedbox',
            name: 'quotationapprovedbox',
            component: () => import('./pages/crm/quotation/QuotationApprovedBox.vue')
        }, {
            path: 'quotationdraftbox',
            name: 'quotationdraftbox',
            component: () => import('./pages/crm/quotation/QuotationDraftBox.vue')
        }, {
            path: 'quotationrubbishbox',
            name: 'quotationrubbishbox',
            component: () => import('./pages/crm/quotation/QuotationRubbishBox.vue')
        }, {
            path: 'quotationhistorybox',
            name: 'quotationhistorybox',
            component: () => import('./pages/crm/quotation/QuotationHistoryBox.vue')
        }]
    },
    {
        path: '/crmfactory',
        name: 'crmfactory',
        component: mapp,
        children: [{
            path: 'factorydraftbox',
            name: 'factorydraftbox',
            component: () => import('./pages/crm/factory/FactoryInfoDraftBox.vue')
        }, {
            path: 'factorylist',
            name: 'factorylist',
            component: () => import('./pages/crm/factory/FactoryList.vue')
        }, {
            path: 'factoryuntreatedbox',
            name: 'factoryuntreatedbox',
            component: () => import('./pages/crm/factory/FactoryInfoBox.vue')
        }, {
            path: 'factoryhistorybox',
            name: 'factoryhistorybox',
            component: () => import('./pages/crm/factory/FactoryInfoHistoryBox.vue')
        }]
    },
    {
        path: '/crmfinancial',
        name: 'crmfinancial',
        component: mapp,
        children: [{
            path: 'financialbox',
            name: 'financialbox',
            component: () => import('./pages/crm/financial/FinancialBox.vue')
        }, {
            path: 'paymentitem',
            name: 'paymentitem',
            component: () => import('./pages/crm/financial/PaymentItem.vue')
        }, {
            path: 'paymentaccount',
            name: 'paymentaccount',
            component: () => import('./pages/crm/financial/PaymentAccount.vue')
        }, {
            path: 'collectionaccount',
            name: 'collectionaccount',
            component: () => import('./pages/crm/financial/CollectionAccount.vue')
        }, {
            path: 'historybox',
            name: 'historybox',
            component: () => import('./pages/crm/financial/FinancialHistoryBox.vue')
        }, {
            path: 'draftbox',
            name: 'draftbox',
            component: () => import('./pages/crm/financial/FinancialDraftBox.vue')
        }, {
            path: 'expensepay',
            name: 'expensepay',
            component: () => import('./pages/crm/financial/ExpensePay.vue')
        }, {
            path: 'costverification',
            name: 'costverification',
            component: () => import('./pages/crm/financial/CostVerification.vue')
        }]
    },
    {
        path: '/inspection',
        name: 'inspection',
        component: mapp,
        children: [{
            path: 'inspectionbox',
            name: 'inspectionbox',
            component: () => import('./pages/crm/inspection/InspectionBox.vue')
        }, {
            path: 'inspectionapprovedbox',
            name: 'inspectionapprovedbox',
            component: () => import('./pages/crm/inspection/InspectionApprovedBox.vue')
        }, {
            path: 'inspectionhistorybox',
            name: 'inspectionhistorybox',
            component: () => import('./pages/crm/inspection/InspectionHistoryBox.vue')
        }, {
            path: 'inspectiondraftbox',
            name: 'inspectiondraftbox',
            component: () => import('./pages/crm/inspection/InspectionDraftBox.vue')
        }]
    },
    {
        path: '/general',
        name: 'general',
        component: mapp,
        children: [{
            path: 'generallist',
            name: 'generallist',
            component: () => import('./pages/crm/general/GeneralList.vue')
        }]
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;